import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import theme from 'styles/theme';
import styled from '@emotion/styled';


const PREFIX = 'DealerDetails';

const classes = {
    basicCard: `${PREFIX}-basicCard`,
    vehicleDetail: `${PREFIX}-vehicleDetail`,
    vehicleBox: `${PREFIX}-vehicleBox`,
    vehicleTypo: `${PREFIX}-vehicleTypo`,
    dealer: `${PREFIX}-dealer`,
}

const StyledGrid = styled(Grid)(({
    theme
}) => {
    return {
        [`& .${classes.basicCard}`]: {
            background: theme.palette.background.btn
        },
        [`& .${classes.dealer}`]: {
            background: theme.palette.secondary.main
        },
        [`& .${classes.vehicleDetail}`]: {
            borderBottom: `1px solid ${theme.palette.border.default}`,
            padding: 0
        },
        [`& .${classes.vehicleBox}`]: {
            borderLeft: `4px solid ${theme.palette.secondary.main}`,
        },
        [`& .${classes.vehicleTypo}`]: {
            margin: '15px 0px 15px 15px'
        },
    }
});


const DetailsPanel = ({ vehicle }) => {
    return (
        <StyledGrid>
            <Card className={`mt-4 ${classes.basicCard}`}>
                <CardContent className={classes.vehicleDetail}>
                    <Box className={classes.vehicleBox}>
                        <Typography variant='h5' className={classes.vehicleTypo}>VEHICLE DETAILS</Typography>
                    </Box>
                </CardContent>
                <CardContent>
                    {vehicle?.vertical !== 'Powersports' &&
                        <Box className={classes.basic}>
                            <Box>
                                <Typography variant='h7' color={theme.palette.text.grey}>Stock#:</Typography>
                            </Box>
                            <Box className={classes.flex}>
                                <Typography variant='h7'>{vehicle?.stock_number}</Typography>
                            </Box>
                        </Box>}
                    <Box className={classes.basic}>
                        <Box>
                            <Typography variant='h7' color={theme.palette.text.grey}>Year:</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography variant='h7'>{vehicle?.year}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.basic}>
                        <Box>
                            <Typography variant='h7' color={theme.palette.text.grey}>Make:</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography variant='h7'>{vehicle?.make}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.basic}>
                        <Box>
                            <Typography variant='h7' color={theme.palette.text.grey}>Model:</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography variant='h7'>{vehicle?.model}</Typography>
                        </Box>
                    </Box>
                    {vehicle?.vertical === 'Auto' &&
                        <Box className={classes.basic}>
                            <Box>
                                <Typography variant='h7' color={theme.palette.text.grey}>Mileage:</Typography>
                            </Box>
                            <Box className={classes.flex}>
                                <Typography variant='h7'>{vehicle?.mileage?.toLocaleString()}</Typography>
                            </Box>
                        </Box>
                    }
                    <Box className={classes.basic}>
                        <Box>
                            <Typography variant='h7' color={theme.palette.text.grey}>Trim:</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography variant='h7'>{vehicle?.trim}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.basic}>
                        <Box>
                            <Typography variant='h7' color={theme.palette.text.grey}>Style:</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography variant='h7'>{vehicle?.vehicle_style ? vehicle?.vehicle_style : 'Not Specified'}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.basic}>
                        <Box>
                            <Typography variant='h7' color={theme.palette.text.grey}>Fuel Type:</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography variant='h7'>{vehicle?.fuel_type}</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.basic}>
                        <Box>
                            <Typography variant='h7' color={theme.palette.text.grey}>Engine:</Typography>
                        </Box>
                        <Box className={classes.flex}>
                            <Typography variant='h7'>{vehicle?.engine}</Typography>
                        </Box>
                    </Box>
                    {vehicle?.vertical === 'RV' && vehicle?.vertical === 'Powersports' &&
                        <Box className={classes.basic}>
                            <Box>
                                <Typography variant='h7' color={theme.palette.text.grey}>Condition:</Typography>
                            </Box>
                            <Box className={classes.flex}>
                                <Typography variant='h7'>{vehicle?.sale_condition}</Typography>
                            </Box>
                        </Box>}
                    {vehicle?.vertical === 'RV' &&

                        <Box className={classes.basic}>
                            <Box>
                                <Typography variant='h7' color={theme.palette.text.grey}>Slide Outs:</Typography>
                            </Box>
                            <Box className={classes.flex}>
                                <Typography variant='h7'>{vehicle?.slide_outs}</Typography>
                            </Box>
                        </Box>
                    }
                    {vehicle?.vertical === 'Auto' &&
                        <>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>Engine Cylinders:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.engine_cylinders ? vehicle?.engine_cylinders : 'Not Specified'}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>Size:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.vehicle_size ? vehicle?.vehicle_size : 'Not Specified'}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>Doors:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.doors}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>Fuel Capacity:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.fuel_capacity ? `${vehicle?.fuel_capacity} gallons` : 'Not Specified'}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>City Mileage:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.city_mileage ? `${vehicle?.city_mileage.toLocaleString()} miles/gallon` : 'Not Specified'}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>Highway Mileage:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.highway_mileage ? `${vehicle?.highway_mileage.toLocaleString()} miles/gallon` : 'Not Specified'}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>Tranmission:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.transmission_type}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>Drive Type:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.driven_wheels ? vehicle?.driven_wheels : 'Not Specified'}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>ABS:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.anti_brake_system ? vehicle?.anti_brake_system : 'Not Specified'}</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.basic}>
                                <Box>
                                    <Typography variant='h7' color={theme.palette.text.grey}>Made In:</Typography>
                                </Box>
                                <Box className={classes.flex}>
                                    <Typography variant='h7'>{vehicle?.made_in ? vehicle?.made_in : 'Not Specified'}</Typography>
                                </Box>
                            </Box>
                        </>
                    }
                </CardContent>
            </Card>
        </StyledGrid>
    )
}

export default DetailsPanel;