import {useField} from "formik";
import Grid from "@mui/material/Grid";
import TextField from "./widgets/TextField";
import styled from "@emotion/styled";

const PREFIX = 'AddressForm';

const classes = {
  spacer: `${PREFIX}-spacer`,
  field: `${PREFIX}-field`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
)=> {
  return {
    paddingTop: 10,
    [`& .${classes.spacer}`]: {
      paddingRight: 10,
    },
  }
});

export default function AddressForm ({...props}) {
  const [field] = useField(props);

  return (
    <StyledGrid container>
      <Grid item xs={12}>
        <TextField
          label='Address'
          name={`${field.name}.address1`}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label='Address 2'
          name={`${field.name}.address2`}
        />
      </Grid>

      <Grid item xs={5} className={classes.spacer}>
          <TextField
            label='City'
            name={`${field.name}.city`}
          />
      </Grid>

      <Grid xs={3} className={classes.spacer}>
        <TextField
          label='State'
          name={`${field.name}.state`}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label='Zip Code'
          name={`${field.name}.postalCode`}
        />
      </Grid>
    </StyledGrid>
  )
}