import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Form, Formik } from 'formik';
import Spinner from "components/Spinner";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogTitle from '@mui/material/DialogTitle';
import Button from "components/widgets/Button";
import Image from "components/widgets/Image";
import Checkbox from "components/widgets/Checkbox";
import TextField from 'components/widgets/TextField';
import { doLogin, doReset, forgotPassword } from "data/authentication.actions";
import { useAuth } from "utils/UserContext";
import styled from "@emotion/styled";

const PREFIX = 'LoginDialog';

const classes = {
    closeButton: `${PREFIX}-closeButton`,
    forgot: `${PREFIX}-forgot`,
    btn: `${PREFIX}-btn`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.closeButton}`]: {
            float: 'right',
            cursor: 'pointer'
        },
        [`& .${classes.forgot}`]: {
            paddingLeft: 65,
            paddingTop: 10,
            cursor: 'pointer'
        },
        [`& .${classes.btn}`]: {
            marginTop: 30,
            background: '#213e97',
            borderRadius: 7,
            width: '100%',
            height: 50,
            textTransform: 'capitalize',
            marginBottom: 40
        }
    }
});

const validationSchema = yup.object().shape({
    email: yup.string().required("User Name is required"),
    password: yup.string().required("Password is required"),
});

let initialValues = {
    email: '',
    password: '',
};

const Login = ({ isOpen, redirect, handleClose }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const { user, token, error, processing, redirectTo, forgotResponse } = useSelector(state => state.authentication);

    useEffect(() => {
        dispatch(doReset());
    }, [dispatch, isOpen]);

    useEffect(() => {
        if (user && token && auth && navigate) {
            auth.loggedIn(user, token[0]);
            navigate(redirectTo || '/buy');
        }
    }, [auth, navigate, token, redirectTo, user]);

    const handleSubmit = async (values) => {
        let newValues = { ...values };
        if (location.search.indexOf('p=') > -1) {
            newValues.redirectTo = location.search.substring(3);
        }

        dispatch(doLogin(newValues));
    }

    return (
        <StyledDialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'sm'}
        >
            <DialogTitle id="alert-dialog-title">
                <Box
                    onClick={() => {
                        handleClose();
                        setVisible(false);
                    }}
                    className={classes.closeButton}
                >
                    <Typography variant="h4">
                        X
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="pl-20 pr-20">
                    <center>
                        <Image
                            alt="Banner Image"
                            src='/static/images/reinvent-exchange.jpeg'
                            className="logo"
                        />
                    </center>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            handleSubmit(values);
                            actions.setSubmitting(false);
                        }}>
                        {({ handleChange }) => {
                            return (
                                <Form autoComplete={false}>
                                    <Grid container className="pt-6">
                                        <Grid item xs={12}>
                                            <TextField name='email' label='Email' type='email' />
                                        </Grid>
                                        <Grid item xs={12} className="pt-4">
                                            <TextField name='password' label='Enter Password' type='password' />
                                        </Grid>

                                        {error && (
                                            <Grid item xs={12} className="pt-4">
                                                <Typography variant='body1' color='error'>{error}</Typography>
                                            </Grid>
                                        )}

                                        <Grid item xs={12} className="pt-10">
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Checkbox
                                                        name="logged in"
                                                        handleChange={() => handleChange}
                                                        text="Keep me logged in"
                                                    />
                                                </Grid>
                                                <Grid item xs={6} className={classes.forgot} onClick={() => setVisible(!visible)}>
                                                    Forgot Password?
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type='submit'
                                                className={classes.btn}
                                            >
                                                Login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                    {visible && (
                        <Formik
                            initialValues={{
                                email: '',
                            }}
                            enableReinitialize
                            validationSchema={yup.object().shape({
                                email: yup.string().required("Email is required"),
                            })}
                            onSubmit={(values, actions) => {
                                dispatch(forgotPassword(values))
                                actions.setSubmitting(false);
                            }}>
                            {() => {
                                return (
                                    <Form autoComplete={false}>
                                        <Grid container>
                                            <Grid xs={12}>
                                                <center>
                                                    <Typography variant="h4">Forgot password?</Typography>
                                                </center>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField name='email' label='Email' type='email' />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    type='submit'
                                                    className={classes.btn}
                                                >
                                                    Reset Password
                                                </Button>
                                            </Grid>
                                            {forgotResponse?.result === 1 && (
                                                <Grid item xs={12}>
                                                    <Typography variant='body1' color='text.completed'>Password update link sent to your email address if you are registered.</Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    )}

                    <Spinner show={processing} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    )
}

export default Login;