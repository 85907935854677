import React from 'react';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Image from './widgets/Image';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

const PREFIX = 'InventoryCard';

const classes = {
    card: `${PREFIX}-card`,
    cardContent: `${PREFIX}-cardContent`,
    title: `${PREFIX}-title`,
    contact: `${PREFIX}-contact`,
    photo: `${PREFIX}-photo`
}

const StyledGrid = styled(Grid)(({
    theme
}) => {
    return {
        [`& .${classes.card}`]: {
            borderRadius: 10,
            height: 370,
            position: 'relative'
        },
        [`& .${classes.cardContent}`]: {
            padding: 10
        },
        [`& .${classes.title}`]: {
            fontWeight: 600,
            textTransform: 'capitalize'
        },
        [`& .${classes.contact}`]: {
            display: 'flex',
            background: '#d5ddf3',
            padding: 10,
            justifyContent: 'space-between',
            borderRadius: 6,
            width: '95%',
            position: 'absolute',
            bottom: 10
        },
        [`& .${classes.photo}`]: {
            height: 245,
            width: 340
        },
    }
});

const InventoryCard = ({ id, action, vehicle }) => {
    const navigate = useNavigate();
    const imageUrl = `https://photos.reinventauto.com/cars/${vehicle.photo_name || 'missing.jpg'}`
    return (
        <StyledGrid item xs={3} onClick={() => navigate(`/${action}/vehicle/unit/${id}`)}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Image
                        src={imageUrl}
                        alt="inventory photo"
                        className={classes.photo}
                    />
                    <Box className='ml-3 mt-2'>
                        <Typography variant='h6' className={classes.title}>
                            {vehicle?.title}
                        </Typography>
                    </Box>
                    <Box className={classes.contact}>
                        {'Auto' === vehicle.vertical &&
                            <Box>
                                <Typography
                                    variant='body2'
                                    color="text.grey">{vehicle?.mileage?.toLocaleString()} MILES</Typography>
                            </Box>
                        }
                    </Box>
                </CardContent>
            </Card>
        </StyledGrid>
    )
}

export default InventoryCard;