import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import jwt_decode from 'jwt-decode';
//import createHistory from 'history/createBrowserHistory';

import MainLayout from "./layouts/MainLayout";
import MessagesView from './views/MessagesView';
import VehicleView from './views/VehicleView';
import FrontPage from './views/FrontPage';
import LikesView from './views/Likes'
import UserManagementView from './views/UserManagement'
import SignupView from "./views/SignupView";
import BuyView from "./views/Buy";
import SellView from "./views/Sell";
import AuctionsView from "./views/Auctions";
import TasksView from "./views/Tasks";
import PreferencesView from "./views/Preferences";
import LogoutView from "./views/LogoutView";
import LocationManagementView from "./views/LocationManagement";
import ResetView from "views/ResetView";
import TermsOfUse from "./views/TermsOfUse";
import { getCookie } from "./utils/Cookies";
import { doLoadUser } from "./data/authentication.actions";
import { useProvideAuth, authContext } from "./utils/UserContext";
import Spinner from "components/Spinner";
import ScrollToTop from "./ScrollToTop";
import ReportingView from "views/Reporting";

const AppRouter = () => {
  const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();
    return (
      <authContext.Provider value={auth}>
        {children}
      </authContext.Provider>
    );
  }

  const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, processing, redirectTo } = useSelector(state => state.authentication);

    const token = getCookie('jwt');
    const uid = getCookie('uid');
    const location = useLocation();

    if (redirectTo) {
      navigate(redirectTo);
    }
    else if (token) {
      if (!user && !processing) {
        const decoded = jwt_decode(token);
        dispatch(doLoadUser({ uid: decoded.data.id, token }))
      }

      return (
        <>
          <ScrollToTop />

          {children}

          <Spinner show={processing} />
        </>
      );
    }
    else if (uid) {
      if (!user && !processing) {
        dispatch(doLoadUser({ uid }))
      }

      return (
        <>
          {children}

          <Spinner show={processing} />
        </>
      );
    }

    return (
      <Navigate
        to={{
          pathname: `/index`,
          search: `?p=${location.pathname}${location.search}`,
          state: { from: location }
        }}
      />
    )
  }

  // Create an enhanced history that syncs navigation events with the store
  // const browserHistory = createHistory();
  // const history = syncHistoryWithStore(browserHistory, store)

  return (
    <ProvideAuth>
      <Router>
        <Routes>
          <Route
            path="/"
            element={(
              <Navigate
                to={{
                  pathname: "/index"
                }}
              />
            )}
          />

          <Route
            path="/password-reset"
            element={(
              <FrontPage />
            )}
          />

          <Route
            path="/index"
            element={(
              <FrontPage />
            )}
          />

          <Route
            path="/logout"
            element={(
              <LogoutView />
            )}
          />

          <Route
            path="/terms-of-use"
            element={(
              <MainLayout>
                <ScrollToTop />
                <TermsOfUse />
              </MainLayout>
            )}
          />

          <Route
            path="/signup"
            element={(
              <MainLayout>
                <ScrollToTop />
                <SignupView />
              </MainLayout>
            )}
          />

          <Route
            path="/buy"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <BuyView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/sell"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <SellView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/auctions"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <AuctionsView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/auctions/:auctionType"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <AuctionsView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/password-reset/:code"
            element={(
              <ResetView />
            )}
          />

          <Route
            path="/tasks"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <TasksView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/likes"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <LikesView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/messages"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <MessagesView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/rooftops"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <LocationManagementView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            exact
            path="/rooftops/:action"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <LocationManagementView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            exact
            path="/rooftops/:action/:locationId"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <LocationManagementView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/users"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <UserManagementView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            exact
            path="/users/:action"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <UserManagementView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            exact
            path="/users/:action/:userId"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <UserManagementView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/preferences"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <PreferencesView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/:action/vehicle/unit/:id"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <VehicleView />
                </MainLayout>
              </PrivateRoute>
            )}
          />

          <Route
            path="/reports"
            element={(
              <PrivateRoute>
                <MainLayout>
                  <ReportingView />
                </MainLayout>
              </PrivateRoute>
            )}
          />
        </Routes>
      </Router>
    </ProvideAuth>
  )
}

export default AppRouter;