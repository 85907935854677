import React from "react";
import {useField} from "formik";
import Grid from "@mui/material/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import Select from "components/widgets/Select";
import CheckboxInput from "components/widgets/Checkbox";

const LocationAccessPanel = ({classes, key, locationRoles, locationSelections, handleDefaultChanged, handleRemoveAccess, ...props}) => {
  const [field, , helpers] = useField(props);
  return (
      <Grid key={key} container className={classes.locationAccessPanel}>
        <Grid item xs={12} className={classes.fieldContainer}>
          <div style={{float: 'left', marginRight: 20, width: '80%'}}>
            <Select
              label='Rooftop'
              name={`${field.name}.dealerRooftopId`}
              items={locationSelections}
            />
          </div>
          <div style={{float: 'left', paddingTop: 30}}>
            <CheckboxInput
                checked={field.value.defaultMark}
                text={'Default Rooftop'}
                name={`${field.name}.defaultMark`}
                handleChange={() => {
                  helpers.setValue({
                    ...field.value,
                    defaultMark: !field.value.defaultMark
                  })
                  handleDefaultChanged(field.value.dealerRooftopId)
                }}
            />
          </div>
          <div>
            <DeleteIcon className={classes.button} onClick={() => handleRemoveAccess()}/>
          </div>
        </Grid>

        <Grid item xs={12} className={classes.fieldContainer}>
        {locationRoles && locationRoles.filter((role, index) => {
          return role.rooftopRole
        }).map((role, index) => {
          return (
              <div className={classes.inlineCheckbox}>
                <CheckboxInput
                    handleChange={() => {
                      const existing = field.value.locationRoles.find(value => value.roleId  === role.roleId);
                      if (existing) {
                        helpers.setValue({
                          ...field.value,
                          locationRoles: field.value.locationRoles.filter(value => value.roleId  !== role.roleId)
                        });
                      }
                      else {
                        helpers.setValue({
                          ...field.value,
                          locationRoles: [...field.value.locationRoles, {...role}]
                        });
                      }
                    }}
                    checked={ field.value.locationRoles.findIndex(value => value.roleId  === role.roleId) > -1 }
                    text={role.roleName}
                    domainValue={role}
                    name={`${field.name}.locationRoles[${index}].roleId`}
                />
              </div>
          )
        })} 
        </Grid>
      </Grid>
  )
};

export default LocationAccessPanel;