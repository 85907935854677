import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoginDialog from "../../../components/dialogs/Login";

const PREFIX = 'Header';

const classes = {
  signUp: `${PREFIX}-signUp`,
  btn: `${PREFIX}-btn`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
) => {
  return {
    marginTop: 15,
    [`& .${classes.buttonContainer}`]: {
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 50,
        marginTop: 20,
        marginBottom: 20,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: '2%',
      }
    },
    [`& .${classes.btn}`]: {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.normal,
      background: 'radial-gradient(89.22% 89.22% at 58.82% 50.98%, #FF4D53 0%, #ED1D24 100%)',
      borderRadius: 19.5,
      textTransform: 'capitalize',
      float: 'right',
      fontSize: 13,
      [theme.breakpoints.up('xs')]: {
        marginLeft: 70,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 0,
      }
    },
    [`& .${classes.signUp}`]: {
      color: theme.palette.background.default,
      width: 145,
      height: 38,
      backgroundColor: theme.palette.primary.normal,
      fontSize: 13,
      background: 'radial-gradient(89.22% 89.22% at 58.82% 50.98%, #6280DE 0%, #213E98 100%)',
      borderRadius: 19.5,
      textTransform: 'capitalize',
      float: 'right',
      marginRight: 10,
      [theme.breakpoints.up('xs')]: {
        marginLeft: 10,
      },
    },
  }
});

const HeadingButtons = ({}) => {
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);

  return (
      <StyledGrid container>
          <Grid
              item
              xs={12}
              className={classes.buttonContainer}
          >
            <Button
                variant="contained"
                color="primary"
                className={classes.signUp}
                size="large"
                onClick={() => setShowLogin(true)}
            >
              Dealer Login
            </Button>

            <Button
                variant="contained"
                color="primary"
                className={classes.btn}
                size="large"
                onClick={() => { navigate('/signup') }}
            >
              Dealer Sign Up
            </Button>
        </Grid>

        <LoginDialog
            isOpen={showLogin}
            redirect='/buy'
            handleClose={() => setShowLogin(false)}
        />
      </StyledGrid>
  )
}

export default HeadingButtons;