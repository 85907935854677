import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { formatDateTime, formatDollarAmount } from "utils/Formatters";
import ContentDialog from "components/dialogs/ContentDialog";
import DataTable from "components/widgets/DataTable";
import Button from "components/widgets/Button";
import Spinner from "components/Spinner";
import { getBidHistory } from "data/auction.actions";

const tableColumns = [
  {
    label: 'Bid Amount',
    name: 'bid_amount',
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant='body1'>{formatDollarAmount(value)}</Typography>
        )
      }
    }
  },
  {
    label: 'Time',
    name: 'created_date',
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant='body1'>{formatDateTime(value)} </Typography>
        )
      }
    }
  },
  {
    label: 'User',
    name: 'user_name',
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant='body1'>{value ? value : '-'} </Typography>
        )
      }
    }
  },
  {
    label: 'Rooftop Name',
    name: 'rooftop_name',
    options: {
      customBodyRender: (value) => {
        return (
          <Typography variant='body1'>{value ? value : '-'} </Typography>
        )
      }
    }
  },
  {
    label: 'Rooftop Location',
    name: 'rooftop_location',
  },
];

const BidsDialog = ({ isOpen, vehicle, auction, handleClose }) => {
  const dispatch = useDispatch();

  const { auction: { bidHistory, bidsLoading } } = useSelector(state => state);

  const getAuctionToken = () => {
    return vehicle ? vehicle.auction_token : auction?.auction_token;
  }

  useEffect(() => {
    const auctionToken = getAuctionToken();
    if (auctionToken && isOpen) {
      const params = {
        token: auctionToken,
        page: 50,
        flag: 'bid_list'
      }
      dispatch(getBidHistory(params));
    }
  }, [vehicle, isOpen, auction, dispatch, getBidHistory]);

  return (
    <ContentDialog
      open={isOpen}
      title={'HISTORY OF BIDS'}
      children={
        <Grid item xs={12}>
          <DataTable
            data={bidHistory && getAuctionToken() ? bidHistory[getAuctionToken()] : []}
            columns={tableColumns}
            options={{
              selectableRows: 'none',
              selectableRowsHideCheckboxes: true,
              selectableRowsHeader: false
            }}
          />
          <Spinner show={bidsLoading} />
        </Grid>
      }
      buttonsPanel={
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClose}
        >
          Close
        </Button>
      }
    />
  );
}

export default BidsDialog;