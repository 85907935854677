import React from "react";
import Button from "@mui/material/Button";

const REButton = ({color = 'primary', children, ...props}) => {
    return (
      <Button
        variant="contained"
        size="large"
        {...props}
      >
          {children}
      </Button>
    )
}

export default REButton;