import { Typography, AccordionSummary } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadAgingVehicles } from "data/vehicle.actions";
import Spinner from "components/Spinner";
import UpdateReservePriceDialog from "components/dialogs/UpdateReservePriceDialog";
import CancelAuctionDialog from "components/dialogs/CancelAuctionDialog";
import PlaceAuctionDialog from "components/dialogs/PlaceAuctionDialog";
import BidsDialog from "components/dialogs/BidsDialog";
import ConditionReportDialog from "components/dialogs/ConditionReportDialog";
import VehicleDetailsDialog from "components/dialogs/VehicleDetailsDialog";
import InternalExternalDialog from "components/dialogs/InternalExternalDialog";
import VehicleDetails from "./VehicleDetails";

const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "#213E98",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25);",
  borderRadius: "7px",
  color: "white",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "white",
  borderBottomRightRadius: "7px",
  borderBottomLeftRadius: "7px",
}));

const ReportingView = () => {
  const dispatch = useDispatch();
  const [showAuctionSelected, setShowAuctionSelected] = useState(false);
  const [showDialog, setShowDialog] = useState("");
  const [dealerRooftops, setDealerRooftops] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [isInternal, setIsInternal] = useState(null);

  const { agingVehicles, isAgingVehiclesLoading } = useSelector(
    (state) => state.search
  );
  const { user } = useSelector((state) => state.authentication);

  const handleEvent = (event, vehicle) => {
    setSelectedVehicle(vehicle);
    setShowDialog(event);
    setSelectedVehicle(vehicle);
  }

  const makeInternalAuction = () => {
    setIsInternal(true);
    handleEvent("auction_dialog", selectedVehicle);
  };
  
  const makeExternalAuction = () => {
    setIsInternal(false);
    if(selectedVehicle?.condition_report_date === null){
      setShowAuctionSelected(true);
      handleEvent("view_condition_report", selectedVehicle);
    } else {
      handleEvent("auction_dialog", selectedVehicle);
    }
  };

  useEffect(() => {
    if (agingVehicles && !isAgingVehiclesLoading) {
      const rooftops = {};
      agingVehicles.forEach((rooftop) => {
        const rooftopName = rooftop?.rooftop_name;

        if (!rooftops[rooftopName]) {
          rooftops[rooftopName] = [];
        }
        rooftops[rooftopName].push(rooftop);
      });
      setDealerRooftops(rooftops);
    }
  }, [agingVehicles, isAgingVehiclesLoading]);

  useEffect(() => {
    if (user?.dealer_id) {
      dispatch(
        loadAgingVehicles({
          sort: "aging",
          dealerId: user?.dealer_id,
          searchType: "my-inventory",
          limitAmount: 130,
        })
      );
    }
  }, [user]);

  return (
    <div className="mt-5 sm:px-7 xl:px-14">
      <Typography
        variant="h1"
        color={"#253241"}
        fontWeight={700}
        textTransform={"uppercase"}
      >
        Reports
      </Typography>
      <div className="mt-5">
        {dealerRooftops &&
          Object.keys(dealerRooftops).map((rooftop, index) => {
            return (
              <Accordion
                key={rooftop}
                defaultExpanded={index === 0}
                className="mt-5 first:mt-0"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon htmlColor="#fff" />}
                  aria-controls={`${rooftop}-panel-content`}
                  id={rooftop}
                >
                  <div className="flex justify-between w-full mr-2">
                    <Typography fontWeight={700} fontSize={"24px"}>
                      {rooftop}
                    </Typography>
                    <Typography fontWeight={700} fontSize={"24px"}>
                      {dealerRooftops[rooftop].length} Vehicles
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {dealerRooftops[rooftop].map((vehicle) => {
                    return (
                      <VehicleDetails
                        vehicle={vehicle}
                        handleEvent={(event) => handleEvent(event, vehicle)}
                      />
                    );
                  })}

                  {showDialog === "view_condition_report" && (
                    <ConditionReportDialog
                      isOpen={showDialog === "view_condition_report"}
                      vehicle={selectedVehicle}
                      handleClose={(hasChanged) => {
                        setShowDialog("");
                        if (showAuctionSelected && hasChanged) {
                          setShowDialog("auction_dialog");
                        }
                      }}
                    />
                  )}

                  <InternalExternalDialog
                    isOpen={showDialog === "internal_external_dialog"}                    
                    makeInternalAuction={makeInternalAuction}
                    makeExternalAuction={makeExternalAuction}
                    handleClose={() => {
                      setShowDialog("");
                    }}
                  />

                  <VehicleDetailsDialog
                    isOpen={showDialog === "view_vehicle_details"}
                    handleClose={() => setShowDialog("")}
                    vehicleToken={selectedVehicle?.token}
                  />

                  <PlaceAuctionDialog
                    open={showDialog === "auction_dialog"}
                    handleClose={() => setShowDialog("")}
                    vehicle={selectedVehicle}
                    isInternal={isInternal}
                  />

                  <CancelAuctionDialog
                    isOpen={showDialog === "cancel_auctions"}
                    handleClose={() => setShowDialog("")}
                    auction={selectedVehicle}
                  />

                  <UpdateReservePriceDialog
                    open={showDialog === "update_reserve_price"}
                    handleClose={() => setShowDialog("")}
                    auction={selectedVehicle}
                  />

                  <BidsDialog
                    isOpen={showDialog === "view_bid_history"}
                    handleClose={() => setShowDialog("")}
                    vehicle={selectedVehicle}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
      <Spinner show={isAgingVehiclesLoading} />
    </div>
  );
};

export default ReportingView;
