/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Grid from "@mui/material/Grid";
import SertifiSigning from "./SertifiSigning";

const DocumentsPanel = ({ classes, transaction, id }) => {
  const {
    buyerSignDate,
    sellerSignDate,
    buyerEmail,
    sellerEmail,
    sellerId,
    buyerId
  } = transaction || {};

  return (
    <Grid container>
      <Grid item xs={12}>
        <SertifiSigning
          classes={classes}
          transaction={transaction}
          userId={sellerId}
          date={sellerSignDate}
          userEmail={sellerEmail}
          text={sellerSignDate ? "Seller Document - Signed" : "Seller Document"}
          id={id}
          sellerSignDate={sellerSignDate}
          resultType={"seller"}
        />
      </Grid>
      <Grid item xs={12}>
        <SertifiSigning
          classes={classes}
          transaction={transaction}
          userId={buyerId}
          date={buyerSignDate}
          userEmail={buyerEmail}
          text={buyerSignDate ? "Buyer Document - Signed" : "Buyer Document"}
          id={id}
          sellerSignDate={sellerSignDate}
          resultType={"buyer"}
        />
      </Grid>
    </Grid>
  )
}

export default DocumentsPanel;
