import React from "react";
import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogTitle from '@mui/material/DialogTitle';
import Button from "components/widgets/Button";
import InfoPopover from "components/widgets/InfoPopover";

const PREFIX = 'internalExternalDialog';

const classes = {
    closeButton: `${PREFIX}-closeButton`,
    btn: `${PREFIX}-btn`,
    rightbtn: `${PREFIX}-rightbtn`,
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {

        [`& .${classes.closeButton}`]: {
            float: 'right',
            cursor: 'pointer'
        },
        [`& .${classes.btn}`]: {
            marginTop: 30,
            background: '#213e97',
            borderRadius: 7,
            width: '45%',
            height: 50,
            textTransform: 'capitalize',
            marginBottom: 40
        },
        [`& .${classes.rightbtn}`]: {
            float: 'right'
        }
    }
});

const InternalExternalDialog = ({ isOpen, handleClose, makeInternalAuction, makeExternalAuction }) => {

    return (
        <StyledDialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { width: "80%" } }}
        >
            <DialogTitle id="alert-dialog-title">
                <Box
                    onClick={() => handleClose()}
                    className={classes.closeButton}
                >
                    <Typography variant="h4">
                        X
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="pl-10 pr-10">
                    <center>
                        <Typography variant="h2">
                            INTERNAL OR EXTERNAL?
                            <InfoPopover info={`Internal auctions are only open to rooftops within your dealer. External auctions are available to all rooftops on Reinvent Exchange.`} />
                        </Typography>
                    </center>
                    <Grid item xs={12}>
                        <Button
                            type='submit'
                            className={classes.btn}
                            onClick={() => makeInternalAuction()}
                        >
                            Internal
                        </Button>
                        <Button
                            type='submit'
                            className={`${classes.btn} ${classes.rightbtn}`}
                            onClick={() => makeExternalAuction()}
                        >
                            External
                        </Button>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    )
}

export default InternalExternalDialog;