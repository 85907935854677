import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';


const PREFIX = 'ConnectWithUs';

const classes = {

    grid: `${PREFIX}-contact-grid`,
    textField: `${PREFIX}-textfield`,
    contactHeader: `${PREFIX}-contact-header`,
    contactSubHeader: `${PREFIX}-contact-subheader`,
    formLabel: `${PREFIX}-form-label`,
    vSpacing: `${PREFIX}-vspace`,
    contactColor: `${PREFIX}-contact-color`,
    icoLinkedIn: `${PREFIX}-linkedin`,
    icoFacebook: `${PREFIX}-facebok`,
    icoTwitter: `${PREFIX}-twitter`,

}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.textField}`]: {
            background: theme.palette.field.background,
            border: `1px solid ${theme.palette.field.border}`,
            borderRadius: 8,
            marginRight: 20,
            marginTop: 20,
            marginBottom: 5,
        },
        [`& .${classes.contactHeader}`]: {
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "30px",
            color: "#253241",
            paddingBottom: 30
        },
        [`& .${classes.contactSubHeader}`]: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "153.69%",
            color: "#000000",
            paddingBottom: 20
        },
        [`& .${classes.formLabel}`]: {
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "14px",
            color: "#253241"
        },
        [`& .${classes.vSpacing}`]: {
            paddingTop: 30,
            fontWeight: 700,
            [theme.breakpoints.up('xs')]: {
                fontSize: 16,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 24,
            },
        },
        [`& .${classes.contactColor}`]: {
            paddingTop: 30,
            color: "#213E98",
            paddingLeft: 5
        },
        [`& .${classes.icoLinkedIn}`]: {
            marginTop: 220,
            fill: "#355BA6",
            [theme.breakpoints.up('xs')]: {
                marginLeft: 70,
                fontSize: 48,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 70,
            },
        },
        [`& .${classes.icoTwitter}`]: {
            marginTop: 220,
            fill: "#3DB8FD",
            marginLeft: 20,
            [theme.breakpoints.up('xs')]: {
                fontSize: 48,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 70,
            }
        },
        [`& .${classes.icoFacebook}`]: {
            marginTop: 220,
            fill: "#355BA6",
            marginLeft: 20,
            [theme.breakpoints.up('xs')]: {
                fontSize: 48,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 70,
            }
        },
    }
});

const ConnectWithUs = () => {
    return (
        <StyledGrid container >
            <Grid item xs={12}>
                <Typography className={`${classes.contactHeader}`} style={{ marginTop: "40px" }} rowSpacing={{ xs: 5 }}>Connect with Us</Typography>
            </Grid>
            <Grid item xs={12} style={{paddingRight: 10}}>
                <Typography variant='body1'>Based in Fontana, Wisconsin – ReinventAuctions is the first and only online wholesale platform to buy,
                    sell, and trade vehicles with other dealers.  If you would like more information or would like to join our Dealer
                    Network, please contact us using any of the methods below!
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={`${classes.vSpacing}`}>Give us a call at <span className={`${classes.contactColor}`}>(262) 228-8742</span></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={`${classes.vSpacing}`}>Email us at <span className={`${classes.contactColor}`}>sales@reinventauctions.com</span></Typography>
            </Grid>
            <Grid item container justifyContent={"center"} spacing={25}>
                <LinkedInIcon className={`${classes.icoLinkedIn}`} />
                <TwitterIcon className={`${classes.icoTwitter}`} />
                <FacebookIcon className={`${classes.icoFacebook}`} />
            </Grid>
        </StyledGrid>
    )
}

export default ConnectWithUs;