import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Form, Formik } from 'formik';
import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Button from "components/widgets/Button";
import TextField from 'components/widgets/TextField';
import NumberFormatCustom from "components/widgets/NumberFormatCustom";
import Spinner from "components/Spinner";
import { toast } from "react-toastify";
import { updatePrice } from 'data/auction.actions';
import { formatDollarAmount } from "utils/Formatters";

const validationSchema = yup.object().shape({
    reservePrice: yup.number().when(['currentReserve'], (currentReserve) => {
        if (yup.number()) {
            return yup.number().integer()
                .required("Reserve price is required")
                .lessThan(currentReserve, 'New reserve price must be less than the current reserve price.')
        }
    }),
});

const StyledDialog = styled(Dialog)(( {theme}) => {
    return {}
});

const UpdateReservePriceDialog = (
    {
        open,
        handleClose,
        auction
    }) => {
    const dispatch = useDispatch();
    const { auction: { reserveSaved, savingReserve } } = useSelector(state => state);

    useEffect(() => {
        if (reserveSaved && reserveSaved === auction.auction_token) {
            toast.success('Reserve price updated successfully !!!');
            handleClose(true);
        }
    }, [reserveSaved, auction.auction_token]);

    return (
        <StyledDialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 700, overflowY: 'hidden', } }}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <div onClick={handleClose} className="float-right cursor-pointer">
                    <Typography variant="h4">X</Typography>
                </div>
                <Typography variant="h1" className="text-center">
                    UPDATE RESERVE PRICE
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Formik
                        initialValues={{
                            currentReserve: auction?.reserve_price,
                            reservePrice: '',
                        }
                        }
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values, meta) => {
                            const data = {
                                token: auction?.auction_token,
                                price: values.reservePrice,
                                dealer_id: auction?.dealer_id
                            }
                            dispatch(updatePrice(data));
                        }}>
                        {({ handleChange, handleSubmit }) => {
                            return (
                                <Form autoComplete={false}>
                                    <Grid container>
                                        <div className="mb-6 mx-auto text-center text-[22.7px]">
                                            <span className="text-[#979797]">
                                                Current Reserve Price:
                                            </span>
                                            <span className="text-[#2CC545]">
                                                {" "}
                                                {formatDollarAmount(auction?.reserve_price)}
                                            </span>
                                        </div>

                                        <Grid item xs={12}>
                                            <TextField
                                                name='reservePrice'
                                                label='New Reserve Price'
                                                placeholder="$00.00"
                                                className="border border-[#253241]"
                                                onChange={handleChange}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </Grid>
                                        <div className="text-white font-bold text-[18px] mt-6 flex justify-end w-full">
                                            <button
                                                onClick={handleClose}
                                                className="bg-[#ED1D24] w-[182px] h-[45px] rounded-[7px] mr-2"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={handleSubmit}
                                                className="bg-[#213E98] w-[182px] h-[45px] rounded-[7px]"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                    <Spinner show={savingReserve} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    );
}

export default UpdateReservePriceDialog;
