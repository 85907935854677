import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Form, Formik } from 'formik';
import Spinner from "components/Spinner";
import Grid from '@mui/material/Grid';
import Button from "components/widgets/Button";
import Typography from "@mui/material/Typography";
import TextField from 'components/widgets/TextField';
import { resetPassword } from "data/authentication.actions";
import styled from "@emotion/styled";
import { toast } from "react-toastify";

const PREFIX = 'Reset-Password';

const classes = {
    btn: `${PREFIX}-btn`,
    card: `${PREFIX}-card`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.btn}`]: {
            background: '#213e97',
            borderRadius: 7,
            textTransform: 'capitalize',
        },
        [`& .${classes.card}`]: {
            marginTop: 160
        }
    }
});

const validationSchema = yup.object().shape({
    new_password: yup.string()
        .required("New Password is required")
        .min(8, 'Password is too short - should be 8 chars minimum.'),
    confirm_password: yup.string()
        .required("Confirm Password is required")
        .min(8, 'Confirm Password is too short - should be 8 chars minimum.')
        .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
});

let initialValues = {
    new_password: '',
    confirm_password: ''
}

const ResetView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { resetResponse, resetProcessing } = useSelector(state => state.authentication);

    useEffect(() => {
        if (resetResponse?.success_msg) {
            toast.success('Reset Password successfully!');
        }
    }, [resetResponse]);

    return (
        <StyledGrid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
                <Card className={classes.card}>
                    <CardContent>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={(values, actions) => {
                                console.log(values);
                                const data = {
                                    token: location?.pathname.split('/')[2],
                                    password: values.new_password
                                }
                                dispatch(resetPassword(data));
                                actions.setSubmitting(false);
                            }}>
                            {({ handleChange }) => {
                                return (
                                    <Form autoComplete={false}>
                                        <center>
                                            <Typography variant="h1" className="pt-4">Reset Password</Typography>
                                        </center>
                                        <Grid container className="pt-6">
                                            <Grid item xs={12}>
                                                <TextField name='new_password' label='New Password' type='password' />
                                            </Grid>
                                            <Grid item xs={12} className="pt-4">
                                                <TextField name='confirm_password' label='Confirm Password' type='password' />
                                            </Grid>
                                            <Grid item xs={12} className="pb-6 pt-4">
                                                <center>
                                                    <Button
                                                        type='submit'
                                                        className={classes.btn}
                                                    >
                                                        Reset Password
                                                    </Button>
                                                </center>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </CardContent>
                </Card>
                <Spinner show={resetProcessing} />

            </Grid>
        </StyledGrid>
    )
}

export default ResetView;