import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { vehicleDetails, getTransactionStatus, getConditionReport } from 'data/vehicle.actions';
import Spinner from "components/Spinner";
import PhotoViewer from "components/PhotoViewer";
import Image from 'components/widgets/Image';
import styled from '@emotion/styled';
import InventoryCard from "components/InventoryCard";
import SidePanel from './components/SidePanel';
import ConditionReportPanel from './components/ConditionReportPanel';
import Button from '@mui/material/Button';
import ConfirmSaleDialog from "../dialogs/ConfirmSaleDialog";
import BidsDialog from "components/dialogs/BidsDialog";

const PREFIX = 'Details';

const classes = {
  typo: `${PREFIX}-typo`,
  box: `${PREFIX}-box`,
  photoContainer: `${PREFIX}-photoContainer`,
  makebtn: `${PREFIX}-make-btn`,
  bidIcon: `${PREFIX}-bidIcon`,
  condition: `${PREFIX}-condition`
}

const StyledGrid = styled(Grid)(({
  theme
}) => {
  return {
    [`& .${classes.typo}`]: {
      marginTop: -2,
      color: theme.palette.border.default
    },
    [`& .${classes.box}`]: {
      padding: 10,
      borderBottom: `1px solid ${theme.palette.border.default}`
    },
    [`& .${classes.photoContainer}`]: {
      maxHeight: 750,
      [`& img`]: {
        maxHeight: 600
      }
    },
    [`& .${classes.makebtn}`]: {
      borderRadius: 7,
      background: 'rgba(33, 62, 152, 0.2)',
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginTop: 10,
      width: '100%',
      textTransform: 'capitalize'
    },
    [`& .${classes.bidIcon}`]: {
      marginRight: 3,
    },
    [`& .${classes.condition}`]: {
      display: 'flex',
      justifyContent: 'space-between'
    },
  }
});

const DetailsPanel = ({
    id,
    offerToken,
    offerAction
  }) => {
  const dispatch = useDispatch();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [showBids, setShowBids] = useState(false);

  const {
    search: {
      vehicleDetail,
      loadingVehicleDetails,
      loadingTransactionStatuses,
      loadingConditionReport

    }
  } = useSelector(state => state);

  const {
    vehicle,
    photos
  } = vehicleDetail;

  useEffect(() => {
    if (id !== undefined && vehicle?.token !== id) {
      const params = {
        token: id
      }
      dispatch(vehicleDetails(params));
      dispatch(getTransactionStatus(params));
      dispatch(getConditionReport(params));
    }
  }, [dispatch, id]);

  const handleConditionReportChanged = () => {
    const params = {
      token: id
    }
    dispatch(getConditionReport(params));
  }

  const handleShowBidsClicked = (event) => {
    setShowBids(true);

    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }; 

  const displayBids = (vehicle) => {
    if (vehicle?.auction_status === 'Active') {
      return (
        <Box>
          <Box className="flex mt-1">
            <Box>
              <Typography variant="h2">{vehicle?.bids > 0 ? "Current Bid" : "Starting Bid"}</Typography>
            </Box>
            <Box className="ml-2">
              <Typography variant="h2" color='secondary'>${vehicle?.bids > 0 ? vehicle?.current_bid : vehicle?.starting_bid}</Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.makebtn}
            onClick={handleShowBidsClicked}
          >
            <Image
              alt="bid"
              src="/static/images/bid.png"
              className={classes.bidIcon}
            />
            {vehicle?.bids > 0 ? vehicle?.bids : 0} Bids
          </Button>
          <BidsDialog
            auction={vehicle}
            handleClose={() => setShowBids(false)}
            isOpen={showBids}
          />
        </Box>
      )
    }
  }

  return (
    <StyledGrid container>
      <Grid container className='pl-6 pr-6' spacing={2}>
        <Grid item xs={9}>
          <Box className={`mt-2 mb-5 ${classes.box} ${classes.condition}`}>
            <Box>
              <Typography variant='h1'>
                {vehicle?.title}
              </Typography>
              {vehicle?.location ?
                <Box className='flex mt-2'>
                  <Box>
                    <Image
                      src="/static/images/location.png"
                      alt="location"
                    />
                  </Box>
                  <Box className='ml-1'>
                    <Typography variant='body1' className={classes.typo}>{vehicle?.city}, {vehicle?.state} {vehicle?.postal_code}</Typography>
                  </Box>
                </Box>
                : ''}
            </Box>
            {displayBids(vehicle)}
          </Box>

          {photos && photos.length > 0 &&
            <PhotoViewer
              className={classes.photoContainer}
              photos={photos}
              showEmpty
              rootUrl='https://photos.reinventauto.com/cars/'
            />
          }

          {(!photos || photos.length === 0) &&
            <div key='auto-photo-1'>
              <Image alt={'auto-photo-missing'} src='https://photos.reinventauto.com/cars/missing.png' />
            </div>
          }
          <ConditionReportPanel
            vehicle={vehicle}
            handleConditionReportChanged={handleConditionReportChanged}
          />
        </Grid>
        <Grid item xs={3}>
          <SidePanel
            vehicle={vehicle}
            handleConditionReportChanged={handleConditionReportChanged}
          />
        </Grid>
      </Grid>

      <Spinner show={ loadingConditionReport || loadingVehicleDetails || loadingTransactionStatuses } />
    </StyledGrid>
  )
}

export default DetailsPanel;