import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  offer: undefined,
  processing: false,
  offerAccepted: undefined,
  processingConfirm: false,
  offerRejected: undefined,
  processingReject: false,
  processingCounter: false,
  offerCounted: undefined
};

export const offerSlice = createSlice({
  processing: false,
  name: 'transaction',
  initialState,
  reducers: {
    initialize: () => {
      return initialState
    },
    loadingOffer: (state, action) => {
      return {
        ...state,
        processing: true
      }
    },
    loadedOffer: (state, action) => {
      return {
        ...state,
        offer: action.payload.offer.offer,
        processing: false
      }
    },
    loadedBids: (state, action) => {
      return {
        ...state,
        offer: action.payload.offer.bids[0],
        processing: false
      }
    },
    errorLoadingOffer: (state, action) => {
      return {
        ...state,
        ...action.error,
        offer: undefined,
        processing: false
      }
    },
    confirmingSale: (state, action) => {
      return {
        ...state,
        processingConfirm: true
      }
    },
    confirmSaleSuccessful: (state, action) => {
      return {
        ...state,
        offerAccepted: true,
        processingConfirm: false
      }
    },
    errorConfirmingSale: (state, action) => {
      return {
        ...state,
        ...action.error,
        offerAccepted: false,
        processingConfirm: false
      }
    },
    rejectingSale: (state, action) => {
      return {
        ...state,
        processingReject: true
      }
    },
    rejectSaleSuccessful: (state, action) => {
      return {
        ...state,
        offerRejected: true,
        processingReject: false
      }
    },
    errorRejectingSale: (state, action) => {
      return {
        ...state,
        ...action.error,
        offerRejected: false,
        processingReject: false
      }
    },
    loadCounterOffer: (state, action) => {
      return {
        ...state,
        processingCounter: true
      }
    },
    counterOfferSuccessful: (state, action) => {
      return {
        ...state,
        offerCounted: true,
        processingCounter: false
      }
    },
    errorCounterOffer: (state, action) => {
      return {
        ...state,
        ...action.error,
        offerCounted: false,
        processingCounter: false
      }
    },
  }
});

export const {
  loadingOffer,
  loadedOffer,
  loadedBids,
  errorLoadingOffer,
  confirmingSale,
  confirmSaleSuccessful,
  errorConfirmingSale,
  rejectingSale,
  rejectSaleSuccessful,
  errorRejectingSale,
  loadCounterOffer,
  counterOfferSuccessful,
  errorCounterOffer
} = offerSlice.actions

export default offerSlice.reducer