import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const socket = new WebSocket('ws://127.0.0.1:3004/local', "protocolOne"  );

const Messaging = () => {
  const [message, setMessage] = useState('');
  const [isConnected, setIsConnected] = useState(socket.connected);

  const routeMessage = message => {
    switch (message.action) {
      case 'newAuction':
        toast.success('New Auction added.');
        // dispatch();
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    socket.onopen = (event) => {
      setIsConnected(true);
    };

    socket.onerror = (event) => {
      console.log('Error connecting to socket', event);

      setIsConnected(false);
    };

    socket.onclose = (event) => {
      setIsConnected(false);
    };

    socket.onmessage = (event) => {
      try {
        setMessage(event.data);
        routeMessage(JSON.parse(event.data));
      }
      catch (error) {
        console.log('Error parsing chat message', error.message);
      }
    };
  }, []);

  return <div>
    <p> Connected: {`${isConnected}`}</p>
    <p>Last Message: {message}</p>
  </div>
}

export default Messaging;