import styled from "@emotion/styled";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/widgets/Button";
import TextField from "components/widgets/TextField";
import { toggleLikes } from "data/user.actions";
import { toast } from "react-toastify";

const PREFIX = "LikeDialog";

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  submit: `${PREFIX}-submit`,
  closeBtn: `${PREFIX}-closeBtn`,
};

const StyledDialog = styled(Dialog)(({ theme }) => {
  return {
    [`& .${classes.closeButton}`]: {
      float: "right",
      cursor: "pointer",
    },
    [`& .${classes.submit}`]: {
      float: "right",
      marginTop: 10,
    },
    [`& .${classes.closeBtn}`]: {
      float: "right",
      marginTop: 10,
      marginRight: 10,
    },
  };
});

const LikeDialog = ({
  isOpen,
  vehicleToken,
  handleClose,
  removeLikedVehicle = false
}) => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.user);

  const handleSubmit = (values) => {
    const newValues = {
      token: vehicleToken,
      comment: values.likeComment,
    };
    dispatch(toggleLikes(newValues));

    if (error) {
      toast.error(error);
    } else {
      toast.success(
        `Vehicle was successfully ${removeLikedVehicle ? "unliked" : "liked"}`
      );
    }
    handleClose();
  };
  return (
    <StyledDialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "100vw" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box onClick={() => handleClose()} className={classes.closeButton}>
          <Typography variant="h4">X</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
            <Formik
              initialValues={{}}
              onSubmit={async (values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false);
              }}
            >
              {({ handleSubmit, handleChange }) => {
                return (
                  <>
                  {removeLikedVehicle && <Typography className="px-16 pb-16">
                    Are you sure you want to unlike this vehicle?
                    </Typography>}

                    {!removeLikedVehicle && <TextField
                      label="Please leave notes for the vehicle"
                      name="likeComment"
                      multiline
                      rows={5}
                      className="multiline "
                    />}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.submit}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.closeBtn}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </div>
        </DialogContentText>
      </DialogContent>
    </StyledDialog>
  );
};

export default LikeDialog;
