import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatDateTime } from "utils/Formatters";

const TransferToSellerPanel = ({ classes, transaction, id }) => {
  const { paymentReceivedDate } = transaction || {};
  return (
    <Grid container>
      <Grid item xs={12}>
        {paymentReceivedDate && (
          <CheckCircleIcon className={classes.completed} />
        )}
        {!paymentReceivedDate && (
          <ScheduleIcon className={classes.scheduled} />
        )}
        <Typography variant='body'>Transferred Money To Seller</Typography>
        {paymentReceivedDate && (
          <Typography variant='body' className={classes.actionDate}>{formatDateTime(paymentReceivedDate)}</Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default TransferToSellerPanel;