import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Form, Formik } from 'formik';
import styled from "@emotion/styled";
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import { buyNow } from 'data/vehicle.actions';
import Spinner from "components/Spinner";
import Button from "components/widgets/Button";
import Select from 'components/widgets/Select';

const PREFIX = 'offerDialog';
const GRID_PREFIX = 'gridOfferDailog';

const classes = {
    closeButton: `${PREFIX}-closeButton`,
    transportProviderSelect: `${PREFIX}-transportProviderSelect`,
    bgCard: `${PREFIX}-bgCard`,
    bottomSpace: `${GRID_PREFIX}-bottopSpace`,
    buying: `${PREFIX}-buying`,
    btn: `${PREFIX}-btn`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {

        [`& .${classes.closeButton}`]: {
            float: 'right',
            cursor: 'pointer'
        },
        [`& .${classes.transportProviderSelect}`]: {
            width: 410,
        },
        [`& .${classes.bgCard}`]: {
            marginTop: 40,
            padding: 30,
            borderRadius: 20,
            background: theme.palette.secondary.grey,
        },
        [`& .${classes.buying}`]: {
            color: theme.palette.primary.normal,
            fontWeight: 700
        },
        [`& .${classes.btn}`]: {
            marginTop: 30,
            background: '#213e97',
            borderRadius: 7,
            width: '100%',
            height: 50,
            textTransform: 'capitalize',
            marginBottom: 40
        }
    }
});

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        marginBottom: 20,
        [`& .${classes.bottomSpace}`]: {
            marginBottom: 10,
        },
    }
});

const validationSchema = yup.object().shape({
    transProvider: yup.string().required("Transport Provider is required"),
});

const BuyNowDialog = ({ isOpen, handleClose, vehicle = {}, transportProvider }) => {
    const dispatch = useDispatch();
    const { search: { loadingShippingOptions, buyNowSent, buyNowLoading } } = useSelector(state => state);
    const { token, title, buy_it_now_price } = vehicle;


    let initialValues = {
        transProvider: 'transport-reinvent-txn_transport_flat_fee',
        amount: buy_it_now_price ? buy_it_now_price : 0
    };

    useEffect(() => {
        if (buyNowSent && buyNowSent === vehicle?.token) {
            toast.success('Buy Now request sent !!!');
            handleClose(true);
        }
    }, [buyNowSent, vehicle?.token]);

    const handleSubmit = async (values) => {
        dispatch(buyNow(values));
    }

    return (
        <StyledDialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { width: "80%" } }}
        >
            <DialogTitle id="alert-dialog-title">
                <Box
                    onClick={() => handleClose()}
                    className={classes.closeButton}
                >
                    <Typography variant="h4">
                        X
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="pl-10 pr-10">
                    <center>
                        <Typography variant="h2">
                            BUY NOW
                        </Typography>
                    </center>

                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(true);
                            const selectedShipping = transportProvider.find((transport) => transport.value === values.transProvider);
                            const transportationCost = (selectedShipping.label?.split('-')).length > 2 ? selectedShipping.label?.split('-')[2] : selectedShipping.label?.split('-')[1];
                            const transportationType = (selectedShipping.value?.split('-')).length > 1 ? selectedShipping.value?.split('-')[2] : '';
                            const shipperChoice = values.transProvider.includes('transport-reinvent') ? 'reinvent' : 'other';
                            const costPerMile = 0;
                            const data = {
                                inventoryId: token,
                                shipperChoice: shipperChoice,
                                transportationType: transportationType,
                                transportationCost: shipperChoice === 'reinvent' ? Number(transportationCost.replace(/\$/g, '')) : 0,
                                transportationCostPerMile: costPerMile,
                                amount: values.amount,
                                token: token
                            };
                            handleSubmit(data);
                            actions.setSubmitting(false);;
                        }}>
                        {({ handleSubmit, handleChange, touched, errors, values }) => {
                            return (
                                <Form autoComplete={false}>
                                    <StyledGrid container className={classes.bgCard}>
                                        <Grid item xs={12} className={classes.bottomSpace}>
                                            <center>
                                                <Typography variant="h2">{title}</Typography>
                                            </center>
                                        </Grid>
                                        <Grid item xs={12} className={classes.bottomSpace}>
                                            <center>
                                                <Typography variant="h2">Price: <span className={classes.buying}>${initialValues.amount}</span></Typography>
                                            </center>
                                        </Grid>
                                        <Grid item xs={12} className="pt-4">
                                            <InputLabel id="transport-provider-label">Select Transport Provider</InputLabel>
                                            <Select
                                                labelId="transport-provider-label"
                                                id="transProvider"
                                                name="transProvider"
                                                label=""
                                                className="offerDialog-transportProviderSelect"
                                                items={transportProvider}
                                            />
                                        </Grid>
                                    </StyledGrid>
                                    <Grid item xs={12}>
                                        <Button
                                            type='submit'
                                            className={classes.btn}
                                        >
                                            BUY NOW
                                        </Button>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>

                    <Spinner show={loadingShippingOptions || buyNowLoading} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    )
}

export default BuyNowDialog;