import React, { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import ContactUs from './ContactUs';
import ConnectWithUs from './ConnectWithUs';
import ConnectHeader from './ConnectHeder';
import Image from "components/widgets/Image";
import LoginDialog from "components/dialogs/Login";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PREFIX = 'Footer';

const classes = {
  footer: `${PREFIX}-footer`,
  card: `${PREFIX}-card`,
  right: `${PREFIX}-right`,
  grid: `${PREFIX}-grid`,
  img: `${PREFIX}-img`,
  footerGrid: `${PREFIX}-footerGrid`,
  logo: `${PREFIX}-logo`,
  footerLogo: `${PREFIX}-footerLogo`,
  footerTypo: `${PREFIX}-footerTypo`,
  logoContainer: `${PREFIX}-logoContainer`
}

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.footer}`]: {
      color: theme.palette.background.default,
      background: theme.palette.background.dark,
      padding: 10,
      justifyContent: 'center',
      [theme.breakpoints.up('xs')]: {
        padding: '10px 20%',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
      [`& p`]: {
        [theme.breakpoints.up('xs')]: {
          width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
          float: 'left',
          paddingRight: 10,
        },
      }
    },
    [`& .${classes.card}`]: {
      background: theme.palette.background.default,
      boxShadow: '0px 9px 43px rgba(0, 0, 0, 0.07)',
      borderRadius: 19,
      margin: '4% 5% 13% 4%',
      padding: 30
    },
    [`& .${classes.right}`]: {
      color: theme.palette.secondary.main
    },
    [`& .${classes.grid}`]: {
      position: 'relative',
      width: '100%',
    },
    [`& .${classes.footerGrid}`]: {
      paddingTop: 200,
      zIndex: -1,
      backgroundImage: 'url(/static/images/Footer.png)',
      // backgroundRepeat: 'no-repeat',
      [theme.breakpoints.up('xs')]: {
        marginTop: -250,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: -340,
      },
    },
    [`& .${classes.logoContainer}`]: {
      [theme.breakpoints.only('xs')]: {
        marginLeft: 10,
        marginTop: 20,
      },
    },
    [`& .${classes.logo}`]: {
      height: 70
    },
    [`& .${classes.footerLogo}`]: {
      height: 25,
      aspectRatio: 'auto',
      float: 'left',
      marginRight: 10,
      marginBottom: 10,
    },
    [`& .${classes.footerTypo}`]: {
      color: theme.palette.text.white,
      paddingLeft: 5,
      fontSize: 16,
    },
  }
});

const Footer = ({ nav }) => {
  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <StyledGrid>
      <Grid container className={classes.grid}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container item xs={12}>
              <ConnectHeader />
              <Grid xs={12} md={5}><ConnectWithUs /></Grid>
              <Grid xs={12} md={7} className="pl-16"><ContactUs /></Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container className={classes.footerGrid}>
          <Grid item xs={12} sm={3}>
            <Link to="/"><Image src="/static/images/newLogo.png" className={classes.logo} /></Link>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container>
              <Grid xs={12} sm={9}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12} sm={4}>
                      <Image src="/static/images/calling.png" className={classes.footerLogo} />
                      <Typography variant="h6" className={classes.footerTypo}>Call Us(262)228-8742</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Link onClick={() => nav('how')}>
                        <Image src="/static/images/InfoSquare.png" className={classes.footerLogo} />
                        <Typography variant="h6" className={classes.footerTypo}>How ReinventExchange Works</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Link to="/signup">
                        <Image src="/static/images/InfoSquare.png" className={classes.footerLogo} />
                        <Typography variant="h6" className={classes.footerTypo}>Join Dealer Network</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                      <Image src="/static/images/Schedule.png" className={classes.footerLogo} />
                      <Typography variant="h6" className={classes.footerTypo}>Schedule Demo</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Link onClick={() => nav('contactus')}>
                        <Image src="/static/images/SMS.png" className={classes.footerLogo} />
                        <Typography variant="h6" className={classes.footerTypo}>Contact Us</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Link onClick={() => setIsOpen(true)}>
                        <Image src="/static/images/DealerLogin.png" className={classes.footerLogo} />
                        <Typography variant="h6" className={classes.footerTypo}>Dealer Login</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box className={classes.logoContainer}>
                  <Image src="/static/images/LinkdlnLogo.png" className={classes.footerLogo} />
                  <Image src="/static/images/twitterLogo.png" className={classes.footerLogo} />
                  <Image src="/static/images/facebookLogo.png" className={classes.footerLogo} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <Link to='/'>
          <Typography variant='body1' color='text.white'>©2021 ReinventX<span className={classes.right}>change</span>, Inc. {isSmallScreen ? '' : '|'}</Typography>
        </Link>
        <Link to='/terms-of-use'>
          <Typography variant='body1' color='text.white'>Terms of Use {isSmallScreen ? '' : '|'}</Typography>
        </Link>
        <Link to='/privacy-policy'>
          <Typography variant='body1' color='text.white'>Privacy Policy</Typography>
        </Link>
      </Grid>

      <LoginDialog
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </StyledGrid>
  )
}

export default Footer;