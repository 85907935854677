/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";

const PREFIX = 'DealerDrivenPanel';

const classes = {
    background: `${PREFIX}-background`,
    img: `${PREFIX}-img`,
    title: `${PREFIX}-flex`,
    detailone: `${PREFIX}-detailone`,
    detailtwo: `${PREFIX}-detailtwo`,
    card: `${PREFIX}-card`,
    flexBox: `${PREFIX}-flexBox`,
    starImg: `${PREFIX}-starImg`,
    header: `${PREFIX}-header`,
    innerHeader: `${PREFIX}-innerHeader`,
    textContainer: `${PREFIX}-textContainer`,
    cardContainer: `${PREFIX}-cardContainer`
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        marginTop: 50,
        [`& .${classes.textContainer}`]: {
            marginTop: 20,
        },
        [`& .${classes.background}`]: {
            margin: '10px 10px 40px 60px',
        },
        [`& .${classes.img}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.title}`]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        [`& .${classes.detailone}`]: {
            textIndent: 20
        },
        [`& .${classes.detailtwo}`]: {
            textIndent: 20,
            paddingTop: 20
        },
        [`& .${classes.cardContainer}`]: {
            // paddingLeft: 35
        },
        [`& .${classes.card}`]: {
            width: 290,
            height: 220,
            float: 'left',
            marginLeft: 15,
            background: theme.palette.background.default,
            boxShadow: '0px 15px 19px rgb(33 62 152 / 15%)',
            borderRadius: 8,
            padding: 20,
            textAlign: 'center',
            border: '1.10132px solid rgba(156, 163, 177, 0.5)',
            color: theme.palette.secondary.main,
            [theme.breakpoints.up('xs')]: {
                marginTop: 20
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: 0
            },
        },
        [`& .${classes.flexBox}`]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        [`& .${classes.starImg}`]: {
            height: 35,
            width: 35
        },
        [`& .${classes.header}`]: {
            marginTop: 20
        },
        [`& .${classes.innerHeader}`]: {
            marginTop: 20,
            color: theme.palette.primary.main,
            fontWeight: 600
        },
    }
});

const DealerDrivenPanel = () => {
    return (
        <StyledGrid container>
            <Grid
                container
                className={classes.background}
            >
                <Grid item xs={12}>
                    <Box className={classes.title}>
                        <Image
                            alt="Banner Image"
                            src='/static/images/ReinventLogo.png'
                            className={classes.img}
                        />
                        <Typography variant="h1" color="#213E98">Dealer Designed. Dealer Driven.</Typography>
                    </Box>

                    <Grid container className={classes.textContainer}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h3" className={classes.detailone}>
                                Reinvent Auction is the home of the online wholesaletrading platform  to buy and sell vehicles with the click of a button.
                                We have Auto, Powersport and RV Dealers for a huge variety of vehicles to Buy, Sell,
                                Auction and Trade.Frictionless transactions directly from your DMS to delivery.
                                Super Easy. Super Simple.Join.Click.Trade.
                            </Typography>
                            <Typography variant="h3" className={classes.detailtwo}>
                                The Reinvent Exchange program fuctions as an online auction to facilitate easy dealer engagements in real-time.
                                The Goal is smooth B2B transactions with no interference from auction house middle men.
                                Dealers interect directly with other dealers for faster,
                                easier transactions on an online platform so a dealer from New York can
                                easily trade or sell with a dealer in California!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.cardContainer}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Box className={classes.flexBox}>
                                        <Image
                                            alt="Banner Image"
                                            src='/static/images/StarIcon.png'
                                            className={classes.starImg}
                                        />
                                        <Typography variant="h2" className={classes.header}>
                                            Never
                                        </Typography>
                                        <Typography variant="h6" className={classes.innerHeader}>
                                            Ship your vehicle to another physical auction
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Box className={classes.flexBox}>
                                        <Image
                                            alt="Banner Image"
                                            src='/static/images/StarIcon.png'
                                            className={classes.starImg}
                                        />
                                        <Typography variant="h2" className={classes.header}>
                                            Never
                                        </Typography>
                                        <Typography variant="h6" className={classes.innerHeader}>
                                            Pay high auction fees
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledGrid>
    )
}

export default DealerDrivenPanel;