import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Image from "components/widgets/Image";
import Typography from "@mui/material/Typography";
import LoginDialog from "components/dialogs/Login";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Link from '@mui/material/Link';

const PREFIX = 'Header';

const classes = {
    header: `${PREFIX}-header`,
    content: `${PREFIX}-content`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    btn: `${PREFIX}-btn`,
    signUp: `${PREFIX}-signup`,
    menuItem: `${PREFIX}-menu-item`,
    selMenuItem: `${PREFIX}-sel-menu-item`,
    linksContainer: `${PREFIX}-linksContainer`,
    selectedMenuItem: `${PREFIX}-selectedMenuItem`,
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        background: theme.palette.background.default,
        boxShadow: '0px 9px 10px rgb(0 0 0 / 5%)',
        [theme.breakpoints.up('xl')]: {
            padding: '0 200px'
        },
        [`& .${classes.linksContainer}`]: {
            [theme.breakpoints.up('xs')]: {
                paddingLeft: 50,
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: 50,
                marginTop: '5%',
            }
        },
        [`& .${classes.buttonContainer}`]: {
            [theme.breakpoints.up('xs')]: {
                paddingLeft: 50,
                marginTop: 20,
                marginBottom: 20,
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '2%',
            }
        },
        [`& .${classes.btn}`]: {
            color: theme.palette.background.default,
            backgroundColor: theme.palette.primary.normal,
            background: 'radial-gradient(89.22% 89.22% at 58.82% 50.98%, #FF4D53 0%, #ED1D24 100%)',
            borderRadius: 19.5,
            textTransform: 'capitalize',
            float: 'right',
            fontSize: 13,
            [theme.breakpoints.up('xs')]: {
                marginLeft: 70,
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: 0,
            }
        },
        [`& .${classes.signUp}`]: {
            color: theme.palette.background.default,
            width: 145,
            height: 38,
            backgroundColor: theme.palette.primary.normal,
            fontSize: 13,
            background: 'radial-gradient(89.22% 89.22% at 58.82% 50.98%, #6280DE 0%, #213E98 100%)',
            borderRadius: 19.5,
            textTransform: 'capitalize',
            float: 'right',
            marginRight: 10,
            [theme.breakpoints.up('xs')]: {
                marginLeft: 10,
            },
        },
        [`& .${classes.menuItem}`]: {
            fontWeight: 500,
            fontSize: 18,
            lineHeight: 28,
            color: '#253241',
            textDecoration: "none",
            cursor: 'pointer',
        },
        [`& .${classes.selectedMenuItem}`]: {
            fontWeight: 500,
            fontSize: 18,
            lineHeight: 28,
            color: theme.palette.secondary.main,
            textDecoration: "none"
        },
    }
});

const Header = ({ nav }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isMenu, setIsMenu] = useState('home');

    const handleOpen = () => {
        setIsOpen(true);
    }
    const handleClose = () => {
        setIsOpen(false);
    }

    const handleMenuClick = (menuItem) => {
        setIsMenu(menuItem);
        nav(menuItem);
    }

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} lg={3}>
                        <Link onClick={
                            () => navigate('/')
                        }>
                            <Image
                                alt="Reinvent Exchange Logo"
                                src='/static/images/reinvent-exchange.jpeg'
                            />
                        </Link>
                    </Grid>

                    <Grid item lg={5} xs={12}>
                        <Grid container className={classes.linksContainer}>
                            <Grid item xs={2}>
                                <Link
                                    className={isMenu === 'home' ? classes.selectedMenuItem : classes.menuItem}
                                    onClick={
                                        () => handleMenuClick('home')
                                    }
                                >
                                    <Typography variant='h5' >Home</Typography>
                                </Link>
                            </Grid>

                            <Grid item xs={3}>
                                <Link
                                    className={isMenu === 'how' ? classes.selectedMenuItem : classes.menuItem}
                                    onClick={
                                        () => handleMenuClick('how')
                                    }
                                >
                                    <Typography variant='h5' >How It Works</Typography>
                                </Link>
                            </Grid>

                            <Grid item xs={2}>
                                <Link
                                    className={isMenu === 'news' ? classes.selectedMenuItem : classes.menuItem}
                                    onClick={
                                        () => handleMenuClick('news')
                                    }
                                >
                                    <Typography variant='h5' >News</Typography>
                                </Link>
                            </Grid>

                            <Grid item xs={2}>
                                <Link
                                    className={isMenu === 'aboutus' ? classes.selectedMenuItem : classes.menuItem}
                                    onClick={
                                        () => handleMenuClick('aboutus')
                                    }
                                >
                                    <Typography variant='h5' >About Us</Typography>
                                </Link>
                            </Grid>

                            <Grid item xs={2} lg={3} xl={2}>
                                <Link
                                    className={isMenu === 'contactus' ? classes.selectedMenuItem : classes.menuItem}
                                    onClick={
                                        () => handleMenuClick('contactus')
                                    }
                                >
                                    <Typography variant='h5' >Contact Us</Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        lg={4}
                        className={classes.buttonContainer}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.signUp}
                            size="large"
                            onClick={() => { handleOpen() }}
                        >
                            Dealer Login
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.btn}
                            size="large"
                            onClick={() => { navigate('/signup') }}
                        >
                            Dealer Sign Up
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <LoginDialog
                isOpen={isOpen}
                handleClose={handleClose}
            />
        </StyledGrid>

    )
}

export default Header;