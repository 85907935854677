import React, { useState } from "react";
import * as yup from "yup";
import { Form, Formik } from 'formik';
import styled from "@emotion/styled";
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import UploadFiles from "components/widgets/UploadFiles";
import Button from "components/widgets/Button";
import Spinner from "components/Spinner";
import S3Upload from "utils/s3Upload";

const validationSchema = yup.object().shape({
    files: yup.mixed().required('Please select condition photo.')
});

let initialValues = {
    files: null,
};

const PREFIX = 'ConditionPhoto';

const classes = {
    submit: `${PREFIX}-submit`,
    closeBtn: `${PREFIX}-closeBtn`,
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.submit}`]: {
            float: 'right',
            marginTop: 10
        },
        [`& .${classes.closeBtn}`]: {
            float: 'right',
            marginTop: 10,
            marginRight: 10
        },
    }
});

const UploadConditionPhoto = (
    {
        open,
        handleClose,
        vehicle
    }) => {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleSubmit = (values, setFieldError) => {
        const s3UploaderProps = {
            autoUpload: true,
            signingUrl: `/api/vehicle/condition/report/photos/link/${vehicle.token}/1`,
            signingUrlMethod: 'GET',
            signingUrlHeaders: {},
            // signingUrlQueryParams: queryParams,
            uploadRequestHeaders: {},
            withCredentials: true,
            onFinishS3Put: (signResult, file) => {
                setLoading(false);

                handleClose(true);
                toast.success('Vehicle Condition Photo Uploaded Successfully!!!');
                // setProgress(0);
                // setClockToggle(false);
                //
                // // need to apply the same scrubbing of filename here as in s3Uploader...
                // setFieldValue(name, [
                //     ...values[name],
                //     {
                //         file: {
                //             name: file.name.replace(/[^\w\d_\-\.]+/ig, ''),
                //             path: file.path.replace(/[^\w\d_\-\.]+/ig, ''),
                //             type: file.type
                //         },
                //         verification: {
                //             opened: false
                //         },
                //         date: new Date().toISOString(),
                //         user: currentUser
                //     }
                // ]);
                // setSaveSuccess(true);
            },
            onProgress: (evt) => {
                console.log('progress', evt)
                // if (!clockToggle) {
                //     setClockToggle(true);
                // }
                //
                setLoading(true);
                setProgress(evt);
            },
            onError: (evt) => {
                setLoading(false);
                // setClockToggle(false);
                setProgress(0);
                //
                // setErrorNotification(true);
                setFieldError('fields', 'A problem was encountered during the upload operation, please try again or contact Groupware support.')
            }
        };

        new S3Upload({ ...s3UploaderProps, files: values.files });
    }

    return (
        <StyledDialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 700, overflowY: 'hidden' } }}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1">
                    UPLOAD CONDITION PHOTOS
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values, meta) => {
                            handleSubmit(values, meta.setFieldError);
                        }}>
                        {({ handleSubmit, values, setFieldValue }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <UploadFiles name='files' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.submit}
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.closeBtn}
                                                onClick={() => handleClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                    <Spinner show={loading} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    );
}

export default UploadConditionPhoto;
