import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import styled from "@emotion/styled";

const PREFIX = 'Sorting'

const classes = {
    tabs: `${PREFIX}-tabs`,
    sort: `${PREFIX}-sort`
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.tabs}`]: {
            textTransform: 'capitalize',
            fontSize: '19px',
            fontWeight: 800
        },
        [`& .${classes.sort}`]: {
            marginTop: '10px'
        },
    }
});

const SortingPanel = ({ sort, setSort, resultType }) => {
    const handleTabChange = (event, newValue) => {
        localStorage.setItem('sort', newValue);
        setSort(newValue);
    };

    return (
        <StyledGrid container>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={sort}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant='scrollable' onChange={handleTabChange} textColor="secondary">
                            {resultType === 'auctionPanel' && <Tab label="Ending Soon" value="ending_soon" className={classes.tabs} />}
                            <Tab label="Lowest Price" value="lowest_price" className={classes.tabs} />
                            <Tab label="Highest Price" value="highest_price" className={classes.tabs} />
                            <Tab label="Newest" value="newest" className={classes.tabs} />
                            <Tab label="Oldest" value="oldest" className={classes.tabs} />
                            <Tab label="Highest Odometer" value="highest_mileage" className={classes.tabs} />
                            <Tab label="Lowest Odometer" value="lowest_mileage" className={classes.tabs} />
                            <Tab label="Distance" value="distance" className={classes.tabs} />
                        </TabList>
                    </Box>
                </TabContext>
            </Box>
        </StyledGrid>
    )
}

export default SortingPanel;