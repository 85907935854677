import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styled from "@emotion/styled";
import theme from 'styles/theme';
import { Button } from "@mui/material";

const PREFIX = 'report';

const classes = {
    dialogContent: `${PREFIX}-dialogContent`,
    viewReport: `${PREFIX}-viewReport`,
    card: `${PREFIX}-card`
}
const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.dialogContent}`]: {
            border: `1px solid ${theme.palette.border.default}`,
        },
        [`& .${classes.viewReport}`]: {
            margin: '7px 12px 0px 0px',
            background: theme.palette.background.default,
            color: theme.palette.secondary.main,
            width: 120
        },
        [`& .${classes.card}`]: {
            marginTop: 10
        },
    }
});

const ViewReportDialog = ({ isOpen, handleClose, details }) => {
    return (
        <StyledDialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'lg'}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant='h1'>CONDITION REPORT</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {details?.length > 0 ? details?.map((detail) => (
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant='h6'>{detail.name}</Typography>
                            <Typography variant='h6' color={theme.palette.text.grey}>{detail.condition}</Typography>
                        </CardContent>
                    </Card>
                )) : ''}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => handleClose(false)}
                    className={classes.viewReport}
                >
                    Close
                </Button>
            </DialogActions>
        </StyledDialog>
    )
}

export default ViewReportDialog;