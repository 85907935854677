import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SortingPanel from './components/SortingPanel';
import styled from "@emotion/styled";

const PREFIX = 'SortBox'

const classes = {
    tabs: `${PREFIX}-tabs`,
    sort: `${PREFIX}-sort`
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.tabs}`]: {
            textTransform: 'capitalize',
            fontSize: 19,
        },
        [`& .${classes.sort}`]: {
            marginTop: 10
        },
    }
});

const Sort = ({
    sort,
    setSort,
    resultType
}) => {
    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Grid container className='mt-6'>
                    <Grid item sm={1} sx={{display: {xs: 'none', sm: 'block'}}}>
                        <Typography variant='h2' className={classes.sort}> Sort By:</Typography>
                    </Grid>
                    <Grid item sm={11}>
                        <SortingPanel
                            sort={sort}
                            setSort={setSort}
                            resultType={resultType}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </StyledGrid>
    )
}

export default Sort;