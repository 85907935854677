import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  auctionTransactions: [],
  processing: false,
  loadingTransactions: false,
  updatingTitleProcessing: false,
  signingLink: '',
  paymentLink: '',
  acceptMes: '',
  actionType: undefined
};

export const transactionSlice = createSlice({
  processing: false,
  name: 'transaction',
  initialState,
  reducers: {
    initialize: () => {
      return initialState
    },
    starting: (state, action) => {
      return {
          ...state,
          processing: true
      }
  },
    gettingAuctionTransactions: (state, action) => {
      return {
        ...state,
        loadingTransactions: action.payload.showProcessing,
        isProcessingTransactions: true,
        error: undefined
      }
    },
    gettingAuctionTransactionsSuccess: (state, action) => {
      return {
        ...state,
        loadingTransactions: false,
        transactionType: action.payload.transactionType,
        auctionTransactions: action.payload.transactions,
        isProcessingTransactions: false,
        error: undefined
      }
    },
    gettingAuctionTransactionsFailed: (state, action) => {
      return {
        ...state,
        loadingTransactions: false,
        isProcessingTransactions: false,
        error: action.payload.error
      }
    },
    getAuctionTransactionCount: (state, action) => {
      return {
        ...state,
        isLoadingAuctionTransactionCount: true,
        error: undefined
      }
    },
    getAuctionTransactionCountSuccess: (state, action) => {
      return {
        ...state,
        transactionCount: action.payload.transactionCount,
        isLoadingAuctionTransactionCount: false,
        error: undefined
      }
    },
    getAuctionTransactionCountFailed: (state, action) => {
      return {
        ...state,
        isLoadingAuctionTransactionCount: false,
        error: action.payload.error
      }
    },
    creatingTransportation: (state, action) => {
      return {
        ...state,
        processingTransportation: true,
        error: undefined
      }
    },
    creatingTransportationSuccess: (state, action) => {
      return {
        ...state,
        processingTransportation: false,
        error: undefined
      }
    },
    creatingTransportationFailed: (state, action) => {
      return {
        ...state,
        processingTransportation: false,
        error: action.payload.error
      }
    },
    generatingNewSignLink: (state, action) => {
      return {
        ...state,
        processingSignLink: true,
        error: undefined
      }
    },
    generatingNewSignLinkSuccess: (state, action) => {
      return {
        ...state,
        processingSignLink: false,
        signingLink: action.payload.link,
        error: undefined
      }
    },
    generatingNewSignLinkFailed: (state, action) => {
      return {
        ...state,
        processingSignLink: false,
        signingLink: undefined,
        error: action.payload.error
      }
    },
    generatingNewPaymentLink: (state, action) => {
      return {
        ...state,
        generatingPaymentLink: true,
        error: undefined
      }
    },
    generatingNewPaymentLinkSuccess: (state, action) => {
      return {
        ...state,
        generatingPaymentLink: false,
        paymentLink: action.payload.link,
        error: undefined
      }
    },
    generatingNewPaymentLinkFailed: (state, action) => {
      return {
        ...state,
        generatingPaymentLink: false,
        paymentLink: undefined,
        error: action.payload.error
      }
    },
    acceptingVehicle: (state, action) => {
      return {
        ...state,
        acceptingVehicle: false,
        acceptMes: action.payload
      }
    },
    acceptingVehicleSuccess: (state, action) => {
      return {
        ...state,
        acceptingVehicle: false,
        acceptMes: action.payload
      }
    },
    acceptingVehicleFailed: (state, action) => {
      return {
        ...state,
        acceptingVehicle: false,
        acceptMes: undefined,
        error: action.payload.error
      }
    },
    updatingTitleProcess: (state, action) => {
      return {
        ...state,
        updatingTitleProcessing: true,
        error: undefined
      }
    },
    updatingTitleProcessSuccess: (state, action) => {
      return {
        ...state,
        updatingTitleProcessing: false,
        error: undefined
      }
    },
    updatingTitleProcessFailed: (state, action) => {
      return {
        ...state,
        updatingTitleProcessing: false,
        error: action.payload.error
      }
    },
    updateTitleProcess: (state, action) => {
      return {
        ...state,
        updatingTitleProcessing: false,
        error: undefined
      }
    },
    updateTitleProcessSuccess: (state, action) => {
      return {
        ...state,
        updatingTitleProcessing: false,
        error: undefined
      }
    },
    updateTitleProcessFailed: (state, action) => {
      return {
        ...state,
        updatingTitleProcessing: false,
        error: action.payload.error
      }
    },
  }
});

export const {
  gettingAuctionTransactions,
  gettingAuctionTransactionsSuccess,
  gettingAuctionTransactionsFailed,
  getAuctionTransactionCount,
  getAuctionTransactionCountSuccess,
  getAuctionTransactionCountFailed,
  creatingTransportation,
  creatingTransportationSuccess,
  creatingTransportationFailed,
  generatingNewSignLink,
  generatingNewSignLinkSuccess,
  generatingNewSignLinkFailed,
  generatingNewPaymentLink,
  generatingNewPaymentLinkSuccess,
  generatingNewPaymentLinkFailed,
  acceptingVehicle,
  acceptingVehicleSuccess,
  acceptingVehicleFailed,
  updatingTitleProcess,
  updateTitleProcess,
  updateTitleProcessSuccess,
  updateTitleProcessFailed
} = transactionSlice.actions

export default transactionSlice.reducer