/* eslint-disable jsx-a11y/img-redundant-alt */
import { Carousel } from "react-responsive-carousel";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PhotoViewer = ({ photos, rootUrl, className }) => {
  return (
    <div className="overflow-hidden rounded-lg shadow-lg bg-white relative">
      <Carousel
        className={className}
        showStatus={false}
        showThumbs={photos && photos.length > 0}
        showArrows
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${hasPrev ? "absolute" : "hidden"
                } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <KeyboardArrowLeftIcon sx={{ width: '40px', height: '40px', color: "black" }} />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${hasNext ? "absolute" : "hidden"
                } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20 `}
              onClick={clickHandler}
            >
              <KeyboardArrowRightIcon sx={{ width: '40px', height: '40px', color: "black" }} />
            </div>
          );
        }}
      >
        {photos && photos.map((photo, index) => {
          const imageUrl = `${rootUrl ? rootUrl : ''}${photo.replace(/"|'/g, '') || 'missing.png'}`;

          return (
            <div key={photo} className=" h-[600px] mx-auto">
              <img alt={`auto-photo-${index}`} src={imageUrl}  className="object-contain"/>
            </div>
          );
        })}
      </Carousel>
    </div>
  )
}

export default PhotoViewer;