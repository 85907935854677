import Grid from "@mui/material/Grid";
import React, {useEffect} from "react";
import LocationAccessPanel from "./LocationAccessPanel";
import {useDispatch, useSelector} from "react-redux";
import {loadLocationRoles} from "data/user.actions";
import {FieldArray, useField} from "formik";
import Button from "components/widgets/Button";
import Typography from "@mui/material/Typography";
import {hasRooftopPermission} from 'utils/User';

const UserAccessPanel = ({classes, locationSelections, permittedLocationSelections, handleAccessRemoved, handleDefaultChanged, ...props}) => {
  const [field, meta] = useField(props);
  const dispatch = useDispatch();
  const {locationRoles} = useSelector(state => state.user);
  const loggedInUser = useSelector(state => state.authentication.user);

  useEffect(() => {
    if (!locationRoles) {
      dispatch(loadLocationRoles());
    }
  }, [locationRoles, dispatch]);

  const handleRemoveAccess = (helpers, index) => {
    helpers.remove(index);
    handleAccessRemoved(field.value[index]);
  }

  return (
      <FieldArray
          name={field.name}
          render={arrayHelpers => (
              <Grid container>
                <Grid item xs={12}>
                  {field.value && field.value.map((value, index) => {
                    let userPermittedToEdit = loggedInUser && (loggedInUser.isDealerAdmin || hasRooftopPermission(loggedInUser, value.dealerRooftopId, 'edit_users') || !value.dealerRooftopId);
                    let appropriateSelections
                    if(userPermittedToEdit){
                      appropriateSelections = permittedLocationSelections
                    } else {
                      appropriateSelections = locationSelections
                    }
                    return(
                      <div style={{display: userPermittedToEdit ? 'block' : 'none'}}>
                        <LocationAccessPanel
                            key={`locationAccess-${value.userRooftopAccessId}`}
                            classes={classes}
                            locationSelections={appropriateSelections}
                            locationRoles={locationRoles}
                            name={`${field.name}[${index}]`}
                            handleDefaultChanged={handleDefaultChanged}
                            handleRemoveAccess={() => {handleRemoveAccess(arrayHelpers, index)}}
                        />
                      </div>
                    )
                  })
                }

                  <Typography variant='body1' color='error' style={{marginTop: 5, float: 'left'}}>{meta.error}</Typography>
                </Grid>

                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button
                      className={classes.button}
                      onClick={() => arrayHelpers.push({locationRoles: []})}
                  >
                    Add Access
                  </Button>
                </Grid>
              </Grid>
          )}
      />
    )
};

export default UserAccessPanel;