import React from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from 'formik';
import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import Button from "components/widgets/Button";
import CheckboxInput from "components/widgets/Checkbox";
import TextField from "components/widgets/TextField";
import Spinner from "components/Spinner";
import { acceptVehicle } from "data/transaction.actions";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
    rating: yup.string().required("You must enter a dealer rating for this transaction"),
});

let initialValues = {
    review: false,
    rating: ''
};

const PREFIX = 'Review';

const classes = {
    submit: `${PREFIX}-submit`,
    closeBtn: `${PREFIX}-closeBtn`,
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.submit}`]: {
            float: 'right',
            marginTop: 10
        },
        [`& .${classes.closeBtn}`]: {
            float: 'right',
            marginTop: 10,
            marginRight: 10
        },
    }
});

const ReviewConfirmationDialog = (
    {
        open,
        handleClose,
        transaction
    }) => {
    const dispatch = useDispatch();
    const { processing } = useSelector(state => state.transaction);
    const { sellerId, buyerId, transactionId, inventoryId } = transaction || {};

    return (
        <StyledDialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 700, overflowY: 'hidden' } }}
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1">
                    REVIEW AND CONFIRMATION
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            let data = {
                                sellerId,
                                buyerId,
                                rating: values.rating,
                                description: values.details,
                                inventoryId: inventoryId,
                                transactionId,
                                isAccepted: values.review === true ? 1 : 0,
                            };
                            dispatch(acceptVehicle(data));
                            toast.success('Vehicle Accepted Successfully.');
                            handleClose();
                        }}>
                        {({ handleSubmit, handleChange, setFieldValue }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <CheckboxInput
                                                text='By checking this box, you accept the vehicle has been delivered and is in satisfactory condition.'
                                                name='review'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className="flex">
                                                <Box>
                                                    <Typography variant="h6">Rating</Typography>
                                                </Box>
                                                <Box className="ml-2">
                                                    <Rating
                                                        name="rating"
                                                        onChange={(event, newValue) => {
                                                            setFieldValue('rating', newValue);
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="details"
                                                label="Details"
                                                multiline
                                                rows={6}
                                                variant="outlined"
                                                className="multiline"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.submit}
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.closeBtn}
                                                onClick={() => handleClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                    <Spinner show={processing} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    );
}

export default ReviewConfirmationDialog;
