import React from 'react';
import { TabPanel } from '@mui/lab';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { loadRooftopsForFilter } from 'data/location.actions';
import { useDispatch } from 'react-redux';
import { selectMultipleOption } from 'utils/Filter';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const DealerAndRooftopFilter = ({ criteria }) => {
  const {
    search: { vehicleOption },
    location,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  let vehDealers = criteria.vehDealer;
  let rooftops = criteria.rooftops;

  const getRooftops = (value) => {
    dispatch(loadRooftopsForFilter({ dealerToken: value }));
  };

  return (
    <>
      <TabPanel value='dealer'>
        <StyledFilterGrid>
          <Grid container spacing={1}>
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  (
                    localStorage.hasOwnProperty('vehDealers')
                      ? localStorage.getItem('vehDealers') === ''
                      : criteria.vehDealer === ''
                  )
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value=''
                onClick={(e) => {
                  criteria.setPage(1);
                  localStorage.setItem('vehDealers', e.target.value);
                  criteria.handleDealer(e.target.value);
                  getRooftops(e.target.value);
                }}
              >
                All
              </StyledOptionButton>
            </Grid>
            {vehicleOption.dealer?.map((dealer) => (
              <Grid className='ml-4 mt-2'>
                <StyledOptionButton
                  variant='contained'
                  className={
                    localStorage
                      .getItem('vehDealers')
                      ?.includes(dealer.dealer_token)
                      ? classes.btnColor
                      : classes.styledBtn
                  }
                  color='primary'
                  size='large'
                  value={dealer.dealer_token}
                  onClick={(e) => {
                    criteria.setPage(1);
                    selectMultipleOption(
                      'vehDealers',
                      vehDealers,
                      e.target.value,
                      criteria.handleDealer
                    );
                    getRooftops(e.target.value);
                  }}
                >
                  {dealer.name}
                </StyledOptionButton>
              </Grid>
            ))}
          </Grid>
        </StyledFilterGrid>
      </TabPanel>
      <TabPanel value='rooftop'>
        <StyledFilterGrid>
          <Grid container spacing={1}>
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  (
                    localStorage.hasOwnProperty('rooftops')
                      ? localStorage.getItem('rooftops') === ''
                      : criteria.rooftops === ''
                  )
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value=''
                onClick={(e) => {
                  criteria.setPage(1);
                  localStorage.setItem('rooftops', e.target.value);
                  criteria.handleRooftop(e.target.value);
                }}
              >
                All
              </StyledOptionButton>
            </Grid>
            {location.rooftopsForFilter?.map((rooftop) => (
              <Grid className='ml-4 mt-2'>
                <StyledOptionButton
                  variant='contained'
                  className={
                    localStorage
                      .getItem('rooftops')
                      ?.includes(rooftop.dealerRooftopId)
                      ? classes.btnColor
                      : classes.styledBtn
                  }
                  color='primary'
                  size='large'
                  value={rooftop.dealerRooftopId}
                  onClick={(e) => {
                    criteria.setPage(1);
                    selectMultipleOption(
                      'rooftops',
                      rooftops,
                      e.target.value,
                      criteria.handleRooftop
                    );
                  }}
                >
                  {rooftop.rooftopName}
                </StyledOptionButton>
              </Grid>
            ))}
          </Grid>
        </StyledFilterGrid>
      </TabPanel>
    </>
  );
};

export default DealerAndRooftopFilter;