import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";

const PREFIX = 'TeamPanel';

const classes = {
    img: `${PREFIX}-dealer-img`,
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    border: `${PREFIX}-border`,
    container: `${PREFIX}-container`
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        marginTop: 50,
        background: 'rgba(237, 237, 237, 0.4)',
        [`& .${classes.img}`]: {
            marginTop: 50,
            paddingLeft: 10
        },
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        [`& .${classes.logo}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.container}`]: {
            padding: 20,
        },
        [`& .${classes.border}`]: {
            width: 150,
            marginBottom: 10,
            borderTop: `3px solid ${theme.palette.secondary.main}`,
            float: 'left'
        },
    }
});

const TeamPanel = () => {
    return (
        <StyledGrid>
            <Grid item xs={12} className={classes.container}>
                <Box className={classes.flex}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/ReinventLogo.png'
                        className={classes.logo}
                    />
                    <Typography variant='h1' color="#213E98">The Reinvent <span className={classes.right}>Leadership</span> team</Typography>
                </Box>
                <hr className={classes.border} />
            </Grid>
            <Grid xs={12} className={classes.img}>
                <Image
                    alt="Banner Image"
                    src='/static/images/team.png'
                />
            </Grid>
        </StyledGrid>
    )
}

export default TeamPanel;