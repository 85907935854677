/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, Fragment } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from 'formik';
import moment from 'moment';
import Spinner from "components/Spinner";
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RETextField from '../widgets/TextField';
import Button from "components/widgets/Button";
import styled from "@emotion/styled";
import { toast } from 'react-toastify';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { addAuction, getAuctionValidation } from "data/vehicle.actions";
import NumberFormatCustom from "components/widgets/NumberFormatCustom";
import InfoPopover from "components/widgets/InfoPopover";

const validationSchema = yup.object().shape({
    minimumSellingPrice: yup.number().required("Minimum Price is required."),
    startingBid: yup.number().required("Starting bid is required."),
    buyNowPrice: yup.number()
        .when('minimum_selling_price', (minimum_selling_price) => {
            if (yup.number()) {
                return yup.number().integer().nullable().moreThan(minimum_selling_price, 'Buy now price must be greater than minimum price.')
            }
        }),
});

let initialValues = {
    minimumSellingPrice: '',
    startingBid: '',
    buyNowPrice: '',
};

const PREFIX = 'PlaceAuction';

const classes = {
    submit: `${PREFIX}-submit`,
    closeBtn: `${PREFIX}-closeBtn`,
    box: `${PREFIX}-box`,
    formControl: `${PREFIX}-formControl`,
    textField: `${PREFIX}-textField`,
    customDateTimePicker: `${PREFIX}-customDateTimePicker`,
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.submit}`]: {
            float: 'right',
            marginTop: 20
        },
        [`& .${classes.closeBtn}`]: {
            float: 'right',
            marginTop: 20,
            marginRight: 10
        },
        [`& .${classes.box}`]: {
            padding: 20,
            background: 'rgba(156, 163, 177, 0.1)',
            borderRadius: 17,
            height: 460
        },
        [`& .${classes.formControl}`]: {
            marginTop: 10,
        },
        [`& .${classes.textField}`]: {
            width: 240,
            marginTop: 10,
        },
        [`& .${classes.customDateTimePicker}`]: {
            minWidth: 255,
        }
    }
});

const PlaceAuctionDialog = (
    {
        open,
        handleClose,
        vehicle,
        isInternal
    }) => {
    const dispatch = useDispatch();
    const { search: { processing, validationParams, auctionStarted } } = useSelector(state => state);

    const [minStartDate, setMinStartDate] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [minEndDate, setMinEndDate] = useState();

    useEffect(() => {
        if (validationParams) {
            let defaultEndDate = moment();
            defaultEndDate.add(1, 'hours');
            const today = moment();

            setMinStartDate(today);
            setStartDate(today);
            setEndDate(defaultEndDate);
            setMinEndDate(defaultEndDate);

            initialValues.startDate = today;
            initialValues.endDate = defaultEndDate;
        }
    }, [validationParams]);

    useEffect(() => {
        if (auctionStarted && auctionStarted === vehicle?.token) {
            toast.success('Auction Successfully Saved.');
            handleClose();
        }
    }, [auctionStarted]);

    useEffect(() => {
        if (open && vehicle) {
            const data = {
                inventoryToken: vehicle?.token
            }
            dispatch(getAuctionValidation(data));
        }
    }, [open, vehicle, dispatch]);

    return (
        <StyledDialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 700, overflowY: 'hidden' } }}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1">
                    PLACE ON AUCTION
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            const params = {
                                inventory_id: vehicle?.token,
                                vin: vehicle?.vin,
                                minimum_price: values?.minimumSellingPrice,
                                starting_bid: values?.startingBid,
                                buy_now_price: values?.buyNowPrice,
                                start_date: values.startDate.toISOString(),
                                end_date: values.endDate.toISOString(),
                                auction_type_name: "dealer_auction",
                                is_internal: isInternal,
                                dealer_id: vehicle?.dealer_id,
                            }
                            dispatch(addAuction(params));
                        }}>
                        {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box className={classes.box}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <RETextField
                                                            name='minimumSellingPrice'
                                                            label={
                                                                <Fragment>
                                                                    Minimum Selling Price
                                                                    <InfoPopover info="This is your floor price at which you are willing to sell this vehicle. Only you can see and know this number." />
                                                                </Fragment>
                                                            }
                                                            onChange={handleChange}
                                                            InputProps={{
                                                                inputComponent: NumberFormatCustom,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box className={classes.formControl}>
                                                            <RETextField
                                                                name='startingBid'
                                                                label={
                                                                    <Fragment>
                                                                        Starting Bid
                                                                        <InfoPopover info="This is the minimum starting bid amount that you are allowing. This will be presented to the first bidder as
                                                                            a default bid." />
                                                                    </Fragment>
                                                                }
                                                                onChange={handleChange}
                                                                InputProps={{
                                                                    inputComponent: NumberFormatCustom,
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box className={classes.formControl}>
                                                            <RETextField
                                                                name='buyNowPrice'
                                                                label={
                                                                    <Fragment>
                                                                        Buy Now Price (Optional)
                                                                        <InfoPopover info="Entering a Buy Now Price will allow a bidder to instantly stop the auction and buy your vehicle at
                                                                            this set price." />
                                                                    </Fragment>
                                                                }
                                                                onChange={handleChange}
                                                                InputProps={{
                                                                    inputComponent: NumberFormatCustom,
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box className={classes.formControl}>
                                                            <Typography color="#253241">
                                                                Start Date/Time
                                                                <InfoPopover info={`This is the date and time for starting the auction.
                                                                This date/time must be no more than ${validationParams?.maxStartDelayInDays} days from today.`} />
                                                            </Typography>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DateTimePicker
                                                                    className={classes.customDateTimePicker}
                                                                    minDate={minStartDate}
                                                                    maxDate={moment().add( parseInt(validationParams?.maxStartDelayInDays), 'days')}
                                                                    onChange={(value) => setFieldValue("startDate", value, true)}
                                                                    onClose={() => {
                                                                        let minDate = startDate;
                                                                        minDate.set('hours', minDate.get('hours') + parseInt(validationParams?.minLengthInHours));
                                                                        setMinEndDate(minDate);
                                                                        setEndDate(minDate);
                                                                    }}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box className={classes.formControl}>
                                                            <Typography color="#253241">
                                                                End Date/Time
                                                                <InfoPopover info={`This is the date and time for ending the auction.
                                                                                    This date/time must be between ${validationParams?.minLengthInHours}
                                                                                    and ${validationParams?.maxLengthInHours} hours past the start date/time.`} />
                                                            </Typography>
                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <DateTimePicker
                                                                    className={classes.customDateTimePicker}
                                                                    onChange={(value) => setFieldValue("endDate", value, true)}
                                                                    minDate={minEndDate}
                                                                />
                                                            </LocalizationProvider>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.submit}
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.closeBtn}
                                                onClick={() => handleClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                    <Spinner show={processing} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    );
}

export default PlaceAuctionDialog;
