import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Image from "components/widgets/Image";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const PREFIX = 'NewsPanel';

const classes = {
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    border: `${PREFIX}-border`,
    tabs: `${PREFIX}-tabs`,
    tabContainer: `${PREFIX}-tabContainer`,
    img: `${PREFIX}-img`,
    banner: `${PREFIX}-banner`,
    detail: `${PREFIX}-detail`,
    btn: `${PREFIX}-btn`,
    card: `${PREFIX}-card`,
    cardContent: `${PREFIX}-card-content`,
    starImg: `${PREFIX}-starImg`,
    description: `${PREFIX}-description`,
    news: `${PREFIX}-news`
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        marginTop: 50,
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        [`& .${classes.logo}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.border}`]: {
            marginBottom: 10,
            borderTop: `3px solid ${theme.palette.secondary.main}`,
            float: 'left'
        },
        [`& .${classes.tabs}`]: {
            textOverflow: 'ellipsis',
            fontSize: 17,
            textTransform: 'capitalize',
            fontWeight: 600,
            color: '#9CA3B1',
            marginBottom: 30,
        },
        [`& .${classes.tabContainer}`]: {
            borderRight: 1,
            borderColor: 'divider'
        },
        [`& .${classes.img}`]: {
            width: 40,
            height: 40
        },
        [`& .${classes.banner}`]: {
            height: 290,
            marginLeft: 20
        },
        [`& .${classes.detail}`]: {
            marginLeft: 20,
            marginTop: 30,
        },
        [`& .${classes.description}`]: {
            marginLeft: 20,
            marginTop: 10,
        },
        [`& .${classes.news}`]: {
            marginLeft: 20,
        },
        [`& .${classes.btn}`]: {
            marginTop: 40,
            textTransform: 'capitalize',
        },
        [`& .${classes.starImg}`]: {
            height: 35,
            width: 35
        },
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const NewsPanel = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Box className={classes.flex}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/ReinventLogo.png'
                        className={classes.logo}
                    />
                    <Typography variant='h1' color="#213E98"><span className={classes.right}>ReinventX</span>change in the <span className={classes.right}>News</span></Typography>
                </Box>
                <hr className={classes.border} />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Tabs
                    orientation={isSmallScreen ? 'horizontal' : 'vertical'}
                    variant="scrollable"
                    value={value}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabContainer}
                >
                    <Tab
                        label={<Typography variant='h3' width={300} noWrap>2022 Wisconsin Governor’s Business Plan Contest</Typography>}
                        {...a11yProps(0)}
                        className={classes.tabs}
                    />
                    <Tab
                        label={<Typography variant='h3' width={300} noWrap>EXCLUSIVE: Level 5 Launches ReinventRV Site, Predicts Doubled Growth</Typography>}
                        {...a11yProps(1)}
                        className={classes.tabs}
                    />
                    <Tab
                        label={<Typography variant='h3' width={300} noWrap>Wisconsin Startups to Watch in 2021</Typography>}
                        {...a11yProps(2)}
                        className={classes.tabs}
                    />
                </Tabs>
            </Grid>
            <Grid item xs={12} sm={9}>
                <TabPanel value={value} index={0}>
                    <Box className="flex">
                        <Image
                            alt="Banner Image"
                            src='/static/images/teamPic.png'
                            className={classes.banner}
                        />
                        <Box className={classes.detail}>
                            <Typography variant="h5">2022 Wisconsin Governor’s Business Plan Contest</Typography>
                            <Typography variant="h6" color="#9CA3B1" className="pt-6">Out of 250 companies entered in the 2022 Wisconsin Governor’s Business Plan Contest, the ReinventAuctions team finished 2nd in the Business Services category, and was Honored to walk away with the Governor’s 2022 Bright New Idea Award!</Typography>
                            <Typography variant="h6" color="#9CA3B1" className="pt-6">Congratulations to the entire team of ReinventAuctions!!</Typography>
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box className={classes.description}>
                        <Typography variant="h5">EXCLUSIVE: Level 5 Launches ReinventRV Site, Predicts Doubled Growth</Typography>
                    </Box>
                    <Box className="flex">
                        <Box className={classes.description}>
                            <Typography variant="h6" color="#9CA3B1" className="pt-2">
                                Level 5 Advertising launched a new dealer website for ReinventRV, the first online RV wholesale marketplace.
                            </Typography>
                            <Typography variant="h6" color="#9CA3B1" className="pt-6">
                                Level 5, a technology company helping with data management and real-time trading and buying, partnered with ReinventRV to create the online RV auction platform, where users can post a dealer management system’s inventory directly.
                            </Typography>
                            <Typography variant="h6" color="primary" className="pt-2"><Link to="/">Read the whole story on RV News</Link></Typography>
                        </Box>
                        <Box className={classes.description}>
                            <Typography variant="h6" color="#9CA3B1" className="pt-2">
                                As of Dec. 20, 2020, dealers posted more than 10,000 units on the website, published that November. January’s projected statistics include more than 70 dealers posting 20,000 units.
                            </Typography>
                            <Typography variant="h6" color="#9CA3B1" className="pt-6">
                                ReinventRV is growing internally, as the company started with six employees and now has 13. Chief Growth Officer Rob Gadek, previously Cars.com’s chief financial officer, was hired two weeks ago.
                            </Typography>
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box className="flex">
                        <Image
                            alt="Banner Image"
                            src='/static/images/wisconsin.png'
                            className={classes.banner}
                        />
                        <Box className={classes.news}>
                            <Typography variant="h5">Wisconsin Startups to Watch in 2021</Typography>
                            <Typography variant="h6" color="#9CA3B1" className="pt-2">
                                The following post by Matt Cordio, is a guest post, the views expressed hereinafter do not reflect the official views of the Wisconsin Startup Coalition. Photo Credit: Soichi Noguchi Note: The post was updated on 12/20/20 to include 20 additional startups identified by members of the Wisconsin startup ecosystem.
                            </Typography>
                            <Typography variant="h6" color="#9CA3B1" className="pt-2">
                                Despite a global pandemic impacting broad parts of our nation’s economy, Wisconsin tech startups continued to launch new products, create new jobs and attract Venture Capital to the state in 2020. As we head into 2021 expect Wisconsin tech startups are playing a key role in reshaping new markets such as “meat alternatives,” “digital commerce,” and “the future of work” as well as traditional markets such as agriculture, energy, finance, healthcare and logistics.
                            </Typography>
                            <Typography variant="h6" color="primary" className="pt-2"><Link to="/">Read the whole story on RV News</Link></Typography>
                        </Box>
                    </Box>
                </TabPanel>
            </Grid>
        </StyledGrid>
    )
}

export default NewsPanel;