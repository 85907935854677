import {createSlice} from "@reduxjs/toolkit";

const initialState = {};

export const searchSlice = createSlice({
  processing: false,
  name: 'searchCriteria',
  initialState,
  reducers: {
    initialize: () => {
      return {
        page: null,
        sort: localStorage.hasOwnProperty('sort') ? localStorage.getItem('sort') : null,
        search: localStorage.hasOwnProperty('search') ? localStorage.getItem('search') : null,
        distancemin: localStorage.hasOwnProperty('minDis') ? localStorage.getItem('minDis') : null,
        distancemax: localStorage.hasOwnProperty('maxDis') ? localStorage.getItem('maxDis') : null,
        yearmax: localStorage.hasOwnProperty('maxYear') ? localStorage.getItem('maxYear') : null,
        yearmin: localStorage.hasOwnProperty('minYear') ? localStorage.getItem('minYear') : null,
        pricemin: localStorage.hasOwnProperty('minPrice') ? localStorage.getItem('minPrice') : null,
        pricemax: localStorage.hasOwnProperty('maxPrice') ? localStorage.getItem('maxPrice') : null,
        minmileage: localStorage.hasOwnProperty('minMileage') ? localStorage.getItem('minMileage') : null,
        maxmileage: localStorage.hasOwnProperty('maxMileage') ? localStorage.getItem('maxMileage') : null,
        kbbmin: localStorage.hasOwnProperty('minKBBPrice') ? localStorage.getItem('minKBBPrice') : null,
        kbbmax: localStorage.hasOwnProperty('maxKBBPrice') ? localStorage.getItem('maxKBBPrice') : null,
        vehcategory: localStorage.hasOwnProperty('category') ? localStorage.getItem('category') : null,
        make: localStorage.hasOwnProperty('makes') ? localStorage.getItem('makes') : null,
        dealers: localStorage.hasOwnProperty('vehDealers') ? localStorage.getItem('vehDealers') : [],
        rooftops: localStorage.hasOwnProperty('rooftops') ? localStorage.getItem('rooftops') : [],
        model: localStorage.hasOwnProperty('models') ? localStorage.getItem('models') : null,
        trim: localStorage.hasOwnProperty('trims') ? localStorage.getItem('trims') : null,
        fueltype: localStorage.hasOwnProperty('vehFuelTypes') ? localStorage.getItem('vehFuelTypes') : null,
        minweight: localStorage.hasOwnProperty('minWeight') ? localStorage.getItem('minWeight') : null,
        maxweight: localStorage.hasOwnProperty('maxWeight') ? localStorage.getItem('maxWeight') : null,
        minlength: localStorage.hasOwnProperty('minLength') ? localStorage.getItem('minLength') : null,
        maxlength: localStorage.hasOwnProperty('maxLength') ? localStorage.getItem('maxLength') : null,
        sleeps: localStorage.hasOwnProperty('sleeps') ? localStorage.getItem('sleeps') : null,
        slideouts: localStorage.hasOwnProperty('slideout') ? localStorage.getItem('slideout') : null,
        newused: localStorage.hasOwnProperty('used') ? localStorage.getItem('used') : null,
      };
    },
    updateSearchCriteria: (state, action) => {
      const {multiple, value, reset} = action.payload

      let newValue = {};
      Object.keys(value).forEach((key) => {
        if (multiple) {
          const currentValues = state[key] || [];

          if (reset) {
            newValue[key] = multiple ? [] : undefined;
          }
          else if (currentValues.includes(value[key])) {
            newValue[key] = currentValues.filter((item) => item !== value[key]);
          }
          else {
            newValue[key] = [...currentValues, value[key]];
          }
        } else {
          newValue[key] = reset ? undefined : value[key];
        }
      });

      return {
        ...state,
        ...newValue
      }
    }
  }
})

export const {
  initialize,
  updateSearchCriteria,
} = searchSlice.actions

export default searchSlice.reducer