import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import React, {useState} from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";

const PREFIX = 'NotificationsPanel';

const classes = {
  panel: `${PREFIX}-panel`,
  settings: `${PREFIX}-settings`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
)=> {
  return {
    [`& .${classes.settings}`]: {
      marginTop: 30,
    },
  }
})

const NotificationsPanel = ({settings, handleChange}) => {
  const [confirmationProps, setConfirmationProps] = useState();

  const handleConfirmation = () => {
    handleChange(confirmationProps.type);
    setConfirmationProps(undefined);
  }

  return (
      <StyledGrid container>
        <Grid item xs={12}>
          <Typography variant='h4'>Notification Settings</Typography>
          <hr/>

          <div className={classes.settings}>
            <Typography variant='h6'>
              <Switch
                  checked={settings?.emailNotificationOn}
                  onClick={() => setConfirmationProps({
                    type: 'emailNotification',
                    text: `Are you sure you want to 
                    ${settings?.emailNotificationOn ? 'turn off' : 'turn on'} email notifications?`
                  })}
              />
              Email
            </Typography>

            <Typography variant='h6'>
              <Switch
                  checked={settings?.smsNotificationOn}
                  onClick={() => setConfirmationProps({
                    type: 'smsNotification',
                    text: `Are you sure you want to 
                    ${settings?.smsNotificationOn ? 'turn off' : 'turn on'} SMS notifications?`
                  })}
              />
              SMS
            </Typography>
          </div>
        </Grid>

        <ConfirmationDialog
            title='Confirm Notification Change'
            open={confirmationProps !== undefined}
            handleCancel={() => setConfirmationProps(undefined)}
            handleOk={handleConfirmation}
        >
            <Typography variant='h6'>{confirmationProps?.text}</Typography>
        </ConfirmationDialog>
      </StyledGrid>
  )
}

export default NotificationsPanel;