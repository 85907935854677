import React from "react";
import {useField, useFormikContext} from "formik";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";

const FormikSelectionList = ({label, items, ...props}) => {
  const [field, meta] = useField(props);

  return (
      <Box>
        <InputLabel id="select-label" style={{marginBottom: 10}}>{label}</InputLabel>
        <Select
            style={{width: '100%', height: 40}}
            label={label}
            {...field}
            {...props}
        >
          {/* <option value="">--- Select One ---</option> */}
          {items && items.map(item => <MenuItem key={`${item.value}-option`}
                                                value={item.value}>{item.label}</MenuItem>)}
        </Select>

        <span className='text-danger' style={{float: 'left'}}>{meta.touched && meta.error}</span>
      </Box>
  )
}

const SelectList = ({label, items, ...props}) => {
  const context = useFormikContext();

  if (context) {
    return <FormikSelectionList label={label} items={items} {...props} />
  }

  return (
      <Box>
        <InputLabel id="select-label" style={{marginBottom: 10}}>{label}</InputLabel>
        <Select
            style={{width: '100%', height: 40}}
            label={label}
            {...props}
        >
          {/* <option value="">--- Select One ---</option> */}
          {items && items.map(item => <MenuItem key={`${item.value}-option`}
                                                value={item.value}>{item.label}</MenuItem>)}
        </Select>
      </Box>
  )
}

export default SelectList;