import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	user: undefined,
	token: undefined,
	error: undefined,
	processing: false,
	resetProcessing: false,
	forgotResponse: {},
	resetResponse: {}
};

export const authenticationSlice = createSlice({
	processing: false,
	name: 'authentication',
	initialState,
	reducers: {
		initialize: () => {
			return initialState
		},
		startingLogin: (state, action) => {
			return {
				processing: true
			}
		},
		startingLogout: (state, action) => {
			return {
				...state,
				processing: true
			}
		},
		login: (state, action) => {


			return {
				...state,
				...action.payload,
				processing: false
			}
		},
		errorFound: (state, action) => {
			return {
				...state,
				...action.payload,
				processing: false
			}
		},
		logout: (state, action) => {
			return {
				...state,
				user: undefined,
				error: undefined,
				token: undefined,
				redirectTo: undefined,
				processing: false
			}
		},
		startingForgotPassword: (state, action) => {
			return {
				...state,
				processing: true
			}
		},
		ForgotPassword: (state, action) => {
			return {
				...state,
				processing: false,
				...action.payload
			}
		},
		startingResetPassword: (state, action) => {
			return {
				...state,
				resetProcessing: true
			}
		},
		ResetPassword: (state, action) => {
			return {
				...state,
				resetProcessing: false,
				...action.payload
			}
		},
		updatingUserDefaultRooftop: (state, action) => {
      return {
        ...state,
        processing: true,
        error: undefined
      }
    },
		updatedUserDefaultRooftop: (state, action) => {
      return {
        ...state,
        user: {...state.user, rooftop_id: action.payload.default_rooftop}, 
        processing: false
      }
    },
	}
})

// Action creators are generated for each case reducer function
export const {
	initialize,
	login,
	logout,
	startingLogin,
	startingLogout,
	errorFound,
	startingForgotPassword,
	ForgotPassword,
	startingResetPassword,
	ResetPassword,
	updatingUserDefaultRooftop,
	updatedUserDefaultRooftop
} = authenticationSlice.actions

export default authenticationSlice.reducer