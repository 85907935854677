import Grid from "@mui/material/Grid";
import React, {useEffect} from "react";
import GeneralAccessPanel from "./GeneralAccessPanel";
import {useDispatch, useSelector} from "react-redux";
import {loadLocationRoles} from "data/user.actions";
import {useField} from "formik";
import Typography from "@mui/material/Typography";

const GeneralAccessForm = ({classes, ...props}) => {
  const [field, meta] = useField(props);
  const dispatch = useDispatch();
  const {locationRoles} = useSelector(state => state.user);

  useEffect(() => {
    if (!locationRoles) {
      dispatch(loadLocationRoles());
    }
  }, [locationRoles, dispatch]);

  return (
      <>
        <Grid item xs={12}>
            <GeneralAccessPanel
                key='generalAccess'
                classes={classes}
                locationRoles={locationRoles}
                name={`${field.name}`}
            />
          <Typography variant='body1' color='error' style={{marginTop: 5, float: 'left'}}>{meta.error}</Typography>
        </Grid>
      </>
    )
};

export default GeneralAccessForm;