import React, { useState } from "react";
import * as yup from "yup";
import { Form, Formik } from 'formik';
import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import UploadFiles from "components/widgets/UploadFiles";
import Button from "components/widgets/Button";
import Spinner from "components/Spinner";
import S3Upload from "utils/s3Upload";

const validationSchema = yup.object().shape({
    files: yup.mixed().required('Please select files.')
});

let initialValues = {
    files: null,
};

const PREFIX = 'UploadTitle';

const classes = {
    submit: `${PREFIX}-submit`,
    closeBtn: `${PREFIX}-closeBtn`,
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.submit}`]: {
            float: 'right',
            marginTop: 10
        },
        [`& .${classes.closeBtn}`]: {
            float: 'right',
            marginTop: 10,
            marginRight: 10
        },
    }
});

const UploadTitle = (
    {
        open,
        handleClose,
        token
    }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = (values, setFieldError) => {
        const s3UploaderProps = {
            autoUpload: true,
            signingUrl: `/api/transaction/title/link/${token}`,
            signingUrlMethod: 'GET',
            signingUrlHeaders: {},
            uploadRequestHeaders: {},
            withCredentials: true,
            onFinishS3Put: (signResult, file) => {
                setLoading(false);
                handleClose();
            },
            onProgress: (evt) => {
                console.log('progress', evt)
                setLoading(true);
                handleClose();
            },
            onError: (evt) => {
                setLoading(false);
                setFieldError('fields', 'A problem was encountered during the upload operation, please try again or contact Groupware support.')
            }
        };

        new S3Upload({ ...s3UploaderProps, files: values.files });
    }

    return (
        <StyledDialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 700, overflowY: 'hidden' } }}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1">
                    UPLOAD TITLE
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values, meta) => {
                            handleSubmit(values, meta.setFieldError);
                        }}>
                        {({ handleSubmit, values, setFieldValue }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <UploadFiles name='files' />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.submit}
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.closeBtn}
                                                onClick={() => handleClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                    <Spinner show={loading} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    );
}

export default UploadTitle;
