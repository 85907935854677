import React from "react";
import {useField} from "formik";

const InputBox = ({type, handleBlur, ...props}) => {
  const [field, meta] = useField(props);

  const feedback = meta.touched && meta.error;
  const {name} = field;

  return (
    <div className='flex flex-col width-full mt-3 mr-5'>
      <input
        className='rounded-lg border-black border p-3'
        type={type}
        onBlur={handleBlur}
        {...field}
        error={feedback !== undefined}
      />
      { feedback &&
      <div className='text-danger' id={`${name}-feedback`}>{feedback}</div>
      }
    </div>
  )
}

export default InputBox;