import _ from "lodash";
import moment from "moment";

export const formatDollarAmount = (number) => {
    return Number(number).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const toCamelcase = json => {
    return _.mapKeys(json, (v, k) => _.camelCase(k));
}

export const formatDateTime = (dateString) => {
    if (dateString) {
        const date = moment(dateString);
        // return date.format('MMM DD, YYYY hh:MMa');
        return date.format('MMM DD, YYYY hh:mm A');
    }

    return '';
}

export const formatFromNow = (dateString) => {
    if (dateString) {
        const date = moment(dateString);
        return date.fromNow()
    }
    return '';
}

export const formatDaysAgo = (dateString) => {
    if (dateString) {
        const startDate = new Date(dateString)
        const currentDate = new Date();

       // Calculate the time difference in milliseconds
        const timeDifference = currentDate - startDate;

        // Calculate the number of days
        const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        
        return daysPassed
    }
    return '';
}