import React from 'react';
import YouTube from 'react-youtube';

const YouTubeViewer = ({className, videoId, width, height}) => {
  const onReady = (event) => {
    event.target.pauseVideo();
  }

  const opts = {
    height,
    width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} onReady={onReady} />;
}

export default YouTubeViewer;