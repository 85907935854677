import { createAsyncThunk } from '@reduxjs/toolkit'
import { login, logout, startingLogout, startingLogin, initialize, errorFound, startingForgotPassword, ForgotPassword, startingResetPassword, ResetPassword } from './authentication.slice';
import Cookie from 'js-cookie';
import { clearLoactions } from './location.slice';
import { clearAgingVehicles } from './vehicle.slice';

export const doLogin = createAsyncThunk('authentication/login', async (values, { extra, dispatch }) => {
     try {
          dispatch(startingLogin());

          const url = `/user/login${values.redirectTo ? `?p=${encodeURIComponent(values.redirectTo)}` : ''}`;
          const response = await extra.post(url, values);

          if (response.cookieToSet) {
               response.cookieToSet.map(cookie => document.cookie = cookie.replace('HttpOnly', ''));
          }

          dispatch(login({
               user: response?.user,
               token: response?.token,
               redirectTo: response?.forwardTo ?
                    response.forwardTo === '/' ? '/buy' : response.forwardTo : response?.redirect,
               error: response?.redirect?.[1]?.error_msg
          }));
     }
     catch (error) {
          console.log(error.message);
          dispatch(errorFound({ error: 'Invalid Login' }));
     }
});

export const doLoadUser = createAsyncThunk('authentication/loadUser', async (values, { extra, dispatch }) => {
     try {
          dispatch(startingLogin());

          const response = await extra.get(`/user/token/${values.uid}`);

          if (response.cookieToSet) {
               response.cookieToSet.map(cookie => document.cookie = cookie.replace('HttpOnly', ''));
          }

          dispatch(login({ user: response?.user, token: values.token }));
     }
     catch (error) {
          console.log(error.message);
          dispatch(errorFound({ error: 'Invalid Login' }));
     }
});

export const doLogout = createAsyncThunk('authentication/logout', async (values, { extra, dispatch }) => {
     try {
          dispatch(startingLogout());
          dispatch(clearLoactions())
          dispatch(clearAgingVehicles());

          localStorage.clear();

          Cookie.remove('jwt');
          Cookie.remove('uid');

          dispatch(logout({}));
     }
     catch (error) {
          console.log(error.message);
          dispatch(errorFound({ error: error.message }));
     }
});

export const doReset = createAsyncThunk('authentication/reset', async (values, { extra, dispatch }) => {
     dispatch(initialize());
});

export const forgotPassword = createAsyncThunk('authentication/forgotpassword', async (values, { extra, dispatch }) => {
     try {
          dispatch(startingForgotPassword());

          const response = await extra.post(`/user/password/forgot`, values);

          dispatch(ForgotPassword({ forgotResponse: response }));
     }
     catch (error) {
          console.log(error.message);
          dispatch(errorFound({ error: 'Invalid Email' }));
     }
});

export const resetPassword = createAsyncThunk('authentication/resetpassword', async (values, { extra, dispatch }) => {
     try {
          dispatch(startingResetPassword());

          const response = await extra.post(`/user/password/reset/${values.token}`, values);

          dispatch(ResetPassword({ resetResponse: response }));
     }
     catch (error) {
          console.log(error.message);
          dispatch(errorFound({ error: 'Invalid Email' }));
     }
});

