import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import PhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailIcon from '@mui/icons-material/MarkunreadOutlined';
import Spinner from "components/Spinner";
import ChangePasswordForm from "./components/changePasswordForm";
import { loadUserSettings, updateUserDefaultRooftop, updateUserNotification } from "data/user.actions";
import { doLogout } from "data/authentication.actions";
import NotificationsPanel from "./components/notificationsPanel";
import Image from 'components/widgets/Image';
import ChangeRooftop from '../../components/ChangeRooftop';
import { loadRooftopsForUser} from 'data/location.actions';

const PREFIX = 'Preferences';

const classes = {
  panel: `${PREFIX}-panel`,
  profileImageContainer: `${PREFIX}-profileImageContainer`,
  profileImage: `${PREFIX}-profileImage`,
  emptyProfileImage: `${PREFIX}-emptyProfileImage`,
  profileName: `${PREFIX}-profileName`,
  email: `${PREFIX}-email`,
  phone: `${PREFIX}-phone`,
  icon: `${PREFIX}-icon`,
  formContainer: `${PREFIX}-formContainer`,
  settings: `${PREFIX}-settings`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.panel}`]: {
      marginTop: 30,
      padding: 30,
      borderRadius: 20,
      background: theme.palette.secondary.grey
    },
    [`& .${classes.profileImageContainer}`]: {
      width: 100,
      borderRadius: 20,
      [`& .${classes.profileImage}`]: {
        width: 100,
        marginRight: 10,
        borderRadius: 20,
      },
      [`& .${classes.emptyProfileImage}`]: {
        width: 100,
        height: 100,
        border: '1px black dashed',
        marginRight: 10,
        borderRadius: 8,
      },
    },
    [`& .${classes.profileName}`]: {
      marginLeft: 30,
    },
    [`& .${classes.icon}`]: {
      height: 25,
      width: 25,
      padding: 5,
      marginRight: 5,
      background: theme.palette.primary.main,
      color: theme.palette.text.white
    },
    [`& .${classes.email}`]: {
      marginTop: 5,
      padding: 10,
      borderRadius: 5,
      background: theme.palette.text.white,
      float: 'left',
      border: '1px solid black'
    },
    [`& .${classes.phone}`]: {
      padding: 10,
      marginTop: 5,
      marginRight: 10,
      borderRadius: 5,
      background: theme.palette.text.white,
      float: 'left',
      border: '1px solid black'
    },
    [`& .${classes.formContainer}`]: {
      marginTop: 30,
    },
  }
});
const PreferencesView = () => {
  const { user } = useSelector(state => state.authentication);
  const { processing, settings, updatedPassword, error } = useSelector(state => state.user);
  const {processing: processingLocations, locations, rooftopsForUser, error: locationError} = useSelector(state => state.location)

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(loadUserSettings({ userId: user.id }));
    }
  }, [user, dispatch])

  useEffect(() => {
    if (updatedPassword) {
      dispatch(doLogout());
    }
  }, [updatedPassword, dispatch])

  useEffect(() => {
    if (user && rooftopsForUser === undefined) {
        dispatch(loadRooftopsForUser({
          userId: user.id
        }))
    } 
}, [user, rooftopsForUser, dispatch]);

  const handleNotificationSettingChange = (type) => {
    dispatch(updateUserNotification({
      emailNotification: {
        ...settings.emailNotification,
        active: type === 'emailNotification' ? !settings.emailNotification.active : settings.emailNotification.active
      },
      smsNotification: {
        ...settings.smsNotification,
        active: type === 'smsNotification' ? !settings.smsNotification.active : settings.smsNotification.active
      }
    }));
  }

  const handleChangeRooftop = (values) => {
    const newValues = {
      userId: user.id,
      default_rooftop: values.rooftop,
    }
    dispatch(updateUserDefaultRooftop(newValues));
  }

  return (
    <StyledGrid>
      <Grid item xs={12}>
        <Typography variant='h3'>Preferences</Typography>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.panel}>
          <Grid container>
            <div className={classes.profileImageContainer}>
              {user?.image && (
                <Image
                  src={user?.profileImage}
                />
              )}
              {!user?.image && (
                <Card className={classes.emptyProfileImage} />
              )}
            </div>
            <div className={classes.profileName}>
              <Typography variant='h3' color='error'>{user?.first_name} {user?.last_name}</Typography>

              <div className={classes.phone}>
                <Typography variant='h6'>
                  <PhoneIcon className={classes.icon} /> {user?.phone}
                </Typography>
              </div>

              <div className={classes.email}>
                <Typography variant='h6'>
                  <EmailIcon className={classes.icon} /> {user?.email}
                </Typography>
              </div>

              <ChangeRooftop
                 initialValue={{ rooftop: parseInt(user?.rooftop_id)}}
                 rooftops={rooftopsForUser}
                handleSubmit={(values) => handleChangeRooftop(values)}
              />
            </div>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.panel}>
        <NotificationsPanel
          settings={settings}
          handleChange={handleNotificationSettingChange}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.panel}>
          <Typography variant='h4'>Change Password</Typography>
          <hr />

          <div className={classes.formContainer}>
            <ChangePasswordForm />
          </div>
        </div>
      </Grid>

      <Typography variant='h6' color='error'>{error || locationError}</Typography>

      <Spinner show={processing || processingLocations} />
    </StyledGrid>
  )
}

export default PreferencesView;