import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";

const PREFIX = 'VehicleCategoryPanel';

const classes = {
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    border: `${PREFIX}-border`,
    card: `${PREFIX}-card`,
    flexBox: `${PREFIX}-flexBox`,
    starImg: `${PREFIX}-starImg`,
    header: `${PREFIX}-header`,
    subText: `${PREFIX}-subText`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        marginTop: 50,
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        [`& .${classes.logo}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.border}`]: {
            width: 210,
            marginBottom: 10,
            borderTop: `3px solid ${theme.palette.secondary.main}`,
            float: 'left',
        },
        [`& .${classes.card}`]: {
            background: theme.palette.background.default,
            boxShadow: '0px 15px 19px rgb(33 62 152 / 15%)',
            borderRadius: 8,
            textAlign: 'center',
            border: '1.10132px solid rgba(156, 163, 177, 0.5)',
            color: theme.palette.text.secondary,
            fontWeight: 400,
            margin: 10
        },
        [`& .${classes.flexBox}`]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 100
        },
        [`& .${classes.starImg}`]: {
            aspectRatio: 'auto',
            height: 40
        },
        [`& .${classes.header}`]: {
            marginTop: 10
        },
        [`& .${classes.subText}`]: {
            marginTop: 10,
            marginLeft: 10,
        },
    }
});

const VehicleCategoryPanel = () => {
    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Box className={classes.flex}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/ReinventLogo.png'
                        className={classes.logo}
                    />
                    <Typography variant='h1' color="#213E98"><span className={classes.right}>Multiple</span> vehicle types</Typography>
                </Box>
                <Typography variant='h6' color="#213E98" className={classes.subText}>ReinventX<span className={classes.right}>change</span> works with Auto,Powersport Marine, and RV Dealers</Typography>
                <hr className={classes.border} />
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/Auto1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Auto(5000)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/RV1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                RV (2500)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/Boat1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Boat(250)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/Motorcycle1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Motorcycle(400)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/eBike1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Ebike(25)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/ATV1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Atv(2000)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/Personal Watercraft1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Personal Watercraft(240)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/Snowmobile1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Snow Mobile (5054)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/Marine Engine1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Marine(200)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/UTV1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                UTV(598)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/Golf1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Golf(589)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={5} md={2}>
                <Card className={classes.card}>
                    <CardContent>
                        <Box className={classes.flexBox}>
                            <Image
                                alt="Banner Image"
                                src='/static/images/Other1.png'
                                className={classes.starImg}
                            />
                            <Typography variant="h6" className={classes.header}>
                                Other(200)
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </StyledGrid>
    )
}

export default VehicleCategoryPanel;