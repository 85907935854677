import React from 'react';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { modelOption, trimOption } from 'data/vehicle.actions';
import { selectMultipleOption } from 'utils/Filter';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const MakeModelTrimFilter = ({ criteria, searchType }) => {
  const dispatch = useDispatch();
  const {
    search: { vehicleOption, modelOptions, trimOptions },
    authentication,
  } = useSelector((state) => state);
  const { user } = authentication;

  let makes = criteria.vehMake;
  let models = criteria.vehModel;
  let trims = criteria.vehTrim;

  const getModelOptions = (value) => {
    const params = {
      dealer: user?.dealer_id,
      dealerRooftopId: parseInt(user.rooftop_id),
      searchType,
      activeCategory: criteria.vehcategory,
      make: `'${value}'`,
    };

    dispatch(modelOption(params));
  };

  const getTrimOptions = (value) => {
    const params = {
      dealer: user?.dealer_id,
      dealerRooftopId: parseInt(user.rooftop_id),
      searchType,
      activeCategory: criteria.vehcategory,
      make: criteria.vehMake,
      model: value,
    };

    dispatch(trimOption(params));
  };

  return (
    <>
      <TabPanel value='make'>
        <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                  (
                      localStorage.hasOwnProperty('makes')
                      ? localStorage.getItem('makes') === ''
                      : criteria.vehMake === ''
                      )
                      ? classes.btnColor
                      : classes.styledBtn
                    }
                    color='primary'
                    size='large'
                    value=''
                    onClick={(e) => {
                        criteria.setPage(1);
                        localStorage.setItem('makes', e.target.value);
                        criteria.handleMake(e.target.value);
                        getModelOptions(e.target.value);
                    }}
                    >
              All
            </StyledOptionButton>
          </Grid>
          {vehicleOption.makes?.map((make) => (
              <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                    localStorage.getItem('makes')?.includes(make.make)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={make.make}
                onClick={(e) => {
                    criteria.setPage(1);
                    selectMultipleOption(
                        'makes',
                        makes,
                        e.target.value,
                        criteria.handleMake
                        );
                        getModelOptions(e.target.value);
                    }}
                    >
                {make.make} ({make.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
          </StyledFilterGrid>
      </TabPanel>
      <TabPanel value='model'>
        <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                  (
                      localStorage.hasOwnProperty('models')
                      ? localStorage.getItem('models') === ''
                      : criteria.vehModel === ''
                )
                  ? classes.btnColor
                  : classes.styledBtn
                }
                color='primary'
                size='large'
                value=''
                onClick={(e) => {
                    criteria.setPage(1);
                    localStorage.setItem('models', e.target.value);
                    criteria.handleModel(e.target.value);
                    getTrimOptions(e.target.value);
                }}
                >
              All
            </StyledOptionButton>
          </Grid>
          {modelOptions?.model?.map((model) => (
              <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                    localStorage.getItem('models')?.includes(model.model)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={model.model}
                onClick={(e) => {
                    criteria.setPage(1);
                    selectMultipleOption(
                        'models',
                        models,
                        e.target.value,
                        criteria.handleModel
                        );
                        getTrimOptions(e.target.value);
                    }}
                    >
                {model.model} ({model.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
          </StyledFilterGrid>
      </TabPanel>
      <TabPanel value='trim'>
        <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                  (
                      localStorage.hasOwnProperty('trims')
                      ? localStorage.getItem('trims') === ''
                      : criteria.vehTrim === ''
                      )
                      ? classes.btnColor
                      : classes.styledBtn
                    }
                    color='primary'
                    size='large'
                    value=''
                    onClick={(e) => {
                        criteria.setPage(1);
                        localStorage.setItem('trims', e.target.value);
                        criteria.handleTrim(e.target.value);
                    }}
                    >
              All
            </StyledOptionButton>
          </Grid>
          {trimOptions?.trims?.map((trim) => (
              <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                    localStorage.getItem('trims')?.includes(trim.name)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={trim.name}
                onClick={(e) => {
                    criteria.setPage(1);
                    selectMultipleOption(
                        'trims',
                        trims,
                        e.target.value,
                        criteria.handleTrim
                        );
                    }}
                    >
                {trim.name} ({trim.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
          </StyledFilterGrid>
      </TabPanel>
    </>
  );
};

export default MakeModelTrimFilter;