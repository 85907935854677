/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PREFIX = 'Welcome';

const classes = {
    background: `${PREFIX}-background`,
    carouselContainer: `${PREFIX}-content`,
    imageContainer: `${PREFIX}-imageContainer`,
    backgroundImg: `${PREFIX}-img`,
    imageHeight: `${PREFIX}-imgHeight`,
    carouselContent: `${PREFIX}-carouselContent`,
    mainHeader: `${PREFIX}-mainHeader`,
    innerHeader: `${PREFIX}-innerHeader`,
    bannerImg: `${PREFIX}-bannerImg`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        padding: '10px 0',
        background: theme.palette.background.light,
        [theme.breakpoints.up('lg')]: {
            backgroundImage: 'url(/static/images/background1.png)',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
        },
        [`& .${classes.carouselContainer}`]: {
            paddingTop: 50,
            paddingLeft: 30,
        },
        [`& .${classes.imageContainer}`]: {
            paddingRight: 10,
            [theme.breakpoints.up('xs')]: {
                paddingTop: 10,
                paddingLeft: 10
            },
            [theme.breakpoints.up('xl')]: {
                paddingTop: 0
            }
        },
        [`& .${classes.carouselContent}`]: {
            paddingLeft: 30,
            height: 150,
            textAlign: 'left'
        },
        [`& .${classes.mainHeader}`]: {
            color: theme.palette.primary.main,
            fontSize: 64
        },
        [`& .${classes.innerHeader}`]: {
            color: theme.palette.text.secondary,
            marginTop: 18,
            marginLeft: 5,
        },
        [`& .${classes.bannerImg}`]: {
            marginLeft: 'auto',
            display: 'block'
        },
    }
});

const Welcome = () => {
    return (
        <StyledGrid container>
            <Grid item xs={12} lg={5} className={classes.carouselContainer}>
                <Typography variant='h1' className={classes.mainHeader}>The REINVENT Solution</Typography>
                <Carousel
                    showStatus={false}
                    showArrows={false}
                    autoPlay
                    infiniteLoop
                    interval={2000}
                >
                    <div className={classes.carouselContent}>
                        <Typography variant='h1' className={classes.innerHeader}>Expand your online showroom</Typography>
                    </div>
                    <div className={classes.carouselContent}>
                        <Typography variant='h1' className={classes.innerHeader}>Dealer to Dealer inventory access</Typography>
                    </div>
                    <div className={classes.carouselContent}>
                        <Typography variant='h1' className={classes.innerHeader}>Cutting edge technology</Typography>
                    </div>
                </Carousel>
            </Grid>

            <Grid item xs={12} lg={7} className={classes.imageContainer}>
                <Image
                    className={classes.imageHeight}
                    alt="Banner Image"
                    src='/static/images/collage.png'
                />
            </Grid>
        </StyledGrid>
    )
}

export default Welcome;