import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  processing: false,
  locations: undefined,
  detailedLocations: undefined,
  rooftopsForFilter: undefined,
  rooftopsForUser: undefined,
};

export const locationSlice = createSlice({
  processing: false,
  name: 'location',
  initialState,
  reducers: {
    initialize: () => {
      return initialState
    },
    clearLoactions: (state, action) => {
      return {
        ...state,
        locations: undefined,
        rooftopsForUser: undefined,
      }
    },
    gettingLocations: (state, action) => {
      return {
        ...state,
        processing: true,
        locations: undefined
      }
    },
    updatingLocation: (state, action) => {
      return {
        ...state,
        processing: true,
        locations: undefined
      }
    },
    addingLocation: (state, action) => {
      return {
        ...state,
        processing: true,
        locations: undefined
      }
    },
    gettingDetailedLocations: (state, action) => {
      return {
        ...state,
        processing: true,
        detailedLocations: undefined
      }
    },
    loadedLocations: (state, action) => {
      return {
        ...state,
        processing: false,
        locations: action.payload.locations
      }
    },
    loadedDetailedLocations: (state, action) => {
      return {
        ...state,
        processing: false,
        detailedLocations: action.payload.detailedLocations
      }
    },
    updatedLocation: (state, action) => {
      return {
        ...state,
        processing: false,
        detailedLocations: undefined,
        locations: undefined
      }
    },
    addedLocation: (state, action) => {
      return {
        ...state,
        processing: false,
        detailedLocations: undefined,
        locations: undefined
      }
    },
    errorFound: (state, action) => {
      return {
        ...state,
        ...action.payload,
        processing: false
      }
    },
    gettingRooftops: (state, action) => {
      return {
        ...state,
        processing: true,
      }
    },
    loadedRooftops: (state, action) => {
      return {
        ...state,
        ...action.payload,
        processing: false,
      }
    },
    loadingRooftopsForUser: (state, action) => {
      return {
        ...state, 
        rooftopsForUser: undefined,
        processing: true,
      }
    },
    loadedRooftopsForUser: (state, action) => {
      return {
        ...state,
        rooftopsForUser: action.payload.rooftopsForUser,
        processing: false,
      }
    }
  }
});

export const {
  gettingLocations,
  gettingDetailedLocations,
    updatingLocation,
    updatedLocation,
  loadedLocations,
  loadedDetailedLocations,
  errorFound,
  addingLocation,
  addedLocation,
  gettingRooftops,
  loadedRooftops,
  clearLoactions,
  loadingRooftopsForUser,
  loadedRooftopsForUser
} = locationSlice.actions

export default locationSlice.reducer