import React from 'react';
import {useDispatch} from "react-redux";
import { Formik } from 'formik';
import * as yup from "yup";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import TextField from "components/widgets/TextField";
import Button from "components/widgets/Button";
import {updateUserPassword} from "data/user.actions";

const PREFIX = 'ChangePasswordForm';

const classes = {
  panel: `${PREFIX}-panel`,
  fieldContainer: `${PREFIX}-fieldContainer`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
)=> {
  return {
    [`& .${classes.fieldContainer}`]: {
      paddingRight: 10,
    },
    [`& .${classes.buttonContainer}`]: {
      marginTop: 10,
    },
    [`& .${classes.button}`]: {
      float: 'right',
    },
  }
})

const initialValues = {
  currentPassword: undefined,
  newPassword: undefined,
  verifyPassword: undefined
}

const validationSchema = yup.object().shape({
  currentPassword: yup
      .string()
      .required('You must enter your current password.'),
  newPassword: yup
      .string()
      .required('Please enter your new password.'),
  verifyPassword: yup
      .string()
      .required('Please enter your verify password.')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePasswordForm = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(updateUserPassword(values));
  };

  return (
      <StyledGrid container>
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              handleSubmit(values);
              actions.setSubmitting(false);
            }}>

          {({handleSubmit, handleChange, touched, errors, setFieldValue, values}) => {
            return (
                <Grid container>
                  <Grid item xs={4} className={classes.fieldContainer}>
                    <TextField
                      label='Current Password'
                      name='currentPassword'
                    />
                  </Grid>

                  <Grid item xs={4} className={classes.fieldContainer}>
                    <TextField
                        label='New Password'
                        name='newPassword'
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                        label='Verify Password'
                        name='verifyPassword'
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.buttonContainer}>
                    <Button onClick={handleSubmit} className={classes.button}>
                      Update Password
                    </Button>
                  </Grid>
                </Grid>
            );
          }}
        </Formik>
      </StyledGrid>
  )
}

export default ChangePasswordForm;