/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from "@mui/material/Button";
import Image from "components/widgets/Image";
import ViewReportDialog from "components/dialogs/ViewReportDialog";
import UploadConditionPhoto from "components/dialogs/UploadConditionPhoto";
import UploadConditionVideo from "components/dialogs/UploadConditionVideo";
import ConditionReportDialog from "components/dialogs/ConditionReportDialog";
import PhotoViewer from "components/PhotoViewer";
import ConditionReportMediaPanel from "./ConditionReportMediaPanel";
import styled from '@emotion/styled';

const PREFIX = 'ConditionReportPanel';

const classes = {
    basicCard: `${PREFIX}-basicCard`,
    vehicleDetail: `${PREFIX}-vehicleDetail`,
    vehicleBox: `${PREFIX}-vehicleBox`,
    vehicleTypo: `${PREFIX}-vehicleTypo`,
    viewMore: `${PREFIX}-viewMore`,
    viewReport: `${PREFIX}-viewReport`,
    conditionReport: `${PREFIX}-conditionReport`,
    condition: `${PREFIX}-condition`,
    conditionPhotos: `${PREFIX}-conditionPhotos`
}

const StyledGrid = styled(Grid)(({
    theme
}) => {
    return {
        [`& .${classes.basicCard}`]: {
            background: theme.palette.background.btn
        },
        [`& .${classes.vehicleDetail}`]: {
            borderBottom: `1px solid ${theme.palette.border.default}`,
            padding: 0
        },
        [`& .${classes.vehicleBox}`]: {
            borderLeft: `4px solid ${theme.palette.secondary.main}`,
        },
        [`& .${classes.vehicleTypo}`]: {
            margin: '15px 0px 15px 15px'
        },
        [`& .${classes.viewMore}`]: {
            marginTop: 30,
            width: 350,
            background: theme.palette.field.background,
            color: theme.palette.text.secondary,
            marginBottom: 30,
        },
        [`& .${classes.viewReport}`]: {
            margin: '7px 7px 7px 7px',
            height: 35,
            background: theme.palette.field.background,
            color: theme.palette.text.secondary,
            fontSize: 12
        },
        [`& .${classes.conditionReport}`]: {
            margin: '7px 7px 7px 7px',
            height: 35,
            background: theme.palette.background.default,
            color: theme.palette.text.secondary,
            fontSize: 12
        },
        [`& .${classes.condition}`]: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        [`& .${classes.conditionPhotos}`]: {
            marginTop: 20,
            maxWidth: 300
        }
    }
});


const ConditionReportPanel = ({ vehicle, handleConditionReportChanged }) => {
    const [showMore, setShowMore] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showConditionReport, setShowConditionReport] = useState(false);
    const [showConditionReportPhotos, setShowConditionReportPhotos] = useState(false);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [allDetails, setAllDetails] = useState([]);

    const { search: { conditionReport: { details, report, photos } } } = useSelector(state => state);

    useEffect(() => {
        if (details) {
            if (showMore) {
                setAllDetails(details);
            }
            else {
                if (details.length > 2) {
                    setAllDetails(details.slice(0, 3));
                }
                else {
                    setAllDetails(details);
                }
            }
        }
    }, [showMore, details]);

    const handleConditionReportClosed = (updated) => {
        if (updated) {
            handleConditionReportChanged();
        }

        setShowConditionReport(false);
    }

    const handleConditionReportPhotosClosed = (updated) => {
        if (updated) {
            handleConditionReportChanged();
        }

        setShowConditionReportPhotos(false);
    }

    const handleConditionReportVideoClosed = (updated) => {
        if (updated) {
            handleConditionReportChanged();
        }

        setIsVideoOpen(false);
    }

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Card className={`mt-4 ${classes.basicCard}`}>
                    <CardContent className={classes.vehicleDetail}>
                        {vehicle?.mine === 1 ? <>
                            <Box className={classes.condition}>
                                <Box item>
                                    <Box className={classes.vehicleBox}>
                                        <Typography variant='h5' className={classes.vehicleTypo}>CONDITION REPORT</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <ConditionReportDialog
                                        isOpen={showConditionReport}
                                        vehicle={vehicle}
                                        handleClose={handleConditionReportClosed}
                                    />

                                    {details?.length > 0 && (
                                        <Button
                                            variant="contained"
                                            size="large"
                                            className={classes.conditionReport}
                                            onClick={() => {
                                                setShowConditionReport(true);
                                            }}
                                        >
                                            Update Report
                                        </Button>
                                    )}

                                    {(!details || details?.length === 0) && (
                                        <>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className={classes.conditionReport}
                                                onClick={() => {
                                                    setShowConditionReport(true);
                                                }}
                                            >
                                                Add Report
                                            </Button>
                                        </>
                                    )}

                                    {details?.length > 0 &&
                                        <>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className={classes.conditionReport}
                                                onClick={() => setIsVideoOpen(true)}
                                            >
                                                Add Condition Video
                                            </Button>

                                            <UploadConditionVideo
                                                open={isVideoOpen}
                                                handleClose={handleConditionReportVideoClosed}
                                                report={report}
                                            />

                                            <Button
                                                variant="contained"
                                                size="large"
                                                className={classes.conditionReport}
                                                onClick={() => setShowConditionReportPhotos(true)}
                                            >
                                                Add Condition Photos
                                            </Button>

                                            <UploadConditionPhoto
                                                open={showConditionReportPhotos}
                                                handleClose={handleConditionReportPhotosClosed}
                                                vehicle={vehicle}
                                            />
                                            <Button
                                                variant="contained"
                                                size="large"
                                                className={classes.viewReport}
                                                onClick={() => setShowReport(true)}
                                            >
                                                View Report
                                            </Button>
                                            <ViewReportDialog
                                                isOpen={showReport}
                                                handleClose={() => setShowReport(false)}
                                                details={details}
                                            />
                                        </>
                                    }
                                </Box>
                            </Box>
                        </> :
                            <Grid container>
                                <Grid item xs={10}>
                                    <Box className={classes.vehicleBox}>
                                        <Typography variant='h5' className={classes.vehicleTypo}>CONDITION REPORT</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box className="ml-6">
                                        {details?.length > 0 &&
                                            <Box>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    className={classes.viewReport}
                                                    onClick={() => setShowReport(true)}
                                                >
                                                    View Report
                                                </Button>

                                                <ViewReportDialog
                                                    isOpen={showReport}
                                                    handleClose={() => setShowReport(false)}
                                                    details={details}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>}
                    </CardContent>

                    <CardContent>
                        {allDetails.length === 0 && report?.is_front_line_ready !== 1 && (
                            <center>
                                <Typography variant="h2">No Condition Report</Typography>
                            </center>
                        )}

                        {allDetails?.length > 0 && allDetails.map((detail) => (
                            <Card className="mt-2">
                                <CardContent>
                                    <Typography variant='h6'>{detail.name}</Typography>
                                    <Typography variant='h6' color='text.grey'>{detail.condition}</Typography>
                                    {detail.notes && (
                                        <>
                                            <hr />
                                            <Typography style={{ marginTop: 10 }} variant='h6' color='text.grey'><b>NOTES:</b> {detail.notes}</Typography>
                                        </>
                                    )}
                                    {detail.photos.length > 0 && (
                                        <div className={classes.conditionPhotos}>
                                            <PhotoViewer
                                                photos={detail?.photos?.map(photo => photo.link)}
                                            />
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        ))}

                       { report?.is_front_line_ready && <Card className="mt-2">
                                <CardContent>
                                    <Typography variant='h6'>Front Line Ready Description</Typography>
                                    <Typography variant='h6' color='text.grey'>{report?.frontline_ready_description}</Typography>
                                    
                                </CardContent>
                            </Card>}

                        {details?.length > 0 &&
                            <center>
                                <Button
                                    variant="contained"
                                    size="large"
                                    className={classes.viewMore}
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    <Box className="flex">
                                        <Box>
                                            <Typography variant="h4">{!showMore ? 'VIEW MORE' : 'VIEW LESS'}</Typography>
                                        </Box>
                                        <Box className="ml-1">
                                            <Image
                                                src="/static/images/belowArrow.png"
                                            />
                                        </Box>
                                    </Box>
                                </Button>
                            </center>
                        }

                        <hr />

                        <ConditionReportMediaPanel photos={photos} report={report} />
                    </CardContent>
                </Card>
            </Grid>
        </StyledGrid>

    )
}

export default ConditionReportPanel;