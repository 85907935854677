import React from 'react';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const SleepsFilter = ({ criteria }) => {
  const {
    search: { vehicleOption },
  } = useSelector((state) => state);

  return (
    <TabPanel value='sleeps'>
      <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                (
                  localStorage.hasOwnProperty('sleeps')
                    ? localStorage.getItem('sleeps') === ''
                    : criteria.sleeps === ''
                )
                  ? classes.btnColor
                  : classes.styledBtn
              }
              color='primary'
              size='large'
              value=''
              onClick={(e) => {
                criteria.setPage(1);
                localStorage.setItem('sleeps', e.target.value);
                criteria.handleSleeps(e.target.value);
              }}
            >
              All
            </StyledOptionButton>
          </Grid>
          {vehicleOption.sleep?.map((sleep) => (
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  sleep.sleeps === localStorage.getItem('sleeps')
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={sleep.sleeps}
                onClick={(e) => {
                  criteria.setPage(1);
                  localStorage.setItem('sleeps', e.target.value);
                  criteria.handleSleeps(e.target.value);
                }}
              >
                {sleep.sleeps} ({sleep.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
      </StyledFilterGrid>
    </TabPanel>
  );
};

export default SleepsFilter;