import React from "react";
import { Field, Formik } from "formik";
import { InputLabel } from "@mui/material";

const ChangeRooftop = ({ initialValue, handleSubmit, rooftops, label }) => {
  return (
    <div className="float-left ml-[10px] mt-[5px]">
      <Formik
        initialValues={initialValue}
        onSubmit={async (values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, handleChange }) => {
          return (
          <>
          {label &&  <InputLabel id="rooftop">{label}</InputLabel>}
            <div className="rounded-[5px] p-[10px] bg-white  border border-black ">
              <Field
                as="select"
                name="rooftop"
                className="w-[180px]"
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                {rooftops &&
                  rooftops.map((rooftop) => {
                    return (
                      <option key={rooftop.dealerRooftopId} value={rooftop.dealerRooftopId}>{rooftop.rooftopName}</option>
                    );
                  })}
              </Field>
            </div>
          </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ChangeRooftop;
