import React from "react";
import { styled } from '@mui/material/styles';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const PREFIX = 'ProgressSpinner';

const classes = {
  backdrop: `${PREFIX}-backdrop`
};

const StyledBackdrop = styled(Backdrop)((
    {
      theme
    }
) => ({
  [`&.${classes.backdrop}`]: {
    zIndex: theme.zIndex ? theme.zIndex.drawer + 1 : 1,
    color: theme.palette.background.default,
  }
}));

export default function Spinner({show}) {

  return (
      <StyledBackdrop className={classes.backdrop} open={show}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
  );
}
