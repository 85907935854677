import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Spinner from "components/Spinner";
import TaskItem from "./components/TaskItem";
import TaskLists from "./components/TaskLists";
import { getPurchaseTransactions, getSoldTransactions, getNeedAttentionTransactions } from "data/transaction.actions";
import useInterval from "hooks/useInterval";

const PREFIX = 'SortBox'

const classes = {
  tabs: `${PREFIX}-tabs`,
  sort: `${PREFIX}-sort`
}

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.tabs}`]: {
      textTransform: 'capitalize',
      fontSize: 19,
    },
  }
});

const TasksView = () => {
  const [selectedList, setSelectedList] = useState('needs_attention');

  const dispatch = useDispatch();
  const {
    transactionType,
    loadingTransactions,
    auctionTransactions,
    error,
  } = useSelector(state => state.transaction);

  const { user } = useSelector(state => state.authentication);

  useInterval(  () => {
    if (selectedList === 'purchased') {
      dispatch(getPurchaseTransactions({showProcessing: selectedList !== transactionType}));
    }

    if (selectedList === 'sold') {
      dispatch(getSoldTransactions({showProcessing: selectedList !== transactionType}));
    }

    if (selectedList === 'needs_attention') {
      dispatch(getNeedAttentionTransactions({showProcessing: selectedList !== transactionType}));
    }
  }, 1000);

  // const pollingCallback = () => {
  //     if (selectedList === 'purchased') {
  //       dispatch(getPurchaseTransactions({showProcessing: selectedList !== transactionType}));
  //     }
  //
  //     if (selectedList === 'sold') {
  //       dispatch(getSoldTransactions({showProcessing: selectedList !== transactionType}));
  //     }
  //
  //     if (selectedList === 'needs_attention') {
  //       dispatch(getNeedAttentionTransactions({showProcessing: selectedList !== transactionType}));
  //     }
  // };

  return (
    <StyledGrid container>
      <Grid item xs={12}>
        <TaskLists
          selectedTab={selectedList}
          handleTabChange={(e, value) => {
            setSelectedList(value)
          }} />
      </Grid>

      <Grid item xs={12}>
        {auctionTransactions && auctionTransactions.map(transaction => {
          return (
            <TaskItem key={transaction.token} transaction={transaction} id={user?.id} rooftop_id={user?.rooftop_id} />
          )
        })}
      </Grid>

      <Typography variant='h6' color='error'>{error}</Typography>
      <Spinner show={selectedList !== transactionType && loadingTransactions} />

      {/*<Polling*/}
      {/*  transactionType={transactionType}*/}
      {/*  shouldContinue={() => true}*/}
      {/*  callback={pollingCallback}*/}
      {/*/>*/}
    </StyledGrid>
  )
}

export default TasksView;