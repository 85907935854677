import { combineReducers } from 'redux';
import { createMetaReducer } from "redux-beacon";
import authenticationReducer from './data/authentication.slice'
import locationReducer from './data/location.slice'
import userReducer from './data/user.slice'
import vehicleReducer from './data/vehicle.slice'
import auctionReducer from './data/auction.slice'
import searchCriteriaReducer from './data/search.slice'
import transactionReducer from './data/transaction.slice'
import offerReducer from './data/offer.slice'
// import { routerReducer } from 'react-router-redux'

export const createReducer = (eventsMap, ga) => {
  const gaMetaReducer = createMetaReducer(eventsMap, ga);

  return combineReducers(
{
          authentication: authenticationReducer,
          location: locationReducer,
          user: userReducer,
          search: vehicleReducer,
          auction: auctionReducer,
          transaction: transactionReducer,
          offer: offerReducer,
          searchCriteria: searchCriteriaReducer,
          // analytics: gaMetaReducer,
          // routing: routerReducer,
    });
}