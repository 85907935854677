import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import BouncingDotsLoader from "../../BouncingDotsLoader";

const PREFIX = 'CountDown';

const classes = {
  errorBlock: `${PREFIX}-errorBlock`,
  countdown: `${PREFIX}-countdown`,
  finalizingBlock: `${PREFIX}-finalizingBlock`,
  finalizingText: `${PREFIX}-finalizingText`,
};

const StyledContainer = styled(Box)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.countdown}`]: {
      width: 330,
      background: '#ffebec',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      padding: '15px 20px 15px 20px',
      borderRadius: 6,
      display: 'flex',
      justifyContent: 'space-between',
      float: 'right'
    },
    [`& .${classes.errorBlock}`]: {
      width: 300,
      background: '#ffebec',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      padding: 25,
      borderRadius: 6
    },
    [`& .${classes.finalizingBlock}`]: {
      width: 400,
      background: '#ffebec',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      padding: 25,
      borderRadius: 6
    },
    [`& .${classes.finalizingText}`]: {
      marginBottom: 30
    }
  }
});

const AuctionCountdown = ({ auction, size = "big" }) => {
  const [expiryTime, setExpiryTime] = useState(auction?.auction_end_date);
  const [countdownTime, setCountdownTime] = useState({
    countdownDays: '',
    countdownHours: '',
    countdownlMinutes: '',
    countdownSeconds: '',
  });

  useEffect(() => {
    const timeInterval = setInterval(() => {
      const countdownDateTime = new Date(expiryTime).getTime();
      const currentTime = new Date().getTime();
      const remainingDayTime = countdownDateTime - currentTime;
      const totalDays = Math.floor(remainingDayTime / (1000 * 60 * 60 * 24));
      const totalHours = Math.floor(
        (remainingDayTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const totalMinutes = Math.floor(
        (remainingDayTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const totalSeconds = Math.floor(
        (remainingDayTime % (1000 * 60)) / 1000
      );

      const runningCountdownTime = {
        countdownDays: totalDays,
        countdownHours: totalHours,
        countdownMinutes: totalMinutes,
        countdownSeconds: totalSeconds,
      };

      setCountdownTime(runningCountdownTime);

      if (remainingDayTime < 0) {
        clearInterval(timeInterval);
        setExpiryTime(false);
      }
    }, 1000);
  }, [expiryTime]);

  return (
    <>
   {size === "big" && <StyledContainer>
      {expiryTime !== false ? (
        <Box className={classes.countdown}>
          <Box>
            <Typography variant="h2">{countdownTime.countdownDays?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}</Typography>
            <Typography variant="body2" color="black">Days</Typography>
          </Box>
          <Typography variant="h2">:</Typography>
          <Box>
            <Typography variant="h2">{countdownTime.countdownHours?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}</Typography>
            <Typography variant="body2" color="black">Hours</Typography>
          </Box>
          <Typography variant="h2">:</Typography>
          <Box>
            <Typography variant="h2">{countdownTime.countdownMinutes?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}</Typography>
            <Typography variant="body2" color="black">Minutes</Typography>
          </Box>
          <Typography variant="h2">:</Typography>
          <Box>
            <Typography variant="h2">{countdownTime.countdownSeconds?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}</Typography>
            <Typography variant="body2" color="black">Seconds</Typography>
          </Box>
        </Box> ) : (
        <Box style={{ float: 'right' }} className={classes.finalizingBlock}>
          <Box>
            <Typography className={classes.finalizingText} color='text.primary' variant="h2">Auction Ended. Processing.</Typography>
            <BouncingDotsLoader />
          </Box>
        </Box>
        )
      }
    </StyledContainer>}
    {size === "small" && <div>
    {expiryTime !== false ? (
        <Box className={`flex justify-between bg-[#ED1D24] bg-opacity-10 w-[95px] float-left text-[#ED1D24] p-2 h-[33px] rounded-[3.1px]`}>
          <Box>
            <Typography variant="h2" fontSize={10.5}>{countdownTime.countdownDays?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}</Typography>
            <Typography variant="body2" color="black" fontSize={7.37}>Days</Typography>
          </Box>
          <Typography variant="h2" fontSize={10.5}>:</Typography>
          <Box>
            <Typography variant="h2" fontSize={10.5}>{countdownTime.countdownHours?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}</Typography>
            <Typography variant="body2" color="black" fontSize={7.37}>Hrs:</Typography>
          </Box>
          <Typography variant="h2" fontSize={10.5}>:</Typography>
          <Box>
            <Typography variant="h2" fontSize={10.5}>{countdownTime.countdownMinutes?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}</Typography>
            <Typography variant="body2" color="black" fontSize={7.37}>Mins</Typography>
          </Box>
          <Typography variant="h2" fontSize={10.5}>:</Typography>
          <Box>
            <Typography variant="h2" fontSize={10.5}>{countdownTime.countdownSeconds?.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            })}</Typography>
            <Typography variant="body2" color="black" fontSize={7.37}>Secs</Typography>
          </Box>
        </Box> ) : (
        <Box style={{ float: 'right' }} className={classes.finalizingBlock}>
          <Box>
            <Typography className={classes.finalizingText} color='text.primary' variant="h2" fontSize={7.37}>Auction Ended. Processing.</Typography>
            <BouncingDotsLoader/>
          </Box>
        </Box>
        )
      }
    </div>
    }
    </>
  );
}

export default AuctionCountdown;