import React from 'react';

export const useStateWithLocalStorage = localStorageKey => {
    const [value, setValue] = React.useState(
        localStorage.getItem(localStorageKey) || undefined
    );

    React.useEffect(() => {
        if (value) {
            localStorage.setItem(localStorageKey, value);
        }
        else {
            localStorage.removeItem(localStorageKey);
        }
    }, [localStorageKey, value]);

    return [value, setValue];
};
