import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styled from "@emotion/styled";
import RooftopSearchPanel from "../../Search/RooftopSearchPanel";
import {internalAuctions} from "../../../data/auction.actions";

const StyledOptionButton = styled(Button)((
    {
        theme
    }
) => {
    return {
        boxShadow: '0px 9px 10px rgb(0 0 0 / 5%)',
        match: '2px',
    }
});


const PREFIX = 'Search'

const classes = {
    card: `${PREFIX}-card`,
    verticalCard: `${PREFIX}-verticalCard`,
    btn: `${PREFIX}-btn`,
    btnBackground: `${PREFIX}-btnBackground`,
    tabs: `${PREFIX}-tabs`,
    styledBtn: `${PREFIX}-styledBtn`,
    btnColor: `${PREFIX}-btnColor`,
    btnBox: `${PREFIX}-btnBox`
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.card}`]: {
            marginBottom: 30,
            boxShadow: '0px 4px 31px rgb(0 0 0 / 5%)',
            borderRadius: 10,
        },
        [`& .${classes.btn}`]: {
            textTransform: 'capitalize',
            width: 100,
            height: 45
        },
        [`& .${classes.btnBackground}`]: {
            textTransform: 'capitalize',
            background: theme.palette.secondary.main,
            width: 100,
            height: 45
        },
        [`& .${classes.verticalCard}`]: {
            marginBottom: 10,
            marginTop: 10,
            boxShadow: '0px 4px 31px rgb(0 0 0 / 5%)',
            borderRadius: 10,
            padding: 12
        },
        [`& .${classes.tabs}`]: {
            textTransform: 'capitalize',
            fontWeight: 600
        },
        [`& .${classes.styledBtn}`]: {
            textTransform: 'capitalize',
            color: theme.palette.border.default,
            background: theme.palette.background.btn
        },
        [`& .${classes.btnColor}`]: {
            textTransform: 'capitalize',
            background: theme.palette.primary.main,
            color: theme.palette.background.default
        },
        [`& .${classes.btnBox}`]: {
            marginLeft: 10,
            marginTop: 5
        },
    }
});

const verticals = [
    { value: 'Auto,RV,Powersport', label: 'All Verticals' },
    { value: 'Auto', label: 'Auto' },
    { value: 'RV', label: 'RV' },
    { value: 'Powersports', label: 'Powersports' }
];

const SearchPanel = ({
    props
}) => {
    const dispatch = useDispatch();
    const { auctionType } = props;
    const [value, setValue] = useState();
    const { searchCriteria } = useSelector(state => state);

    useEffect(() => {
        if (searchCriteria.rooftops?.length > 0) {
            const data = {
                rooftops: searchCriteria.rooftops
            }
            dispatch(internalAuctions(data));
        }
    }, [searchCriteria.rooftops]);

    useEffect(() => {
        setValue(auctionType === 'internal' ? 'rooftop' :'vertical')
    }, [auctionType]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const selectMultipleOption = (options, selectedOption, setOption) => {
        if (selectedOption === 'All Auctions') {
            options = 'Auto,RV,Powersports';
        } else {

            if (options === 'Auto,RV,Powersports' && options !== selectedOption) {
                options = selectedOption;
            } else {
                if (options.includes(selectedOption)) {
                    const replacement = '';
                    options = options.replace(`${selectedOption}`, '');
                    if (options.indexOf(",") === 0) {
                        options = options.replace(/,/, replacement);
                    }
                } else {
                    options = `${options},${selectedOption}`;
                }
            }
        }
        setOption(options);
    }


    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Box sx={{ display: 'flex', flexDirection: { xs: "column", md: "row"}, width: '1', mt: 2, ml: 2 }}>
                                <Paper
                                    component="form"
                                    sx={{ display: 'inline-flex', flex: 1, alignItems: 'center'}}
                                >
                                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search Auction"
                                        inputProps={{
                                            'aria-label': 'search google maps'
                                        }}
                                        onChange={(e) => props.setSearch(e.target.value)}
                                        value={props.vehSearch}
                                    />
                                </Paper>
                                <Box sx={{ display: 'flex', marginLeft: {sm: '5px'}, marginTop:  {xs: '5px', md: '0px' }}}> 
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.btnBackground}
                                        onClick={props.handleSearch}
                                        >
                                        Search
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Card className={classes.verticalCard}>
                            <Grid item xs={12}>
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTabChange}>
                                                {auctionType !== 'internal' ? <Tab label="Verticals" value="vertical" className={classes.tabs} /> : ''}
                                                {auctionType === 'internal' ? <Tab label="Rooftops" value="rooftop" className={classes.tabs} /> : ''}
                                            </TabList>
                                        </Box>
                                        {auctionType !== 'internal' && (
                                            <TabPanel value="vertical">
                                                <Grid container spacing={1}>
                                                    {verticals.slice(1, 4).map(vertical => (
                                                        <Grid className={classes.btnBox}>
                                                            <StyledOptionButton
                                                                variant="contained"
                                                                className={
                                                                    (props.vertical === 'Auto,RV,Powersports') ? classes.btnColor :
                                                                        (props.vertical?.includes(vertical.value) ? classes.btnColor : classes.styledBtn)
                                                                }
                                                                color="primary"
                                                                size="large"
                                                                value={vertical.value}
                                                                onClick={(e) => {
                                                                    selectMultipleOption(props.vertical, e.target.value, props.setVertical, vertical.value, verticals);
                                                                }}
                                                            >
                                                                {vertical.label}
                                                            </StyledOptionButton>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </TabPanel>
                                        )}
                                        {auctionType === 'internal' && (
                                            <TabPanel value="rooftop">
                                                <RooftopSearchPanel />
                                            </TabPanel>
                                        )}
                                    </TabContext>
                                </Box>
                            </Grid>
                        </Card>
                    </CardContent>
                </Card>
            </Grid>
        </StyledGrid >
    )
}

export default SearchPanel;