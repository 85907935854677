import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    updateUserSettings,
    gettingUserSettings,
    gettingUserForEdit,
    gettingLocationRoles,
    updatingUserPassword,
    updatedUserPassword,
    userToEditLoaded,
    loadingUsers,
    usersLoaded,
    cleaningUser,
    creatingUser,
    userCreated,
    loadedLocationRoles,
    updatingUser,
    updatedUser,
    errorFound,
    togglingLikes,
    toggledLikes,
    loadingLikes,
    loadedLikes,
    addingMessage,
    addedMessage,
    loadedMessages,
    loadingMessages,
    loadingMessageThread,
    loadedMessageThread

} from "./user.slice";
import { updatingUserDefaultRooftop, updatedUserDefaultRooftop } from "./authentication.slice";
import {toCamelcase, formatDateTime} from "utils/Formatters";

export const loadLocationRoles = createAsyncThunk('users/loadLocationRoles', async (values, {extra, dispatch}) => {
  try {
    dispatch((gettingLocationRoles()));

    const response = await extra.get('/user/roles');
    const locationRoles = response.map(location => toCamelcase(location));

    dispatch(loadedLocationRoles({
      locationRoles
    }));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: error.message}));
  }
});


export const loadUserForEdit = createAsyncThunk('user/userToEdit', async (values, {extra, dispatch}) => {
  try {
    dispatch((gettingUserForEdit()));

    const response = await extra.get(`/user/${values.userId}`);
    const user = toCamelcase(response[0][0]);
    const roles = response[2].map(role => toCamelcase(role));
    const locations = response[1].map(location => {
      const camel = toCamelcase(location);
      const myRoles = roles.filter(role => camel.dealerRooftopId === role.dealerRooftopId);
      return {
        ...camel,
        defaultMark: camel.defaultMark.data[0] === 1,
        locationRoles: myRoles
      }
    } );
    const generalAccess = { dealerId: user.dealerId , generalAccess: response[3].map(role => toCamelcase(role)) }

    dispatch(userToEditLoaded({
      user: {
        ...user,
        userId: values.userId,
        enabled: user.disabledDate === null,
        locationAccess: locations,
        generalAccess: generalAccess
      }
    }));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: error.message}));
  }
});

export const loadUserSettings = createAsyncThunk('user/settings', async (values, {extra, dispatch}) => {
  try {
    dispatch((gettingUserSettings()));

    const {settings} = await extra.get(`/user/settings/${values.userId}`);

    dispatch(updateUserSettings({settings}));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Invalid Login'}));
  }
});

export const updateUserPassword = createAsyncThunk('user/updatePassword', async (values, {extra, dispatch}) => {
  try {
    dispatch((updatingUserPassword()));

    await extra.post(`/user/password`, {
      current_password: values.currentPassword,
      password: values.newPassword
    });

    dispatch(updatedUserPassword());
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to update password'}));
  }
});

export const updateUserNotification = createAsyncThunk('user/userNotifications', async (values, {extra, dispatch}) => {
  try {
    dispatch((gettingUserSettings()));

    const {user} = await extra.post(`/user/settings`, {
      ...values
    });

    dispatch(loadUserSettings({userId: user.uid}));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to update settings'}));
  }
});

export const loadUsers = createAsyncThunk('user/loadDealerUsers', async (values, {extra, dispatch}) => {
  try {
    dispatch((loadingUsers()));

    const users = await extra.get(
        `/users/permissions/${values.uid}/dealer/${values.dealerId}?sort=${values.sort}&rows=${values.rows}&offset=${values.offset}`
    );

    dispatch(usersLoaded({users}));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to loadUsers.'}));
  }
});

export const updateUser = createAsyncThunk('user/updateUser', async (values, {extra, dispatch}) => {
  try {
    dispatch((updatingUser()));

    const user = await extra.post(
        `/user/update`,
        values
    );

    dispatch(updatedUser({user}));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to update user.'}));
  }
});

export const updateUserDefaultRooftop = createAsyncThunk('user/updateUserDefaultRooftop', async (values, {extra, dispatch}) => {
  try {
    dispatch((updatingUserDefaultRooftop()))

    const user = await extra.post(
      `/users/defaultRooftop`,
      values 
    );
    dispatch(updatedUserDefaultRooftop(values));
  }
  catch (error) {
    console.log(error.message);
    dispatch(errorFound({error: "Unable to update user's default rooftop"}))
  }

  
})

export const cleaningUserData = (dispatch) => {
  dispatch(cleaningUser());
}
export const createUser = createAsyncThunk('user/updateUser', async (values, {extra, dispatch}) => {
  try {
    dispatch((creatingUser()));

    const response = await extra.post(
        `/user/create`,
        values
    );

    if (response.error) {
      if (response.error.type === "email-exists"){
        dispatch(errorFound({error: "Unable to create user. A user with this email address already exists."}));
      } else {
        dispatch(errorFound({error: 'Unable to create user.'}));
      }
    } else {
      dispatch(userCreated());
    }
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to create user.'}));
  }
});

export const toggleLikes = createAsyncThunk('user/toggleLikes', async (values, {extra, dispatch}) => {
  try {
    dispatch((togglingLikes()));

    const response = await extra.post(`/bookmarks/toggle`, values)
    dispatch(toggledLikes(response));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to toggle likes'}));
  }
});

export const loadLikes = createAsyncThunk('user/loadLikes', async (values, {extra, dispatch}) => {
  try {
    dispatch((loadingLikes()));

    const response = await extra.get(`/bookmarks?page=${values?.page}&sort=${values?.sort}&search=${values?.search}`);
    
      dispatch(loadedLikes({likes: response?.bookmarks}));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to load likes'}));
  }
});

export const loadMessages = createAsyncThunk('user/loadMessage', async (values, {extra, dispatch}) => {
  try {
    dispatch((loadingMessages()));

    const response = await extra.get(`/messages/inbox`)

    dispatch((loadedMessages({messages: response?.data})))
  } 
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to load messages'}));
  }
})

export const addMessage = createAsyncThunk('user/addMessage', async (values, {extra, dispatch})  => {
  try {
    dispatch((addingMessage()));

    const response = await extra.post(`/message`, values)
    dispatch(addedMessage(response));
    dispatch(loadMessageThread({messageId: values.threadId}))
  }
  catch (error) {
  console.log(error.message)
  dispatch(errorFound({error: 'Unable to add message'}))
  }
})

export const loadMessageThread = createAsyncThunk('user/loadMessageThread', async (values, {extra, dispatch}) => {
  try {
    dispatch((loadingMessageThread()));
    
    const response = await extra.get(`/message/thread/${values.messageId}`)
    dispatch((loadedMessageThread({messageThread: response?.data})))
  }
  catch (error) {
    console.log(error.message);
    dispatch(errorFound({error: 'Unable to load message thread'}))
  }
})