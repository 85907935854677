import FormControl from "./widgets/FormControl";

export default function AddressView () {
  return (
    <div className='col-span-12 mt-8'>
      <FormControl
        label='Corporate Address'
        type='text'
        name='dealer_address'
      />

      <FormControl
        label='Address 2'
        type='text'
        name='dealer_address2'
      />

      <div className='grid grid-cols-12'>
        <div className='col-span-5'>
          <FormControl
            label='City'
            type='text'
            name='dealer_city'
          />
        </div>

        <div className='col-span-3'>
          <FormControl
            label='State'
            type='text'
            name='dealer_state'
          />
        </div>

        <div className='col-span-4'>
          <FormControl
            label='Zip Code'
            type='text'
            name='dealer_zip'
          />
        </div>
      </div>
    </div>
  )
}