import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";

const PREFIX = 'ExchangeStoryPanel';

const classes = {
    img: `${PREFIX}-dealer-img`,
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    border: `${PREFIX}-border`,
    detail: `${PREFIX}-detail`,
    imageHeight: `${PREFIX}-imgHeight`,
    headerContainer: `${PREFIX}-headerContainer`,
    textContainer: `${PREFIX}-textContainer`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        marginTop: 50,
        [`& .${classes.img}`]: {
            borderRadius: 10,
            width: '90%'
        },
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        [`& .${classes.logo}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.border}`]: {
            width: 170,
            marginBottom: 10,
            borderTop: `3px solid ${theme.palette.secondary.main}`,
            float: 'left'
        },
        [`& .${classes.detail}`]: {
            textIndent: 20,
            [theme.breakpoints.up('xs')]: {
                paddingTop: 10
            },
            [theme.breakpoints.up('lg')]: {
                paddingTop: 5
            },
        },
        [`& .${classes.imageHeight}`]: {
            height: 320,
            [theme.breakpoints.up('xs')]: {
                marginLeft: 20,
                marginTop: 20,
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: 80
            },
        },
        [`& .${classes.headerContainer}`]: {
            marginBottom: 30
        },
        [`& .${classes.textContainer}`]: {
            paddingLeft: 10
        },
    }
});

const ExchangeStoryPanel = () => {
    return (
        <StyledGrid container>
            <Grid item xs={12} className={classes.headerContainer}>
                <Box className={classes.flex}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/ReinventLogo.png'
                        className={classes.logo}
                    />
                    <Typography variant='h1' color="#213E98">The ReinventX<span className={classes.right}>change</span> Story</Typography>
                </Box>
                <hr className={classes.border} />
            </Grid>

            <Grid container className="mt-6">
                <Grid item xs={12} md={6} className={classes.textContainer}>
                    <Typography variant="h1" color="text.link">Dealer Designed. Dealer Driven.</Typography>
                    <Typography variant="h3" className={classes.detail}>
                        Reinvent Exchange is the home of the most versatile online wholesale trading
                        platform to buy and sell vehicles with the click of a button.
                        Frictionless transactions directly from your DMS to delivery.
                        Super Easy. Super Simple. Join. Click. Trade.The ReinventAuctions program functions as an online auction to
                        facilitate easy dealer engagements in real-time.
                    </Typography>
                    <Typography variant="h3" className={classes.detail}>
                        The goal is smooth B2B transactions with no interference from auction house middlemen.
                        Dealers interact directly with other dealers for faster, easier transactions on an online platform
                        so a dealer from New York can easily trade or sell with a dealer in California!
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Image
                        className={classes.imageHeight}
                        alt="Banner Image"
                        src='/static/images/collage.png'
                    />
                </Grid>
            </Grid>
        </StyledGrid>
    )
}

export default ExchangeStoryPanel;