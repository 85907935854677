import Button from '@mui/material/Button';
import styled from "@emotion/styled";

const StyledOptionButton = styled(Button)((
    {
        theme
    }
) => {
    return {
        boxShadow: '0px 9px 10px rgb(0 0 0 / 5%)',
        match: '2px',
    }
});

export default StyledOptionButton