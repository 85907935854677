import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";

const PREFIX = 'getConnected';

const classes = {
    img: `${PREFIX}-connected-img`,
    imageContainer: `${PREFIX}-imageContainer`,
    heading: `${PREFIX}-connected-heading`,
    header: `${PREFIX}-header`,
    cardContainer: `${PREFIX}-cardContainer`,
    card: `${PREFIX}-connected-card`,
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    cardHeader: `${PREFIX}-header`,
    cardText: `${PREFIX}-heading`,
    secondRowCard: `${PREFIX}-secondRowCard`,
    selectedCard: `${PREFIX}-selectedCard`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [theme.breakpoints.up('xs')]: {
            paddingTop: 50,
        },
        [theme.breakpoints.up('lg')]: {
            padding: '50px 180px 0 0'
        },
        [`& .${classes.imageContainer}`]: {
            padding: '0 10px'
        },
        [`& .${classes.cardHeader}`]: {
            paddingLeft: 10
        },
        [`& .${classes.cardText}`]: {
            paddingTop: 10
        },
        [`& .${classes.img}`]: {
            width: 450,
            height: 500
        },
        [`& .${classes.heading}`]: {
            color: theme.palette.primary.main,
        },
        [`& .${classes.header}`]: {
            marginBottom: 20
        },
        [`& .${classes.card}`]: {
            boxShadow: '1px 10px 40px rgb(33 62 152 / 62%)',
            borderRadius: 20,
            marginRight: 30,
            [theme.breakpoints.up('xs')]: {
                height: 150,
            },
            [theme.breakpoints.up('lg')]: {
                height: 175,
            },
        },
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
        },
        [`& .${classes.cardContainer}`]: {
            justifyContent: 'center',
        },
        [`& .${classes.logo}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.secondRowCard}`]: {
            paddingTop: 20
        },
        [`& .${classes.selectedCard}`]: {
            [theme.breakpoints.up('xs')]: {
                marginTop: 20
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: 0
            },
            background: '#213E98',
        },
    }
});

const GetConnected = () => {
    return (
        <StyledGrid container>
            <Grid item xs={12} className={classes.header}>
                <Box className={classes.flex}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/ReinventLogo.png'
                        className={classes.logo}
                    />
                    <Typography variant="h1" className={classes.heading}>Get <span className={classes.right}>Connected</span> with other dealers</Typography>
                </Box>
            </Grid>

            <Grid item xs={12} md={6} lg={4} className={classes.imageContainer}>
                <Image
                    alt="connected"
                    src='/static/images/connected.png'
                    className={classes.img}
                />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Box className={classes.flex}>
                                    <Image
                                        alt="Buy"
                                        src='/static/images/Buy.png'
                                    />
                                    <Typography variant="h5" color="#213E98" className={classes.cardHeader}>BUY</Typography>
                                </Box>
                                <Typography variant='h4' className={classes.cardText}>
                                    Purchase units direct from other dealers to keep your inventory full when manufacturers are on back-order.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Card className={`${classes.card} ${classes.selectedCard}`} >
                            <CardContent>
                                <Box className={classes.flex}>
                                    <Image
                                        alt="Auction"
                                        src='/static/images/Auction.png'
                                    />
                                    <Typography variant="h5" color='#FFFFFF' className={classes.cardHeader}>AUCTION</Typography>
                                </Box>
                                <Typography variant='h4' className={classes.cardText} color='#FFFFFF'>
                                    The only Vehicle Auction platform that is fast, secure, and frictionless.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={6} className={classes.secondRowCard}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Box className={classes.flex}>
                                    <Image
                                        alt="Sell"
                                        src='/static/images/Sell.png'
                                    />
                                    <Typography variant="h5" color="#213E98" className={classes.cardHeader}>SELL</Typography>
                                </Box>
                                <Typography variant='h4' className={classes.cardText}>
                                    Sell off units that aren’t doing well in your market to dealers across the USA.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={6} className={classes.secondRowCard}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Box className={classes.flex}>
                                    <Image
                                        alt="Sell"
                                        src='/static/images/Trade.png'
                                    />
                                    <Typography variant="h5" color="#213E98" className={classes.cardHeader}>TRADE</Typography>
                                </Box>

                                <Typography variant='h4' className={classes.cardText}>
                                    Keep your cash on hand, trade with other dealers nationwide, and keep your inventory moving.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </StyledGrid>
    )
}

export default GetConnected;