import React, {useEffect, useState} from 'react';
import SignupEmailDialog from "../../components/dialogs/SignupEmailDialog";
import SignupForm from "../../components/forms/SignupForm";
import TermsAndConditionsForm from "../../components/forms/TermsAndConditionsForm";
import API from '../../utils/API';
import Spinner from "../../components/Spinner";

const SignupView = () => {

  const [dataProviders, setDataProviders] = useState([])
  const [referralSources, setReferralSources] = useState([])
  const [formInputValues, setFormInputValues] = useState({})
  const [step, setStep] = useState(1)
  const [errorData, setErrorData] = useState()
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const newDataProviders = await API.get('/dms/siteTypes')
      const newReferralSources = await API.get('/referral/sources')

      setDataProviders(newDataProviders.map(provider => {
        return {
          label: provider.text,
          value: provider.value
        }
      }))
      setReferralSources(newReferralSources.map(source => {
        return {
          label: source.text,
          value: source.value
        }
      }))
    }

    getData()
  }, [])

  const handleSubmit = async values => {
    setFormInputValues(values)
    setStep(2)
  }

  const handleFinalize = async values => {
    try {
      setProcessing(true)

      const formData = {
        ...formInputValues,
        is_rv: formInputValues.verticals.includes('is_rv') ? '1' : undefined,
        is_auto: formInputValues.verticals.includes('is_auto') ? '1' : undefined,
        is_powersports: formInputValues.verticals.includes('is_powersports') ? '1' : undefined,
      };

      const response = await API.post('/dealer/signup/', formData);

      if (response.error ) {
        if (response.error.type === "email-exists") {
          setErrorData({
            ...formData
          })
        }
      }
      else {
        setFormInputValues({})
        setStep(3)
      }

      setProcessing(false)
    }
    catch(error) {
      console.log({message: 'error in finalize', error: error.message})
      setProcessing(false)
    }
  }

  return (
    <div className="grid grid-cols-12 px-8 pb-3">
      <div className="width-48 ml-8 mt-1 col-span-12 text-3xl" >
          Sign Up for the Reinvent Platform
      </div>
      {step === 1 && (
        <>
          <div className="border-t-4 width-48 pt-5 ml-8 mt-5 col-span-12" >
              Enter your dealership information to begin transacting on the Reinvent Platform
          </div>

          <div className="ml-8 mt-5 col-span-12" >
            <SignupForm
              values={formInputValues}
              referralSources={referralSources}
              dataProviders={dataProviders}
              handleSubmit={handleSubmit}
            />
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div className="border-t-4 width-48 pt-5 ml-8 mt-5 col-span-12" >
            Read and accept the terms and conditions for the site.
          </div>

          <div className="ml-8 mt-5 col-span-12" >
            <TermsAndConditionsForm
              handleBackClicked={() => setStep(1)}
              handleSubmit={handleFinalize}
              processing={processing}
            />
          </div>
        </>
      )}

      {step === 3 && (
        <div className="ml-8 mt-5 col-span-12" >
          <b className="text-2xl">Dealer Sign Up for Complete</b>
          <div>
            <p className="mt-3 text-lg">Thank You for registering for the Reinvent platform!</p>

            <p className='mt-3'>We are busy working on uploading your inventory and you will soon be receiving an email 
              containing your dealer username and password.
            </p>

            <p className='mt-3'>A Reinvent representative will be in contact with you to walk you through the platform and show you 
              how easy it is to buy and sell with Reinvent Exchange!
            </p>

            <p className='mt-3'>If you have any questions, please contact us at:</p>

            <p className='mt-3'>support@reinvnetauctions.com</p>

           <p className='mt-3'>Thank you!</p>

           <p className='mt-3'>Reinvent Team</p>
          </div>
        </div>
      )
      }

     <Spinner show={processing} />

      <SignupEmailDialog
        show={errorData !== undefined}
        data={errorData}
        handleCloseClicked={() => setErrorData(undefined)}
      >
      </SignupEmailDialog>
    </div>
  )
}

export default SignupView;