import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import Spinner from "components/Spinner";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { cancelAuction } from 'data/vehicle.actions';

const CancelAuctionDialog = ({
  isOpen,
  handleClose,
  auction,
}) => {
  const dispatch = useDispatch()

   const {
     search: { isCancelAuctionLoading, auctionCanceled },
   } = useSelector((state) => state);


   useEffect(() => {
     if (auctionCanceled && auctionCanceled === auction?.auction_token) {
       toast.success("The Auction was Canceled");
       handleClose();
     }
   }, [auctionCanceled, auction?.auction_token]);

  const submitCancelAuction =  async () => {
    dispatch(cancelAuction(auction));
  }
  
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="sm"
      open={isOpen}
    >
      <DialogTitle>
      <div onClick={handleClose} className="float-right cursor-pointer">
          <Typography variant="h4">X</Typography>
        </div>
        </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div>
                <h3 className="text-center capitalize text-[40px] font-bold tracking-[5px]">
                  Cancel Auction?
                </h3>

                <div className="text-white font-bold text-[18px] mt-10 flex justify-center">
                  <button
                    onClick={handleClose}
                    className="bg-[#ED1D24] w-[182px] h-[50px] rounded-[7px] mr-2"
                  >
                    Back
                  </button>
                  <button
                    onClick={submitCancelAuction}
                    className="bg-[#213E98] w-[182px] h-[50px] rounded-[7px]"
                  >
                    Cancel
                  </button>
                </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <Spinner show={isCancelAuctionLoading} />
    </Dialog>
  );
};

export default CancelAuctionDialog;
