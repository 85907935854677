import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatDateTime } from "utils/Formatters";
import CompleteTaskDialog from "components/dialogs/CompleteTaskDialog";

const TitleProcessing = ({ classes, date, title, transactionId, isUploaded, id }) => {
    const [open, setIsOpen] = useState(false);

    return (
        date ? (
            <Grid item xs={12}>
                <CheckCircleIcon className={classes.completed} />
                <Typography variant='body'>{title}</Typography>
                <Typography variant='body' className={classes.actionDate}>{formatDateTime(date)}</Typography>
            </Grid>
        ) : (
            isUploaded ? (<Grid item xs={12}>
                <ScheduleIcon className={classes.scheduled} />
                <Typography variant='body'>{title}</Typography>
                <Typography variant='body' className={classes.action} onClick={() => setIsOpen(true)}>Complete</Typography>
                <CompleteTaskDialog
                    open={open}
                    handleClose={() => setIsOpen(false)}
                    title={title}
                    transactionId={transactionId}
                />
            </Grid>) :
                (<Grid item xs={12}>
                    <ScheduleIcon className={classes.scheduled} />
                    <Typography variant='body'>{title}</Typography>
                </Grid>)
        )
    )
}

export default TitleProcessing;