import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  gettingLocations,
  loadedLocations,
  errorFound,
  gettingDetailedLocations,
  loadedDetailedLocations,
  updatingLocation,
  updatedLocation,
  addingLocation,
  addedLocation,
  gettingRooftops,
  loadedRooftops,
  loadedRooftopsForUser,
  loadingRooftopsForUser,
} from "./location.slice";
import {toCamelcase} from "utils/Formatters";

export const loadLocations = createAsyncThunk('locations/loadLocations', async (values, {extra, dispatch}) => {
  try {
    dispatch((gettingLocations()));

    const response = await extra.get(`/locations/${values.dealerToken}`);
    const locations = response.map(location => toCamelcase(location));

    dispatch(loadedLocations({
      locations
    }));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: error.message}));
  }
});

export const loadRooftopsForFilter = createAsyncThunk('locations/loadRooftopsForFilter', async (values, {extra, dispatch}) => {
  try {
    dispatch((gettingRooftops()));

    const response = await extra.get(`/locations/${values.dealerToken}`);
    const rooftops = response.map(location => toCamelcase(location));

    dispatch(loadedRooftops({
      rooftopsForFilter: rooftops
    }));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: error.message}));
  }
});

export const updateLocation = createAsyncThunk('locations/updateLocation', async (values, {extra, dispatch}) => {
  try {
    dispatch((updatingLocation()));

    await extra.post('/locations/update', {
      ...values
    });

    dispatch(updatedLocation({}));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: error.message}));
  }
});

export const addLocation = createAsyncThunk('locations/addLocation', async (values, {extra, dispatch}) => {
  try {
    dispatch((addingLocation()));

    await extra.post('/locations/add', {
      ...values
    });

    dispatch(addedLocation({}));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: error.message}));
  }
});

export const loadDetailedLocations = createAsyncThunk('locations/loadDetailedLocations', async (values, {extra, dispatch}) => {
  try {
    dispatch((gettingDetailedLocations()));

    const response = await extra.get(`/locations/all/detailed/${values.dealerId}`);
    const detailedLocations = response.map(location => {
      const camel = toCamelcase(location);

      return {
        dealerRooftopId: camel.dealerRooftopId,
        rooftopName: camel.rooftopName,
        country: camel.country,
        phone: camel.phone,
        address: {
          address1: camel.address1,
          address2: camel.address2,
          city: camel.city,
          state: camel.state,
          postalCode: camel.postalCode
        }
      }
    });

    dispatch(loadedDetailedLocations({
      detailedLocations
    }));
  }
  catch(error) {
    console.log(error.message);
    dispatch(errorFound({error: error.message}));
  }
});

export const loadRooftopsForUser = createAsyncThunk('locations/loadRooftopsForUser', async (values, {extra, dispatch}) => {
  try {
    dispatch((loadingRooftopsForUser()));

    const response = await extra.get(`/locations/user/${values.userId}`);
    const rooftopsForUser = response.map((rooftop) => toCamelcase(rooftop));

    dispatch(loadedRooftopsForUser({rooftopsForUser}));
  }
  catch (error) {
    console.log(error.message);
    dispatch(errorFound({error: error.message}));
  }
});
