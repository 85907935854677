import React from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import Grid from '@mui/material/Grid';

const LengthFilter = ({ criteria }) => {
  return (
    <TabPanel value='length'>
      <Grid container>
        <Grid xs={12}>
          <RangeSlider
            minValue={0}
            maxValue={100}
            defaultValue={[
              criteria.defaultMinLength,
              criteria.defaultMaxLength,
            ]}
            labelText={''}
            handleChange={(value) => {
              const [min, max] = value;
              criteria.setPage(1);
              localStorage.setItem('minLength', min);
              localStorage.setItem('maxLength', max);
              criteria.handleMinLength(min);
              criteria.handleMaxLength(max);
              criteria.setDefaultMinLength(null);
              criteria.setDefaultMaxLength(null);
            }}
            disabled={true}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default LengthFilter;