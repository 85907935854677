import React from "react";
import Grid from "@mui/material/Grid";
import AuctionView from "components/AuctionResultPanel";

const AuctionsView = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <AuctionView />
            </Grid>
        </Grid>
    );
}

export default AuctionsView;