import React from "react";
import styled from "@emotion/styled";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import VehicleDetailPanel from "components/vehicleDetailPanel";

const PREFIX = 'VehicleDetailsDialog';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const StyledDialog = styled(Dialog)((
    {
      theme
    }
) => {
  return {
    [`& .${classes.closeButton}`]: {
      float: 'right',
      cursor: 'pointer'
    },
  }
});

const VehicleDetailsDialog = ({
    isOpen,
    vehicleToken,
    handleClose
}) => {

  return (
    <StyledDialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { maxWidth: "100vw" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box onClick={() => handleClose()} className={classes.closeButton}>
          <Typography variant="h4">X</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <VehicleDetailPanel id={vehicleToken} />
        </DialogContentText>
      </DialogContent>
    </StyledDialog>
  );
}

export default VehicleDetailsDialog;