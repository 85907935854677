import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const ContentDialog = (
  {
    id,
    keepMounted,
    open,
    title,
    children,
    buttonsPanel,
    ...other
  }) => {
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 700, overflowY: 'hidden' } }}
      maxWidth="md"
      open={open}
      {...other}
    >
      <DialogTitle>
        <Typography variant="h1">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        {buttonsPanel}
      </DialogActions>
    </Dialog>
  );
}

export default ContentDialog;
