import { Formik } from 'formik';
import * as yup from "yup";
import classNames from 'classnames';
import AddressView from '../AddressView'
import Button from '../widgets/Button'
import FormControl from '../widgets/FormControl'
import Checkbox from "../widgets/Checkbox";
import React from "react";

const validationSchema = yup.object().shape({
  verticals: yup.array().length(1, 'You must select one type.'),
  contact_first_name: yup
    .string()
    .required('Please enter your first name.')
    .min(3, 'First Name must be at least 3 characters'),
  contact_last_name: yup
    .string()
    .required('Please enter your last name.')
    .min(2, 'Last Name must be at least 2 characters'),
  contact_email: yup
    .string()
    .required('Please enter your email address.')
    .email('Please enter a valid email address'),
  contact_phone: yup
    .string()
    .required('Please enter your cell phone.')
    .min(10, 'Please enter only phone digits without formatting.'),
  dealership_name: yup
    .string()
    .required('Please enter your dealership name.')
    .min(3, 'Dealership name must be at least 3 characters.'),
  dealership_locations: yup
    .number('Please enter a valid number.')
    .required('Please enter the number of locations (rooftops).')
    .min(1, 'Please enter a number greater than zero(0).'),
  dealership_site_type: yup
    .string()
    .required('Please select a Dealership Management System.'),
  dealership_site_other: yup
    .string()
    .when('dealership_site_type', ([dealership_site_other]) => {
    return  dealership_site_other === "other" ? yup.string().required('Please enter Dealership Management System Name.') : yup.string() 
    }),
  dealer_address: yup
    .string()
    .required('Please enter Dealership Address.'),
  dealer_city: yup
    .string()
    .required('Please enter Dealership City.'),
  dealer_state: yup
    .string()
    .required('Please enter Dealership State.')
    .max(2, 'Please enter 2 character abbreviation for the state.'),
  dealer_zip: yup
    .number()
    .required('Please enter Dealership Zip Code.'),
  website: yup
    .string()
    .required('Please enter Dealership Website.'),
  referral_source: yup
    .string()
    .required('Please select how you were referred to our site.'),
  dealer_license: yup
    .string()
    .required('Please enter license number.'),
});

const initialValues = {
  verticals: [],
  contact_first_name: '',
  contact_last_name: '',
  contact_email: '',
  contact_phone: '',
  dealership_name: '',
  dealership_locations: '',
  dealership_site_type: undefined,
  dealership_site_other: undefined,
  dealer_address: '',
  dealer_address2: '',
  dealer_city: '',
  dealer_state: undefined,
  dealer_zip: '',
  website: '',
  referral_source: undefined,
  dealer_license: ''
}

export default function SingupForm({ values, referralSources, dataProviders, handleSubmit }) {

  const currentValues = {
    ...initialValues,
    ...values
  }

  return (
    <div className="container mx-auto">
      <Formik
        initialValues={currentValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}>

        {({ handleSubmit, handleChange, touched, errors, setFieldValue, values }) => {
          return (
            <div className='grid grid-cols-12'>
              <div className='col-span-12'>
                <span className='float-left pt-3 mr-3'>Type of Dealership?</span>

                <div>
                  <div className='grid grid-cols-12'>
                    <div className='col-span-12'>
                      <Checkbox
                        name='verticals'
                        text='Auto'
                        domainValue='is_auto'
                        checked={values && values.verticals.includes('is_auto')}
                        handleChange={(checked, domainValue) => {
                          setFieldValue('verticals',
                              checked ?
                                  [...values.verticals, domainValue] :
                                  values.verticals.filter(item => item !== domainValue))
                        }}
                        className="ml-2 form-checkbox rounded"
                      />

                      <Checkbox
                        name='verticals'
                        domainValue='is_rv'
                        text='RV'
                        checked={values && values.verticals.includes('is_rv')}
                        handleChange={(checked, domainValue) => {
                          setFieldValue('verticals',
                              checked ?
                                  [...values.verticals, domainValue] :
                                  values.verticals.filter(item => item !== domainValue))
                        }}
                      />

                      <Checkbox
                        name='verticals'
                        domainValue='is_powersports'
                        text='Powersports'
                        checked={values && values.verticals.includes('is_powersports')}
                        handleChange={(checked, domainValue) => {
                          setFieldValue('verticals',
                            checked ?
                              [...values.verticals, domainValue] :
                              values.verticals.filter(item => item !== domainValue))
                        }}
                      />
                    </div>

                    <div className='col-span-12'>
                      {errors && errors.verticals &&
                        <div
                          className='w-screen pl-2 pt-3 text-danger'
                          id={`verticals-feedback`}
                        >
                          {errors.verticals}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className='border-primary border-2 pl-8 pt-5 pb-5 col-span-12 mt-8'>
                <span className='text-xl'>Contact Information</span>

                <FormControl
                  label='First Name'
                  type='text'
                  name='contact_first_name'
                />

                <FormControl
                  label='Last Name'
                  type='text'
                  name='contact_last_name'
                />

                <FormControl
                  label='Email'
                  type='text'
                  name='contact_email'
                />

                <FormControl
                  label='Cell Phone'
                  type='text'
                  name='contact_phone'
                />
              </div>

              <div className='border-primary border-2 pl-8 pt-5 pb-5 col-span-12 mt-8'>
                <span className='text-xl'>Dealer Information</span>

                <FormControl
                  label='Dealership Name'
                  type='text'
                  name='dealership_name'
                />

                <FormControl
                  label='Number of Locations'
                  type='text'
                  name='dealership_locations'
                />

                <FormControl
                  label='Inventory Data Provider (How does your DMS feed to your website?)'
                  type='select'
                  items={dataProviders}
                  name='dealership_site_type'
                />

                <FormControl
                  className={classNames({ hidden: values.dealership_site_type !== 'other' })}
                  label='Other Data Provider Name'
                  type='text'
                  name='dealership_site_other'
                />

                <AddressView
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                  touched={touched}
                />

                <FormControl
                  label='Website'
                  type='text'
                  name='website'
                />

                <FormControl
                  label='License Number'
                  type='text'
                  name='dealer_license'
                />
              </div>

              <div className='border-primary border-2 pl-8 pt-5 pb-5 col-span-12 mt-8'>
                <span className='text-xl'>Referral</span>

                <FormControl
                  label='Referral Source'
                  type='select'
                  name='referral_source'
                  items={referralSources}
                />
              </div>

              <div className='mt-3 col-span-12'>
                <div className='col-span-2'>
                  <Button type='submit' className='width-full border-2 border-primary' onClick={handleSubmit}>Next</Button>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}