import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';

const PREFIX = 'FilterStyle'

const classes = {
    styledBtn: `${PREFIX}-styledBtn`,
    btnColor: `${PREFIX}-btnColor`,
    morebtn: `${PREFIX}-morebtn`,
    weightSel: `${PREFIX}-weightSel`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.styledBtn}`]: {
            textTransform: 'capitalize',
            color: theme.palette.border.default,
            background: theme.palette.background.btn
        },
        [`& .${classes.btnColor}`]: {
            textTransform: 'capitalize',
            background: theme.palette.primary.main,
            color: theme.palette.background.default
        },
        [`& .${classes.morebtn}`]: {
            textTransform: 'capitalize',
            color: theme.palette.secondary.main,
            background: theme.palette.background.btn,
            position: 'absolute',
            right: '125px !important'
        },
        [`& .${classes.weightSel}`]: {
            width: '98%',
            height: 40
        },
    }
});

const StyledFilterGrid = ({children}) => {

	return (
		<StyledGrid>
			{children}
		</StyledGrid>
	);
}

export default StyledFilterGrid;