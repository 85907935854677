import React from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import { useSelector } from 'react-redux';

const AgeFilter = ({ criteria }) => {
  const {
    search: { vehicleOption },
  } = useSelector((state) => state);

  return (
    <TabPanel value='age'>
      <RangeSlider
        minValue={vehicleOption.age?.[vehicleOption.age?.length - 1]?.age}
        maxValue={vehicleOption.age?.[0]?.age}
        defaultValue={[criteria.defaultMinAge, criteria.defaultMaxAge]}
        labelText={''}
        handleChange={(value) => {
          const [min, max] = value;
          criteria.setPage(1);
          localStorage.setItem('minAge', min);
          localStorage.setItem('maxAge', max);
          criteria.handleMinAge(min);
          criteria.handleMaxAge(max);
          criteria.setDefaultMinAge(null);
          criteria.setDefaultMaxAge(null);
        }}
        disabled={true}
      />
    </TabPanel>
  );
};

export default AgeFilter;