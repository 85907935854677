import React from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import YouTubeViewer from "components/widgets/YouTubeViewer";
import PhotoViewer from "components/PhotoViewer";

const PREFIX = 'ConditionReportMediaPanel';

const classes = {
  photoViewer: `${PREFIX}-photoViewer`,
  conditionImage: `${PREFIX}-conditionImage`,
  video: `${PREFIX}-video`,
  mediaHeader: `${PREFIX}-mediaHeader`,
  viewLink: `${PREFIX}-viewLink`,
}

const StyledGrid = styled(Grid)(({
 theme
}) => {
  return {
    marginTop: 30,
    marginBottom: 30,
    [`& .${classes.conditionImage}`]: {
      width: 300,
      float: 'left',
      marginRight: 10
    },
    [`& .${classes.photoViewer}`]: {
      maxWidth: 300,
      maxHeight: 500,
      [`& img`]: {
        maxWidth: 200,
        maxHeight: 300,
      }
    },
    [`& .${classes.video}`]: {
      marginLeft: 30,
      float: 'left',
    },
    [`& .${classes.mediaHeader}`]: {
      marginBottom: 10
    },
    [`& .${classes.viewLink}`]: {
      marginTop: 10,
    },
  }
});

const ConditionReportMediaPanel = ({report, photos}) => {
  return (
    <StyledGrid container>
      <Grid item xs={12}>
        <Typography className={classes.mediaHeader} variant='h4'>Photos/Video</Typography>
        {photos && (
            <div className={classes.conditionImage}>
              <PhotoViewer
                  className={classes.photoViewer}
                  photos={photos?.map(photo => photo.link)}
              />
            </div>
        )}
        {report?.videoCode && (
            <YouTubeViewer
                height='120px'
                width='200px'
                className={classes.video}
                videoId={report.videoCode}
            />
        )}
      </Grid>
    </StyledGrid>
  );
}

export default ConditionReportMediaPanel