import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik } from 'formik';
import * as yup from "yup";
import { loadLocations, loadRooftopsForUser } from "data/location.actions";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "components/widgets/Button";
import UserDataFormPanel from "./UserDataForm";
import UserAccessForm from "./UserAccessForm";
import GeneralAccessForm from "./GeneralAccessForm"
import { hasRooftopPermission } from 'utils/User';
import { doLoadUser } from "data/authentication.actions";

const PREFIX = 'UserManagementView';

const classes = {
  panel: `${PREFIX}-panel`,
  tabContainer: `${PREFIX}-tabContainer`,
  checkboxContainer: `${PREFIX}-checkboxContainer`,
  button: `${PREFIX}-button`,
  formButton: `${PREFIX}-formButton`,
  fieldContainer: `${PREFIX}-fieldContainer`,
  locationAccessPanel: `${PREFIX}-locationAccessPanel`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  formButtonContainer: `${PREFIX}-formButtonContainer`,
  inlineCheckbox: `${PREFIX}-inlineCheckbox`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
)=> {
  return {
    [`& .${classes.panel}`]: {
      marginTop: 30,
      padding: 30,
      borderRadius: 20,
      background: theme.palette.secondary.grey
    },
    [`& .${classes.button}`]: {
      float: 'right',
    },
    [`& .${classes.tabContainer}`]: {
      float: 'right',
    },
    [`& .${classes.fieldContainer}`]: {
      marginTop: 10,
      paddingRight: 20,
    },
    [`& .${classes.locationAccessPanel}`]: {
      marginTop: 10,
      padding: 30,
      borderRadius: 20,
      background: theme.palette.secondary.grey
    },
    [`& .${classes.formButtonContainer}`]: {
      marginTop: 20,
      paddingTop: 10,
      borderTop: 'solid black 1px',
      marginRight: 30,
    },
    [`& .${classes.checkboxContainer}`]: {
      marginTop: 40,
    },
    [`& .${classes.buttonContainer}`]: {
      marginTop: 20,
    },
    [`& .${classes.formButton}`]: {
      marginRight: 10,
      float: 'right',
    },
    [`& .${classes.inlineCheckbox}`]: {
      marginRight: 10,
      float: 'left',
    },
  }});

const initialValues = {
  enabled: true,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  locationAccess: [{
    locationRoles: []
  }],
  generalAccess: {
    generalAccess: []
  },
  error: undefined
}

const validateOneHasDefault = (val) => {
  // Check for the default set on an object
  const filteredArray = val.filter((e) => e.defaultMark);

  // Only 1 object should have a default
  if (filteredArray.length === 1) return true;

  return false;
}

const validateRooftopsUnique = (val) => {
  const dealerRooftopIdArray = val.map(a => a.dealerRooftopId);
  const uniqueDealerRooftopIdArray = [...new Set(dealerRooftopIdArray)]
  return dealerRooftopIdArray.length === uniqueDealerRooftopIdArray.length
}

const validateRooftop = (val) =>{
  // Check to see if defaultMark was checked without a defaultRooftop selected
  const dealerRooftopIdArray = val.filter((a) => !a.dealerRooftopId && a.defaultMark);
   if (dealerRooftopIdArray.length < 1 ) return true;
   return false;
}

const validationSchema = yup.object().shape({
  enabled: yup.boolean(),
  firstName: yup
      .string()
      .required('Please enter first name.'),
  lastName: yup
      .string()
      .required('Please enter last name.'),
  email: yup
      .string()
      .required('Please enter email address.'),
  phone: yup
      .string()
      .required('Please enter phone number.'),
  locationAccess: yup
      .array()
      .of(yup.object({
        dealerRooftopId: yup.number(),
        defaultMark: yup.boolean(),
        locationRoles: yup.array().of(yup.object({
          roleId: yup.number()
        }))
      }))
      .test('defaultMarkSet', '1 default rooftop should be set (user may already have a default rooftop selected on a rooftop you cannot edit)', validateOneHasDefault)
      .test('defaultRooftopId', 'Please set the roles of each rooftop only once.', validateRooftopsUnique)
      .test('defaultRooftopId', 'Please select a rooftop', validateRooftop)
});
   
const UserForm = ({dealerId, dealerToken, userToEdit, handleUpdateUser, error}) => {
  const initialValues = {
    enabled: true,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    generalAccess: {
      dealerId: dealerId,
      generalAccess: []
    },
    locationAccess: [{
      locationRoles: []
    }],
    error: undefined
  }
  const [startingValues, setStartingValues] = useState(initialValues);
  const [locationSelections, setLocationSelections] = useState([]);
  const [removedAccess, setRemovedAccess] = useState([]);
  const [tabIndex, setTabIndex] = useState('1');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {locations} = useSelector(state => state.location);
  const loggedInUser = useSelector(state => state.authentication.user);
  let locationItems
  let permittedLocationItems
  let permittedDetailedRooftops

  if (loggedInUser && locations) {
      if(loggedInUser.isDealerAdmin){
          permittedDetailedRooftops = locations;
      } else {
        permittedDetailedRooftops = locations.filter((location) => hasRooftopPermission(loggedInUser, location.dealerRooftopId, 'edit_users'));
      }

    locationItems = locations.map(location => {
      return {
        value: location.dealerRooftopId,
        label: location.rooftopName
      }
    });

    permittedLocationItems = permittedDetailedRooftops.map(location => {
      return {
        value: location.dealerRooftopId,
        label: location.rooftopName
      }
    });
  }

  useEffect(() => {
    if (userToEdit) {
      setStartingValues({...userToEdit});
    }
    else {
      setStartingValues({...initialValues});
    }
  }, [userToEdit])

  useEffect(() => {
    if (dealerToken) {
      dispatch(loadLocations({dealerToken: dealerToken}));
    }
  }, [dealerToken, dispatch]);

  useEffect(() =>  {
    if (locations) {
      const locationItems = locations.map(location => {
        return {
          value: location.dealerRooftopId,
          label: location.rooftopName
        }
      });

      setLocationSelections( locationItems );
    }
  }, [locations]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleAccessRemoved = (access) => {
    setRemovedAccess([...removedAccess, access]);
  };

  const nextTab = () => {
    if(loggedInUser.isDealerAdmin){
      let pageToInt = parseInt(tabIndex)
      pageToInt += 1
      let pageBackToString = pageToInt.toString()
      setTabIndex(pageBackToString)
    } else {
      setTabIndex('3')
    }
  }

  const previousTab = () => {
    if(loggedInUser.isDealerAdmin){
      let pageToInt = parseInt(tabIndex)
      pageToInt -= 1
      let pageBackToString = pageToInt.toString()
      setTabIndex(pageBackToString)
    } else {
      setTabIndex('1')
    }
  }

  const updateLoggedInUserRooftopsAndPermissions = () => {
    dispatch(doLoadUser(loggedInUser.uid));
    dispatch(loadRooftopsForUser({
      userId: loggedInUser.id
    }));
  }

  return (
    <StyledGrid container>
      <Grid item xs={12}>
        <Formik
            initialValues={startingValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={
              async (values, actions) => {
                await handleUpdateUser({
                  ...values,
                  userId: userToEdit?.userId,
                  dealerId,
                  removedAccess
                });
                actions.setSubmitting(false);
                if(parseInt(userToEdit?.userId) === parseInt(loggedInUser.id)){
                  updateLoggedInUserRooftopsAndPermissions();
                }
              }
          }
        >
          {({handleSubmit, handleChange, touched, errors, setFieldValue, values}) => {
            const handleDefaultChanged = (newDealerId) => {
              // meta.setValue
              setFieldValue('defaultRooftop', `${newDealerId}`);
            }

            return (
                <Grid container>
                  <Grid item xs={12}>
                    <TabContext value={tabIndex}>
                      <Box classes={classes.tabContainer}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                          <Tab label="Personal Details" value="1" />
                          { loggedInUser?.isDealerAdmin && 
                            <Tab label="General Roles" value="2" />
                          }
                          <Tab label="Rooftop Roles" value="3" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <UserDataFormPanel
                            classes={classes}
                            locationSelections={locationSelections}
                            email={userToEdit && userToEdit.email}
                        />
                      </TabPanel>
                      <TabPanel value="2">
                        <GeneralAccessForm
                            classes={classes}
                            name='generalAccess'
                        />
                      </TabPanel>
                      <TabPanel value="3">
                        <UserAccessForm
                            loggedInUser={loggedInUser}
                            classes={classes}
                            name='locationAccess'
                            locationSelections={locationItems}
                            permittedLocationSelections={permittedLocationItems}
                            handleDefaultChanged={handleDefaultChanged}
                            handleAccessRemoved={handleAccessRemoved}
                        />
                      </TabPanel>
                    </TabContext>
                  </Grid>

                  <Grid item xs={12} className={classes.formButtonContainer}>
                    { tabIndex !== '3' &&
                      <Button className={classes.button} onClick={() => nextTab()}>Next</Button>
                    }
                    <Button className={classes.formButton} onClick={handleSubmit}>Save</Button>
                    <Button className={classes.formButton} onClick={() => navigate('/users?reload=true')}>Cancel</Button>
                    { tabIndex !== '1' &&
                    <Button className={classes.formButton} onClick={() => previousTab()}>Previous</Button>
                    }
                  </Grid>
                </Grid>
            )
          }}
        </Formik>
      </Grid>
    </StyledGrid>
  )
}

export default UserForm;