import { createContext, useContext, useState, useEffect } from "react";
import { useStateWithLocalStorage } from '../utils/useStateWithLocalStorage';
import Cookie from 'js-cookie'

export const authContext = createContext();

export const useAuth = () => {
    return useContext(authContext);
}

export const useProvideAuth = () => {
    const [user, setUser] = useState();
    const [userData, setUserData] = useStateWithLocalStorage('user');
    const [token, setToken] = useStateWithLocalStorage('token');

    useEffect(() => {
        if( userData ) {
            setUser( JSON.parse(userData) );
        }
    }, [userData]);

    const loggedIn = (user, token) => {
        setUserData(user ? JSON.stringify(user) : user);
        setToken(token);
    };

    const signout = () => {
        setUserData(undefined);
        setToken( undefined);
        Cookie.remove('uid');
        Cookie.remove('token');
    };

    return {
        user,
        token,
        loggedIn,
        signout
    };
}