import React from 'react';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Select from 'components/widgets/Select';
import theme from 'styles/theme';

const weight = [
  { label: 'Any', value: 'ANY' },
  { label: '2000 lbs', value: '2000' },
  { label: '3000 lbs', value: '3000' },
  { label: '4000 lbs', value: '4000' },
  { label: '5000 lbs', value: '5000' },
  { label: '6000 lbs', value: '6000' },
  { label: '7000 lbs', value: '7000' },
  { label: '8000 lbs', value: '8000' },
  { label: '9000 lbs', value: '9000' },
  { label: '10000 lbs', value: '10000' },
];

const GrossWeightFilter = ({ criteria }) => {
  return (
    <TabPanel value='grossweight'>
      <Grid container>
        <Grid xs={6}>
          <Typography variant='h6' color={theme.palette.border.default}>
            Min Weight
          </Typography>
          <Select
            items={weight}
            value={criteria.minWeight}
            onChange={(e) => {
              criteria.setPage(1);
              localStorage.setItem('minWeight', e.target.value);
              criteria.handleMinWeight(e.target.value);
            }}
          />
        </Grid>
        <Grid xs={6}>
          <Typography variant='h6' color={theme.palette.border.default}>
            Max Weight
          </Typography>
          <Select
            items={weight}
            value={criteria.maxWeight}
            onChange={(e) => {
              criteria.setPage(1);
              localStorage.setItem('maxWeight', e.target.value);
              criteria.handleMaxWeight(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default GrossWeightFilter;