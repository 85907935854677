import React from "react";
import styled from "@emotion/styled";

const PREFIX = 'CountDown';

const classes = {
  bouncingLoader: `${PREFIX}-bouncingLoader`,
};

const StyledDiv = styled("div")((
    {
      theme
    }
) => {
  return {
    [`@keyframes ${classes.bouncingLoader}`]: {
      to: {
        opacity: 0.1,
        transform: 'translateY(-16px)',
      }
    },
    [`& .${classes.bouncingLoader}`]: {
      display: 'flex',
      justifyContent: 'center',
    },
    [`& .${classes.bouncingLoader} > div`]: {
      width: 10,
      height: 10,
      margin: '3px 6px',
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      opacity: 1,
      animation: `${classes.bouncingLoader} 0.6s infinite alternate`
    },
    [`& .${classes.bouncingLoader} > div:nth-child(2)`]: {
      animationDelay: '0.2s'
    },
    [`& .${classes.bouncingLoader} > div:nth-child(3)`]: {
      animationDelay: '0.4s'
    }
  }
});
const BouncingDotsLoader = (props) => {
  return (
      <StyledDiv>
        <div className={classes.bouncingLoader}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </StyledDiv>
  );
};

export default BouncingDotsLoader;