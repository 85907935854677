import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "components/widgets/Button";
import Spinner from "components/Spinner";
import DataTable from "components/widgets/DataTable";
import { loadUsers, loadUserForEdit, updateUser, createUser, cleaningUserData } from "data/user.actions";
import { useDispatch, useSelector } from "react-redux";
import UserForm from "./components/UserForm";
import { hasAPermission, hasEditUsersPermissionForOneOfUsersRooftops } from 'utils/User';

const PREFIX = 'UserManagementView';

const classes = {
    panel: `${PREFIX}-panel`,
    button: `${PREFIX}-button`,
    tableContainer: `${PREFIX}-table`,
    link: `${PREFIX}-link`,
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.panel}`]: {
            marginTop: 30,
            padding: 30,
            borderRadius: 20,
            background: theme.palette.secondary.grey
        },
        [`& .${classes.button}`]: {
            float: 'right',
        },
        [`& .${classes.tableContainer}`]: {
            marginTop: 30,
        },
        [`& .${classes.link}`]: {
            marginTop: 10,
            textAlign: 'top',
        },
    }
});

const tableColumns = [
    {
        name: 'userId',
        options: {
            display: 'excluded',
            filter: false,
        }
    },
    {
        name: 'phone',
        options: {
            display: 'excluded',
            filter: false,
        }
    },
    {
        name: 'locationAccess',
        options: {
            display: 'excluded',
            filter: false,
        }
    },
    {
        label: 'Name / Phone',
        name: 'userName',
        options: {
            customBodyRender: (value, tableMeta) => {
                const phoneNumber = tableMeta.rowData[1];
                return (
                    <div className={classes.link}>
                        <Typography variant='body1'>{value} </Typography>
                        <Typography variant='body1' color='primary.main'>{phoneNumber}</Typography>
                    </div>
                )
            }
        }
    },
    {
        label: 'Rooftop Access',
        name: 'rooftopName',
        options: {
            customBodyRender: (value, tableMeta) => {
                // Using the locationAccess array to display rows
                return tableMeta.rowData[2] && tableMeta.rowData[2].map((access, index) => {
                    const { roles, rooftopName } = access;
                    return (
                        <div key={`access-${rooftopName}`} style={index > 0 ? { marginTop: 10 } : {}}>
                            <Typography variant='body1'>{rooftopName} </Typography>
                            <Typography variant='body1' color='error'>
                                {
                                    roles.map((role, index) => `${role.roleName}${index < roles.length - 1 ? ', ' : ''}`)
                                }
                            </Typography>
                        </div>
                    );
                });
            }
        }
    },
    {
        label: 'Active Date',
        name: 'createdDate',
        customBodyRender: (value) => {
            console.log(value)
            return (
                <Typography variant='body1'>
                    {value}
                </Typography>
            );
        }
    },
    {
        label: 'Disabled Date',
        name: 'disabledDate',
        customBodyRender: (value) => {
            return (
                <Typography variant='body1'>
                    {value}
                </Typography>
            );
        }
    }
];

const UserManagementView = () => {
    const { action, userId } = useParams();
    const { reload } = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { processing, users, userToEdit, error } = useSelector(state => state.user);
    const { user } = useSelector(state => state.authentication);
    const { processing: processingLocations, error: locationError } = useSelector(state => state.location);
    let filteredUsers

    if(user && user.isDealerAdmin){
        filteredUsers = users
    } else {
        filteredUsers = users?.filter((userToEdit) => hasEditUsersPermissionForOneOfUsersRooftops(user, userToEdit));
    }

    useEffect(() => {
        if (user && !userId && !userToEdit && users === undefined) {
            dispatch(loadUsers({
                dealerId: user.dealer_id,
                uid: user.uid,
                sort: 'created_date',
                rows: 20,
                offset: 0
            }))
        }
    }, [reload, dispatch, user, userId, userToEdit, users])

    useEffect(() => {
        if (userId) {
            dispatch(loadUserForEdit({ userId }));
        }
    }, [userId, dispatch])

    const handleUpdateUser = async (values) => {
        const newValues = {
            first_name: values.firstName,
            last_name: values.lastName,
            dealerId: values.dealerId,
            email: values.email,
            phone: values.phone,
            userId: values.userId,
            default_rooftop: values.locationAccess.find(access => access.defaultMark === true).dealerRooftopId,
            roofTop_access: values.locationAccess.map(access => {
                return {
                    user_rooftop_id: access.userRooftopAccessId,
                    roof_top: access.dealerRooftopId,
                    default_mark: access.defaultMark,
                    role: access.locationRoles.map(role => role.roleId)
                }
            }),
            general_access: values.generalAccess.generalAccess.map(access => {
                return {
                    dealer_id: access.dealerId,
                    role_id: access.roleId
                }
            })
        };

        if (values.enabled) {
            newValues.disabled_date = null;
        }
        else {
            if (values.disabledDate === null) {
                newValues.disabled_date = new Date().toISOString();
            }
        }

        await dispatch(updateUser(newValues));

        navigate('/users');
    }

    const handleAddUser = (values) => {
        dispatch(createUser({
            first_name: values.firstName,
            last_name: values.lastName,
            dealerId: values.dealerId,
            email: values.email,
            phone: values.phone,
            default_rooftop: values.locationAccess.find(access => access.defaultMark === true).dealerRooftopId,
            roofTop_access: values.locationAccess.map(access => {
                return {
                    user_rooftop_id: access.userRooftopAccessId,
                    roof_top: access.dealerRooftopId,
                    default_mark: access.defaultMark,
                    role: access.locationRoles.map(role => role.roleId)
                }
            }),
            general_access: values.generalAccess.generalAccess.map(access => {
                return {
                    dealer_id: access.dealerId,
                    role_id: access.roleId
                }
            })
        }));

        navigate('/users');
    }

    const addUser = () => {
        cleaningUserData(dispatch);
        navigate('/users/add');
    }

    return (
        <StyledGrid>

            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        {action === 'edit' &&
                            <Typography variant='h3'>Edit User</Typography>
                        }
                        {action === 'add' &&
                            <Typography variant='h3'>Add User</Typography>
                        }
                        {action === undefined &&
                            <Typography variant='h3'>User Management</Typography>
                        }
                    </Grid>

                    {userId === undefined &&
                        <Grid item xs={6}>
                            {((user && user?.isDealerAdmin) || (user && hasAPermission(user, 'add_user'))) &&
                                <Button className={classes.button} onClick={() => addUser()}>Add New User</Button>
                            }
                        </Grid>
                    }
                </Grid>
            </Grid>

            {userId === undefined && action !== 'add' &&
                <Grid item xs={12} className={classes.tableContainer}>
                    <DataTable
                        data={filteredUsers}
                        columns={tableColumns}
                        options={{
                            selectableRows: 'none',
                            selectableRowsHideCheckboxes: true,
                            selectableRowsHeader: false,
                            responsive: "standard",
                            onRowClick: (rowData) => {
                                const id = rowData[0];
                                navigate(`/users/edit/${id}`);
                            },
                        }}
                    />
                </Grid>
            }

            {userId !== undefined &&
                <Grid item xs={12} className={classes.tableContainer}>
                    <UserForm
                        dealerId={user?.dealer_id}
                        dealerToken={user?.dealer_token}
                        userToEdit={userToEdit}
                        handleUpdateUser={handleUpdateUser}
                    />
                </Grid>
            }

            {action === 'add' &&
                <Grid item xs={12} className={classes.tableContainer}>
                    <UserForm
                        loggedInUser={user}
                        dealerId={user?.dealer_id}
                        dealerToken={user?.dealer_token}
                        handleUpdateUser={handleAddUser}
                    />
                </Grid>
            }

            <Typography variant='h6' color='error'>{error || locationError}</Typography>
            <Spinner show={processing || processingLocations} />

        </StyledGrid>

    )
}

export default UserManagementView;