import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import Header from "./components/Header";
import ScrollToTop from "../../ScrollToTop";
import {getActiveAuctionCount, myAuctions} from "data/auction.actions";
import {getNeedAttentionTransactionCount} from "data/transaction.actions";
import useInterval from "../../hooks/useInterval";

const PREFIX = 'MainLayout';

const classes = {
  body: `${PREFIX}-body`,
  headerContainer: `${PREFIX}-headerContainer`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
) => {
  return {
    [`& .${classes.headerContainer}`]: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 1,
      height: 100
    },
    [`& .${classes.body}`]: {
      [theme.breakpoints.up('xs')]: {
        marginTop: 350,
        padding: '0 10px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 250,
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 140,
      },
    },
  }
});

const MainLayout = ({ header, children }) => {
  const dispatch = useDispatch();
  const {
    isLoadingAuctionTransactionCount,
    isLoadingActiveAuctionCount
  } = useSelector(state => state.auction);

  const loadData = () => {
    if (!isLoadingAuctionTransactionCount) {
      dispatch(getNeedAttentionTransactionCount());
    }

    if (!isLoadingActiveAuctionCount) {
      dispatch(getActiveAuctionCount());
    }
  }

  useInterval(() => {
    loadData();
  }, 30 * 1000);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <StyledGrid container>
      <Grid item xs={12} className={classes.headerContainer}>
        {header ? header : <Header />}
      </Grid>

      <Grid item xs={12} className={classes.body}>
        <ScrollToTop />
        {children}
      </Grid>
    </StyledGrid>
  )
}

export default MainLayout;