import React from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useField } from "formik";
import styled from "@emotion/styled";
import { removeConditionPhoto } from 'data/vehicle.actions';

const PREFIX = 'UplodadFiles';

const classes = {
  dropzone: `${PREFIX}-dropzone`,
  fileIcon: `${PREFIX}-fileIcon`,
  flex: `${PREFIX}-flex`,
  img: `${PREFIX}-img`,
  fileName: `${PREFIX}-fileName`,
  fileType: `${PREFIX}-fileType`,
  remove: `${PREFIX}-remove`
};

const StyledContainer = styled('div')((
  {
    theme
  }
) => {
  return {
    [`& .${classes.dropzone}`]: {
      height: 138,
      border: `1px solid ${theme.palette.text.primary}`,
      borderRadius: 8,
      textAlign: 'center'
    },
    [`& .${classes.fileIcon}`]: {
      fontSize: 60,
      color: '#9CA3B1',
      marginTop: 20
    },
    [`& .${classes.flex}`]: {
      display: 'flex',
      marginTop: 5,
      position: 'relative'
    },
    [`& .${classes.img}`]: {
      width: 50,
      height: 50
    },
    [`& .${classes.fileName}`]: {
      marginTop: 15,
      marginLeft: 5
    },
    [`& .${classes.fileType}`]: {
      marginTop: 15,
      marginLeft: 15
    },
    [`& .${classes.remove}`]: {
      position: 'absolute',
      right: 15,
      top: 15,
      color: 'red',
      cursor: 'pointer'
    },
  }
});

const UploadFiles = ({ ...props }) => {
  const dispatch = useDispatch();
  const [field, meta, utils] = useField(props);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      const files = field.value || [];
      const filteredFiles = files ? acceptedFiles.filter(file => {
        return !files.find(included => included.name === file.name)
      }) : acceptedFiles;
      const newFiles = files ? [...files, ...filteredFiles] : filteredFiles;
      utils.setValue(newFiles);
    },
  });

  const removeFile = (name) => {
    if (name?.includes('/')) {
      const strArr = name?.split('/');
      let data = {
        inventoryToken: strArr[0],
        conditionTypeId: strArr[1],
        fileName: strArr[2]
      }
      dispatch(removeConditionPhoto(data));
    } else {
      const files = field.value || [];
      const index = files?.findIndex(e => e.name === name);
      files.splice(index, 1);
      utils.setValue(files);
    }
  }

  return (
    <StyledContainer>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <>
          <FileUploadOutlinedIcon className={classes.fileIcon} />
          <br />
          <p>Attach files by dropping them here</p>
        </>
      </div>
      {field?.value && field?.value?.length > 0 &&
        field?.value.map(file =>
          file?.link ?
            <div className={classes.flex}>
              <img src={file?.link} alt="" className={classes.img} />
              <div className={classes.remove} onClick={() => removeFile(file?.name)}>X</div>
            </div> :
            <div className={classes.flex}>
              <img src={URL.createObjectURL(file)} alt="" className={classes.img} />
              <div className={classes.fileName}>{file?.name}</div>
              <div className={classes.fileType}>({file?.size} KB)</div>
              <div className={classes.fileType}>{file?.type}</div>
              <div className={classes.remove} onClick={() => removeFile(file?.name)}>X</div>
            </div>
        )
      }
    </StyledContainer>
  );
};

export default UploadFiles;