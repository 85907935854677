/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import * as yup from "yup";
import Spinner from "components/Spinner";
import API from 'utils/API';
import { Form, Formik } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Grid';
import styled from "@emotion/styled";

const PREFIX = 'ContactUs';

const classes = {
    card: `${PREFIX}-contact-card`,
    grid: `${PREFIX}-contact-grid`,
    logoBorder: `${PREFIX}-logo-border`,
    logo: `${PREFIX}-contact-logo`,
    label: `${PREFIX}-contact-label`,
    subLabel: `${PREFIX}-contact-sublabel`,
    textField: `${PREFIX}-contact-textfield`,
    error: `${PREFIX}-contact-error`,
    btn: `${PREFIX}-contact-btn`,
    img: `${PREFIX}-footer-img`,
    contactHeader: `${PREFIX}-contact-header`,
    contactSubHeader: `${PREFIX}-contact-subheader`,
    formLabel: `${PREFIX}-form-label`
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.card}`]: {
            background: theme.palette.background.default,
            borderRadius: 19,
            position: 'absolute',
            left: '0%',
            right: '0%',
            margin: 50,
            top: '-46%',
        },
        [`& .${classes.logoBorder}`]: {
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        },
        [`& .${classes.logo}`]: {
            width: 320
        },
        [`& .${classes.label}`]: {
            fontWeight: '700',
            fontSize: 40,
            lineHeight: 46,
            letterSpacing: 5,
            color: theme.palette.text.secondary,
            marginTop: 20
        },
        [`& .${classes.subLabel}`]: {
            fontWeight: '400',
            fontSize: 16,
            lineHeight: 26,
            color: theme.palette.text.grey,
            width: 630
        },
        [`& .${classes.textField}`]: {
            background: 'none',
            borderRadius: 8,
            marginRight: 20,
            marginTop: 20,
            marginBottom: 5,
            width: '100%', 
        },
        [`& .${classes.error}`]: {
            float: 'left',
            marginTop: 5
        },
        [`& .${classes.btn}`]: {
            background: "radial-gradient(89.22% 89.22% at 58.82% 50.98%, #FF4D53 0%, #ED1D24 100%)",
            borderRadius: 32,
            color: "#FFFFFF",
            height: 66,
            textAlign: "center",
            marginTop: "10px",
            paddingTop: "20px",
            fontWeight: 700
        },
        [`& .${classes.contactHeader}`]: {
            fontWeight: 600,
            fontSize: "26px",
            lineHeight: "30px",
            color: "#253241",
            paddingBottom: 30
        },
        [`& .${classes.contactSubHeader}`]: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "153.69%",
            color: "#000000",
            paddingBottom: 20
        },
        [`& .${classes.formLabel}`]: {
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "14px",
            color: "#253241"

        },
        [`& .${classes.img}`]: {
            height: 1000
        },
    }
});

const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required").max(20),
    lastName: yup.string().required("Last Name is required").max(20),
    dealershipName: yup.string().required("Dealership is required").max(20),
    email: yup.string().required("Email is required"),
    phone: yup.string().required("Phone number is required").max(20),
    message: yup.string().required("Comment is required"),
});

let initialValues = {
    firstName: '',
    lastName: '',
    dealershipName: '',
    email: '',
    phone: '',
    message: '',
};

const ContactUs = () => {
    const [showLoading, setShowLoading] = useState(false);
    const handleSubmit = async (values) => {
        setShowLoading(true);
        await API.post(`/contactus`, values);
        setShowLoading(false);
    }

    return (
        <StyledGrid container >
            <Grid item xs={12}>
                <Grid container >
                    <Grid item xs={12}>
                        <Typography className={`${classes.contactHeader}`}>Send Us A Message</Typography>
                        <Typography className={`${classes.contactSubHeader}`}>Please fill out the for provided with any questions or to set up a demo.</Typography>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={(values, actions) => {
                                handleSubmit(values);
                                actions.setSubmitting(false);
                                actions.resetForm();
                            }}>
                            {({ handleSubmit, handleChange, touched, errors, values }) => {
                                return (
                                    <Form autoComplete={false}>
                                        <Grid container direction="row"
                                            justifyContent="space-around"
                                            spacing={2}
                                        >
                                            <Grid item xs={12} sm={6}>
                                                <label className={classes.formLabel}>First Name</label>
                                                <TextField
                                                    name="firstName"
                                                    placeholder="Enter First Name"
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    value={values.firstName}
                                                />
                                                <br />
                                                <span className={`text-danger ${classes.error}`}>{errors["firstName"]}</span>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className={classes.formLabel}>Last Name</label>
                                                <TextField
                                                    name="lastName"
                                                    placeholder="Enter Last Name"
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    value={values.lastName}
                                                />
                                                <br />
                                                <span className={`text-danger ${classes.error}`}>{errors["lastName"]}</span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <label className={classes.formLabel}>Dealership Name*</label>
                                                <TextField
                                                    required
                                                    name="dealershipName"
                                                    placeholder="Enter Dealership Name"
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    value={values.dealershipName}
                                                />
                                                <br />
                                                <span className={`text-danger ${classes.error}`}>{errors["dealershipName"]}</span>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className={classes.formLabel}>Email*</label>
                                                <TextField
                                                    required
                                                    name="email"
                                                    placeholder="Enter Email"
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    type="email"
                                                />
                                                <br />
                                                <span className={`text-danger ${classes.error}`}>{errors["email"]}</span>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label className={classes.formLabel}>Phone Number*</label>
                                                <TextField
                                                    required
                                                    name="phone"
                                                    placeholder="Enter Phone Number"
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    value={values.phone}
                                                    type="number"
                                                />
                                                <br />
                                                <span className={`text-danger ${classes.error}`}>{errors["phone"]}</span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <label className={classes.formLabel}>Additional Comments*</label>
                                                <TextField
                                                    required
                                                    name="message"
                                                    placeholder="Enter your message here"
                                                    multiline
                                                    rows={6}
                                                    variant="outlined"
                                                    className={classes.textField}
                                                    onChange={handleChange}
                                                    value={values.message}
                                                />
                                                <br />
                                                <span className={`text-danger ${classes.error}`}>{errors["message"]}</span>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.btn}
                                                    size="large"
                                                    onClick={() => handleSubmit()}
                                                >
                                                    Send Message
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
            <Spinner show={showLoading} />
        </StyledGrid>
    );
};

export default ContactUs;