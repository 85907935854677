import React, { useState } from 'react';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import InfoPopover from "components/widgets/InfoPopover";
import DocumentsPanel from "./components/DocumentsPanel";
import FundingPanel from "./components/FundingPanel";
import TransportationPanel from "./components/TransportationPanel";
import TitleTransferPanel from "./components/TitleTransferPanel";
import BuyerAcceptancePanel from "./components/BuyerAcceptancePanel";
import TransferToSellerPanel from "./components/TransferToSellerPanel";
import { classes, StyledGrid } from './index.styling';
import Image from 'components/widgets/Image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const steps = [
  {
    label: 'Documents',
    description: `The buyer and seller documents that are required to confirm the details of the sale.`,
    content: (transaction, id) => <DocumentsPanel transaction={transaction} id={id} classes={classes} />,
    completed: (transaction) => transaction !== undefined,
  },
  {
    label: 'Funding',
    description: `The details for the fund Transfer.`,
    content: (transaction, id) => <FundingPanel transaction={transaction} id={id} classes={classes} />,
    completed: (transaction) => transaction !== undefined,
  },
  {
    label: 'Transportation',
    description: `The details for the transportation of the vehicle from the seller to the buyer.`,
    content: (transaction, id, rooftop_id) => <TransportationPanel transaction={transaction} id={id} rooftop_id={rooftop_id} classes={classes} />,
    completed: (transaction) => transaction !== undefined,
  },
  {
    label: 'Title Transfer',
    description: `The details on the transfer of the title from the seller to the buyer.`,
    content: (transaction, id) => <TitleTransferPanel transaction={transaction} id={id} classes={classes} />,
    completed: (transaction) => transaction !== undefined,
  },
  {
    label: 'Buyer Acceptance',
    description: `Once the vehicle is shipped to the buyer, the buyer must accept the vehicle.`,
    content: (transaction, id) => <BuyerAcceptancePanel transaction={transaction} id={id} classes={classes} />,
    completed: (transaction) => transaction !== undefined,
  },
  {
    label: 'Transfer Money To Seller',
    description: `Once the vehicle is accepted by the buyer, the funds are released to the seller.`,
    content: (transaction, id) => <TransferToSellerPanel transaction={transaction} id={id} classes={classes} />,
    completed: (transaction) => transaction !== undefined,
  },
];

const PostSaleProgressPanel = ({ transaction, id, rooftop_id }) => {
  const [activeStep, setActiveStep] = useState(0);
  const {
    buyerSignDate,
    sellerSignDate,
    sertifiPaymentReceivedDate,
    orderStatus,
    uploadedDate,
    sellerMailDate,
    titleProcessorReceiveDate,
    processingDate,
    processorReasignDate,
    mailToBuyerDate,
    titleReceiveDate,
    reviewDate,
    paymentReceivedDate
  } = transaction;

  const getContentForStep = index => {
    if (steps[index]) {
      const { content } = steps[index];

      if (content) {
        return steps[index].content(transaction, id, rooftop_id)
      }
    }

    return <div>Step {index}</div>
  }

  const StepIcon = (props) => {
    const icons = {
      1: (buyerSignDate && sellerSignDate) ? <CheckCircleIcon className={classes.checkImg} /> : <Image src="/static/images/document.svg" className={classes.img} />,
      2: (sertifiPaymentReceivedDate) ? <CheckCircleIcon className={classes.checkImg} /> : <Image src="/static/images/money.svg" className={classes.img} />,
      3: (orderStatus?.length > 0 && orderStatus[0].created_date) ? <CheckCircleIcon className={classes.checkImg} /> : <Image src="/static/images/transporation.svg" className={classes.img} />,
      4: (uploadedDate && sellerMailDate && titleProcessorReceiveDate && processingDate && processorReasignDate && mailToBuyerDate && titleReceiveDate) ? <CheckCircleIcon className={classes.checkImg} /> : <Image src="/static/images/title.svg" className={classes.img} />,
      5: (reviewDate) ? <CheckCircleIcon className={classes.checkImg} /> : <Image src="/static/images/satisfaction.svg" className={classes.img} />,
      6: (paymentReceivedDate) ? <CheckCircleIcon className={classes.checkImg} /> : <Image src="/static/images/transfer.svg" className={classes.img} />,
    };

    return (
      icons[String(props.icon)]
    );
  }


  return (
    <StyledGrid item xs={12}>
      <Box>
        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label} completed={steps[index]?.completed(transaction)}>
              <StepLabel
                onClick={() => setActiveStep(index)}
                StepIconComponent={StepIcon}
              >
                {step.label} <InfoPopover info={step.description} />
              </StepLabel>
              <StepContent>
                {getContentForStep(index)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </StyledGrid>
  )
}

export default PostSaleProgressPanel;