/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Spinner from 'components/Spinner';
import Sort from "components/Sort/index";
import AuctionPanel from "./components/AuctionPanel";
import SearchPanel from './components/searchPanel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { allAuctions, myAuctions, internalAuctions } from 'data/auction.actions';
import styled from "@emotion/styled";
import useInterval from "../../hooks/useInterval";
import VehicleDetailsDialog from "../dialogs/VehicleDetailsDialog"; 

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
    }
});

const AuctionView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSearchResult, setIsSearchResult] = useState(false);
    const [vehSearch, setSearch] = useState("");
    const [sort, setSort] = useState('ending_soon');
    const [vertical, setVertical] = useState("Auto,RV,Powersports");
    const [selectedRooftops, setSelectedRooftops] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState()

    const { auction,  authentication, searchCriteria } = useSelector(state => state);
    const {
        count,
        auctions,
        error,
        auctionLoading,
        isLoadingAuctions,
        loadedType,
        loadedSort
    } = auction;

    const { auctionType = 'all' } = useParams();

    useInterval(() => {
        pollingCallback();
    }, 15 * 1000);

    const pollingCallback = () => {
        const params = {
            sort,
            search: vehSearch,
            platformVertical: vertical,
            rooftops: searchCriteria.rooftops,
            type: auctionType,
            showLoading: sort !== loadedSort || auctionType !== loadedType
        }

        if (!isLoadingAuctions || !params.showLoading) {
            switch(auctionType) {
                case 'mine':
                    dispatch(myAuctions(params));
                    break;
                case 'internal':
                    dispatch(internalAuctions(params));
                    break;
                default:
                    dispatch(allAuctions(params));
            }
        }
    };

    useEffect(() => {
        if (auctionType !== loadedType || sort !== loadedSort || searchCriteria.rooftops !== selectedRooftops) {
            setSelectedRooftops(searchCriteria.rooftops);
            pollingCallback();
        }
    }, [dispatch, isSearchResult, sort, vehSearch, vertical, auctionType, searchCriteria.rooftops]);

    const handleSearch = () => {
        setIsSearchResult(!isSearchResult);
    }

    const getHeaderText = () => {
        switch (auctionType) {
            case 'mine':
                return 'My Auctions';
            case 'internal':
                return 'Internal Auctions';
            default:
                return 'Active Auctions';
        }
    }
    return (
        <StyledGrid container sx={{pl: {sm: 7}, pr: {sm: 7}}}>
            <Grid item xs={12}>
                <Box className='pt-3 pb-3'>
                    <Typography variant="h1" color='primary'>{getHeaderText()}</Typography>
                </Box>

                <SearchPanel
                    props={{
                        setSearch,
                        handleSearch,
                        auctionType,
                        vehSearch,
                        vertical,
                        setVertical
                    }}
                />

                <Sort
                    sort={sort}
                    setSort={setSort}
                    resultType={'auctionPanel'}
                />

                {auctions?.map((auction) => {
                    return <AuctionPanel auction={auction} handleVehicleClicked={() => setSelectedVehicle(auction)} />
                })
                }

                {error !== undefined ? <div>search error: {error}</div> : (count === 0 ? <div>Record Not Found</div> : '')}
            </Grid>

            <VehicleDetailsDialog
                isOpen={selectedVehicle !== undefined}
                handleClose={() => setSelectedVehicle(undefined)}
                vehicleToken={selectedVehicle?.token}
            />

            <Spinner show={auctionLoading} />

        </StyledGrid>
    )
}

export default AuctionView;