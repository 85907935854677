import { Typography } from "@mui/material";
import InputBox from "components/widgets/InputBox";
import {useEffect, useState} from "react";
import dongleIcon from "components/TextEditor/icons/dongle.svg";
import searchIcon from "components/TextEditor/icons/search2.svg";
import PinIcon from "components/TextEditor/icons/pin.svg"
import UnpinIcon from "components/TextEditor/icons/unpinned.svg"
import TextEditor from "components/TextEditor/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import { loadMessages, loadMessageThread, addMessage } from "data/user.actions";
import Spinner from "components/Spinner";
import { formatFromNow } from 'utils/Formatters';
import Avatar from "@mui/material/Avatar";
import {Formik} from "formik"
import TextField from "components/widgets/TextField"
import Button from "components/widgets/Button"



const MessagesView = () => {
  const dispatch = useDispatch()
  const [isFetching, setIsFetching] = useState(false);
  const [currentThread, setCurrentThread] = useState(null);
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState(null);

  const {  messages, messageThread, processing } = useSelector((state) => state.user);
  const [orderedMessages, setOrderedMessages] = useState(messages)
  const [pinnedMessage, setPinnedMessage] = useState(null)

  const  {user} = useSelector((state) => state.authentication)
  


  useEffect(() => {
    if (messages === undefined && !processing) {
      setIsFetching(true);
      if (isFetching){
  dispatch(loadMessages())
  setIsFetching(false)
}
    }
  }, [messages, processing, isFetching])

const getInitials = (name) => {
  const splitName = name.split(" ");
  const newInitials = `${splitName[0][0]}${splitName[1][0]}`
  return newInitials
}

const openThread = (message, fromIndex) => {
  dispatch(loadMessageThread({messageId: message.id}))
  setCurrentThread(message)
  pinMessage(message, fromIndex)
}

const pinMessage = (message, fromIndex) => {
  const newOrderedMessages = [...orderedMessages];
  const [movedMessage] = newOrderedMessages.splice(fromIndex, 1);
  newOrderedMessages.splice(0, 0, movedMessage);

  setPinnedMessage(message)
  setOrderedMessages(newOrderedMessages)
}

const unpinMessage = () => {
  setPinnedMessage(null)
  setOrderedMessages(messages)
}

  const handleSubmit = (values) => {

    const newValues = {
      from_rooftop_id: currentThread.to_rooftop_id,
      to_rooftop_id: currentThread.from_rooftop_id,
      inventory_id: currentThread.inventory_token,
      message: values.message,
      threadId: currentThread.id
    }
    dispatch(addMessage(newValues))

  }
  
  return (
    <div className="mt-5 ">
      <Typography variant="h1" color={"#213E98"}>
        MESSAGES
      </Typography>
      <div className="flex mt-5">
        <div className="w-1/2">
          <div className="flex shadow-[0px_2.78px_5.56px_0px_rgba(0,_0,_0,_0.25)] items-center">
            <button
              type="button"
              className="bg-[#F7F7F7] flex h-10 items-center pr-8"
            >
              <img src={dongleIcon} alt="dongle" className="mr-3 ml-2" />
              <Typography variant="h4" color={"#E9392C"}>
                Newest
              </Typography>
            </button>
            <div className="flex ml-2 w-full">
              <img src={searchIcon} alt="search" className="w-9 h-9" />
              <input placeholder="Search by subject..." className="w-full" />
            </div>
          </div>

          <div className="overflow-y-auto h-[660px]">
            {orderedMessages &&
              orderedMessages?.map((message, index) => {
                return (
                  <div
                    key={index}
                    className={`${currentThread?.id === message?.id ? "bg-white" : "bg-[#F9F9F9]" }
                      ${pinnedMessage?.id === message?.id ? "shadow-lg sticky top-2" : ""} 
                      w-full shadow-md mt-2 p-2`}
                  >
                  <div className="flex">
                    <div className="mr-5 flex flex-col items-center">
                      <Avatar sx={{width: 55, height: 55}}>{getInitials(message?.from_name)}</Avatar>

                      <span className="text-xs text-center block w-max mt-1">{message?.from_name}</span>
                    </div>

                    <div className="w-full">
                      <div className="flex justify-between">
                        <Typography variant="h4" color={"#0D6EFF"}>
                          <span className="text-black">Interested in{" "}</span>
                          <span>{message?.title_url}</span>
                        </Typography>
                        <Typography>{formatFromNow(message?.message_date)}</Typography>
                      </div>

                      <p className="pr-10 text-sm mt-2 leading-4">{message?.message}</p>
                    </div>
                   </div>
                    <div className="flex justify-between mt-2">
                      <span className="text-[#E9392C]">{message?.unread_messages > 1 ? `${message?.unread_messages} unread messages` : "" }</span>
                      <span className="text-[#E9392C]">{message?.unread_messages === 1 ? `${message?.unread_messages} unread message` : "" }</span>

                      {currentThread?.id !== message?.id ? <button 
                      onClick={() => openThread(message, index)}
                      className="ml-auto">
                          <span className="text-[#0D6EFF]">click to open thread</span>
                        </button> 

                       : <div className="ml-auto">
                          <span className="text-[#E9392C] text-[15px]">CURRENT THREAD </span>
                        {currentThread?.id === pinnedMessage?.id ? <button onClick={unpinMessage}>
                            <img src={PinIcon} alt="Pin Icon" className="w-5 h-5 inline"/>
                          </button>
                           :  <button onClick={() => pinMessage(message, index)}>
                            <img src={UnpinIcon} alt="Pin Icon" className="w-5 h-5 inline"/>
                          </button>}
                        </div>}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="w-1/2 ml-9 ">
          <div className="shadow-[0px_2.78px_5.56px_0px_rgba(0,_0,_0,_0.25)] p-2">
            {currentThread ?
             <Typography variant="h4" color={"#0D6EFF"}>
             <span className="text-black">Interested in{" "}</span>
             <span>{currentThread.title_url}</span>
           </Typography> : <Typography variant="h4" color="black" fontWeight={500}>
              Select a message to view
            </Typography>}
          </div>

          <div className="h-[480px] overflow-y-auto">
           {messageThread && currentThread !== null && messageThread?.map((message) => {
            return <>
            {!message?.read && message?.id === firstUnreadMessageId && <div className="flex items-center">
                  <div className="w-1/2 h-[0px] border border-[#E9392C]" />
                  <div className="text-[#E9392C] text-[19px] text-center px-3 min-w-max">NEW MESSAGES</div>
                  <div className="w-1/2 h-[0px] border border-[#E9392C]" />
            </div>}
            <div key={message?.id} className="mt-2 flex py-2 pr-8">
               <Avatar sx={{width: 50, height: 50, marginRight: "12px", marginTop: "8px"}}>{getInitials(message?.from_name)}</Avatar>
               <div>
               <div className="flex text-sm">
                <span className="mr-2 font-medium">{message?.mine ? "You" : message?.from_name}</span>
                <span>{formatFromNow(message?.message_date)}</span>
                
               </div>
               <p className="text-sm mt-1 leading-4">{message?.message}</p>
               </div>
            </div> 
            </>
           })}
          </div>
          
          {/* <TextEditor handleSubmit={handleSubmit} /> */}

          <Formik
              initialValues={{}}
              enableReinitialize
              onSubmit={async (values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false);
                actions.resetForm({
                  values: { message: ''},
                });     
              }}
            >
              {({ handleSubmit, handleChange }) => {
                return (
                  <>
                    <TextField
                      name="message"
                      placeholder="Reply..."
                      multiline
                      rows={5}
                      className="multiline"
                      
                    /> 

                      <Button
                      disabled={currentThread === null || processing }
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </>
                      );
              }}
                    </Formik>
          
        </div>
      </div>
      <Spinner show={processing} />
    </div>
  );
};

export default MessagesView;
