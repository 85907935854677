import { createAsyncThunk } from '@reduxjs/toolkit'
import { toCamelcase } from 'utils/Formatters';
import {
    startingSearch,
    searchSuccess,
    searchFailed,
    loadingSearchOptions,
    loadingSearchOptionsSuccess,
    loadingSearchOptionsFailed,
    loadingModelOptions,
    loadingModelOptionsSuccess,
    loadingModelOptionsFailed,
    loadingTrimOptions,
    loadingTrimOptionsSuccess,
    loadingTrimOptionsFailed,
    loadingVehicleDetails,
    loadingVehicleDetailsSuccess,
    loadingVehicleDetailsFailed,
    loadingShippingOptions,
    loadingShippingOptionsFailed,
    loadingShippingOptionsSuccess,
    loadingCategoryCount,
    loadingCategoryCountSuccess,
    loadingCategoryCountFailed,
    loadingTransactionStatuses,
    loadingTransactionStatusesSuccess,
    loadingTransactionStatusesFailed,
    submittingMakeOffer,
    submittingMakeOfferSuccess,
    submittingMakeOfferFailed,
    loadingConditionDefinitions,
    loadingConditionDefinitionsSuccess,
    loadingConditionDefinitionsFailed,
    creatingConditionReport,
    creatingConditionReportSuccess,
    creatingConditionReportFailed,
    updatingConditionReport,
    updatingConditionReportSuccess,
    updatingConditionReportFailed,
    deleteConditionPhoto,
    deleteConditionPhotoFailed,
    deleteConditionPhotoSuccess,
    loadAuctionValidation,
    loadAuctionValidationSuccess,
    loadAuctionValidationFailed,
    loadingConditionReport,
    loadingConditionReportSuccess,
    loadingConditionReportFailed,
    savingAuction,
    savingAuctionSuccess,
    savingAuctionFailed,
    buyingNowStart,
    buyingNow,
    buyingNowFailed,
    cancelAuctionStart,
    cancelAuctionSuccess,
    cancelAuctionFailed,
    loadingAgingVehicles,
    loadedAgingVehicles,
    agingVehiclesFailed,
}
    from './vehicle.slice';

export const vehicleSearch = createAsyncThunk('buy/search', async (values, { extra, dispatch }) => {
    try {
        const radius = `${values.distancemin},${values.distancemax}`;
        const year = `${values.yearmin},${values.yearmax}`;
        const price = `${values.pricemin},${values.pricemax}`;
        const mileage = `${values.minmileage},${values.maxmileage}`;
        const kbb = `${values.kbbmin},${values.kbbmax}`;
        const weight = `${values.minweight},${values.maxweight}`;
        const length = `${values.minlength},${values.maxlength}`;
        const age = `${values.agemin},${values.agemax}`;
        dispatch(startingSearch());
        const response = await extra.get(`/buy/search?pa=${values?.page}&so=${values?.sort}&se=${values?.search}&ra=${radius}&ye=${year}&pr=${price}&mi=${mileage}&kbb=${kbb}&vc=${values?.vehcategory}&ma=${values?.make}&de=${values?.dealers}&rts=${values?.rooftops}&mo=${values?.model}&tr=${values?.trim}&fu=${values?.fueltype}&we=${weight}&le=${length}&sle=${values?.sleeps}&sli=${values?.slideouts}&nu=${values?.newused}&dealer=${values?.dealer}&com=${values?.resultType}&rooftop=${values?.rooftop}&internalOnly=${values?.internalOnly}&unpermittedSell=${values?.unpermittedSell}&age=${age}`);
        dispatch(searchSuccess({ count: response?.count, vehicles: response?.results, mergeArray: values.mergeArray, auctionStarted: '' }));
    }
    catch (error) {
        console.log("error =>", error);
        dispatch(searchFailed({ error: 'Record not found' }));
    }
});

export const searchOption = createAsyncThunk('vehicle/search/options', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingSearchOptions());
        const response = await extra.get(`/vehicle/search/options/?dealerRooftopId=${values.dealerRooftopId}&dealer=${values.dealer}&searchType=${values.searchType}&category=${values.activeCategory || 'all'}&internalOnly=${values.internalOnly}`);
        dispatch(loadingSearchOptionsSuccess({ vehicleOption: response }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingSearchOptionsFailed({ error: 'Record not found' }));
    }
});

export const modelOption = createAsyncThunk('vehicle/search/options/model', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingModelOptions());

        const response = await extra.get(`/vehicle/search/options/model/?dealerRooftopId=${values.dealerRooftopId}&dealer=${values.dealer}&searchType=${values.searchType}&category=${values.activeCategory || 'all'}&make=${values.make}&internalOnly=${values.internalOnly}`);
        dispatch(loadingModelOptionsSuccess({ modelOptions: response }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingModelOptionsFailed({ error: 'Record not found' }));
    }
});

export const trimOption = createAsyncThunk('vehicle/search/options/trim', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingTrimOptions());

        const response = await extra.get(`/vehicle/search/options/trim/?dealerRooftopId=${values.dealerRooftopId}&dealer=${values.dealer}&searchType=${values.searchType}&category=${values.activeCategory || 'all'}&make=${values.make}&model=${values.model}&internalOnly=${values.internalOnly}`);
        dispatch(loadingTrimOptionsSuccess({ trimOptions: response }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingTrimOptionsFailed({ error: 'Record not found' }));
    }
});

export const vehicleDetails = createAsyncThunk('vehicle/unit', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingVehicleDetails());

        const response = await extra.get(`/vehicle/unit/${values.token}`);
        dispatch(loadingVehicleDetailsSuccess({ vehicleDetail: response }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingVehicleDetailsFailed({ error: 'Record not found' }));
    }
});


export const getReinventShippingOption = createAsyncThunk('shipping/quote', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingShippingOptions({ loadingShippingOptions: true }));
        const response = await extra.post('/shipping/quote', values);
        dispatch(loadingShippingOptionsSuccess({ quotes: response }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingShippingOptionsFailed({ error: 'Record not found', loadingShippingOptions: false }));
    }
});

export const getCategoryCount = createAsyncThunk('vehicle/categories', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingCategoryCount());

        const response = await extra.get(`/vehicle/categories/${values}`);
        dispatch(loadingCategoryCountSuccess({ categoryCount: response }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingCategoryCountFailed({ error: 'Record not found' }));
    }
});

export const getConditionReport = createAsyncThunk('vehicle/condition', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingConditionReport({ updatedConditionReport: false, savedConditionReport: false, loadingConditionReport: true, token: values.token }));

        const response = await extra.get(`/vehicle/condition/report?token=${values.token}`);
        dispatch(loadingConditionReportSuccess({ updatedConditionReport: false, savedConditionReport: false, loadingConditionReport: false, conditionReport: response }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingConditionReportFailed({ updatedConditionReport: false, savedConditionReport: false, loadingConditionReport: false, error: 'Record not found' }));
    }
});

export const getConditionDefinitions = createAsyncThunk('vehicle/condition/report', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingConditionDefinitions({ loadingConditionDefinitions: true }));
        const { category, vertical } = values;
        const response = await extra.get(`/vehicle/condition/report/definition?vehicleCategory=${category}&platformVertical=${vertical}`);
        const definitions = response?.definition.map(def => toCamelcase(def));
        dispatch(loadingConditionDefinitionsSuccess({ definitions }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingConditionDefinitionsFailed({ error: 'Record not found', loadingConditionDefinitions: false }));
    }
});

export const getTransactionStatus = createAsyncThunk('transaction/status', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingTransactionStatuses());

        const response = await extra.get(`/transaction/status/${values.token}`);
        dispatch(loadingTransactionStatusesSuccess({ transactionStatus: response }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingTransactionStatusesFailed({ error: 'Record not found' }));
    }
});

export const makeOffer = createAsyncThunk('offer/make/', async (values, { extra, dispatch }) => {
    try {
        dispatch(submittingMakeOffer());
        const response = await extra.post('/offer/make/', values);
        dispatch(submittingMakeOfferSuccess({ offer: response, offerSent: values.inventoryId }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(submittingMakeOfferFailed({ errorMakeOffer: 'Something went wrong!' }));
    }
});
export const createConditionReport = createAsyncThunk('vehicle/condition/report', async (values, { extra, dispatch }) => {
    try {
        dispatch(creatingConditionReport());

        const response = await extra.post('/vehicle/condition/report', values);
        dispatch(creatingConditionReportSuccess({ reportStatus: response, token: values.inventoryToken }));

        if (values?.dealer_id) {
            dispatch(
                loadAgingVehicles({
                  sort: "aging",
                  dealerId: values.dealer_id,
                  searchType: "my-inventory",
                  limitAmount: 130,
                })
                );
        }
    }
    catch (error) {
        console.log(error.message);
        dispatch(creatingConditionReportFailed({ error: 'Record not found' }));
    }
});

export const updateConditionReport = createAsyncThunk('vehicle/condition/report/update', async (values, { extra, dispatch }) => {
    try {
        dispatch(updatingConditionReport());

        const response = await extra.post('/vehicle/condition/report/update', values);
        dispatch(updatingConditionReportSuccess({ reportStatus: response?.reportid, token: values.inventoryToken }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(updatingConditionReportFailed({ error: 'Record not found' }));
    }
});
export const removeConditionPhoto = createAsyncThunk('vehicle/condition/report/photos/delete', async (values, { extra, dispatch }) => {
    try {
        dispatch(deleteConditionPhoto());
        const response = await extra.delete(`/vehicle/condition/report/photos/delete/${values.inventoryToken}/${values.conditionTypeId}/${values.fileName}`);
        if (response) {
            dispatch(getConditionReport({ token: values.inventoryToken }));
        }

        dispatch(deleteConditionPhotoSuccess())
    }
    catch (error) {
        console.log(error.message);
        dispatch(deleteConditionPhotoFailed({ error: 'Record not found' }));
    }
});

export const getAuctionValidation = createAsyncThunk('auction/validation', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadAuctionValidation());
        const response = await extra.post('/auction/validation', values);
        dispatch(loadAuctionValidationSuccess({ validationParams: response?.params }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadAuctionValidationFailed({ error: 'Record not found' }));
    }
});


export const addAuction = createAsyncThunk('auction', async (values, { extra, dispatch }) => {
    try {
        dispatch(savingAuction());
        await extra.post('/auction', values);
        dispatch(savingAuctionSuccess({ auctionStarted: values.inventory_id }));
        if (values?.dealer_id) {
          dispatch(
            loadAgingVehicles({
              sort: "aging",
              dealerId: values.dealer_id,
              searchType: "my-inventory",
              limitAmount: 130,
            })
          );
        }
    }
    catch (error) {
        console.log(error.message);
        dispatch(savingAuctionFailed({ error: 'Unable to start auction' }));
    }
});

export const buyNow = createAsyncThunk('vehicle/buy-now/', async (values, { extra, dispatch }) => {
    try {
        dispatch(buyingNowStart({ buyNowSent: undefined, buyNowLoading: true }));
        const response = await extra.post('/vehicle/buy-now/', values);
        dispatch(buyingNow({ buying: response, buyNowSent: values.inventoryId, buyNowLoading: false }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(buyingNowFailed({ error: 'Record not found', buyNowLoading: false }));
    }
});

export const cancelAuction = createAsyncThunk('auction/cancel/', async (values, { extra, dispatch }) => {
    try {
        dispatch(cancelAuctionStart());
        await extra.post(`/auction/cancel/${values?.auction_token}`);
        dispatch(
          cancelAuctionSuccess({ auctionCanceled: values?.auction_token })
        );

        if (values?.dealer_id) {
        dispatch(
            loadAgingVehicles({
              sort: "aging",
              dealerId: values.dealer_id,
              searchType: "my-inventory",
              limitAmount: 130,
            })
          );
        }
    }
    catch (error){
        console.log(error.message);
        dispatch(cancelAuctionFailed({error: "Auction not found"}))
    }
})

export const loadAgingVehicles = createAsyncThunk('/vehicle/search/', async(values, {extra, dispatch}) => {
    try {
        dispatch(loadingAgingVehicles());
       const response = await extra.get(`/vehicle/search?sort=${values.sort}&searchType=${values.searchType}&dealer=${values.dealerId}&limitAmount=${values.limitAmount}`);

       dispatch(loadedAgingVehicles({agingVehicles: response?.results}));
    }
    catch (error) {
        console.log(error.message);
        dispatch(agingVehiclesFailed({error: "Something Went Wrong"}));
    }
})