import React from 'react';
import Grid from '@mui/material/Grid';
import { TabPanel } from '@mui/lab';
import { useSelector } from 'react-redux';
import { selectMultipleOption } from 'utils/Filter';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const FuelTypeFilter = ({ criteria }) => {
  const {
    search: { vehicleOption },
  } = useSelector((state) => state);
  let vehFuelTypes = criteria.fuelType;

  return (
    <TabPanel value='fueltype'>
      <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                (
                  localStorage.hasOwnProperty('vehFuelTypes')
                    ? localStorage.getItem('vehFuelTypes') === ''
                    : criteria.fuelType === ''
                )
                  ? classes.btnColor
                  : classes.styledBtn
              }
              color='primary'
              size='large'
              value=''
              onClick={(e) => {
                criteria.setPage(1);
                localStorage.setItem('vehFuelTypes', e.target.value);
                criteria.handleFuelType(e.target.value);
              }}
            >
              All
            </StyledOptionButton>
          </Grid>
          {vehicleOption.fuelTypes?.map((fuel) => (
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  localStorage
                    .getItem('vehFuelTypes')
                    ?.split(',')
                    ?.find((x) => x === fuel.fuelType)
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={fuel.fuelType}
                onClick={(e) => {
                  criteria.setPage(1);
                  selectMultipleOption(
                    'vehFuelTypes',
                    vehFuelTypes,
                    e.target.value,
                    criteria.handleFuelType
                  );
                }}
              >
                {fuel.fuelType} ({fuel.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
      </StyledFilterGrid>
    </TabPanel>
  );
};

export default FuelTypeFilter;