import React from "react";
import {useField} from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CheckboxInput = (
{
  key,
  text,
  items,
  domainValue,
  handleChange,
  checked,
  ...props
}) => {
  const [field, , helpers] = useField(props);

  const handleLocalChange = (e) => {
    const newValue = e.target.checked;
    if (handleChange) {
      handleChange(newValue, domainValue);
    }
    else {
      helpers.setValue(newValue)
    }
  }

  const getControl = () => {
    return checked ?
        (
            <Checkbox
                {...field}
                {...props}
                checked={checked}
                onChange={handleLocalChange}
            />
        ) :
        (
          <Checkbox
              {...field}
              {...props}
              checked={domainValue ? domainValue === field.value : field.value}
              onChange={handleLocalChange}
          />
        )
  }

  return (
      <FormControlLabel
          control={getControl()}
          label={text}
      />
  )
}

export default CheckboxInput;