import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import styled from "@emotion/styled";
import Slider, { SliderThumb } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const StyledTypography = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    color: theme.palette.border.default,
    fontSize: "15px",
    lineHeight: "10px",

}));

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.secondary.main,
    height: 10,
    padding: '20px 0',

    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: theme.palette.secondary.main,
        border: `5px solid ${theme.palette.background.default}`,
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 8,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.border.default,
        opacity: 1,
        height: 8,
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'bold',
        top: 40,
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.text.primary,
        },
    },
}));

function SliderThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
        </SliderThumb>
    );
}

const RangeSlider = ({ minValue, maxValue, defaultValue, labelText, handleChange, disabled }) => {
    const [range, setRange] = useState([minValue, maxValue]);

    function valuetext(value) {
        return `${labelText} ${value}`;
    }

    const onRangeChange = (e, value) => {
        handleChange(value);
    };

    const onRangeChangeSetLabel = (event, newValue) => {
        setRange(newValue);
    };


    useEffect(() => {
        if (defaultValue[0] !== null) {
            setRange(defaultValue);
        }
    }, [defaultValue]);
    
    return (
        <Grid container >
            <Grid container direction="row"
                justifyContent="space-between">
                <StyledTypography>
                    Min
                </StyledTypography>
                <StyledTypography>
                    Max
                </StyledTypography>
            </Grid>
            <Grid item xs={12}>
                <StyledSlider
                    components={{ Thumb: SliderThumbComponent }}
                    min={minValue}
                    max={maxValue}
                    value={range ? range : defaultValue}
                    onChangeCommitted={onRangeChange}
                    onChange={onRangeChangeSetLabel}
                    valueLabelDisplay="on"
                    getAriaValueText={valuetext}
                    valueLabelFormat={valuetext}
                    disabled={!disabled}
                />
            </Grid>
        </Grid>
    );
}

export default RangeSlider;