/* eslint-disable jsx-a11y/iframe-has-title */
import {Formik} from 'formik';
import * as yup from "yup";
import Button from '../widgets/Button'
import Checkbox from "../widgets/Checkbox";

const validationSchema = yup.object().shape({
  accepted: yup
    .boolean()
    .oneOf([true], 'Must Accept Terms and Conditions'),
});

const initialValues = {
  accepted: false
}

export default function SingupForm({handleBackClicked, handleSubmit}) {
  return (
    <div className="container mx-auto">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={ async (values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}>

        {({ handleSubmit, handleChange, touched, errors, setFieldValue, values }) => {
          return (
            <div className='grid grid-cols-12'>
              <div className='col-span-12'>
                <span className='float-left pt-3 mr-3 text-3xl'>Terms and conditions</span>
              </div>

              <div className='mt-8 mb-8 border-primary border-2 pl-8 pt-5 pb-5 col-span-12'>
                <iframe width="1100px"
                        height="600px"
                        src="https://reinventauto-public.s3-us-west-2.amazonaws.com/terms_and_conditions.pdf"></iframe>
              </div>

              <div className='col-span-12'>
                By accepting, I agree that I accept terms & conditions above.
              </div>

              <div className='col-span-12'>
                <Checkbox
                  name='accepted'
                  value='accepted'
                  label='Accept'
                  checked={values.accepted}
                  errors={errors}
                  handleChange={(value) => {
                    setFieldValue('accepted', value)
                  }}
                  className="ml-2 form-checkbox rounded"
                />
              </div>

              <div className='mt-3 col-span-12'>
                <div className='float-left mr-6'>
                  <Button type='submit' className='width-full border-2 border-primary' onClick={handleBackClicked}>Back</Button>
                </div>

                <div className='col-span-2'>
                  <Button type='submit' className='width-full border-2 border-primary' onClick={handleSubmit}>Finish</Button>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}