import React from 'react';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const newUsedCriteria = [
  { label: 'All', value: '' },
  { label: 'New', value: 'New' },
  { label: 'Used', value: 'Used' },
];

const YearFilter = ({ criteria }) => {
  return (
    <TabPanel value='neworused'>
      <StyledFilterGrid>
        <Grid container spacing={1}>
          {newUsedCriteria.map((item) => (
            <Grid className='ml-4'>
              <StyledOptionButton
                variant='contained'
                className={
                  (
                    localStorage.hasOwnProperty('used')
                      ? item.value === localStorage.getItem('used')
                      : item.value === criteria.used
                  )
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={item.value}
                onClick={(e) => {
                  criteria.setPage(1);
                  localStorage.setItem('used', e.target.value);
                  criteria.handleUsed(e.target.value);
                }}
              >
                {item.label}
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
      </StyledFilterGrid>
    </TabPanel>
  );
};

export default YearFilter;