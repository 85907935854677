import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import theme from 'styles/theme';
import styled from '@emotion/styled';


const PREFIX = 'DealerDetails';

const classes = {
    basicCard: `${PREFIX}-basicCard`,
    vehicleDetail: `${PREFIX}-vehicleDetail`,
    vehicleBox: `${PREFIX}-vehicleBox`,
    vehicleTypo: `${PREFIX}-vehicleTypo`,
    dealer: `${PREFIX}-dealer`,
}

const StyledGrid = styled(Grid)(({
    theme
}) => {
    return {
        [`& .${classes.basicCard}`]: {
            background: theme.palette.background.btn
        },
        [`& .${classes.dealer}`]: {
            background: theme.palette.secondary.main
        },
        [`& .${classes.vehicleDetail}`]: {
            borderBottom: `1px solid ${theme.palette.border.default}`,
            padding: 0
        },
        [`& .${classes.vehicleBox}`]: {
            borderLeft: `4px solid ${theme.palette.secondary.main}`,
        },
        [`& .${classes.vehicleTypo}`]: {
            margin: '15px 0px 15px 15px'
        },
    }
});

const RooftopDetail = ({ vehicle }) => {
    return (
        <StyledGrid>
            <Card className={`mt-4 ${classes.basicCard}`}>
                <CardContent className={classes.vehicleDetail}>
                    <Box className={classes.vehicleBox}>
                        <Typography variant='h5' className={classes.vehicleTypo}>ROOFTOP DETAILS</Typography>
                    </Box>
                </CardContent>
                <CardContent className={classes.dealer}>
                    <Box>
                        <Typography variant='h6' color={theme.palette.background.default}>{vehicle?.rooftop_name}</Typography>
                        <Typography variant='h6' color={theme.palette.background.default}>{vehicle?.address_1}</Typography>
                        <Typography variant='h6' color={theme.palette.background.default}>{vehicle?.address_2}</Typography>
                        <Typography variant='h6' color={theme.palette.background.default}>{vehicle?.city}, {vehicle?.state} {vehicle?.postal_code}</Typography>
                    </Box>
                </CardContent>
            </Card>
        </StyledGrid>
    )
}

export default RooftopDetail;