// import {trackPageView} from "@redux-beacon/google-analytics;

export const createEventMap = () => {
  // const pageView = trackPageView((action, prevState, nextState) => {
  //   return {
  //     title: 'Page View',
  //     location: ,
  //     path: /* (optional) */,
  //     fieldsObject: { /* (optional) */
  //       [ /* dimension | metric */]: /* value */,
  //     },
  //   };
  // });
  //
  // return {
  //   pageView
  // }

  return {

  }
}