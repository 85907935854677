import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import { formatDollarAmount } from "utils/Formatters";

const PREFIX = 'PriceSubPanel';

const classes = {
  flex: `${PREFIX}-flex`,
  auction: `${PREFIX}-auction`,
  kbb: `${PREFIX}-kbb`,
  price: `${PREFIX}-price`,
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.flex}`]: {
      display: 'flex',
      marginTop: 15
    },
    [`& .${classes.auction}`]: {
      width: 160,
      padding: 10,
      borderRight: `1px dashed ${theme.palette.border.default}`
    },
    [`& .${classes.price}`]: {
      width: 160,
      padding: 10,
    },
    [`& .${classes.kbb}`]: {
      padding: 10,
      marginLeft: 30
    }
  }
});

export const PriceSubPanel = ({ vehicle }) => {
  return (
    <StyledGrid container>
      <Grid item xs={10} className="p-2">
        <div className={classes.flex}>
          {vehicle?.kbb_auction_value || vehicle?.blackbook_wholesale_value ?
            <>
              <div className={classes.auction}>
                <Typography variant='h5' color='primary'>{vehicle?.kbb_auction_value ? `${formatDollarAmount(vehicle?.kbb_auction_value)}` : `$ 0`}</Typography>
                <Typography variant='body1' color="textSecondary">Kelly Blue Book</Typography>
              </div>
              <div className={classes.kbb}>
                <Typography variant='h5' color='secondary'>{vehicle?.blackbook_wholesale_value ? `${formatDollarAmount(vehicle?.blackbook_wholesale_value)}` : `$ 0`}</Typography>
                <Typography variant='body1' color="textSecondary">Blackbook</Typography>
              </div>
            </>
            :
            <div className={classes.price}>
              <Typography variant='h5' color='primary'>{vehicle?.price ? `${formatDollarAmount(vehicle?.price)}` : `$ 0`}</Typography>
              <Typography variant='body1' color="textSecondary">Retail Price</Typography>
            </div>
          }
        </div>
      </Grid>
    </StyledGrid>
  )
}

export default PriceSubPanel;