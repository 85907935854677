import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TitleProcessing from "./TitleProcessing";
import UploadTitle from "components/dialogs/UploadTitle";
import { ToastContainer } from "react-toastify";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatDateTime } from "utils/Formatters";

const TitleTransferPanel = ({ classes, transaction, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { token, sellerId, buyerId, uploadedDate, sellerMailDate, titleProcessorReceiveDate, processingDate, processorReasignDate, mailToBuyerDate, titleReceiveDate, transactionId } = transaction;

  return (
    <Grid container>
      {(sellerId === id && !uploadedDate) && (
        <Grid item xs={12}>
          <ScheduleIcon className={classes.scheduled} />
          <Link onClick={() => setIsOpen(true)}><Typography variant='body'>Upload Title</Typography></Link>
          <UploadTitle
            open={isOpen}
            handleClose={() => setIsOpen(false)}
            token={token}
          />
        </Grid>
      )}

      {uploadedDate && (
        <Grid item xs={12}>
          <CheckCircleIcon className={classes.completed} />
          <Typography variant='body'>Title Uploaded</Typography>
          <Typography variant='body' className={classes.actionDate}>{formatDateTime(uploadedDate)}</Typography>
        </Grid>)}

      {(buyerId === id && !uploadedDate) && (
        <Grid item xs={12}>
          <ScheduleIcon className={classes.scheduled} />
          <Typography variant='body'>Title Uploaded</Typography>
        </Grid>)}

      {sellerId === id && (<>
        <TitleProcessing classes={classes} date={sellerMailDate} title="Seller Mailed Title to Processor" transactionId={transactionId} isUploaded={uploadedDate} />
        <TitleProcessing classes={classes} date={titleProcessorReceiveDate} title="Title Processor Received Title" transactionId={transactionId} isUploaded={uploadedDate} />
        <TitleProcessing classes={classes} date={processingDate} title="Processor validated title" transactionId={transactionId} isUploaded={uploadedDate} />
        <TitleProcessing classes={classes} date={processorReasignDate} title="Processor Reassigns title" transactionId={transactionId} isUploaded={uploadedDate} />
        <TitleProcessing classes={classes} date={mailToBuyerDate} title="Processor mails to buyer" transactionId={transactionId} isUploaded={uploadedDate} />
        <TitleProcessing classes={classes} date={titleReceiveDate} title="Buyer receives title" transactionId={transactionId} isUploaded={uploadedDate} />
      </>)}

      {buyerId === id && (<>
        <TitleProcessing classes={classes} date={sellerMailDate} title="Seller Mailed Title to Processor" transactionId={transactionId} isUploaded={false} />
        <TitleProcessing classes={classes} date={titleProcessorReceiveDate} title="Title Processor Received Title" transactionId={transactionId} isUploaded={false} />
        <TitleProcessing classes={classes} date={processingDate} title="Processor validated title" transactionId={transactionId} isUploaded={false} />
        <TitleProcessing classes={classes} date={processorReasignDate} title="Processor Reassigns title" transactionId={transactionId} isUploaded={false} />
        <TitleProcessing classes={classes} date={mailToBuyerDate} title="Processor mails to buyer" transactionId={transactionId} isUploaded={false} />
        <TitleProcessing classes={classes} date={titleReceiveDate} title="Buyer receives title" transactionId={transactionId} isUploaded={false} />
      </>)}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Grid>
  )
}

export default TitleTransferPanel;