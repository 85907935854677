import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";

const PREFIX = 'Testimonials';

const classes = {
    card: `${PREFIX}-testimonials-card`,
    img: `${PREFIX}-testimonials-img`,
    content: `${PREFIX}-testimonials-content`,
    cardContent: `${PREFIX}-card-content`,
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    border: `${PREFIX}-border`,
    comment: `${PREFIX}-comment`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        paddingTop: 50,
        [`& .${classes.card}`]: {
            margin: 10,
            borderRadius: 20,
            position: 'relative',
            background: '#FFFFFF',
            border: '1px solid rgba(156, 163, 177, 0.5)',
            boxShadow: '0px 10px 26px rgba(0, 0, 0, 0.12)',
            [theme.breakpoints.up('xs')]: {
                height: 175,
            },
            [theme.breakpoints.up('lg')]: {
                height: 150,
            },
        },
        [`& .${classes.img}`]: {
            height: 72,
            width: 72,
            borderRadius: '50%',
        },
        [`& .${classes.content}`]: {
            display: 'flex',
        },
        [`& .${classes.cardContent}`]: {
            marginLeft: 10,
            marginTop: 10
        },
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        [`& .${classes.logo}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.border}`]: {
            width: 170,
            marginBottom: 10,
            float: 'left',
            paddingLeft: 10,
            borderTop: `3px solid ${theme.palette.secondary.main}`,
        },
        [`& .${classes.comment}`]: {
            color: theme.palette.secondary.main,
            paddingTop: 10
        },
    }
});

const Testimonials = () => {
    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Box className={classes.flex}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/ReinventLogo.png'
                        className={classes.logo}
                    />
                    <Typography variant='h1' color="#213E98">See why we are <span className={classes.right}>Trusted</span></Typography>
                </Box>
                <hr className={classes.border} />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} md={6} lg={5}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid className={classes.content}>
                                    <div>
                                        <Image
                                            alt="connected"
                                            src='/static/images/testimonials/dan_roesch.jfif'
                                            className={classes.img}
                                        />
                                    </div>
                                    <div className={classes.cardContent}>
                                        <Typography variant='h6' color="#213E98"><b>Dan Roesch</b></Typography>
                                        <Typography variant='body1' color="#616161">Midwest Top Ford Dealer</Typography>
                                    </div>
                                </Grid>
                                <Typography variant='h3' className={classes.comment}>
                                    "<b>Every dealer should be on Reinvent!</b>"
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid className={classes.content}>
                                    <div>
                                        <Image
                                            alt="connected"
                                            src='/static/images/testimonials/todd_berlin.png'
                                            className={classes.img}
                                        />
                                    </div>
                                    <div className={classes.cardContent}>
                                        <Typography variant='h6' color="#213E98"><b>Todd Berlin</b></Typography>
                                        <Typography variant='body1' color="#616161">Largest Harley-Davidson Dealer location in US</Typography>
                                    </div>
                                </Grid>
                                <Typography variant='h3' className={classes.comment}>
                                    <b>"One of the easiest transactions I've ever made"</b>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={0} lg={2} />
                    <Grid item xs={12} md={6} lg={5}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid className={classes.content}>
                                    <div>
                                        <Image
                                            alt="connected"
                                            src='/static/images/testimonials/todd_docter.png'
                                            className={classes.img}
                                        />
                                    </div>
                                    <div className={classes.cardContent}>
                                        <Typography variant='h6' color="#213E98"><b>Todd Doctor</b></Typography>
                                        <Typography variant='body1' color="#616161">3rd Largest RV Dealer in US</Typography>
                                    </div>
                                </Grid>
                                <Typography variant='h3' className={classes.comment}>
                                    <b>"I can see using their platform every day"</b>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Grid className={classes.content}>
                                    <div>
                                        <Image
                                            alt="connected"
                                            src='/static/images/testimonials/john_wooden.png'
                                            className={classes.img}
                                        />
                                    </div>
                                    <div className={classes.cardContent}>
                                        <Typography variant='h6' color="#213E98"><b>John Wooden</b></Typography>
                                        <Typography variant='body1' color="#616161">Largest Marine Dealer in Midwest</Typography>
                                    </div>
                                </Grid>
                                <Typography variant='h3' className={classes.comment}>
                                    <b>"Saves us Time and Money!"</b>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </StyledGrid>
    )
}

export default Testimonials;