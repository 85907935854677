import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "components/widgets/TextField";
import CheckboxInput from "components/widgets/Checkbox";

const UserDataFormPanel = ({classes, email}) => {

  return (
      <Grid container>

        <Grid item xs={4} className={classes.fieldContainer}>
          <TextField
              label='First Name'
              name='firstName'
          />
        </Grid>

        <Grid item xs={4} className={classes.fieldContainer}>
          <TextField
              label='Last Name'
              name='lastName'
          />
        </Grid>

        <Grid item xs={4} className={classes.fieldContainer}>
          <TextField
              label='Email Address'
              name='email'
              disabled={email !== '' && email !== undefined}
          />
        </Grid>

        <Grid item xs={4} className={classes.fieldContainer}>
          <TextField
              label='Phone Number'
              name='phone'
          />
        </Grid>

        <Grid item xs={4} className={classes.checkboxContainer}>
          <CheckboxInput
              text='Enabled'
              name='enabled'
          />
        </Grid>
      </Grid>
  )
}

export default UserDataFormPanel;