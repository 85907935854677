import React from 'react';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import StyledOptionButton from './StyledOptionButton';
import StyledFilterGrid from './StyledFilterGrid';

const PREFIX = 'FilterStyle';

const classes = {
  styledBtn: `${PREFIX}-styledBtn`,
  btnColor: `${PREFIX}-btnColor`,
};

const SlideOutsFilter = ({ criteria }) => {
  const {
    search: { vehicleOption },
  } = useSelector((state) => state);

  return (
    <TabPanel value='slide-outs'>
      <StyledFilterGrid>
        <Grid container spacing={1}>
          <Grid className='ml-4 mt-2'>
            <StyledOptionButton
              variant='contained'
              className={
                (
                  localStorage.hasOwnProperty('slideout')
                    ? localStorage.getItem('slideout') === ''
                    : criteria.slideout === ''
                )
                  ? classes.btnColor
                  : classes.styledBtn
              }
              color='primary'
              size='large'
              value=''
              onClick={(e) => {
                criteria.setPage(1);
                localStorage.setItem('slideout', e.target.value);
                criteria.handleSlideOut(e.target.value);
              }}
            >
              All
            </StyledOptionButton>
          </Grid>
          {vehicleOption.slideout?.map((slideout) => (
            <Grid className='ml-4 mt-2'>
              <StyledOptionButton
                variant='contained'
                className={
                  slideout.slideouts === localStorage.getItem('slideout')
                    ? classes.btnColor
                    : classes.styledBtn
                }
                color='primary'
                size='large'
                value={slideout.slideouts}
                onClick={(e) => {
                  criteria.setPage(1);
                  localStorage.setItem('slideout', e.target.value);
                  criteria.handleSlideOut(e.target.value);
                }}
              >
                {slideout.slideouts} ({slideout.count})
              </StyledOptionButton>
            </Grid>
          ))}
        </Grid>
      </StyledFilterGrid>
    </TabPanel>
  );
};

export default SlideOutsFilter;