/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PREFIX = 'FeaturePanel';

const classes = {
    background: `${PREFIX}-background`,
    content: `${PREFIX}-content`,
    backgroundImg: `${PREFIX}-img`,
    imageHeight: `${PREFIX}-imgHeight`,
    welcomeContent: `${PREFIX}-welcomeContent`,
    card: `${PREFIX}-card`,
    cardTop: `${PREFIX}-cardTop`,
    header: `${PREFIX}-header`,
    border: `${PREFIX}-border`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        position: 'relative',
        background: theme.palette.background.light,
        [theme.breakpoints.up('xs')]: {
            marginTop: 20
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 35,
        },
        [`& .${classes.backgroundImg}`]: {
            position: 'absolute',
            color: theme.palette.background.default,
            top: 40,
            right: 50,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
        },
        [`& .${classes.imageHeight}`]: {
            height: 450
        },
        [`& .${classes.welcomeContent}`]: {
            background: theme.palette.background.default,
            [theme.breakpoints.up('xs')]: {
                padding: '0 10%',
            },
            [theme.breakpoints.up('sm')]: {
                padding: 0,
            },
            [theme.breakpoints.up('xl')]: {
                paddingLeft: 20,
            },
        },
        [`& .${classes.heading}`]: {
            color: theme.palette.text.heading
        },
        [`& .${classes.card}`]: {
            background: theme.palette.background.default,
            boxShadow: '0px 15px 19px rgb(33 62 152 / 15%)',
            borderRadius: 8,
            padding: '15px 0 15px 15px',
            marginLeft: 15,
            marginTop: 15,
            textAlign: 'center',
            [theme.breakpoints.up('xs')]: {
                float: 'center'
            },
            [theme.breakpoints.up('sm')]: {
                float: 'left'
            },
        },
        [`& .${classes.cardTop}`]: {
            marginTop: 70
        },
        [`& .${classes.header}`]: {
            color: theme.palette.primary.main
        },
        [`& .${classes.border}`]: {
            width: 120,
            borderTop: `3px solid ${theme.palette.secondary.main}`,
            [theme.breakpoints.up('xs')]: {
                margin: '5px 27% 10px',
            },
            [theme.breakpoints.up('sm')]: {
                margin: '5px 18% 10px',
            },
        },
    }
});

const FeaturePanel = () => {
    return (
        <StyledGrid container>
            <Grid
                item
                xs={12}
                className={classes.welcomeContent}
            >
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h2" className={classes.header}>
                            JOIN
                        </Typography>
                        <hr className={classes.border} />
                        <Typography variant='h5' color="gray">
                            List your inventory for free!
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h2" className={classes.header}>
                            BUY
                        </Typography>
                        <hr className={classes.border} />
                        <Typography variant='h5' color="gray">
                            Frictionless transactions.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h2" className={classes.header}>
                            SELL
                        </Typography>
                        <hr className={classes.border} />
                        <Typography variant='h5' color="gray">
                            Direct B2B interactions.
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h2" className={classes.header}>
                            AUCTION
                        </Typography>
                        <hr className={classes.border} />
                        <Typography variant='h5' color="gray">
                            100% Online platform.
                        </Typography>
                    </CardContent>
                </Card>

                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h2" className={classes.header}>
                            TRADE
                        </Typography>
                        <hr className={classes.border} />
                        <Typography variant='h5' color="gray">
                            Keep your inventory moving
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </StyledGrid>
    )
}

export default FeaturePanel;