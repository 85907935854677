import InputBox from "./InputBox";
import Select from "./Select";

export default function({label, className, type, items, ...props}) {
  return (
    <div className={`${className} col-span-12 mt-8`}>
      <div className='col-span-12'>
        <label>{label}</label>
      </div>

      <div className='col-span-12'>
        {type !== 'select' && (
          <InputBox
            type={type}
            {...props}
          />
        )}

        {type === 'select' && (
          <Select
            items={items}
            {...props}
          />
        )}
      </div>
    </div>
  )
}