/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import { vehicleSearch } from 'data/vehicle.actions';
import Spinner from 'components/Spinner';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import VehiclePanel from "./components/VehiclePanel";
import Search from "components/Search/index";
import Sort from "components/Sort/index";
import VehicleDetailsDialog from "../dialogs/VehicleDetailsDialog";
import LikeDialog from 'components/dialogs/LikeDialog';
import { hasRooftopPermission } from 'utils/User';

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        padding: '0 60px',
        [theme.breakpoints.down('md')]: {
            padding: '0 0px',
        },
    }
});

const SearchView = ({ resultType }) => {
    const dispatch = useDispatch();
    const [mergeArray, setMergeArray] = useState(false);
    const [isSearchResult, setIsSearchResult] = useState(false);
    const [page, setPage] = useState(1);
    const [vehSearch, setSearch] = useState("");
    const [vehcategory, setCategory] = useState(localStorage.hasOwnProperty('category') ? localStorage.getItem('category') : 'Auto');
    const [minDis, setMinDist] = useState(null);
    const [maxDis, setMaxDist] = useState(null);
    const [used, setUsed] = useState("");
    const [fuelType, setFuelType] = useState("");
    const [vehMake, setMake] = useState("");
    const [vehModel, setModel] = useState("");
    const [vehTrim, setTrim] = useState("");
    const [vehDealer, setDealer] = useState("");
    const [rooftops, setRooftops] = useState("");
    const [minWeight, setMinWeight] = useState("ANY");
    const [maxWeight, setMaxWeight] = useState("ANY");
    const [minYear, setMinYear] = useState(null);
    const [maxYear, setMaxYear] = useState(null);
    const [minMileage, setMinMileage] = useState(null);
    const [maxMileage, setMaxMileage] = useState(null);
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [minKbbPrice, setMinKbbPrice] = useState(null);
    const [maxKbbPrice, setMaxKbbPrice] = useState(null);
    const [minLength, setMinLength] = useState("ANY");
    const [maxLength, setMaxLength] = useState("ANY");
    const [slideOut, setSlideOut] = useState("");
    const [sleeps, setSleeps] = useState("");
    const [sort, setSort] = useState('lowest_price');
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState()
    const [defaultMinDis, setDefaultMinDis] = useState(null);
    const [defaultMaxDis, setDefaultMaxDis] = useState(null);
    const [defaultMinYear, setDefaultMinYear] = useState(null);
    const [defaultMaxYear, setDefaultMaxYear] = useState(null);
    const [defaultMinPrice, setDefaultMinPrice] = useState(null);
    const [defaultMaxPrice, setDefaultMaxPrice] = useState(null);
    const [defaultMinKbbPrice, setDefaultMinKbbPrice] = useState(null);
    const [defaultMaxKbbPrice, setDefaultMaxKbbPrice] = useState(null);
    const [defaultMinMileage, setDefaultMinMileage] = useState(null);
    const [defaultMaxMileage, setDefaultMaxMileage] = useState(null);
    const [defaultMinLength, setDefaultMinLength] = useState(null);
    const [defaultMaxLength, setDefaultMaxLength] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState('distance');
    const [showLikesDialog, setShowLikesDialog] = useState(null);
    const [minAge, setMinAge] = useState(null);
    const [maxAge, setMaxAge] = useState(null)
    const [defaultMinAge, setDefaultMinAge] = useState(null);
    const [defaultMaxAge, setDefaultMaxAge] = useState(null);
    
    const { search, authentication, auction: { activeAuctionCountLoading } } = useSelector(state => state);
    const { user } = authentication;
    const {
        count,
        vehicles,
        loadingSearchOptions,
        loadingCategoryCount,
        loadingModelOptions,
        searching,
        vehicleOption
    } = search;

    const fetchItems = useCallback(
        async () => {
            if (fetching) {
                return;
            }
            setMergeArray(true);
            try {
                if (count >= page * 20) {
                    setPage(page + 1);
                    setFetching(true);
                }
            } finally {
                setFetching(false);
            }
        },
        [fetching, count, page]
    );

    useEffect(() => {
        localStorage.clear();
    }, [resultType]);

    useEffect(() => {
        if (user) {
            const dealer = user.dealer_id;
            const rooftop = user.rooftop_id;

            let data = {
                page: page ? page : null,
                sort: localStorage.hasOwnProperty('sort') ? localStorage.getItem('sort') : sort,
            };

            data.search = localStorage.hasOwnProperty('search') ? localStorage.getItem('search') : vehSearch;
            data.distancemin = localStorage.hasOwnProperty('minDis') ? localStorage.getItem('minDis') : minDis;
            data.distancemax = localStorage.hasOwnProperty('maxDis') ? localStorage.getItem('maxDis') : maxDis;
            data.yearmax = localStorage.hasOwnProperty('maxYear') ? localStorage.getItem('maxYear') : maxYear;
            data.yearmin = localStorage.hasOwnProperty('minYear') ? localStorage.getItem('minYear') : minYear;
            data.pricemin = localStorage.hasOwnProperty('minPrice') ? localStorage.getItem('minPrice') : minPrice;
            data.pricemax = localStorage.hasOwnProperty('maxPrice') ? localStorage.getItem('maxPrice') : maxPrice;
            data.minmileage = localStorage.hasOwnProperty('minMileage') ? localStorage.getItem('minMileage') : minMileage;
            data.maxmileage = localStorage.hasOwnProperty('maxMileage') ? localStorage.getItem('maxMileage') : maxMileage;
            data.kbbmin = localStorage.hasOwnProperty('minKBBPrice') ? localStorage.getItem('minKBBPrice') : minKbbPrice;
            data.kbbmax = localStorage.hasOwnProperty('maxKBBPrice') ? localStorage.getItem('maxKBBPrice') : maxKbbPrice;
            data.vehcategory = localStorage.hasOwnProperty('category') ? localStorage.getItem('category') : vehcategory;
            data.make = localStorage.hasOwnProperty('makes') ? localStorage.getItem('makes') : vehMake;
            data.dealers = localStorage.hasOwnProperty('vehDealers') ? localStorage.getItem('vehDealers') : vehDealer;
            data.rooftops = localStorage.hasOwnProperty('rooftops') ? localStorage.getItem('rooftops') : rooftops;
            data.model = localStorage.hasOwnProperty('models') ? localStorage.getItem('models') : vehModel;
            data.trim = localStorage.hasOwnProperty('trims') ? localStorage.getItem('trims') : vehTrim;
            data.fueltype = localStorage.hasOwnProperty('vehFuelTypes') ? localStorage.getItem('vehFuelTypes') : fuelType;
            data.minweight = localStorage.hasOwnProperty('minWeight') ? localStorage.getItem('minWeight') : minWeight;
            data.maxweight = localStorage.hasOwnProperty('maxWeight') ? localStorage.getItem('maxWeight') : maxWeight;
            data.minlength = localStorage.hasOwnProperty('minLength') ? localStorage.getItem('minLength') : minLength;
            data.maxlength = localStorage.hasOwnProperty('maxLength') ? localStorage.getItem('maxLength') : maxLength;
            data.sleeps = localStorage.hasOwnProperty('sleeps') ? localStorage.getItem('sleeps') : sleeps;
            data.slideouts = localStorage.hasOwnProperty('slideout') ? localStorage.getItem('slideout') : slideOut;
            data.newused = localStorage.hasOwnProperty('used') ? localStorage.getItem('used') : used;
            data.dealer = dealer;
            data.resultType = resultType;
            data.mergeArray = mergeArray;
            data.rooftop = rooftop;
            data.internalOnly = !hasRooftopPermission(user, parseInt(rooftop), 'purchase_vehicle_from_any_rooftop_owned_by_a_different_dealer') && resultType !== 'sellPage';
            data.unpermittedSell = !hasRooftopPermission(user, parseInt(rooftop), 'sell_vehicle') && resultType === 'sellPage';
            data.agemax = localStorage.hasOwnProperty('maxAge') ? localStorage.getItem('maxAge') : maxAge;
            data.agemin = localStorage.hasOwnProperty('minAge') ? localStorage.getItem('minAge') : minAge;
            dispatch(vehicleSearch(data));
        }
    }, [
        dispatch,
        page,
        user,
        isSearchResult,
        minDis,
        maxDis,
        used,
        fuelType,
        vehMake,
        vehModel,
        vehTrim,
        vehDealer,
        rooftops,
        minWeight,
        maxWeight,
        maxYear,
        minYear,
        minMileage,
        maxMileage,
        minPrice,
        maxPrice,
        minKbbPrice,
        maxKbbPrice,
        sort,
        minLength,
        maxLength,
        sleeps,
        slideOut,
        vehcategory,
        vehSearch,
        resultType,
        loading,
        user?.rooftop_id,
        maxAge,
        minAge
    ]);


    const handleCategory = (value) => {
        setMergeArray(false);
        setCategory(value);
    }

    const handleSearchChange = (value) => {
        setMergeArray(false);
        setSearch(value);
    }

    const handleMaxDistance = (value) => {
        setMergeArray(false);
        setMaxDist(value);
    }

    const handleMinDistance = (value) => {
        setMergeArray(false);
        setMinDist(value);
    }

    const handleUsed = (value) => {
        setMergeArray(false);
        setUsed(value);
    }

    const handleFuelType = (value) => {
        setMergeArray(false);
        setFuelType(value);
    }

    const handleMake = (value) => {
        setMergeArray(false);
        setMake(value);
    }

    const handleModel = (value) => {
        setMergeArray(false);
        setModel(value);
    }

    const handleTrim = (value) => {
        setMergeArray(false);
        setTrim(value);
    }

    const handleDealer = (value) => {
        setMergeArray(false);
        setDealer(value);
    }

    const handleRooftop = (value) => {
        setMergeArray(false);
        setRooftops(value);
    }

    const handleMaxWeight = (value) => {
        setMergeArray(false);
        setMaxWeight(value);
    }

    const handleMinWeight = (value) => {
        setMergeArray(false);
        setMinWeight(value);
    }

    const handleMaxYear = (value) => {
        setMergeArray(false);
        setMaxYear(value);
    }

    const handleMinYear = (value) => {
        setMergeArray(false);
        setMinYear(value);
    }

    const handleMaxMileage = (value) => {
        setMergeArray(false);
        setMaxMileage(value);
    }

    const handleMinMileage = (value) => {
        setMergeArray(false);
        setMinMileage(value);
    }

    const handleMaxPrice = (value) => {
        setMergeArray(false);
        setMaxPrice(value);
    }

    const handleMinPrice = (value) => {
        setMergeArray(false);
        setMinPrice(value);
    }

    const handleMaxKbbPrice = (value) => {
        setMergeArray(false);
        setMaxKbbPrice(value);
    }

    const handleMinKbbPrice = (value) => {
        setMergeArray(false);
        setMinKbbPrice(value);
    }

    const handleMaxLength = (value) => {
        setMergeArray(false);
        setMaxLength(value);
    }

    const handleMinLength = (value) => {
        setMergeArray(false);
        setMinLength(value);
    }

    const handleSlideOut = (value) => {
        setMergeArray(false);
        setSlideOut(value);
    }

    const handleSleeps = (value) => {
        setMergeArray(false);
        setSleeps(value);
    }

    const handleSearch = () => {
        setIsSearchResult(!isSearchResult);
    }

    const handleMaxAge = (value) => {
        setMergeArray(false);
        setMaxAge(value);
    }

    const handleMinAge = (value) => {
        setMergeArray(false);
        setMinAge(value);
    }

    const handleReset = () => {
        setIsSearchResult(!isSearchResult);
        setSearch("");
        localStorage.setItem('category', criteria.vehcategory);
        localStorage.setItem('search', "");
        localStorage.setItem('minDis', null);
        localStorage.setItem('maxDis', null);
        localStorage.setItem('used', "");
        localStorage.setItem('vehFuelTypes', "");
        localStorage.setItem('makes', "");
        localStorage.setItem('models', "");
        localStorage.setItem('trims', "");
        localStorage.setItem('vehDealers', "");
        localStorage.setItem('rooftops', "");
        localStorage.setItem('minWeight', "ANY");
        localStorage.setItem('maxWeight', "ANY");
        localStorage.setItem('minYear', null);
        localStorage.setItem('maxYear', null);
        localStorage.setItem('minMileage', null);
        localStorage.setItem('maxMileage', null);
        localStorage.setItem('minPrice', null);
        localStorage.setItem('maxPrice', null);
        localStorage.setItem('minKBBPrice', null);
        localStorage.setItem('maxKBBPrice', null);
        localStorage.setItem('minLength', "ANY");
        localStorage.setItem('maxLength', "ANY");
        localStorage.setItem('slideout', "");
        localStorage.setItem('sleeps', "");
        localStorage.setItem('sort', 'lowest_price');
        localStorage.setItem('minAge', null);
        localStorage.setItem('maxAge', null);
        setDefaultMinDis(Math.floor(vehicleOption.distance?.[vehicleOption.distance?.length - 1]?.distance));
        setDefaultMaxDis(Math.floor(vehicleOption.distance?.[0]?.distance));
        setDefaultMinYear(vehicleOption.year?.[vehicleOption.year?.length - 1]?.year);
        setDefaultMaxYear(vehicleOption.year?.[0]?.year);
        setDefaultMinPrice(vehicleOption.price?.[vehicleOption.price?.length - 1]?.price);
        setDefaultMaxPrice(vehicleOption.price?.[0]?.price);
        setDefaultMinKbbPrice(vehicleOption.kbbPrice?.[vehicleOption.kbbPrice?.length - 1]?.kbb_auction_value !== null
            ?
            vehicleOption.kbbPrice?.[vehicleOption.kbbPrice?.length - 1]?.kbb_auction_value
            :
            0);
        setDefaultMaxKbbPrice(vehicleOption.kbbPrice?.[0]?.kbb_auction_value);
        setDefaultMinMileage(0);
        setDefaultMaxMileage(1000000);
        setDefaultMinLength(0);
        setDefaultMaxLength(100);
        setDefaultMinAge(vehicleOption.age?.[vehicleOption.age?.length - 1]?.age);
        setDefaultMaxAge(vehicleOption.age?.[0]?.age);

        if (resultType === 'sellPage') {
            setSelectedFilter('year')
        } else {
            setSelectedFilter('distance');
        }
    }

    const criteria = {
        vehcategory,
        handleCategory,
        vehSearch,
        handleSearchChange,
        handleSearch,
        handleReset,
        minDis,
        handleMinDistance,
        maxDis,
        handleMaxDistance,
        used,
        handleUsed,
        fuelType,
        handleFuelType,
        vehMake,
        handleMake,
        vehModel,
        handleModel,
        vehTrim,
        handleTrim,
        vehDealer,
        rooftops,
        handleDealer,
        handleRooftop,
        minWeight,
        handleMinWeight,
        maxWeight,
        handleMaxWeight,
        minYear,
        handleMinYear,
        maxYear,
        handleMaxYear,
        minMileage,
        handleMinMileage,
        maxMileage,
        handleMaxMileage,
        minPrice,
        handleMinPrice,
        maxPrice,
        handleMaxPrice,
        minKbbPrice,
        handleMinKbbPrice,
        maxKbbPrice,
        handleMaxKbbPrice,
        minLength,
        handleMinLength,
        maxLength,
        handleMaxLength,
        slideOut,
        handleSlideOut,
        sleeps,
        handleSleeps,
        setPage,
        setMergeArray,
        defaultMinDis,
        setDefaultMinDis,
        defaultMaxDis,
        setDefaultMaxDis,
        defaultMinYear,
        setDefaultMinYear,
        defaultMaxYear,
        setDefaultMaxYear,
        defaultMinPrice,
        setDefaultMinPrice,
        defaultMaxPrice,
        setDefaultMaxPrice,
        defaultMinKbbPrice,
        setDefaultMinKbbPrice,
        defaultMaxKbbPrice,
        setDefaultMaxKbbPrice,
        defaultMinMileage,
        setDefaultMinMileage,
        defaultMaxMileage,
        setDefaultMaxMileage,
        defaultMinLength,
        setDefaultMinLength,
        defaultMaxLength,
        setDefaultMaxLength,
        selectedFilter,
        setSelectedFilter,
        minAge,
        handleMinAge,
        maxAge,
        handleMaxAge,
        defaultMinAge,
        setDefaultMinAge,
        defaultMaxAge,
        setDefaultMaxAge,
    }

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Search
                    criteria={criteria}
                    resultType={resultType}
                />
                {count > 0 && (
                    <Typography variant="h6" color='primary'>Found {count} Vehicle{count > 1 ? 's' : ''}</Typography>
                )}
                <Sort
                    sort={sort}
                    setSort={setSort}
                    resultType={'searchPanel'}
                />
                <InfiniteScroll
                    dataLength={20 * page} //This is important field to render the next data
                    next={fetchItems}
                    scrollThreshold={1}
                    hasMore={true}
                    loader={20 * page <= count ? <h4>Loading...</h4> : ''}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You have seen it all</b>
                        </p>
                    }
                >
                    {vehicles && vehicles?.map((vehicle) => {
                        return (
                            <VehiclePanel
                                key={vehicle.id}
                                vehicle={vehicle}
                                resultType={resultType}
                                setLoading={setLoading}
                                showDetailsHandler={() => setSelectedVehicle(vehicle)}
                                showLikeDialog={() => setShowLikesDialog(vehicle?.token)}
                            />
                        );
                     })
                    }
                </InfiniteScroll>

                {/*{error !== undefined ? <div>search error: {error}</div> : (count === 0 ? <div>Record Not Found</div> : '')}*/}
            </Grid>

            <VehicleDetailsDialog
                isOpen={selectedVehicle !== undefined}
                handleClose={() => setSelectedVehicle(undefined)}
                vehicleToken={selectedVehicle?.token}
            />

            <LikeDialog 
            isOpen={showLikesDialog !== null}
            handleClose={() => setShowLikesDialog(null)}
            vehicleToken={showLikesDialog}
            />

            <Spinner show={
                loadingSearchOptions ||
                searching ||
                loadingCategoryCount ||
                loadingModelOptions ||
                activeAuctionCountLoading
            } />


        </StyledGrid>
    )
}

export default SearchView;