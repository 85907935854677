import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
      <Grid item xs={6} className="w-max">
        <div className="wrapper">
          {breadcrumbs?.map((crumb, i) => {
            if (crumb?.type === "link") {
              return (
                <div className={i === 0 ? `wrapper1` : "wrapper2"}>
                  <Link className={crumb?.className} to={crumb?.to}>
                    {crumb?.label}
                  </Link>
                </div>
              );
            } else if (crumb?.type === "button") {
              return (
                <div className={i === 0 ? `wrapper1` : "wrapper2"}>
                  <button className={crumb?.className} onClick={crumb?.callback}>
                    {crumb?.label}
                  </button>
                </div>
              );
            } else if (crumb?.type === "span") {
              return (
                <div className="wrapper3">
                <div className={crumb?.className}>{crumb?.label}</div>
                 </div>
              );
            }
          })}
        </div>
      </Grid>
  );
};

export default Breadcrumbs;
