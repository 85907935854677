import React from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import { useSelector } from 'react-redux';

const YearFilter = ({ criteria }) => {
  const {
    search: { vehicleOption },
  } = useSelector((state) => state);

  return (
    <TabPanel value='year'>
      <RangeSlider
        minValue={vehicleOption.year?.[vehicleOption.year?.length - 1]?.year}
        maxValue={vehicleOption.year?.[0]?.year}
        defaultValue={[criteria.defaultMinYear, criteria.defaultMaxYear]}
        labelText={''}
        handleChange={(value) => {
          const [min, max] = value;
          criteria.setPage(1);
          localStorage.setItem('minYear', min);
          localStorage.setItem('maxYear', max);
          criteria.handleMinYear(min);
          criteria.handleMaxYear(max);
          criteria.setDefaultMinYear(null);
          criteria.setDefaultMaxYear(null);
        }}
        disabled={true}
      />
    </TabPanel>
  );
};

export default YearFilter;