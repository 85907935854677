import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatDateTime } from "utils/Formatters";
import ReviewConfirmationDialog from "components/dialogs/ReviewConfirmationDialog";
import { ToastContainer } from "react-toastify";

const BuyerAcceptancePanel = ({ classes, transaction, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { buyerId, reviewDate, sellerId } = transaction || {};

  return (
    <Grid container>
      {reviewDate && (
        <Grid item xs={12}>
          <CheckCircleIcon className={classes.completed} />
          <Typography variant='body'>Accepted Vehicle</Typography>
          <Typography variant='body' className={classes.actionDate}>{formatDateTime(reviewDate)}</Typography>
        </Grid>
      )}

      {(buyerId === id && !reviewDate) && (
        <Grid item xs={12}>
          <ScheduleIcon className={classes.scheduled} />
          <Link onClick={() => setIsOpen(true)}><Typography variant='body'>Accept Vehicle</Typography></Link>
          <ReviewConfirmationDialog
            open={isOpen}
            handleClose={() => setIsOpen(false)}
            transaction={transaction}
          />
        </Grid>
      )}

      {(sellerId === id && !reviewDate) &&
        (<Grid item xs={12}>
          <ScheduleIcon className={classes.scheduled} />
          <Typography variant='body'>Accepted Vehicle</Typography>
        </Grid>)}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Grid>
  )
}

export default BuyerAcceptancePanel;