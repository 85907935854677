/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatDateTime } from "utils/Formatters";
import { generateSignLink } from "data/transaction.actions";
import InfoPopover from "components/widgets/InfoPopover";

const SertifiSigning = ({ classes, transaction, userId, date, userEmail, text, id, sellerSignDate, resultType }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { transaction: { signingLink } } = useSelector(state => state);
    const {
        folderId,
        documentId,
        paymentId,
    } = transaction || {};

    const generateNewSignLink = (documentId, documentFolderId, paymentId, email) => {
        const data = {
            documentId: documentId,
            folderId: documentFolderId,
            paymentId: paymentId,
            userEmail: email
        };
        dispatch(generateSignLink(data));
        setIsLoading(true);
    }

    useEffect(() => {
        if (signingLink !== "" && isLoading) {
            window.open(signingLink, '_blank');
            setIsLoading(false);
        }
    }, [signingLink, isLoading]);

    console.log(resultType);
    return (
        <>
            {date && (
                <CheckCircleIcon className={classes.completed} />
            )}

            {!date && (
                <ScheduleIcon className={classes.scheduled} />
            )}

            <Typography variant='body'>{text}</Typography>

            {date && (
                <Typography variant='body' className={classes.actionDate}>{formatDateTime(date)}</Typography>
            )}

            {(!date && (userId === id)) ?
                ((folderId && paymentId) ?
                    resultType === "seller" ?
                        <Typography variant='body' className={classes.action} onClick={() => generateNewSignLink(documentId, folderId, paymentId, userEmail)}>Sign</Typography>
                        : (resultType === "buyer" && sellerSignDate) && <Typography variant='body' className={classes.action} onClick={() => generateNewSignLink(documentId, folderId, paymentId, userEmail)}>Sign</Typography>
                    : <Typography variant='body' className={classes.action}><InfoPopover info="Please contact to the reinvent teams." text="N/A" /></Typography>)
                : ''}
        </>
    )
}

export default SertifiSigning;