import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScheduleIcon from "@mui/icons-material/Schedule";
import { createTransportation } from "data/transaction.actions";
import { toast } from "react-toastify";
import { useEffect } from "react";

const TransportationPanel = ({ classes, transaction, id, rooftop_id }) => {
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const { transaction: { error, processing } } = useSelector(state => state);
  const { buyerId, dealerRooftopId, vin, orderStatus } = transaction;

  const handleCreateTransporation = (dealerRooftopId, vin) => {
    const data = {
      buyerRooftopId: Number(rooftop_id),
      sellerRooftopId: dealerRooftopId,
      vin: vin
    };
    dispatch(createTransportation(data));
    setIsError(true);
  }

  useEffect(() => {
    if (!processing && isError && !error) {
      toast.success('Transporation Order Created Successfully!!');
    } else if (error) {
      toast.error(error);
      setIsError(false);
    }
  }, [isError, error]);

  return (
    <Grid container>
      {(orderStatus?.length > 0) ?
        orderStatus.map((order) => (<Grid item xs={12}>
          <CheckCircleIcon className={classes.completed} />
          <Typography variant='body'>{order.name}</Typography>
          <Typography variant='body' className={classes.actionDate}>{order.created_date}</Typography>
        </Grid>))
        :
        <Grid item xs={12}>
          {(buyerId === id) && (
            <>
              <ScheduleIcon className={classes.scheduled} />
              <Link onClick={() => handleCreateTransporation(dealerRooftopId, vin)}><Typography variant='body'>Create Transportation</Typography></Link>
            </>
          )}
        </Grid>
      }

    </Grid>
  )
}

export default TransportationPanel;
