import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import Image from 'components/widgets/Image';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import theme from 'styles/theme';
import { formatDateTime } from 'utils/Formatters';
import Button from "components/widgets/Button";
import PlaceAuctionDialog from 'components/dialogs/PlaceAuctionDialog';
import ConditionReportDialog from "components/dialogs/ConditionReportDialog";
import MakeOfferDialog from 'components/dialogs/MakeOfferDialog';
import PlaceBidDialog from "components/dialogs/PlaceBidDialog";
import DetailsPanel from './DetailPanel';
import RooftopDetail from './RooftopDetails';
import { getReinventShippingOption } from 'data/vehicle.actions';
import styled from '@emotion/styled';
import ConfirmSaleDialog from "../../dialogs/ConfirmSaleDialog";
import { formatDollarAmount } from 'utils/Formatters';
import MessageDialog from 'components/dialogs/MessageDialog';
import { hasRooftopPermission } from 'utils/User';
import classNames from 'classnames';

const PREFIX = 'SidePanel';

const classes = {
  price: `${PREFIX}-price`,
  basic: `${PREFIX}-basic`,
  basicCard: `${PREFIX}-basicCard`,
  basicContent: `${PREFIX}-basicContent`,
  flex: `${PREFIX}-flex`,
  buybtn: `${PREFIX}-buy-btn`,
  makebtn: `${PREFIX}-make-btn`,
  vehicleDetail: `${PREFIX}-vehicleDetail`,
  vehicleBox: `${PREFIX}-vehicleBox`,
  vehicleTypo: `${PREFIX}-vehicleTypo`,
  detailbtn: `${PREFIX}-detailbtn`,
  sold: `${PREFIX}-sold`,
  btnDisabled: `${PREFIX}-btn-disabled`,
  detailbtnDisabled: `${PREFIX}-detil-btn-disabled`,
}

const StyledGrid = styled(Grid)(({
  theme
}) => {
  return {
    [`& .${classes.price}`]: {
      background: theme.palette.text.heading,
      color: theme.palette.background.default,
      width: "145px",
      height: '65px',
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 9
    },
    [`& .${classes.sold}`]: {
      background: theme.palette.text.completed,
      color: theme.palette.background.default,
      width: "155px",
      height: '65px',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 9
    },
    [`& .${classes.basicCard}`]: {
      background: theme.palette.background.btn
    },
    [`& .${classes.basic}`]: {
      display: 'flex',
      position: 'relative'
    },
    [`& .${classes.vehicleDetail}`]: {
      borderBottom: `1px solid ${theme.palette.border.default}`,
      padding: 0
    },
    [`& .${classes.vehicleBox}`]: {
      borderLeft: `4px solid ${theme.palette.secondary.main}`,
    },
    [`& .${classes.vehicleTypo}`]: {
      margin: '15px 0px 15px 15px'
    },
    [`& .${classes.basicContent}`]: {
      borderBottom: `1px solid ${theme.palette.border.default}`,
    },
    [`& .${classes.flex}`]: {
      position: 'absolute',
      right: 0
    },
    [`& .${classes.buybtn}`]: {
      background: theme.palette.primary.main,
      borderRadius: 7,
      marginTop: 10,
      width: '100%',
      textTransform: 'capitalize'
    },
    [`& .${classes.detailbtn}`]: {
      background: theme.palette.primary.main,
      borderRadius: 7,
      height: 66,
      marginLeft: 15,
      width: '100%',
      textTransform: 'capitalize',
      fontSize: 20
    },
    [`& .${classes.makebtn}`]: {
      borderRadius: 7,
      background: 'rgba(33, 62, 152, 0.2)',
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginTop: 10,
      width: '100%',
      textTransform: 'capitalize'
    },
    [`& .${classes.btnDisabled}`]: {
      opacity: .40,
    },
    [`& .${classes.detailbtnDisabled}`]: {
      color: 'white'
    },
  }
});

const SidePanel = ({ vehicle, myDealer, handleConditionReportChanged, setLoading }) => {
  const dispatch = useDispatch();
  const [showAuction, setShowAuction] = useState(false);
  const [showConditionReport, setShowConditionReport] = useState(false);
  const [isMakeOfferOpen, setIsMakeOfferOpen] = useState(false);
  const [bidOpen, setBidOpen] = useState(false);
  const [showAuctionSelected, setShowAuctionSelected] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [userCanSell, setUserCanSell] = useState(false);

  const { search: { quoteOptions, transactionStatus: { transaction } }, authentication: { user } } = useSelector(state => state);

  const getShipping = (vehicle) => {
    const data = {
      buyerRooftopId: Number(user.rooftop_id),
      sellerRooftopId: vehicle?.dealer_rooftop_id,
      vin: vehicle?.vin,
      inventoryId: vehicle?.token
    }
    dispatch(getReinventShippingOption(data));
  }

  const getMakeButton = (vehicle) => {
    if (vehicle?.mine === 0 && (vehicle?.status === 'Available' || (vehicle?.status === 'On Auction' && vehicle?.last_bid_amount < vehicle?.reserve_price))) {
      return <Button
        className={classes.makebtn}
        onClick={() => {
          setIsMakeOfferOpen(true);
          getShipping(vehicle);
        }}
      >
        Make an Offer
      </Button>
    }
  }

  useEffect( () => {
    setUserCanSell(hasRooftopPermission(user, vehicle?.dealer_rooftop_id, 'sell_vehicle'));
  }, [user, vehicle]);

  return (
    <StyledGrid container className='mt-2'>
      <Grid item xs={5}>
        {vehicle?.sold == 0 &&
        <Box className={`${classes.price}`}>
          <Typography variant='h5'>{formatDollarAmount(vehicle?.price)}</Typography>
          <Typography variant='subtitle'>{vehicle?.mine === 1 ? 'Sell Price' : 'Retail Price'}</Typography>
        </Box>
        }
        {vehicle?.sold == 1 &&
            <Box className={`${classes.price}`}>
              <Typography variant='h5'>{formatDollarAmount(vehicle?.sold_price)}</Typography>
              <Typography variant='subtitle'>{vehicle?.mine === 1 ? 'Sold' : 'Purchased'}</Typography>
            </Box>
        }
      </Grid>
      {vehicle?.mine === 1 ?
        ((vehicle?.auction === 0 || (vehicle?.auction === 1 && vehicle?.auction_ended === 1)) && (vehicle?.auction_status === null || !['Active', 'Expiring', 'Sold', 'Pending Sale', 'Pending Transaction'].includes(vehicle?.auction_status))) && 'Available' === vehicle?.status ? (
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classNames(classes.detailbtn, {[classes.btnDisabled] : !userCanSell}, {[classes.detailbtnDisabled] : !userCanSell})}
              onClick={() => {
                if (vehicle.condition_report_date) {
                  setShowAuction(true)
                }
                else {
                  setShowConditionReport(true);
                  setShowAuctionSelected(true);
                }
              }}
              disabled={
                !userCanSell
              }
            >
              Start Auction
            </Button>
          </Grid>
        ) : ['Active', 'Expiring'].includes(vehicle?.auction_status) ? (
          <Grid item xs={6} style={{paddingLeft: 10}}>
            <Box className={`${classes.sold}`}>
              <Typography variant='h3'>On Auction</Typography>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            <Box className={`${classes.sold}`}>
              <Typography variant='h3'>{vehicle?.status}</Typography>
            </Box>
          </Grid>
        ) : ''
      }
      {vehicle?.mine === 0 &&
        <Grid item xs={6}>
          <Box className="flex">
            <Box className="ml-5">
              <Image
                src="/static/images/Favourite.png"
              />
            </Box>
            <Box className="ml-5">
              <button type='button' onClick={() => setIsMessageDialogOpen(true)}>
                <Image
                  src="/static/images/message.png"
                />
              </button>
            </Box>
          </Box>
        </Grid>}

      {vehicle?.mine === 1 && transaction &&
        <>
          <Grid item xs={12}>
            <Card className={`mt-4 ${classes.basicCard}`}>
              <CardContent className={classes.vehicleDetail}>
                <Box className={classes.vehicleBox}>
                  <Typography variant='h5' className={classes.vehicleTypo}>SOLD TO</Typography>
                </Box>
              </CardContent>
              <CardContent className={classes.vehicleDetail}>
                <Box className='ml-6 mt-3'>
                  <Typography variant='h5'>{transaction?.buyer_dealer}</Typography>
                  <Typography variant='h6' color={theme.palette.text.grey}>{transaction?.buyer_address} {transaction?.buyer_location}</Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={`mt-4 ${classes.basicCard}`}>
              <CardContent className={classes.vehicleDetail}>
                <Box className={classes.vehicleBox}>
                  <Typography variant='h5' className={classes.vehicleTypo}>TRANSACTION DETAILS</Typography>
                </Box>
              </CardContent>
              <CardContent>
                <Box className={classes.basic}>
                  <Box>
                    <Typography variant='h7' color={theme.palette.text.grey}>Type :</Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography variant='h7'>{transaction?.transaction_type_name}</Typography>
                  </Box>
                </Box>
                <Box className={classes.basic}>
                  <Box>
                    <Typography variant='h7' color={theme.palette.text.grey}>Signatures Started :</Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography variant='h7'>{formatDateTime(transaction?.start_date)}</Typography>
                  </Box>
                </Box>
                <Box className={classes.basic}>
                  <Box>
                    <Typography variant='h7' color={theme.palette.text.grey}>Signatures Completed :</Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography variant='h7'>{transaction?.completed_date ? formatDateTime(transaction?.completed_date) : 'Pending'}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </>}
      <Grid item xs={12}>
        <Card className={`mt-9 ${classes.basicCard}`}>
          {vehicle?.vertical === 'Auto' &&
            <CardContent className={classes.basicContent}>
              <Box className={classes.basic}>
                <Box>
                  <Typography variant='h2' color={theme.palette.primary.main}>${vehicle?.blackbook_wholesale_value ? vehicle?.blackbook_wholesale_value.toLocaleString() : 0}</Typography>
                  <Typography variant='h7' color={theme.palette.text.grey}>Blackbook Wholesale Value</Typography>
                </Box>
                <Box className='ml-10'>
                  <Typography variant='h2' color={theme.palette.primary.main}>${vehicle?.kbb_auction_value ? vehicle?.kbb_auction_value.toLocaleString() : 0}</Typography>
                  <Typography variant='h7' color={theme.palette.text.grey}>Kbb Auction Value</Typography>
                </Box>
              </Box>
            </CardContent>
          }
          <CardContent>
            <Box className={classes.basic}>
              <Box>
                <Typography variant='h7' color={theme.palette.text.grey}>VIN :</Typography>
              </Box>
              <Box className={classes.flex}>
                <Typography variant='h7'>{vehicle?.vin}</Typography>
              </Box>
            </Box>
            {vehicle?.vertical === 'Auto' &&
              <>
                <Box className={classes.basic}>
                  <Box>
                    <Typography variant='h7' color={theme.palette.text.grey}>Exterior :</Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography variant='h7'>{vehicle?.exterior_color}</Typography>
                  </Box>
                </Box>
                <Box className={classes.basic}>
                  <Box>
                    <Typography variant='h7' color={theme.palette.text.grey}>Interior :</Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography variant='h7'>{vehicle?.interior_color}</Typography>
                  </Box>
                </Box>
              </>}
            {vehicle?.vertical === 'RV' &&
              <>
                <Box className={classes.basic}>
                  <Box>
                    <Typography variant='h7' color={theme.palette.text.grey}>Floorplan :</Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography variant='h7'>{vehicle?.sleeping_capacity ? vehicle?.sleeping_capacity : 'N/A'}</Typography>
                  </Box>
                </Box>
                <Box className={classes.basic}>
                  <Box>
                    <Typography variant='h7' color={theme.palette.text.grey}>Sleeps :</Typography>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography variant='h7'>{vehicle?.floor_plan ? vehicle?.floor_plan : 'N/A'}</Typography>
                  </Box>
                </Box>
              </>}

            {vehicle?.vertical === 'Powersports' &&
              <Box className={classes.basic}>
                <Box>
                  <Typography variant='h7' color={theme.palette.text.grey}>Stock Number :</Typography>
                </Box>
                <Box className={classes.flex}>
                  <Typography variant='h7'>{vehicle?.stock_number}</Typography>
                </Box>
              </Box>
            }
          </CardContent>
        </Card>
      </Grid>
      {getMakeButton(vehicle)}

      {(vehicle?.auction_status === 'Active' && vehicle?.mine === 0 && vehicle?.auction_ended === 0 && vehicle?.your_bid === 0) &&
        <>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.makebtn}
              onClick={(e) => {
                e.stopPropagation();
                setBidOpen(true);
                getShipping();
              }}
            >
              Place bid
            </Button>
          </Grid>
          <PlaceBidDialog
            isOpen={bidOpen}
            handleClose={() => setBidOpen(false)}
            vehicle={vehicle}
            transportProvider={quoteOptions}
          />
        </>}

      {vehicle?.mine === 1 && vehicle?.status === 'Pending Sale' && vehicle?.offer_rejected_date === null &&
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classNames(classes.makebtn, {[classes.btnDisabled] : !userCanSell})}
            onClick={() => {
              setIsConfirmDialogOpen(true);
            }}
            disabled={
              !userCanSell
            }
          >
            Approve Sale
          </Button>
        </Grid>
      }

      {vehicle?.mine === 1 && vehicle?.offer_rejected_date !== null &&
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classNames(classes.makebtn, {[classes.btnDisabled] : !userCanSell})}
            onClick={() => {
              setIsConfirmDialogOpen(true);
            }}
            disabled={
              !userCanSell
            }
          >
            Counter Offer
          </Button>
        </Grid>
      }


      <Grid item xs={12}>
        <DetailsPanel vehicle={vehicle} classes={classes} />
      </Grid>
      <Grid item xs={12}>
        <RooftopDetail vehicle={vehicle} classes={classes} />
      </Grid>

      <PlaceAuctionDialog
        open={showAuction}
        handleClose={() => {
          setShowAuction(false);
        }}
        vehicle={vehicle}
      />

      <ConditionReportDialog
        isOpen={showConditionReport}
        handleClose={(hasChanged) => {
          setShowConditionReport(false)
          if (showAuctionSelected && hasChanged) {
            setShowAuction(true)
          }
        }}
        vehicle={vehicle}
        setShowAuction={setShowAuction}
      />


      <ConfirmSaleDialog
        isOpen={isConfirmDialogOpen}
        token={vehicle?.token}
        offerToken={vehicle?.is_bid_won === 1 ? vehicle?.auction_token : vehicle?.offer_token}
        action={vehicle?.is_bid_won === 1 ? 'auctionSuccess' : vehicle?.offer_rejected_date === null ? 'confirmSale' : 'counterOffer'}
        handleClose={() => { setIsConfirmDialogOpen(false) }}
      />


      <MakeOfferDialog
        isOpen={isMakeOfferOpen}
        handleClose={() => setIsMakeOfferOpen(false)}
        vehicle={vehicle}
        transportProvider={quoteOptions}
      />

      <MessageDialog 
      isOpen={isMessageDialogOpen}
      vehicleToken={vehicle?.token}
      toRooftopId={vehicle?.dealer_rooftop_id}
      handleClose={() => setIsMessageDialogOpen(false)}
      />
    </StyledGrid>
  );
}

export default SidePanel;