import Image from "../../../components/widgets/Image";
import React from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import PostSaleProgressPanel from "../../../components/PostSaleProgressPanel";
import { formatDollarAmount } from "utils/Formatters";
import InfoPopover from "../../../components/widgets/InfoPopover";

const PREFIX = 'TaskItem'

const classes = {
  container: `${PREFIX}-container`,
  progress: `${PREFIX}-progress`,
  vehicle: `${PREFIX}-vehicle`,
  dealer: `${PREFIX}-dealer`,
  location: `${PREFIX}-location`,
  mileage: `${PREFIX}-mileage`,
  flex: `${PREFIX}-flex`,
  valueFlex: `${PREFIX}-valueFlex`,
  auction: `${PREFIX}-auction`,
  kbb: `${PREFIX}-kbb`,
  price: `${PREFIX}-price`,
  bold: `${PREFIX}-bold`,
  statusHeader: `${PREFIX}-statusHeader`,
}

const MainPanel = styled(Paper)((
  {
    theme
  }
) => {
  return {
    margin: 30,
    [`& .${classes.container}`]: {
      padding: 10,
    },
    [`& .${classes.progress}`]: {
      maxHeight: 250,
      overflow: 'visible',
      overflowY: 'scroll',
      float: 'right',
      padding: '5px 10px 5px 10px',
      border: `1px solid ${theme.palette.border.default}`,
      ["&::-webkit-scrollbar"]: {
        // General scrollbar
        width: "10px",
      },
      "&::-webkit-scrollbar-button": {
        // Side buttons
        width: "0px",
        height: "0px"
      },
      "&::-webkit-scrollbar-thumb": {
        // Scrollbar slider
        background: theme.palette.primary.dark,
        borderRadius: "2px"
      },
      "&::-webkit-scrollbar-thumb:hover": {
        // Slider hover
        background: theme.palette.secondary.dark
      },
      "&::-webkit-scrollbar-thumb:active": {
        // Slider active
        background: theme.palette.primary.light,
      },
      "&::-webkit-scrollbar-track": {
        // Scrollbar track
        background: theme.palette.text.grey,
        borderRadius: "2px"
      },
      "&::-webkit-scrollbar-track:hover": {
        // Track hover
        background: theme.palette.text.lightGrey,
      },
      "&::-webkit-scrollbar-track:active": {
        // Track active
        background: theme.palette.text.lightGrey,
      },
      "&::-webkit-scrollbar-corner": {
        // Scrollbar corners where scrollbars meet
        background: "transparent"
      }
    },
    [`& .${classes.vehicle}`]: {
      position: 'relative'
    },
    [`& .${classes.dealer}`]: {
      position: 'absolute',
      display: 'flex',
      background: theme.palette.secondary.main,
      backdropFilter: 'blur(37px)',
      borderRadius: 5,
      paddingTop: 5,
      paddingBottom: 4,
      width: 150,
      bottom: 10,
      justifyContent: 'center',
      right: 10
    },
    [`& .${classes.location}`]: {
      marginRight: 3,
      marginTop: 1
    },
    [`& .${classes.mileage}`]: {
      color: theme.palette.background.default
    },
    [`& .${classes.flex}`]: {
      display: 'flex',
      marginTop: 7,
    },
    [`& .${classes.valueFlex}`]: {
      marginTop: 98,
      display: 'flex'
    },
    [`& .${classes.auction}`]: {
      width: 160,
      padding: 10,
      borderRight: `1px dashed ${theme.palette.border.default}`,
    },
    [`& .${classes.kbb}`]: {
      padding: 10,
      marginLeft: 30
    },
    [`& .${classes.price}`]: {
      width: 160,
      padding: 10,
      borderRight: `1px dashed ${theme.palette.border.default}`,
      marginLeft: 30
    },
    [`& .${classes.bold}`]: {
      fontWeight: 600
    },
    [`& .${classes.statusHeader}`]: {
      marginBottom: 10
    },
  }
});

const TaskItem = ({ transaction, key, id, rooftop_id }) => {
  return (
    <MainPanel elevation={4}>
      <Grid key={key} container className={classes.container}>
        <Grid item xs={3} >
          <div className={classes.vehicle}>
            <Image
              alt="vehicle photo"
              src={`https://photos.reinventauto.com/cars/${transaction?.photoName}`}
            />
            <div className={classes.dealer}>
              <div>
                <Image
                  alt="like"
                  src="/static/images/white-location.png"
                  className={classes.location}
                />
              </div>
              <div>
                <Typography variant='body1' className={classes.mileage}>{transaction?.mileage ? `${transaction?.mileage?.toLocaleString()}` : 0} Miles</Typography>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={5} className="p-4">
          <Typography variant="h5" color="textSecondary">{transaction?.title}</Typography>
          <div className={classes.flex}>
            <div>
              {transaction?.address ? (<Image
                alt="like"
                src="/static/images/location.png"
              />) :
                ''}
            </div>
            <div>
              <Typography variant='body1' color="textSecondary" className={classes.bold}>{transaction?.address ? `${transaction?.address}` : ''}</Typography>
            </div>
          </div>
          <div>
            <Typography variant='h7' color="textSecondary">{transaction?.buyerRooftopName ? `Purchased By ${transaction?.buyerRooftopName}` : ''}</Typography>
          </div>
          <div>
            <Typography variant='h7' color="textSecondary">{transaction?.sellerRooftopName ? `Sold By ${transaction?.sellerRooftopName}` : ''}</Typography>
          </div>
          <div className={classes.valueFlex}>
            {transaction?.buyerId === id ? <div>
              <Typography variant='h5' color="text.completed" className={classes.bold}>{transaction?.purchasedPrice ? `${formatDollarAmount(transaction?.purchasedPrice)}` : `$ 0`}</Typography>
              <Typography variant='body1' color="textSecondary" className={classes.bold}>Purchased</Typography>
            </div> :
              <div>
                <Typography variant='h5' color="text.completed" className={classes.bold}>{transaction?.purchasedPrice ? `${formatDollarAmount(transaction?.purchasedPrice)}` : `$ 0`}</Typography>
                <Typography variant='body1' color="textSecondary" className={classes.bold}>Sold</Typography>
              </div>
            }

          </div>
        </Grid>

        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={12} className={classes.statusHeader}>
              <Typography style={{width: 100, float: 'left'}} variant='body1'>Task Status</Typography>
              <div  style={{float: 'left'}}>
                <InfoPopover>
                  <div>
                    <Typography variant='body1'>List of steps that need to be completed organized by category.</Typography>
                    <Typography variant='body1' color='error.main'>NOTE: You may have to scroll to see more categories</Typography>
                  </div>
                </InfoPopover>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.progress}>
              <PostSaleProgressPanel transaction={transaction} id={id} rooftop_id={rooftop_id} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainPanel>
  )
}

export default TaskItem;