import Grid from "@mui/material/Grid";
import SearchPanel from "components/SearchResultsPanel";

const SellView = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <SearchPanel resultType={'sellPage'} />
            </Grid>
        </Grid>
    )
}

export default SellView;