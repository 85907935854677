import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";

const PREFIX = 'getConnected';

const classes = {
    img: `${PREFIX}-dealer-img`,
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    border: `${PREFIX}-border`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        paddingTop: 50,
        [`& .${classes.img}`]: {
            borderRadius: 10,
            width: '90%'
        },
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        [`& .${classes.logo}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.border}`]: {
            width: 170,
            marginBottom: 10,
            float: 'left',
            borderTop: `3px solid ${theme.palette.secondary.main}`,
        },
    }
});

const DealerPanel = () => {
    return (
        <StyledGrid container>
            <Grid container className="p-12" >
                <Grid item xs={12}>
                    <Box className={classes.flex}>
                        <Image
                            alt="Banner Image"
                            src='/static/images/ReinventLogo.png'
                            className={classes.logo}
                        />
                        <Typography variant='h1' color="#213E98"><span className={classes.right}>Featured</span> Dealers</Typography>
                    </Box>
                    <hr className={classes.border} />
                    <Grid container className="mt-6">
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/PatrickGroup.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/RoeschFord.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/AceMotorsports.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Suburban.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/RV Country.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/TMC.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Open.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Desperado.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Engelhart.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/GageMarine.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Nassau.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Indian.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Lynnwood.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/onewater.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Powersport.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Reeds.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Marine.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Valley.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={2}>
                            <img alt="quote" src='/static/images/featuredDealers/Skipper.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/White.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Yamaha.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Motorsport.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Bish.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Bobby.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/UtahRV.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/CountryRide.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Cruise.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Diamond.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/DonRV.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/McKeever.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Sunshine.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Sutton.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Tonies.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Arizon.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Gail.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/TeamLatus.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/MadBoar.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/STPaul.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/WildHorse.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/TerryTown.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/campers.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/RV-Station.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/KARV.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Dennis-Dillon.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/BishsRV.jpg' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Johnston-RV.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/Collier.png' className={classes.img} />
                        </Grid>
                        <Grid item className="pt-6" xs={6} sm={4} md={4} lg={2} xl={1}>
                            <img alt="quote" src='/static/images/featuredDealers/BobbyCombsRV.jpg' className={classes.img} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledGrid>
    )
}

export default DealerPanel;