import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { searchOption, modelOption, trimOption } from 'data/vehicle.actions';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { hasRooftopPermission } from 'utils/User';
import DistanceFilter from './DistanceFilter';
import YearFilter from './YearFilter';
import NewOrUsedFilter from './NewOrUsedFilter';
import PriceFilter from './PriceFilter'; 
import FuelTypeFilter from './FuelTypeFilter';
import OdometerFilter from './OdometerFilter';
import GrossWeightFilter from './GrossWeightFilter';
import AgeFilter from './AgeFilter';
import MakeModelTrimFilter from './MakeModelTrimFilter';
import DealerAndRooftopFilter from './DealerAndRooftopFilter';
import LengthFilter from './LengthFilter';
import SlideOutsFilter from './SlideOutsFilter';
import SleepsFilter from './SleepsFilter';
import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';

const PREFIX = 'FilterPanel'

const classes = {
    tabs: `${PREFIX}-tabs`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.tabs}`]: {
            textTransform: 'capitalize',
            fontWeight: 600
        },
    }
});

const FilterPanel = ({
    criteria,
    resultType
}) => {
    const searchType = resultType;
    const dispatch = useDispatch();

    const { authentication } = useSelector(state => state);
    const { user } = authentication;

    useEffect(() => {
        if (user) {
            const params = {
                dealer: user.dealer_id,
                dealerRooftopId: parseInt(user.rooftop_id),
                searchType,
                activeCategory: criteria.vehcategory,
                make: "",
                model: "",
                internalOnly: !hasRooftopPermission(user, parseInt(user.rooftop_id), 'purchase_vehicle_from_any_rooftop_owned_by_a_different_dealer')
            };
            dispatch(searchOption(params));
            dispatch(modelOption(params));
            dispatch(trimOption(params));
            if (resultType === 'sellPage') {
                criteria.setSelectedFilter('year')
            }
        }
    }, [dispatch, user, criteria.vehcategory, searchType, resultType])

    const handleTabChange = (event, newValue) => {
        criteria.setSelectedFilter(newValue);
        criteria.handleMinWeight('ANY');
        criteria.handleMaxWeight('ANY');
    };

    return (
        <StyledGrid container>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={criteria.selectedFilter}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant='scrollable' onChange={handleTabChange}>
                            {resultType === 'buyPage' ? <Tab label="Distance" value="distance" className={classes.tabs} /> : ''}
                            <Tab label="Year" value="year" className={classes.tabs} />
                            <Tab label="New or Used" value="neworused" className={classes.tabs} />
                            <Tab label="Price" value="price" className={classes.tabs} />
                            <Tab label="Make" value="make" className={classes.tabs} />
                            <Tab label="Model" value="model" className={classes.tabs} />
                            <Tab label="Trim" value="trim" className={classes.tabs} />
                            {resultType === 'buyPage' ? <Tab label="Dealer" value="dealer" className={classes.tabs} /> : ''}
                            {resultType === 'buyPage' ? <Tab label="Rooftop" value="rooftop" className={classes.tabs} /> : ''}
                            {criteria.vehcategory === 'Auto' || criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <Tab label="Fuel Type" value="fueltype" className={classes.tabs} /> : ''}
                            <Tab label={criteria.vehcategory === 'Boat' || criteria.vehcategory === 'Personal Watercraft' || criteria.vehcategory === 'Marine Engine' ? 'Hours' : 'Odometer'} value="odometer" className={classes.tabs} />
                            {criteria.vehcategory === 'Auto' || criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <Tab label="Gross Weight" value="grossweight" className={classes.tabs} /> : ''}
                            {resultType === "sellPage" ? <Tab label="Age" value="age"  className={classes.tabs} /> : ''}
                            {criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <Tab label="Length" value="length" className={classes.tabs} /> : ''}
                            {criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <Tab label="Slide-Outs" value="slide-outs" className={classes.tabs} /> : ''}
                            {criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <Tab label="Sleeps" value="sleeps" className={classes.tabs} /> : ''}
                        </TabList>
                    </Box>
                    {resultType === 'buyPage' ? <DistanceFilter criteria={criteria}></DistanceFilter> : ''}
                    <YearFilter criteria={criteria}></YearFilter>
                    <NewOrUsedFilter criteria={criteria}></NewOrUsedFilter>
                    <PriceFilter criteria={criteria}></PriceFilter>
                    <MakeModelTrimFilter criteria={criteria} searchType={searchType}></MakeModelTrimFilter>
                    {resultType === 'buyPage' ? <DealerAndRooftopFilter criteria={criteria}></DealerAndRooftopFilter> : ''}
                    {criteria.vehcategory === 'Auto' || criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <FuelTypeFilter criteria={criteria}></FuelTypeFilter> : ''}
                    <OdometerFilter criteria={criteria}></OdometerFilter>
                    {criteria.vehcategory === 'Auto' || criteria.vehcategory === 'RV' ? <GrossWeightFilter criteria={criteria}></GrossWeightFilter> : ''}
                    <AgeFilter criteria={criteria}></AgeFilter>
                    {criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <LengthFilter criteria={criteria}></LengthFilter> : ''}
                    {criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <SlideOutsFilter criteria={criteria}></SlideOutsFilter> : ''}
                    {criteria.vehcategory === 'RV' || criteria.vehcategory === 'Other' ? <SleepsFilter criteria={criteria}></SleepsFilter> : ''}
                </TabContext>
            </Box>
        </StyledGrid>
    );
}

export default FilterPanel;