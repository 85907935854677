import React, { useState } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from 'formik';
import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Button from "components/widgets/Button";
import Spinner from "components/Spinner";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { toast } from "react-toastify";
import { updateTitleProcessing } from "data/transaction.actions";

const PREFIX = 'CompleteTaskDialog';

const classes = {
    submit: `${PREFIX}-submit`,
    closeBtn: `${PREFIX}-closeBtn`,
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.submit}`]: {
            float: 'right',
            marginTop: 10
        },
        [`& .${classes.closeBtn}`]: {
            float: 'right',
            marginTop: 10,
            marginRight: 10
        },
    }
});

const validationSchema = yup.object().shape({
});

let initialValues = {
};

const CompleteTaskDialog = (
    {
        open,
        handleClose,
        title,
        transactionId
    }) => {
    const dispatch = useDispatch();
    const { processing } = useSelector(state => state.transaction);
    const [completeDate, setCompleteDate] = useState(new Date());

    return (
        <StyledDialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 700, overflowY: 'hidden' } }}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1">
                    Complete Task
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values, meta) => {
                            let data = {
                                completeDate: new Date(completeDate).toISOString(),
                                title,
                                transactionId
                            }
                            dispatch(updateTitleProcessing(data));
                            toast.success('Completed Date updated successfully..');
                            handleClose();
                        }}>
                        {({ handleSubmit }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography color="#253241">Task Completed Date</Typography>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DateTimePicker
                                                    renderInput={(props) => <TextField {...props} />}
                                                    minDate={completeDate}
                                                    value={completeDate}
                                                    onChange={(newValue) => {
                                                        setCompleteDate(newValue?.$d);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.submit}
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.closeBtn}
                                                onClick={() => handleClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                    <Spinner show={processing} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    );
}

export default CompleteTaskDialog;
