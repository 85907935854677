export const selectMultipleOption = (
  name,
  options,
  selectedOption,
  setOption
) => {
  if (options) {
    if (options?.split(',')?.find((x) => x === selectedOption)) {
      options = options
        .split(',')
        .filter((x) => x !== selectedOption)
        .join(',');
    } else {
      options = `${options},${selectedOption}`;
    }
  } else {
    options = selectedOption;
  }
  localStorage.setItem(name, options);
  setOption(options);
};