import React from "react";
import { styled } from '@mui/material/styles';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useField } from "formik";

const PREFIX = 'RadioControl';

const classes = {
  radioButton: `${PREFIX}-radioButton`,
  radioLabel: `${PREFIX}-radioLabel`,
  options: `${PREFIX}-options`
};

const StyledFormControl = styled(FormControl)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.radioButton}`]: {
      color: theme.palette.text.primary,
      padding: '5px 5px 5px 9px'
    },
    [`& .${classes.radioLabel}`]: {
      color: theme.palette.text.primary,
      paddingLeft: 10,
      fontSize: 16,
    },
    [`& .${classes.options}`]: {
      width: '800px !important',
      padding: '3px 0px 3px 15px !important',
      background: '#fff !important',
      border: '1px solid #9ca3b1 !important',
      borderRadius: '8px !important',
      marginTop: 10,
    }
  };
});

const RadioControl = ({
  label,
  items,
  description,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <StyledFormControl component="fieldset">
      <FormLabel component="legend">
        <Typography variant="h2">{label}</Typography>
        <Typography variant="h7" color="#9CA3B1">{description}</Typography>
      </FormLabel>
      <RadioGroup
        aria-label={label}
        id={field.name}
        name={field.name}
        value={field.value ? field.value : ''}
        disabled={props.disabled}
        onChange={(event) => {
          field.onChange(event);
        }}
      >
        {items && items.map((option) => (
          <div key={option.value} className={classes.options}>
            <FormControlLabel
              classes={{
                label: classes.radioLabel
              }}
              control={<Radio disabled={props.disabled} className={classes.radioButton} />}
              label={option.label}
              value={option.value}
            />
          </div>
        ))
        }
      </RadioGroup>

      <Typography variant='body2' color='error'>{meta.touched && meta.error}</Typography>
    </StyledFormControl>
  );
}

export default RadioControl;