import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Rating from '@mui/material/Rating';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "components/widgets/Button";
import Image from "components/widgets/Image";
import styled from "@emotion/styled";
import MakeOfferDialog from "components/dialogs/MakeOfferDialog";
import PlaceAuctionDialog from 'components/dialogs/PlaceAuctionDialog';
import ConditionReportDialog from "components/dialogs/ConditionReportDialog";
import InternalExternalDialog from "components/dialogs/InternalExternalDialog";
import PriceSubPanel from "components/PriceSubPanel";
import { getReinventShippingOption } from 'data/vehicle.actions';
import Box from "@mui/material/Box";

const PREFIX = 'Search';

const classes = {
  card: `${PREFIX}-card`,
  imageContainer: `${PREFIX}-imageContainer`,
  vehicleImg: `${PREFIX}-vehicle-img`,
  vehicle: `${PREFIX}-vehicle`,
  like: `${PREFIX}-like`,
  dealer: `${PREFIX}-dealer`,
  buybtn: `${PREFIX}-buy-btn`,
  makebtn: `${PREFIX}-make-btn`,
  flex: `${PREFIX}-flex`,
  mileage: `${PREFIX}-mileage`,
  auction: `${PREFIX}-auction`,
  kbb: `${PREFIX}-kbb`,
  price: `${PREFIX}-price`,
  detailpanel: `${PREFIX}-detail-panel`,
  location: `${PREFIX}-location`,
  mile: `${PREFIX}-mile`,
  distance: `${PREFIX}-distance`,
  action: `${PREFIX}-action`,
  errorBlock:`${PREFIX}-errorBlock`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.card}`]: {
      background: theme.palette.background.default,
      boxShadow: '0px 4px 15px rgb(152 155 160 / 24%)',
      borderRadius: 20,
    },
    [`& .${classes.imageContainer}`]: {
      marginTop: 8
    },
    [`& .${classes.vehicleImg}`]: {
      borderRadius: 16,
      width: '100%',
    },
    [`& .${classes.vehicle}`]: {
      marginRight: 10,
      position: 'relative'
    },
    [`& .${classes.like}`]: {
      position: 'absolute',
      top: 8,
      left: 15,
    },
    [`& .${classes.dealer}`]: {
      position: 'absolute',
      top: 8,
      right: 15,
      background: 'rgba(255, 255, 255, 0.58)',
      backdropFilter: 'blur(37px)',
      borderRadius: 5,
      padding: 5,
      width: 150,
      textAlign: 'center'
    },
    [`& .${classes.buybtn}`]: {
      background: theme.palette.primary.main,
      borderRadius: 7,
      width: 160,
      textTransform: 'capitalize',
    },
    [`& .${classes.makebtn}`]: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 7,
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginTop: 10,
      width: 160,
      textTransform: 'capitalize',
      float: 'right'
    },
    [`& .${classes.startbtn}`]: {
      float: 'right'
    },
    [`& .${classes.flex}`]: {
      display: 'flex',
      marginTop: 15
    },
    [`& .${classes.mileage}`]: {
      color: theme.palette.background.default
    },
    [`& .${classes.distance}`]: {
      display: 'flex',
      marginTop: 5
    },
    [`& .${classes.auction}`]: {
      width: 160,
      padding: 10,
      borderRight: `1px dashed ${theme.palette.border.default}`
    },
    [`& .${classes.price}`]: {
      width: 160,
      padding: 10,
    },
    [`& .${classes.kbb}`]: {
      padding: 10,
      marginLeft: 30
    },
    [`& .${classes.detailpanel}`]: {
      background: theme.palette.secondary.grey,
      borderRadius: 10,
      marginTop: 10,
      padding: 22,
      width: '100%',
      // height: 85
    },
    [`& .${classes.location}`]: {
      marginRight: 3,
      marginTop: 1
    },
    [`& .${classes.action}`]: {
      width: 180,
      background: '#d8f5d8',
      color: theme.palette.text.completed,
      textAlign: 'center',
      padding: 25,
      borderRadius: 6,
      float: 'right'
    },
    [`& .${classes.errorBlock}`]: {
      width: 180,
      background: '#ffebec',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      padding: 25,
      borderRadius: 6,
      float: 'right'
    },
    [`& .${classes.mile}`]: {
      position: 'absolute',
      display: 'flex',
      background: theme.palette.secondary.main,
      backdropFilter: 'blur(37)',
      borderRadius: 5,
      paddingTop: 5,
      paddingBottom: 4,
      width: 150,
      bottom: 10,
      justifyContent: 'center',
      right: 10
    },
  }
});

const VehiclePanel = ({ vehicle, resultType, setLoading, showDetailsHandler, showLikeDialog }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showInternalExternalDialog, setShowInternalExternalDialog] = useState(false);
  const [isInternal, setIsInternal] = useState(null);
  const [showMakeOffer, setShowMakeOffer] = useState(false);
  const [showAuction, setShowAuction] = useState(false);
  const [showConditionReport, setShowConditionReport] = useState(false);
  const [showAuctionSelected, setShowAuctionSelected] = useState(false);
  const { search: { quoteOptions }, authentication: { user } } = useSelector(state => state);

  const getShipping = (vehicle) => {
    const data = {
      buyerRooftopId: Number(user?.rooftop_id),
      sellerRooftopId: vehicle?.dealer_rooftop_id,
      vin: vehicle?.vin,
      inventoryId: vehicle?.token
    }
    dispatch(getReinventShippingOption(data));
  }

  const getMakeButton = (vehicle) => {
    if (vehicle?.yours === 0 && (vehicle?.status === 'Available' || (vehicle?.status === 'On Auction' && vehicle?.last_bid_amount < vehicle?.reserve_price))) {
      return <Button
        className={classes.makebtn}
        onClick={() => {
          setShowMakeOffer(true);
          getShipping(vehicle);
        }}
      >
        Make an Offer
      </Button>
    } else {
      return <Grid item>
        <Box className={`${classes.action}`}>
          <Typography variant='h3'>{vehicle?.status}</Typography>
        </Box>
      </Grid>
    }
  }
  
  const makeInternalAuction = () => {
    setIsInternal(true);
    setShowInternalExternalDialog(false);
    setShowAuction(true);
  }
  
  const makeExternalAuction = () => {
    setIsInternal(false);
    setShowInternalExternalDialog(false);
    if(vehicle?.condition_report_date === null){
      setShowConditionReport(true);
      setShowAuctionSelected(true);
    } else {
      setShowAuction(true);
    } 
  }

  return (
    <StyledGrid item xs={12}>
      <Card className={`mt-8 ${classes.card}`}>
        <CardContent>
          <Grid container>
            <Grid item sm={5} className={classes.imageContainer}>
              <div className={classes.vehicle}
              >
                <button onClick={showDetailsHandler}>
                  <Image
                    className={classes.vehicleImg}
                    alt="vehicle photo"
                    src={`https://photos.reinventauto.com/cars/${vehicle?.photo_name}`}
                  />
                </button>
                <button onClick={showLikeDialog}
                  >
                  <Image
                    className={classes.like}
                    alt="like"
                    src="/static/images/like.png"
                  >
                  </Image>
                </button>

                <div className={classes.dealer} onClick={showDetailsHandler}>
                  <Typography variant="body1" color="textSecondary">
                    {vehicle?.rooftop_name}
                  </Typography>
                </div>
                <div className={classes.mile} onClick={showDetailsHandler}>
                  <div onClick={showDetailsHandler}>
                    <Typography variant='body1' className={classes.mileage}>{vehicle?.mileage ? `${vehicle?.mileage?.toLocaleString()}` : 0} Miles</Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item sm={7} className="m-4">
              <Grid container>
                <Grid item md={7} className="p-4" onClick={showDetailsHandler}>
                  <Typography variant="h5" color="textSecondary">{vehicle?.title}</Typography>
                  <div className={classes.flex}>
                    <div>
                      {vehicle?.name ? (<Image
                        alt="like"
                        src="/static/images/location.png"
                      />) :
                        ''}
                    </div>
                    <div>
                      <Typography variant='body1' color="textSecondary">{vehicle?.name ? `${vehicle?.city}, ${vehicle?.state} ${vehicle?.postal_code}` : ''}</Typography>
                    </div>
                  </div>
                  <div className={classes.distance}>
                    <div>
                      <Image
                        alt="like"
                        src="/static/images/location.png"
                      />
                    </div>
                    <div>
                      <Typography variant='body1' color="textSecondary">{vehicle?.distance} Mi</Typography>
                    </div>
                  </div>

                  {resultType ==="sellPage" &&<div className="mt-1">
                  <Typography variant='body1' color="textSecondary">{vehicle?.age} Days on Lot</Typography>
                  </div>}
                  {vehicle?.seller_rating > 0 &&
                    <div className={classes.distance}>
                      <div>
                        <Rating name="read-only" value={vehicle?.seller_rating} precision={0.1} readOnly />
                      </div>
                    </div>
                  }
                </Grid>
                <Grid item md={5}>
                  {resultType === 'buyPage' && getMakeButton(vehicle)}

                  {resultType === 'sellPage' && vehicle?.yours === 1 ?
                    ((vehicle?.auction === 0 || (vehicle?.auction === 1 && vehicle?.auction_ended === 1)) && (vehicle?.auction_status === null || !['Active', 'Expiring', 'Sold', 'Not Sold', 'Pending Sale', 'Pending Transaction'].includes(vehicle?.auction_status))) && 'Available' === vehicle?.status ? (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={`${classes.startbtn}`}
                        onClick={() => {
                          setShowInternalExternalDialog(true);
                        }}
                      >
                        Start Auction
                      </Button>
                    ) : ['Active', 'Expiring'].includes(vehicle?.auction_status) ? (
                      <Grid item>
                        <Box className={`${classes.action}`}>
                          <Typography variant='h3'>On Auction</Typography>
                        </Box>
                      </Grid>

                    ) : 
                      vehicle?.auction_status === "Not Sold" ? (
                      <div className="flex flex-col items-end">
                        <Grid item >
                          <Box className={`${classes.errorBlock}`} >
                            <Typography variant='h3'>Not Sold</Typography>
                          </Box>
                        </Grid>
                        
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={`${classes.startbtn}`}
                          style={{marginTop: "40px"}}
                          onClick={() => {
                            setShowInternalExternalDialog(true);
                          }}
                        >
                          Start Auction
                        </Button>
                      </div>
                      )
                    : vehicle?.auction_status === 'Pending Sale' ? (
                      <Grid item>
                        <Box className={`${classes.action}`}>
                          <Typography variant='h3'>Pending Sale</Typography>
                        </Box>
                      </Grid>
                    ) :
                    (
                      <Grid item>
                        <Box className={`${classes.action}`}>
                          <Typography variant='h3'>{vehicle?.status}</Typography>
                        </Box>
                      </Grid>
                    ) : ''
                  }

                  <InternalExternalDialog
                    isOpen={showInternalExternalDialog}
                    makeInternalAuction={makeInternalAuction}
                    makeExternalAuction={makeExternalAuction}
                    handleClose={() => {
                      setShowInternalExternalDialog(false);
                    }}
                  />

                  <PlaceAuctionDialog
                    open={showAuction}
                    handleClose={() => {
                      setShowAuction(false);
                      setLoading(true);
                    }}
                    vehicle={vehicle}
                    isInternal={isInternal}
                  />

                  <MakeOfferDialog
                    isOpen={showMakeOffer}
                    handleClose={() => setShowMakeOffer(false)}
                    vehicle={vehicle}
                    transportProvider={quoteOptions}
                  />

                  {showConditionReport &&
                    <ConditionReportDialog
                      isOpen={showConditionReport}
                      handleClose={(hasChanged) => {
                        setShowConditionReport(false)
                        if (showAuctionSelected && hasChanged) {
                          setShowAuction(true)
                        }
                      }}
                      vehicle={vehicle}
                      setShowAuction={setShowAuction}
                    />
                  }

                </Grid>
              </Grid>

              <PriceSubPanel vehicle={vehicle} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.detailpanel}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Typography variant="body2" color="textSecondary">VIN :</Typography>
                    <Typography variant="body2" color="textSecondary">{vehicle?.vin}</Typography>
                  </Grid>
                  
                 {resultType === "sellPage" && <Grid item xs={2}>
                    <Typography variant="body2" color="textSecondary">Stock Number :</Typography>
                    <Typography variant="body2" color="textSecondary">{vehicle?.stock_number}</Typography>
                  </Grid>}

                  {vehicle.vertical === 'Auto' &&
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="body2" color="textSecondary">Interior :</Typography>
                        <Typography variant="body2" color="textSecondary">{vehicle?.interior_color}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="body2" color="textSecondary">Exterior :</Typography>
                        <Typography variant="body2" color="textSecondary">{vehicle?.exterior_color}</Typography>
                      </Grid>
                      {resultType === 'buyPage' &&
                        <> <Grid item xs={12} sm={6} md={4} lg={2}>
                          <Typography variant="body2" color="textSecondary">Cylinders :</Typography>
                          <Typography variant="body2" color="textSecondary">{vehicle?.engine_cylinders ? vehicle?.engine_cylinders : 'N/A'}</Typography>
                        </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Typography variant="body2" color="textSecondary">Drivetrain :</Typography>
                            <Typography variant="body2" color="textSecondary">{vehicle?.drivetrain ? vehicle?.drivetrain : 'N/A'}</Typography>
                          </Grid>
                        </>
                      }
                    </>
                  }

                  {vehicle.vertical === 'RV' && resultType === 'buyPage' &&
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="body2" color="textSecondary">Floorplan :</Typography>
                        <Typography variant="body2" color="textSecondary">{vehicle?.floor_plan ? vehicle?.floor_plan : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="body2" color="textSecondary">Sleeps :</Typography>
                        <Typography variant="body2" color="textSecondary">{vehicle?.sleeping_capacity ? vehicle?.sleeping_capacity : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="body2" color="textSecondary">EngineType :</Typography>
                        <Typography variant="body2" color="textSecondary">{vehicle?.engine_type ? vehicle?.engine_type : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="body2" color="textSecondary">Slide outs :</Typography>
                        <Typography variant="body2" color="textSecondary">{vehicle?.slide_outs ? vehicle?.slide_outs : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={2}>
                        <Typography variant="body2" color="textSecondary">Odometer :</Typography>
                        <Typography variant="body2" color="textSecondary">{vehicle?.mileage ? vehicle?.mileage : 'N/A'}</Typography>
                      </Grid>
                    </>

                  }

                  {vehicle.vertical === 'Powersports' && resultType === 'buyPage' &&
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Typography variant="body2" color="textSecondary">Stock Number :</Typography>
                      <Typography variant="body2" color="textSecondary">{vehicle?.stock_number ? vehicle?.stock_number : 'N/A'}</Typography>
                    </Grid>
                  }

                  {vehicle.vertical !== 'RV' && resultType === 'buyPage' &&
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                      <Typography variant="body2" color="textSecondary">Fuel Type :</Typography>
                      <Typography variant="body2" color="textSecondary">{vehicle?.fuel_type ? vehicle?.fuel_type : 'N/A'}</Typography>
                    </Grid>
                  }
                </Grid>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </StyledGrid>
  )
};

export default VehiclePanel;