import { createAsyncThunk } from "@reduxjs/toolkit";
import { toCamelcase } from "utils/Formatters";
import {
  loadingOffer,
  loadedOffer,
  loadedBids,
  errorLoadingOffer,
  confirmingSale,
  confirmSaleSuccessful,
  errorConfirmingSale,
  rejectingSale,
  rejectSaleSuccessful,
  errorRejectingSale,
  loadCounterOffer,
  counterOfferSuccessful,
  errorCounterOffer
} from './offer.slice';

export const loadOffer = createAsyncThunk('offers/loadOffer', async (values, { extra, dispatch }) => {
  try {
    console.log(values);
    if (values.token && values.offerToken) {
      dispatch((loadingOffer()));
      const response = await extra.get(`/offer/accept/${values.token},${values.offerToken}`);

      dispatch(loadedOffer({
        offer: toCamelcase(response)
      }));
    } else {
      dispatch((loadedOffer({
        offer: []
      })));
    }
  }
  catch (error) {
    console.log(error.message);
    dispatch(errorLoadingOffer({ error: error.message }));
  }
});

export const getBuyer = createAsyncThunk('auction/bids', async (values, { extra, dispatch }) => {
  try {
    console.log(values);
    if (values.offerToken) {
      dispatch((loadingOffer()));

      const response = await extra.get(`/auction/bids/${values.offerToken},1`);

      dispatch(loadedBids({
        offer: toCamelcase(response)
      }));
    } else {
      dispatch((loadedBids({
        offer: []
      })));
    }
  }
  catch (error) {
    console.log(error.message);
    dispatch(errorLoadingOffer({ error: error.message }));
  }
});

export const confirmSale = createAsyncThunk('offers/confirmOffer', async (values, { extra, dispatch }) => {
  try {
    dispatch((confirmingSale()));

    const { action, offerToken, lienPresent, lienAmount, lienholderName, lienAddress, signerEmail } = values;
    const response = await extra.post(`/transaction/start`, {
      auction_token: 'auctionSuccess' === action ? offerToken : undefined,
      offer_token: 'auctionSuccess' !== action ? offerToken : undefined,
      lien_present: (lienPresent ? 1 : 0),
      lien_amount: lienAmount,
      lien_holder_name: lienholderName,
      lien_address: lienAddress?.address1 || '',
      lien_zipcode: lienAddress?.zip || '',
      in_seller_signer_email: signerEmail
    });

    dispatch(confirmSaleSuccessful({
      offer: response
    }));
  }
  catch (error) {
    console.log(error.message);
    dispatch(errorConfirmingSale({ error: error.message }));
  }
});

export const rejectSale = createAsyncThunk('offers/rejectOffer', async (values, { extra, dispatch }) => {
  try {
    dispatch((rejectingSale()));

    const { offerToken, token, message } = values;
    const response = await extra.post(`/offer/reject/`, {
      offer_token: offerToken,
      token: token,
      message: message
    });

    dispatch(rejectSaleSuccessful({
      offer: response
    }));
  }
  catch (error) {
    console.log(error.message);
    dispatch(errorRejectingSale({ error: error.message }));
  }
});

export const counterOffer = createAsyncThunk('offers/counterOffer', async (values, { extra, dispatch }) => {
  try {
    dispatch((loadCounterOffer()));

    const { offerToken, counterAmount } = values;
    const response = await extra.post(`/offer/counter`, {
      offerToken: offerToken,
      counterAmount: counterAmount
    });

    dispatch(counterOfferSuccessful({
      offer: response
    }));
  }
  catch (error) {
    console.log(error.message);
    dispatch(errorCounterOffer({ error: error.message }));
  }
});