import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Image from "components/widgets/Image";
import LikeDialog from "components/dialogs/LikeDialog";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { loadLikes } from "data/user.actions";
import Spinner from "components/Spinner";
import VehicleDetailsDialog from "components/dialogs/VehicleDetailsDialog";
import { toggleLikes } from "data/user.actions";

const PREFIX = "LikesPage";

const classes = {
  card: `${PREFIX}-card`,
  imageContainer: `${PREFIX}-imageContainer`,
  vehicleImg: `${PREFIX}-vehicle-img`,
  vehicle: `${PREFIX}-vehicle`,
  like: `${PREFIX}-like`,
};

const StyledGrid = styled(Grid)(({ theme }) => {
  return {
    padding: "20px 60px ",
    [theme.breakpoints.down("md")]: {
      padding: "0 0px",
    },
    [`& .${classes.card}`]: {
      background: theme.palette.background.default,
      boxShadow: "0px 4px 15px rgb(152 155 160 / 24%)",
      borderRadius: 20,
    },
    [`& .${classes.imageContainer}`]: {
      marginTop: 8,
    },
    [`& .${classes.vehicleImg}`]: {
      borderRadius: 16,
      width: "100%",
    },
    [`& .${classes.vehicle}`]: {
      marginRight: 10,
      position: "relative",
    },
    [`& .${classes.like}`]: {
      position: "absolute",
      top: 8,
      left: 15,
    },
  };
});
const LikesView = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [showLikesDialog, setShowLikesDialog] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const values = { page, sort, search };

  const showDetailsHandler = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  const { likes, processing } = useSelector((state) => state.user);

  const showLikeDialog = (selectedVehicle) => {
    setShowLikesDialog({
      likeComment: selectedVehicle?.comments,
      token: selectedVehicle?.token,
    });
  };

  useEffect(() => {
    if (likes === undefined && !processing) {
      setIsFetching(true);
      if (isFetching) {
        dispatch(loadLikes(values));
        setIsFetching(false);
      }
    }
  }, [likes, dispatch, processing, isFetching]);


  return (
    <StyledGrid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">Likes Page</Typography>
          {likes?.count > 0 && (
            <Typography variant="h6" color="primary">
              Found {likes?.count} Vehicle{likes?.count > 1 ? "s" : ""}
            </Typography>
          )}
          <div>
            {likes?.data?.map((vehicle) => {
              return (
                <div key={vehicle.token}>
                  <Card className={`mt-8 ${classes.card}`}>
                    <CardContent>
                      <Grid container>
                        <Grid item sm={5} className={classes.imageContainer}>
                          <div className={classes.vehicle}>
                            <button onClick={() => showDetailsHandler(vehicle)}>
                              <Image
                                className={classes.vehicleImg}
                                alt="vehicle photo"
                                src={`https://photos.reinventauto.com/cars/${vehicle?.photo_name}`}
                              />
                            </button>
                          </div>
                        </Grid>
                        <Grid item sm={7} className="m-4">
                          <Grid container>
                            <Grid item md={7} className="p-4">
                              <Typography variant="h5" color="textSecondary">
                                {vehicle?.title}
                              </Typography>
                              <div>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  VIN: {vehicle?.vin}
                                </Typography>
                              </div>
                              <div className="mt-2">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  COMMENTS:{" "}
                                  {vehicle?.comments
                                    ? vehicle?.comments
                                    : "N/A"}
                                </Typography>
                              </div>
                            </Grid>
                            <div className="mt-4 ml-auto mr-6">
                            <Button 
                            onClick={() => showLikeDialog(vehicle)}
                             variant="contained"
                             color="primary"
                             size="large">
                              Remove
                            </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </div>
        </Grid>
      </Grid>
      <VehicleDetailsDialog
        isOpen={selectedVehicle !== null}
        handleClose={() => setSelectedVehicle(null)}
        vehicleToken={selectedVehicle?.token}
      />
      <LikeDialog
        isOpen={showLikesDialog?.token}
        handleClose={() => setShowLikesDialog(null)}
        vehicleToken={showLikesDialog?.token}
        removeLikedVehicle={true}
      />
      <Spinner show={processing} />
    </StyledGrid>
  );
};

export default LikesView;
