import React, {useEffect, useState} from 'react';
import Table from 'mui-datatables';

export default function DataTable({classes, data, columns, options, dataShaper}) {
  const [tableData, setTableData] = useState();

  useEffect(() => {
    if (dataShaper && data) {
      const shapedData = data.map((value) => dataShaper(value));
      setTableData(shapedData);
    }
  }, [data, dataShaper]);

  return (
      <Table
          classes={classes}
          data={tableData || data}
          columns={columns}
          options={{
            searchAlwaysOpen: true,
            enableNestedDataAccess: '.',
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 20, 100],
            ...options,
          }}
      />
  );
}