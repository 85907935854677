import React from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';

const OdometerFilter = ({ criteria}) => {
  return (
    <TabPanel value='odometer'>
      <RangeSlider
        minValue={0}
        maxValue={1000000}
        defaultValue={[criteria.defaultMinMileage, criteria.defaultMaxMileage]}
        labelText={''}
        handleChange={(value) => {
          const [min, max] = value;
          criteria.setPage(1);
          localStorage.setItem('minMileage', min);
          localStorage.setItem('maxMileage', max);
          criteria.handleMinMileage(min);
          criteria.handleMaxMileage(max);
          criteria.setDefaultMinMileage(null);
          criteria.setDefaultMaxMileage(null);
        }}
        disabled={true}
      />
    </TabPanel>
  );
};

export default OdometerFilter;