import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  updatedPassword: false,
  settings: undefined,
  users: undefined,
  userToEdit: undefined,
  locationRoles: undefined,
  error: undefined,
  likes: undefined,
  messages: undefined,
  messageThread: undefined,
};

export const userSlice = createSlice({
  processing: false,
  name: 'user',
  initialState,
  reducers: {
    initialize: () => {
      return initialState
    },
    gettingUserSettings: (state, action) => {
      return {
        ...state,
        processing: true,
        settings: undefined,
        error: undefined
      }
    },
    gettingUserForEdit: (state, action) => {
      return {
        ...state,
        processing: true,
        userToEdit: undefined,
        error: undefined
      }
    },
    updatingUserPassword: (state, action) => {
      return {
        ...state,
        processing: true,
        error: undefined
      }
    },
    updatingUser: (state, action) => {
      return {
        ...state,
        processing: true,
        users: undefined,
        error: undefined
      }
    },
    creatingUser: (state, action) => {
      return {
        ...state,
        processing: true,
        error: undefined
      }
    },
    cleaningUser: (state, action) => {
      return {
        ...state,
        processing: false,
        error: undefined
      }
    },
    gettingLocationRoles: (state, action) => {
      return {
        ...state,
        processing: true,
        error: undefined
      }
    },
    loadingUsers: (state, action) => {
      return {
        ...state,
        processing: true,
        users: undefined,
        error: undefined
      }
    },
    updatedUserPassword: (state, action) => {
      return {
        ...state,
        processing: false,
        updatedPassword: true
      }
    },
    updatedUser: (state, action) => {
      return {
        ...state,
        userToEdit: undefined,
        processing: false
      }
    },
    userCreated: (state, action) => {
      return {
        ...state,
        userToEdit: undefined,
        users: undefined,
        processing: false
      }
    },
    updateUserSettings: (state, action) => {
      return {
        ...state,
        settings: action.payload.settings,
        processing: false
      }
    },
    usersLoaded: (state, action) => {
      return {
        ...state,
        users: action.payload.users,
        processing: false
      }
    },
    loadedLocationRoles: (state, action) => {
      return {
        ...state,
        locationRoles: action.payload.locationRoles,
        processing: false
      }
    },
    userToEditLoaded: (state, action) => {
      return {
        ...state,
        userToEdit: action.payload.user,
        processing: false
      }
    },
    errorFound: (state, action) => {
      return {
        ...state,
        ...action.payload,
        processing: false
      }
    },
    togglingLikes: (state, action) => {
      return {
        ...state,
        likes: undefined,
        processing: true,
        
      }
    },
    toggledLikes: (state, action) => {
      return {
        ...state,
        likes: action.payload.likes,
        processing: false,
      }
    },
    loadingLikes: (state, action) => {
      return {
        ...state,
        likes: undefined,
        processing: true,
      }
    },
    loadedLikes: (state, action) => {
      return {
        ...state,
        likes: action.payload.likes,
        processing: false,
      }
    },
    loadingMessages: (state, action) => {
      return {
        ...state, 
        messages: undefined,
        processing: true
      }
    },
    loadedMessages: (state, action) => {
      return {
        ...state,
        messages: action.payload.messages,
        processing: false,
      }
    },
    addingMessage: (state, action) => {
      return {
        ...state,
        messages: undefined,
        processing: true,
      }
    },
    addedMessage: (state, action) => {
      return {
        ...state, 
        messages: action.payload.messages,
        processing: false
      }
    },
    loadingMessageThread: (state, action) => {
      return {
        ...state,
        messageThread: undefined,
        processing: true
      }
    },
    loadedMessageThread: (state, action) => {
      return {
        ...state,
        messageThread: action.payload.messageThread,
        processing: false
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const {
    initialize,
    gettingUserSettings,
    gettingUserForEdit,
    gettingLocationRoles,
    updateUserSettings,
    updatingUserPassword,
    updatedUserPassword,
    userToEditLoaded,
    usersLoaded,
    loadingUsers,
    loadedLocationRoles,
    updatingUser,
    updatedUser,
    creatingUser,
    userCreated,
    errorFound,
    cleaningUser,
    togglingLikes,
    toggledLikes,
    loadingLikes,
    loadedLikes,
    loadingMessages,
    loadedMessages,
    addingMessage,
    addedMessage,
    loadingMessageThread,
    loadedMessageThread
} = userSlice.actions

export default userSlice.reducer