/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import YouTubeViewer from "components/widgets/YouTubeViewer";
import { useNavigate } from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PREFIX = 'vehiclePanel';

const classes = {
    position: `${PREFIX}-position`,
    panel: `${PREFIX}-panel`,
    content: `${PREFIX}-panel-content`,
    btn: `${PREFIX}-vehicle-btn`,
    img: `${PREFIX}-panelImg`,
    right: `${PREFIX}-right`,
    video: `${PREFIX}-video`,
    videoContainer: `${PREFIX}-videoContainer`,
    text: `${PREFIX}-text`
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        position: 'relative',
        [`& .${classes.text}`]: {
            [theme.breakpoints.up('xs')]: {
                fontSize: 30,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 40,
            },
        },
        [`& .${classes.panel}`]: {
            background: theme.palette.primary.main,
            borderRadius: 39,
        },
        [`& .${classes.content}`]: {
            [theme.breakpoints.up('xs')]: {
                paddingTop: 30,
                paddingLeft: 20,
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: 80,
                paddingTop: 85,
            },
        },
        [`& .${classes.btn}`]: {
            background: 'linear-gradient(145.54deg, #FD646A 14.81%, #ED1D24 92.86%)',
            boxShadow: '8px 10px 20px rgba(0, 0, 0, 0.49)',
            color: theme.palette.background.default,
            textTransform: 'none',
            marginTop: 30,
            borderRadius: 35,
            height: 35,
            marginLeft: 15,
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.videoContainer}`]: {
            [theme.breakpoints.up('xs')]: {
                padding: 20,
            },
            [theme.breakpoints.up('lg')]: {
                padding: 0,
            },
        },
    }
});

const RightVehiclePanel = () => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Card className={classes.panel}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} lg={6} className={classes.content}>
                                <Typography variant="h1" color='text.white' className={classes.text}>The <span className={classes.right}>Right</span> Vehicle.</Typography>
                                <Typography variant="h1" color='text.white' className={classes.text}>The Right Place.</Typography>
                                <Typography variant="h1" color='text.white' className={classes.text}>The Right Time.</Typography>
                                <Button variant="contained" color="primary" className={classes.btn} size="large" onClick={()=> navigate('/signup')}>Join. Click. Trade.</Button>
                            </Grid>

                            <Grid item xs={12} lg={6} className={classes.videoContainer}>
                                <YouTubeViewer
                                    height={isSmallScreen ? '200px' : '390px'}
                                    width={isSmallScreen ? '350px' : ' 630px'}
                                    videoId="lppQE7h5Vpg"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </StyledGrid>

    )
}

export default RightVehiclePanel;