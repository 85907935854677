import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [existingPathname, setExistingPathname] = useState();

  useEffect( () => {
    if (pathname !== existingPathname) {
      window.scrollTo(0, 0);
      setExistingPathname(pathname);
    }
  }, [pathname]);

  return null;
}

export default ScrollToTop;