/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from 'formik';
import { createConditionReport } from "data/vehicle.actions";
import styled from "@emotion/styled";
import { toast } from 'react-toastify';
import Spinner from "components/Spinner";
import Grid from '@mui/material/Grid';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import TextField from 'components/widgets/TextField';
import Button from "components/widgets/Button";
import YouTubeViewer from "components/widgets/YouTubeViewer";

const validationSchema = yup.object().shape({
    code: yup.string().required("Please Enter Video Id")
});

let initialValues = {
    code: ''
};

const PREFIX = 'UploadConditionVideo';

const classes = {
    submit: `${PREFIX}-submit`,
    closeBtn: `${PREFIX}-closeBtn`,
    dropzone: `${PREFIX}-dropzone`,
    fileIcon: `${PREFIX}-fileIcon`,
    verifyButtonContainer: `${PREFIX}-verifyButtonContainer`,
    youtubeViewerContainer: `${PREFIX}-youtubeViewerContainer`,
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.submit}`]: {
            float: 'right',
            marginTop: 20
        },
        [`& .${classes.closeBtn}`]: {
            float: 'right',
            marginTop: 20,
            marginRight: 10
        },
        [`& .${classes.dropzone}`]: {
            height: 138,
            border: '1px solid #9ca3b1',
            borderRadius: 8,
            textAlign: 'center'
        },
        [`& .${classes.fileIcon}`]: {
            fontSize: 60,
            color: '#9CA3B1',
            marginTop: 20
        },
        [`& .${classes.verifyButtonContainer}`]: {
            marginTop: 30
        },
        [`& .${classes.youtubeViewerContainer}`]: {
            marginTop: 10
        },
    }
});

const UploadConditionVideo = (
    {
        open,
        handleClose,
        report
    }) => {

    const dispatch = useDispatch();
    const { search: { processing } } = useSelector(state => state);

    const convertCodeInput = (inputValue) => {
        if (inputValue?.match(/^http/)) {
            if (inputValue.match(/\?v=/g)) {
                const splits = inputValue?.split('?v=');
                return splits[1];
            }
            else {
                const splits = inputValue?.split('/');
                return splits[splits.length - 1];
            }
        }

        return inputValue;
    }

    const verify = (code, setFieldValue) => {
        setFieldValue('code', convertCodeInput(code));
    }

    return (
        <StyledDialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 700, overflowY: 'hidden' } }}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h1">
                    SET VIDEO URL
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            const params = {
                                reportId: report?.id,
                                videoCode: convertCodeInput(values.code)
                            }

                            dispatch(createConditionReport(params));
                            handleClose(true);
                            toast.success('Vehicle Condition Video Uploaded Successfully!!!');
                        }}>
                        {({ handleSubmit, handleChange, setFieldValue, values }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container className="pt-6">
                                        <Grid item xs={12}>
                                            <TextField name='code' label='Video ID' type='text' onChange={handleChange} />
                                        </Grid>
                                        <Grid item xs={12} className={classes.verifyButtonContainer}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                onClick={() => verify(values.code, setFieldValue)}
                                            >
                                                Verify
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} className={classes.youtubeViewerContainer}>
                                            <YouTubeViewer
                                                height='100%'
                                                videoId={values.code}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className={classes.buttonContainer}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.submit}
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.closeBtn}
                                                onClick={() => handleClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                    <Spinner show={processing} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    );
}

export default UploadConditionVideo;
