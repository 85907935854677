import React from 'react';
import Grid from '@mui/material/Grid';
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";

const PREFIX = 'TaskLists'

const classes = {
  tabs: `${PREFIX}-tabs`,
  sort: `${PREFIX}-sort`
}

const StyledGrid = styled(Grid)((
    {
      theme
    }
) => {
  return {
    [`& .${classes.tabs}`]: {
      textTransform: 'capitalize',
      fontSize: 19,
      fontWeight: 700
    },
  }
});

const TaskLists = ({
    selectedTab,
    handleTabChange
}) => {
  return (
      <StyledGrid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={selectedTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} textColor="secondary">
                      <Tab label="Needs Attention" value="needs_attention" className={classes.tabs} />
                      <Tab label="Purchased" value="purchased" className={classes.tabs} />
                      <Tab label="Sold" value="sold" className={classes.tabs} />
                    </TabList>
                  </Box>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>
  )
}

export default TaskLists;