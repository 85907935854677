import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  gettingAuctionTransactions,
  gettingAuctionTransactionsSuccess,
  gettingAuctionTransactionsFailed,
  creatingTransportation,
  creatingTransportationSuccess,
  creatingTransportationFailed,
  generatingNewSignLink,
  generatingNewSignLinkSuccess,
  generatingNewSignLinkFailed,
  generatingNewPaymentLink,
  generatingNewPaymentLinkSuccess,
  generatingNewPaymentLinkFailed,
  acceptingVehicle,
  acceptingVehicleSuccess,
  acceptingVehicleFailed,
  updateTitleProcess,
  updateTitleProcessSuccess,
  updateTitleProcessFailed,
  getAuctionTransactionCount,
  getAuctionTransactionCountSuccess,
  getAuctionTransactionCountFailed,
} from "./transaction.slice";
import { toCamelcase } from "utils/Formatters";

export const createTransportation = createAsyncThunk('/transaction/transporation', async (values, { extra, dispatch }) => {
  try {
    dispatch((creatingTransportation()));

    const responseRunbuggy = await extra.post(`/transaction/transporation`, values);
    dispatch((creatingTransportationSuccess()));
    if (responseRunbuggy?.error) {
      dispatch(creatingTransportationFailed({ error: 'Error!! while creating transportation order' }));
    }
  }
  catch (error) {
    console.log(error.message);
    dispatch(creatingTransportationFailed({ error: error.message }));
  }
});

export const getPurchaseTransactions = createAsyncThunk('transactions/user/purchases', async (values, { extra, dispatch }) => {
  try {
    dispatch((gettingAuctionTransactions({showProcessing: values?.showProcessing === undefined ? true : values.showProcessing})));

    const response = await extra.get(`/transactions/user/purchases`);
    const transactions = response.map(transaction => toCamelcase(transaction));

    dispatch(gettingAuctionTransactionsSuccess({
      transactionType: 'purchased',
      transactions
    }));
  }
  catch (error) {
    console.log(error.message);
    dispatch(gettingAuctionTransactionsFailed({ error: error.message }));
  }
});

export const getSoldTransactions = createAsyncThunk('transactions/user/sold', async (values, { extra, dispatch }) => {
  try {
    dispatch((gettingAuctionTransactions({showProcessing: values?.showProcessing === undefined ? true : values.showProcessing})));

    const response = await extra.get(`/transactions/user/sold`);
    const transactions = response.map(transaction => toCamelcase(transaction));

    dispatch(gettingAuctionTransactionsSuccess({
      transactionType: 'sold',
      transactions
    }));
  }
  catch (error) {
    console.log(error.message);
    dispatch(gettingAuctionTransactionsFailed({ error: error.message }));
  }
});

export const getNeedAttentionTransactions = createAsyncThunk('transactions/attention', async (values, { extra, dispatch }) => {
  try {
    dispatch((gettingAuctionTransactions({showProcessing: values?.showProcessing === undefined ? true : values.showProcessing})));

    const response = await extra.get(`/transactions/user/attention`);
    const transactions = response.map(transaction => toCamelcase(transaction));

    dispatch(gettingAuctionTransactionsSuccess({
      transactionType: 'needs_attention',
      transactions
    }));
  }
  catch (error) {
    console.log(error.message);
    dispatch(gettingAuctionTransactionsFailed({ error: error.message }));
  }
});

export const getNeedAttentionTransactionCount = createAsyncThunk('transactions/attention/count', async (values, { extra, dispatch }) => {
  try {
    dispatch(getAuctionTransactionCount());

    const response = await extra.get(`/transactions/user/attention/count`);

    dispatch(getAuctionTransactionCountSuccess({
      transactionCount: response
    }));
  }
  catch (error) {
    console.log(error.message);
    dispatch(getAuctionTransactionCountFailed({ error: error.message }));
  }
});

export const generateSignLink = createAsyncThunk('transaction/signingLink', async (values, { extra, dispatch }) => {
  try {
    dispatch(generatingNewSignLink());
    const link = await extra.post(`/transaction/signingLink`, values);
    dispatch(generatingNewSignLinkSuccess({ link }));
  }
  catch (error) {
    console.log(error.message);
    dispatch(generatingNewSignLinkFailed({ error: error.message }));
  }
});

export const generatePaymentLink = createAsyncThunk('transaction/paymentLink', async (values, { extra, dispatch }) => {
  try {
    dispatch(generatingNewPaymentLink());
    const link = await extra.post(`/transaction/paymentLink`, values);
    dispatch(generatingNewPaymentLinkSuccess({ link }))
  }
  catch (error) {
    console.log(error.message);
    dispatch(generatingNewPaymentLinkFailed({ error: error.message }));
  }
});

export const acceptVehicle = createAsyncThunk('transaction/review', async (values, { extra, dispatch }) => {
  try {
    dispatch(acceptingVehicle());
    const result = await extra.post(`/transaction/review`, values);
    dispatch(acceptingVehicleSuccess({ result }))
  }
  catch (error) {
    console.log(error.message);
    dispatch(acceptingVehicleFailed({ error: error.message }));
  }
});

export const updateTitleProcessing = createAsyncThunk('transaction/completedate', async (values, { extra, dispatch }) => {
  try {
    dispatch(updateTitleProcess());
    await extra.post(`/transaction/completedate`, values);
    dispatch(updateTitleProcessSuccess());
  }
  catch (error) {
    console.log(error.message);
    dispatch(updateTitleProcessFailed({ error: error.message }));
  }
});