import { createSlice } from '@reduxjs/toolkit'

export const auctionSlice = createSlice({
    name: 'search',
    initialState: {
        count: 0,
        activeAuctionCount: 0,
        activeAuctionCountLoading: false,
        isLoadingActiveAuctionCount: false,
        isLoadingAuctionTransactionCount: false,
        auctions: [],
        bidHistory: {},
        error: undefined,
        auctionLoading: false,
        savingBid: false,
        savingReserve: false,
        bidsLoading: false,
        bidSaved: '',
        reserveSaved: '',
        placing: {},
    },
    reducers: {
        loadingAuctions: (state, action) => {
            return {
                ...state,
                ...action.payload,
                isLoadingAuctions: false,
            }
        },
        loadingAuctionsFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                auctionLoading: false,
                isLoadingAuctions: false,
                bidsLoading: false,
            }
        },
        loadingAuctionsSuccessful: (state, action) => {
            return {
                ...state,
                ...action.payload,
                isLoadingAuctions: false,
                auctionLoading: false,
                error: undefined
            }
        },
        bidLoading: (state, action) => {
            return {
                ...state,
                bidsLoading: true
            }
        },
        bidHistory: (state, action) => {
            const newBidHistory = {...state.bidHistory}
            newBidHistory[action.payload.token] = action.payload.bids;

            return {
                ...state,
                bidHistory: newBidHistory,
                bidsLoading: false
            }
        },
        placingBid: (state, action) => {
            return {
                ...state,
                updatedBid: {},
                bidSaved: undefined,
                savingBid: true
            }
        },
        placingBidSuccess: (state, action) => {
            return {
                ...state,
                updatedBid: action.payload.updatedBid,
                bidSaved: action.payload.bidSaved,
                savingBid: false
            }
        },
        placingBidFailed: (state, action) => {
            return {
                ...state,
                error: action.payload.error,
                updatedBid: {},
                bidSaved: undefined,
                savingBid: false
            }
        },
        updateReservePrice: (state, action) => {
            return {
                ...state,
                savingReserve: true
            }
        },
        updatingReservePrice: (state, action) => {
            return {
                ...state,
                ...action.payload,
                savingReserve: false
            }
        },
        startingActiveAuctionCount: (state, action) => {
            return {
                ...state,
                isLoadingActiveAuctionCount: true
            }
        },
        setActiveAuctionCount: (state, action) => {
            return {
                ...state,
                ...action.payload,
                isLoadingActiveAuctionCount: false
            }
        },
        errorFound: (state, action) => {
            return {
                ...state,
                ...action.payload,
                auctionLoading: false,
                bidsLoading: false,
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    loadingAuctions,
    loadingAuctionsSuccessful,
    loadingAuctionsFailed,
    errorFound,
    bidLoading,
    bidHistory,
    placingBid,
    placingBidSuccess,
    placingBidFailed,
    updateReservePrice,
    updatingReservePrice,
    startingActiveAuctionCount,
    setActiveAuctionCount
 } = auctionSlice.actions

export default auctionSlice.reducer