import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import EmailIcon from '@mui/icons-material/EmailOutlined';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import CommentIcon from '@mui/icons-material/ModeCommentOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';
import Image from "components/widgets/Image";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PREFIX = 'WorksPanel';

const classes = {
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    border: `${PREFIX}-border`,
    tabContainer: `${PREFIX}-tabContainer`,
    tabs: `${PREFIX}-tabs`,
    tabPanel: `${PREFIX}-tabPanel`,
    img: `${PREFIX}-img`,
    banner: `${PREFIX}-banner`,
    detail: `${PREFIX}-detail`,
    btn: `${PREFIX}-btn`,
    card: `${PREFIX}-card`,
    cardContent: `${PREFIX}-card-content`,
    starImg: `${PREFIX}-starImg`,
    selected: `${PREFIX}-selected`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        marginTop: 50,
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        [`& .${classes.logo}`]: {
            height: 50,
            width: 50
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.border}`]: {
            width: 170,
            marginBottom: 10,
            borderTop: `3px solid ${theme.palette.secondary.main}`,
            float: 'left',
        },
        [`& .${classes.tabs}`]: {
            fontSize: 17,
            textTransform: 'capitalize',
            fontWeight: 600,
            color: '#9CA3B1',
        },
        [`& .${classes.tabPanel}`]: {
            borderRight: 1,
            borderColor: 'divider',
            height: '100%',
        },
        [`& .${classes.tabContainer}`]: {
            paddingTop: 20,
            background: theme.palette.primary.background,
        },
        [`& .${classes.img}`]: {
            width: 40,
            height: 40
        },
        [`& .${classes.banner}`]: {
            height: 290,
            marginLeft: 20
        },
        [`& .${classes.detail}`]: {
            marginLeft: 20,
            marginTop: 30,
        },
        [`& .${classes.btn}`]: {
            marginTop: 40,
            textTransform: 'capitalize',
        },
        [`& .${classes.card}`]: {
            margin: 10,
            borderRadius: 10,
            padding: '20px 0 20px 20px',
            position: 'relative',
            background: '#FFFFFF',
            border: '1px solid rgba(156, 163, 177, 0.5)',
            boxShadow: '0px 10px 26px rgba(0, 0, 0, 0.12)',
        },
        [`& .${classes.cardContent}`]: {
            display: 'flex',
            alignItems: 'center',
        },
        [`& .${classes.starImg}`]: {
            height: 35,
            width: 35,
            marginRight: 20
        },
        [`& .${classes.selected}`]: {
            background: theme.palette.primary.main
        },
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="flex">
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const WorksPanel = () => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <StyledGrid container>
            <Grid item xs={12}>
                <Box className={classes.flex}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/ReinventLogo.png'
                        className={classes.logo}
                    />
                    <Typography variant='h1' color="#213E98">How it <span className={classes.right}>Works</span></Typography>
                </Box>
                <hr className={classes.border} />
            </Grid>

            <Grid item xs={12} sm={2} className={classes.tabContainer}>
                <Tabs
                    orientation={isSmallScreen ? 'horizontal' : 'vertical'}
                    variant="scrollable"
                    value={value}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabPanel}
                >
                    <Tab
                        icon={<Avatar className={value === 0 ? classes.selected : ''}><EmailIcon/></Avatar>}
                        iconPosition="start"
                        label="Sign Up For FREE"
                        {...a11yProps(0)}
                        className={classes.tabs}
                    />
                    <Tab
                        icon={<Avatar className={value === 1 ? classes.selected : ''}><SearchIcon/></Avatar>}
                        iconPosition="start"
                        label="Browse Inventory"
                        {...a11yProps(1)}
                        className={classes.tabs}
                    />
                    <Tab
                        icon={<Avatar className={value === 2 ? classes.selected : ''}><CommentIcon/></Avatar>}
                        iconPosition="start"
                        label="Connect Virtually"
                        {...a11yProps(2)}
                        className={classes.tabs}
                    />
                    <Tab
                        icon={<Avatar className={value === 3 ? classes.selected : ''}><CreditCardIcon/></Avatar>}
                        iconPosition="start"
                        label="Easy Transaction"
                        {...a11yProps(3)}
                        className={classes.tabs}
                    />
                </Tabs>
            </Grid>

            <Grid item xs={12} sm={10} className={classes.tabContainer}>
                <TabPanel value={value} index={0}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/signupImage.png'
                        className={classes.banner}
                    />
                    <Box className={classes.detail}>
                        <Typography variant="h5">Get started by signing up now! Listing your inventory is easy!</Typography>
                        <Typography variant="h6" color="#9CA3B1" className="pt-6">Once you register with ReinventAuctions, your inventory will automatically be uploaded into our platform through your inventory data provider.</Typography>
                        <Button variant="contained" color="primary" className={classes.btn} size="large" onClick={() => navigate('/signup')}>Join. Click. Trade.</Button>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/secondBanner.png'
                        className={classes.banner}
                    />
                    <Box className={classes.detail}>
                        <Typography variant="h5">Browse vehicles from Trusted Dealers from around the country.</Typography>
                        <Typography variant="h6" color="#9CA3B1" className="pt-6">
                            Reinvent can give dealers more flexibility and confidence
                            in taking in trades because they now have access to a nationwide dealer network to sell those units
                            to dealers who are looking to buy up used inventory or likewise buy more used units to refurbish and sell..
                        </Typography>
                        <Button variant="contained" color="primary" className={classes.btn} size="large" onClick={() => navigate('/signup')}>Join. Click. Trade.</Button>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/thirdBanner.png'
                        className={classes.banner}
                    />
                    <Box className={classes.detail}>
                        <Typography variant="h5">Connecting with other dealers is easy, the transaction is a direct line with no middlemen.</Typography>
                        <Button variant="contained" color="primary" className={classes.btn} size="large" onClick={() => navigate('/signup')}>Join. Click. Trade.</Button>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/fourthBanner.png'
                        className={classes.banner}
                    />
                    <Box className={classes.detail}>
                        <Typography variant="h5">Everything is online – transactions are easy, fast, and secure.</Typography>
                        <Button variant="contained" color="primary" className={classes.btn} size="large" onClick={() => navigate('/signup')}>Join. Click. Trade.</Button>
                    </Box>
                </TabPanel>
            </Grid>

            <Grid item xs={12} style={{marginTop: 50}}>
                <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Image
                                    alt="Banner Image"
                                    src='/static/images/StarIcon.png'
                                    className={classes.starImg}
                                />
                                <Typography variant="h5" className="pl-4">MAKE MORE <br/> MONEY</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Image
                                    alt="Banner Image"
                                    src='/static/images/StarIcon.png'
                                    className={classes.starImg}
                                />
                                <Typography variant="h5" className="pl-4">FIND MORE <br/> INVENTORY</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Image
                                    alt="Banner Image"
                                    src='/static/images/StarIcon.png'
                                    className={classes.starImg}
                                />
                                <Typography variant="h5" className="pl-4">TRADE UNSOLD <br/> UNITS</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <Image
                                    alt="Banner Image"
                                    src='/static/images/StarIcon.png'
                                    className={classes.starImg}
                                />
                                <Typography variant="h5" className="pl-4">EXPAND YOUR <br/> SHOWROOM</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </StyledGrid>
    )
}

export default WorksPanel;