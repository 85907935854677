import { createAsyncThunk } from '@reduxjs/toolkit'
import {
    loadingAuctions,
    loadingAuctionsSuccessful,
    loadingAuctionsFailed,
    errorFound,
    bidLoading,
    bidHistory,
    placingBid,
    placingBidSuccess,
    placingBidFailed,
    updateReservePrice,
    updatingReservePrice,
    startingActiveAuctionCount,
    setActiveAuctionCount
}
    from './auction.slice';
import { loadAgingVehicles } from './vehicle.actions';

export const allAuctions = createAsyncThunk('auctions', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingAuctions({ auctionLoading: values.showLoading }));
        const response = await extra.get(`/auction/vertical?type=others&so=${values.sort}&se=${values.search}&pvr=${values.platformVertical}`);
        dispatch(loadingAuctionsSuccessful({
            count: response?.count,
            auctions: response?.data,
            bidSaved: '',
            reserveSaved: '',
            loadedType: values.type,
            loadedSort: values.sort
        }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingAuctionsFailed({ error: 'Record not found' }));
    }
});

export const myAuctions = createAsyncThunk('auctions/mine', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingAuctions({ auctionLoading: values.showLoading }));
        const response = await extra.get(`/auction/vertical?type=mine&so=${values.sort}&se=${values.search}&pvr=${values.platformVertical}`);
        dispatch(loadingAuctionsSuccessful({
            auctionLoading: false,
            count: response?.count,
            auctions: response?.data,
            bidSaved: '',  // Why??
            reserveSaved: '', // Why??
            loadedType: values.type,
            loadedSort: values.sort
        }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingAuctionsFailed({ auctionLoading: false, error: 'Record not found' }));
    }
});

export const internalAuctions = createAsyncThunk('auctions/internal', async (values, { extra, dispatch }) => {
    try {
        dispatch(loadingAuctions({ auctionLoading: values.showLoading }));
        const response = await extra.get(`/auction/vertical?type=internal&so=${values.sort}&se=${values.search}&pvr=${values.platformVertical}${values.rooftops ? `&rooftops=${values.rooftops}` : ''}`);
        dispatch(loadingAuctionsSuccessful({
            auctionLoading: false,
            count: response?.count,
            auctions: response?.data,
            loadedType: values.type,
            loadedSort: values.sort
        }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(loadingAuctionsFailed({ auctionLoading: false, error: 'Record not found' }));
    }
});

export const getActiveAuctionCount = createAsyncThunk('auction/activeCount', async (values, { extra, dispatch }) => {
    try {
        dispatch(startingActiveAuctionCount());
        const response = await extra.get(`/auction/activeCount`);
        dispatch(setActiveAuctionCount({ activeAuctionCount: response?.count }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(errorFound({ isLoadingActiveAuctionCount: false, error: 'Record not found' }));
    }
});

export const placeBid = createAsyncThunk('auction/bid', async (values, { extra, dispatch }) => {
    try {
        dispatch(placingBid());

        const response = await extra.post('/auction/bid/', values);
        if (response.bid.error) {
            dispatch(placingBidSuccess({ updatedBid: response.bid}));
        } else {
            dispatch(placingBidSuccess({ updatedBid: response.bid, bidSaved: values.auction_id }));
        }
    }
    catch (error) {
        console.log(error.message);
        dispatch(placingBidFailed({ error: 'Record not found' }));
    }
});

export const getBidHistory = createAsyncThunk('auction/bidHistory', async (values, { extra, dispatch }) => {
    try {
        dispatch(bidLoading({ bidLoading: true }));
        const response = await extra.get(`/auction/bids/${values.token},${values.page},${values.flag}`);
        dispatch(bidHistory({ bids: response?.bids, token: values.token }));
    }
    catch (error) {
        console.log(error.message);
        dispatch(errorFound({ bidsLoading: false, error: 'Record not found' }));
    }
});

export const updatePrice = createAsyncThunk('auction/reservePrice', async (values, { extra, dispatch }) => {
    try {
        dispatch(updateReservePrice({ savingReserve: true, reserveSaved: undefined }));
        await extra.post('/auction/reserve', values);
        dispatch(updatingReservePrice({ savingReserve: false, reserveSaved: values.token }));
        dispatch(
            loadAgingVehicles({
              sort: "aging",
              dealerId: values.dealer_id,
              searchType: "my-inventory",
              limitAmount: 130,
            })
          );
    }
    catch (error) {
        console.log(error.message);
        dispatch(errorFound({ savingReserve: false, error: error.message }));
    }
});
