import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import classNames from "classnames";
import { loadRooftopsForFilter } from "data/location.actions";
import { updateFilter } from "data/search.actions";

const PREFIX = 'RooftopSearchPanel';

const classes = {
  button: `${PREFIX}-button`,
  selectedButton: `${PREFIX}-selectedButton`,
  deselectedButton: `${PREFIX}-deselectedButton`,
}

const StyledGrid = styled(Grid)((
    {
      theme
    }
) => {
  return {
    [`& .${classes.button}`]: {
      height: 45
    },
    [`& .${classes.selectedButton}`]: {
      background: theme.palette.primary.main,
      color: theme.palette.text.white
    },
    [`& .${classes.deselectedButton}`]: {
      background: theme.palette.text.lightGray,
      color: theme.palette.text.black
    },
  }
});

const RooftopSearchPanel = ({
}) => {
  const dispatch = useDispatch();
  const { rooftopsForFilter } = useSelector(state => state.location);
  const { rooftops: selectedRooftops } = useSelector(state => state.searchCriteria);
  const { user } = useSelector(state => state.authentication);
  const { dealer_token: dealerToken } = user || {};

  useEffect(() => {
    dispatch(loadRooftopsForFilter({ dealerToken }));
  }, [dispatch, dealerToken]);

  const filterChanged = (value) => {
    if (value === 'all-option') {
      dispatch(updateFilter({
        value: {rooftops: []},
        reset: true,
        multiple: true
      }));
    }
    else {
      dispatch(updateFilter({
        value: {rooftops: parseInt(value)},
        multiple: true
      }));
    }
  }

  return (
      <StyledGrid container spacing={1}>
        <Grid className='ml-4 mt-2'>
          <Button
              variant="contained"
              className={
                classNames({
                  [classes.button]: true,
                  [classes.selectedButton]: (!selectedRooftops || selectedRooftops?.length === 0),
                  [classes.deselectedButton]: (selectedRooftops && selectedRooftops?.length > 0)
                })
              }
              size="large"
              value=""
              onClick={(e) => {
                filterChanged('all-option')
              }}
          >
            All
          </Button>
        </Grid>
        {rooftopsForFilter?.map((rooftop) => (
            <Grid key={rooftop.id} className='ml-4 mt-2'>
              <Button
                  variant="contained"
                  className={
                    classNames({
                      [classes.button]: true,
                      [classes.selectedButton]: selectedRooftops?.includes(rooftop.dealerRooftopId),
                      [classes.deselectedButton]: !selectedRooftops?.includes(rooftop.dealerRooftopId)
                    })
                  }
                  size="large"
                  value={rooftop.dealerRooftopId}
                  onClick={(e) => {
                    filterChanged(e.target.value)
                  }}
              >
                {rooftop.rooftopName}
              </Button>
            </Grid>
        ))}
      </StyledGrid>
  )
}


export default RooftopSearchPanel;