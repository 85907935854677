import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";

const PREFIX = 'TermsOfUsePanel';

const classes = {
  titleContainer: `${PREFIX}-titleContainer`,
  contentContainer: `${PREFIX}-contentContainer`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
) => {
  return {
    padding: 30,
    [`& .${classes.titleContainer}`]: {
      marginTop: 30,
    },
    [`& .${classes.contentContainer}`]: {
      marginTop: 30,
      width: '50%'
    },
  }
});

const TermsOfUsePanel = () => {

  return (
      <StyledGrid container>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>TERMS OF USE</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            These website Terms of Use (“Terms of Use”) govern your use of
            http://www.reinventauctions.com, http://www.reinventauto.com,
            http://www.reinventpowersports.com, http://www.reinventrv.com, and
            http://www.reinventmarine.com, http://www.reinventexchange.com, including all information, text, loops, features, functions,
            integrations, interfaces, images, pictures, photographs, graphics, sounds, video, or other materials
            contained in the websites (collectively, the “Websites”), owned and operated by
            ReinventAuctions, Inc. (“ReinventAuctions”). The term ReinventAuctions also includes: (a)
            ReinventAuto, ReinventPowersports, ReinventRV, ReinventMarine, and (b) any subsidiary,
            affiliate, successor, or assign of ReinventAuctions. The terms “you” and “your” mean any person
            who views, accesses, uses, monitors, provides information to the Websites, or obtains information
            from the Websites. By accessing or otherwise using the Websites, you acknowledge that you have
            read, understood and agree to be legally bound by these Terms of Use. If you do not agree to be
            bound by the Terms of Use, please cease accessing or using any part of the Websites.</Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>TERMS OF USE UPDATES</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            ReinventAuctions may, from time to time, in its sole and absolute
            discretion, relocate, update and modify: (a) the Terms of Use, currently posted at
            https://reinventauctions.com/terms-of-use; and (b) the privacy policy which is hereby incorporated
            into Terms of Use, and is currently posted at https://reinventauctions.com/privacy-policy
            (“Privacy Policy”)U30T. The Terms of Use and the Privacy Policy will indicate the date posted, and
            the use of Websites by you after such posting will bind your thereto. In the event there is any
            conflict or inconsistency between the current or any future Terms of Use, or Privacy Policy, the
            most recently posted version shall prevail, supersede, and govern over any previous versions.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>USE OF THE WEBSITES</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            You shall not, nor attempt to: (a) license, sublicense, sell, resell,
            rent, lease, post, transmit, transfer, assign, distribute, time share, infringe upon, commercially
            exploit, reproduce, republish, or otherwise make the Websites, any Intellectual Property (as
            defined in Section 3), or Data (as defined in Section 4) available to any third party; (b) modify,
            copy, create, cache, harvest, crawl, index, scrape, sniff, spider, mine, gather, extract, compile,
            obtain, aggregate, capture, store any derivative works based upon, reverse engineer, decompile,
            dissemble, misappropriate, sniff, access in order to build any competitive or commercially
            available product or service, or otherwise derive the source code, object code or any other
            intellectual property constituting or relating to the Websites, Intellectual Property, or Data; (c)
            send or store infringing, obscene, pornographic, lewd, lascivious, violent, threatening, libelous or
            otherwise unlawful or tortious material, including material that violates third-party privacy rights
            using Websites, Intellectual Property, or Data; (d) send or store malicious or harmful code and/or
            software, including viruses, worms, trojans, key loggers, spyware, ransomware, timebomb,
            software that bypasses normal authentication mechanisms, exploits or attacks software security,
            or any software or data designed to disable, modify or damage Websites, Intellectual Property, or
            Data; (e) transmit, send or store deceptive, forged, junk or unsolicited span messages, emails, text
            message, short message services (“SMS”) or multi-media message services (“MMS”), (f) interfere
            with or disrupt the integrity or performance of Websites, Intellectual Property, or Data; (g) gain
            unauthorized access to Websites, Intellectual Property, or Data; (h) use Websites, Intellectual
            Property, or Data in violation of any applicable laws or regulation; and (i) use Websites, any
            service or offering of ReinventAuctions, Intellectual Property, or Data in violation of the Terms of
            Use.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>ALL INTELLECTUAL PROPERTY IS EXCLUSIVELY OWNED BY REINVENTAUCTIONS</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            ReinventAuctions retains the sole and exclusive ownership of the Websites, any service or offering
            of ReinventAuctions, and any Intellectual Property (as defined below) of ReinventAuctions. The
            term “Intellectual Property” includes all rights, title, and interest to any proprietary information
            and all forms of intellectual property rights and protections arising under any Federal, state, foreign
            or common law, or administrative regulations, whether registered or unregistered, that may be
            obtained for, or may pertain to ReinventAuctions, Websites, including any other service or offering
            of ReinventAuctions, which includes, without limitation: (a) all inventions, improvements,
            patents, design and/or utility patent registrations and applications (including all reissues,
            reexaminations, provisionals, divisionals, continuations, continuations-in-part and extensions of
            any patent or patent application) currently existing or hereafter filed, designs and applications for
            registration of designs; (b) all copyrights, mask works, other works of authorship, literary property
            or author’s rights, whether or not protected by copyright or as a mask work; (c) all trademarks,
            service marks, trademark application,service mark applications, trade names, trade dress, symbols,
            proprietary indicia, logos and/or brand names; (d) all software, source code, object code,
            applications, application programing integrations, applets, extensible markup language, text,
            graphics, images, icons, and user interfaces; (e) all Data, data information, data rights, and privacy
            rights; (f) all trade secrets (including ideas, research and development, know-how, formulas,
            compositions, processes and techniques, technical data, designs, drawings, specifications) and all
            trade secret rights and equivalent rights; (g) all confidential information; (h) all other proprietary;
            (i) all copies and tangible embodiments thereof, in any and all forms and mediums now known or
            hereinafter created; and (j) all additions, modifications, enhancements, upgrades, updates, changes
            and derivative works thereof.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>ALL DATA IS EXCLUSIVELY OWNED BY REINVENTAUCTIONS.</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            You agree that all information
            and data, whether oral, written, visual, electronic, digital, transmitted, downloaded, uploaded,
            received, stored, created or generated as result of the use of the Websites or using the systems,
            equipment, computers, servers, and software of ReinventAuctions is the property of
            ReinventAuctions (“Data”).
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>DISCLAIMER OF ALL WARRANTIES</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            THE WEBSITES ARE PROVIDED “AS IS” WITHOUT
            WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
            OTHERWISE. REINVENTAUCTIONS SPECIFICALLY DISCLAIMS ALL WARRANTIES
            AND ANY IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY
            WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>LIMITATIONS OF LIABILITY</Typography>
          <Typography variant='h2'>LIMITATION OF ACTUAL DAMAGES</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            IN NO EVENT SHALL
            REINVENTAUCTIONS OR ANY OF REINVENTAUCTIONS’ DIRECTORS, OFFICERS,
            SHAREHOLDERS, EMPLOYEES, INDEPENDENT CONTRACTORS, ADVISORS,
            CONSULTANTS, AGENTS, ATTORNEYS, ACCOUNTANTS, SUBSIDIARIES,
            AFFILIATES, SUCCESSORS, OR ASSIGNS BE LIABLE FOR ANY DAMAGES OF ANY
            KIND ARISING OUT OF OR RELATED TO THE WEBSITES, WHETHER PAST, PRESENT
            OR FUTURE, REGARDLESS OF WHETHER SUCH LIABILITY IS BASED ON BREACH OF
            CONTRACT, BREACH OF WARRANTY, EXPRESS OR IMPLIED, ACTIVE OR PASSIVE
            NEGLIGENCE, INTENTIONAL OR UNINTENTIONAL TORT, STRICT LIABILITY,
            VIOLATION OF STATUTE, ORDINANCE OR REGULATION, FAILURE OF
            CONSIDERATION OR ANY OTHER BASIS, INCLUDING ANY INDIRECT, SPECIAL,
            INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
            LIMITED TO, LOST INCOME, LOST PROFITS, COST OF COVER, OR LOSS OF USE) OF
            ANY KIND. TO THE EXTENT A COURT OF COMPETENT JURISDICTION FINDS THE
            TERMS OF THIS SECTION OVERLY BROAD, UNENFORCEABLE, OR OTHERWISE
            IMPOSES LIABILITY UPON REINVENTAUCTIONS, THEN REINVENTAUCTIONS’
            LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW. MULTIPLE CLAIMS SHALL NOT EXPAND THESE LIMITATIONS.
            YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE USE OF THE WEBSITES OR
            THESE TERMS OF USE IS TO STOP USING THE WEBSITES.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>INDEMNIFICATION</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            You agree to indemnify, defend and hold harmless ReinventAuctions,
            and any of ReinventAuctions’ directors, officers, shareholders, employees, independent
            contractors, advisors, consultants, agents, attorneys, accountants, subsidiaries, affiliates,
            successors, or assigns from and against all expenses, damages, claims, suits, actions, judgments,
            liabilities and costs whatsoever (including attorneys’ fees), whether or not litigation is actually
            commenced, arising out of, or in any way connected with your use of the Websites.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>ENTIRE AGREEMENT</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            This Terms of Use constitute the entire agreement regarding your use
            of the Websites and supersedes all prior agreements, understandings, representations, and
            warranties, whether oral or written. The Terms of Use will not be deemed to create any rights in
            third parties, including but not limited to, or to create any obligations of ReinventAuctions to any
            such third party. No rule of law or contract interpretation which provides that in the case of
            TERMS OF USE PAGE 4 OF 5 REINVENTAUCTIONS, INC.
            ambiguity or uncertainty of a provision should be construed against the draftsman will be applied
            against any party. The language of the Terms of Use shall be interpreted simply according to the
            terms herein and its fair meaning. The headings and titles in the Terms of Use are solely for
            convenience and shall not be used to explain, modify, simplify, or aid in the interpretation of the
            provisions of the Terms of Use.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>EFFECT OF PARTIAL INVALIDITY</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            If one or more provisions of the Terms of Use are held to be
            invalid or unenforceable under applicable law, then such provision shall be excluded from the
            Terms of Use and will be ineffective only to the extent of such invalidity or unenforceability. The
            invalidity of any portion of the Terms of Use will not, and shall not, be deemed to affect the validity
            of any other provision; and all remaining provisions shall be deemed to be in full force and effect.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>GOVERNING LAW</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            The interpretation and construction of the Terms of Use, and all matters
            relating hereto (including, without limitation, the validity or enforcement of the Terms of Use),
            shall be governed by the laws of the State of Illinois without regard to any conflicts or choice of
            law provisions of the State of Illinois that would result in the application of the law of any other
            jurisdiction. You hereby irrevocably: (a) submit to the exclusive jurisdiction of the United States
            District Court for the Northern District of Illinois or any court of the State of Illinois located in
            Cook County in any action, suit or proceeding arising out of or relating to the Terms of Use; (b)
            consent to service of process by any means authorized by the applicable law of the forum in any
            action brought under or arising out of the Terms of Use; (c) waive, to the fullest extent permitted
            by law, any objection that it may now or hereafter have at any time to the venue of any such action,
            suit or proceeding brought in such courts; and (d) waive, to the fullest extent permitted by law, any
            claim that any such action, suit or proceeding brought in such courts has been brought in an
            inconvenient forum, or that such courts do not have jurisdiction.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>LINKS TO OTHER WEBSITES</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            The Websites may periodically provide links to third-party
            websites (“Third-Party Sites”). The Terms of Use governs only the Websites and not any ThirdParty Sites.
            Any link to a Third-Party Site is not an endorsement of the content, products or
            services by ReinventAuctions or the Websites. ReinventAuctions makes no representations
            concerning the information provided in Third-Party Sites, nor the quality of acceptability of the
            information, products, or services offered on any Third-Party Sites. ReinventAuctions has not
            tested or monitored the Third-Party Sites and expressly disclaims any responsibility regarding the
            accuracy, correctness, performance or quality of any information, products, or services on any
            Third-Party Sites. The Third-Party Sites may have different terms of use, privacy policies and
            security standards than the Websites. If you decide to access any linked Third-Party Sites, you do
            so at your own risk.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant='h1'>MINOR CHILDREN</Typography>
        </Grid>

        <Grid item xs={12} className={classes.contentContainer}>
          <Typography variant='h3'>
            The Websites are intended for use by an adult general audience. Any
            use of the Websites by minor children or any individual under the age of eighteen (18) is strictly
            prohibited. The Websites are not intended for use to solicit information from, or to market any
            TERMS OF USE PAGE 5 OF 5 REINVENTAUCTIONS, INC.
            products or services to, minor children or any individual under the age of eighteen (18). Parental
            control protections (such as computer hardware, software, or filtering services) are commercially
            available that may assist you in limiting access to material and information that is harmful to minor
            children.
          </Typography>
        </Grid>
      </StyledGrid>
  )
}

export default TermsOfUsePanel;