import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useField } from "formik";

const RETextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <Typography color="#253241">{label}</Typography>
      <TextField
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
        {...field}
        {...props}
      />
      <br />
      <Typography variant='body2' color='error'>{meta.touched && meta.error}</Typography>
    </>
  )
};

export default RETextField;
