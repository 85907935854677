import React from 'react';
import { TabPanel } from '@mui/lab';
import RangeSlider from 'components/widgets/RangeSlider';
import { useSelector } from 'react-redux';

const DistanceFilter = ({ criteria }) => {
  const {
    search: { vehicleOption },
  } = useSelector((state) => state);

  return (
    <TabPanel value='distance'>
      <RangeSlider
        minValue={
          Math.floor(
            vehicleOption.distance?.[vehicleOption.distance?.length - 1]
              ?.distance
          )
            ? Math.floor(
                vehicleOption.distance?.[vehicleOption.distance?.length - 1]
                  ?.distance
              )
            : 0
        }
        maxValue={
          Math.floor(vehicleOption.distance?.[0]?.distance)
            ? Math.floor(vehicleOption.distance?.[0]?.distance)
            : 0
        }
        defaultValue={[criteria.defaultMinDis, criteria.defaultMaxDis]}
        labelText={''}
        handleChange={(value) => {
          const [min, max] = value;
          criteria.setPage(1);
          localStorage.setItem('minDis', min);
          localStorage.setItem('maxDis', max);
          criteria.handleMinDistance(min);
          criteria.handleMaxDistance(max);
          criteria.setDefaultMinDis(null);
          criteria.setDefaultMaxDis(null);
        }}
        disabled={true}
      />
    </TabPanel>
  );
};

export default DistanceFilter;