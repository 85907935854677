import React from "react";
import {useField} from "formik";
import Grid from "@mui/material/Grid";
import CheckboxInput from "components/widgets/Checkbox";

const GeneralAccessPanel = ({classes, key, locationRoles, ...props}) => {
  const [field, , helpers] = useField(props);

  return (
      <Grid key={key} container className={classes.locationAccessPanel}>
        <Grid item xs={12} className={classes.fieldContainer}>
          <h1>General Access</h1>
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          {locationRoles && locationRoles.filter((role, index) => {
            return !role.rooftopRole
          }).map((role, index) => {
            return (
              <div className={classes.inlineCheckbox}>
                <CheckboxInput
                    handleChange={() => {
                        const existing = field.value.generalAccess.find(value => value.roleId  === role.roleId);
                        if (existing) {
                        helpers.setValue({
                          ...field.value,
                          generalAccess: field.value.generalAccess.filter(value => value.roleId  !== role.roleId)
                        });
                      }
                      else {
                        helpers.setValue({
                          ...field.value,
                          generalAccess: [...field.value.generalAccess, {dealerId: field.value.dealerId, roleId: role.roleId}]
                        });
                      }
                    }}
                    checked={ field.value.generalAccess.findIndex(value => value.roleId  === role.roleId) > -1 }
                    text={role.roleName}
                    domainValue={role}
                    name={`${field.name}.roleId`}
                />
              </div>
            )
          })} 
        </Grid>
      </Grid>
  )
};

export default GeneralAccessPanel;