import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, ErrorMessage } from 'formik';
import * as yup from "yup";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Button from "components/widgets/Button";
import TextField from "components/widgets/TextField";
import AddressForm from "components/AddressForm";
import {loadDetailedLocations} from "data/location.actions";
import MaskedInputField from "components/widgets/MaskedInputField";

const PREFIX = 'LocationManagementForm';

const classes = {
  panel: `${PREFIX}-panel`,
  tabContainer: `${PREFIX}-tabContainer`,
  checkboxContainer: `${PREFIX}-checkboxContainer`,
  button: `${PREFIX}-button`,
  formButton: `${PREFIX}-formButton`,
  fieldContainer: `${PREFIX}-fieldContainer`,
  locationAccessPanel: `${PREFIX}-locationAccessPanel`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  formButtonContainer: `${PREFIX}-formButtonContainer`,
  inlineCheckbox: `${PREFIX}-inlineCheckbox`,
  customFormikError: `${PREFIX}-customFormikError`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
)=> {
  return {
    [`& .${classes.panel}`]: {
      marginTop: 30,
      padding: 30,
      borderRadius: 20,
      background: theme.palette.secondary.grey
    },
    [`& .${classes.button}`]: {
      float: 'right',
    },
    [`& .${classes.tabContainer}`]: {
      float: 'right',
    },
    [`& .${classes.fieldContainer}`]: {
      marginTop: 10,
      paddingRight: 20,
    },
    [`& .${classes.locationAccessPanel}`]: {
      marginTop: 10,
      padding: 30,
      borderRadius: 20,
      background: theme.palette.secondary.grey
    },
    [`& .${classes.formButtonContainer}`]: {
      marginTop: 20,
      paddingTop: 10,
      borderTop: 'solid black 1px',
      marginRight: 30,
    },
    [`& .${classes.checkboxContainer}`]: {
      marginTop: 40,
    },
    [`& .${classes.buttonContainer}`]: {
      marginTop: 20,
    },
    [`& .${classes.formButton}`]: {
      marginRight: 10,
      float: 'right',
    },
    [`& .${classes.inlineCheckbox}`]: {
      marginRight: 10,
      float: 'left',
    },
    [`& .${classes.customFormikError}`]: {
      margin: 0,
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Roboto',
      lineHeight: 1.43,
      color: '#b53f3f',
    },
  }});

const initialValues = {
  rooftopName: '',
  phone: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
  }
}

const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const validationSchema = yup.object().shape({
  rooftopName: yup
      .string()
      .required('Please enter rooftop name.'),
  phone: yup
      .string('Enter your phone number')
      .required('Please enter rooftop name.')
      .matches(phoneRegExp, { message: 'Phone must be completely filled out.', excludeEmptyString: true })
      .required('Please enter rooftop phone number.'),
  address: yup.object({
    address1: yup
        .string()
        .required('Please enter address1.'),
    address2: yup
        .string()
        .nullable(),
    city: yup
        .string()
        .required('Please enter city.'),
    state: yup
        .string()
        .required('Please enter state.'),
    postalCode: yup
        .string()
        .required('Please enter zip code.'),
  })
});

const LocationManagementForm = ({locationId, handleUpdateLocation}) => {
  const [startingValues, setStartingValues] = useState(initialValues);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = useSelector(state => state.authentication);
  const {detailedLocations} = useSelector(state => state.location);

  useEffect(() => {
    if (locationId) {
      if (detailedLocations) {
        const newValues = detailedLocations.find(dl => dl.dealerRooftopId === locationId);
        setStartingValues({...newValues});
      }
      else if (user) {
        dispatch(loadDetailedLocations({
          dealerId: user.dealer_id
        }))
      }
    }
    else {
      setStartingValues({...initialValues});
    }
  }, [detailedLocations, user, locationId])

  return (
    <StyledGrid container>
      <Grid item xs={12}>
        <Formik
            initialValues={startingValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              handleUpdateLocation({
                ...values,
              });
              actions.setSubmitting(false);
            }}
        >
          {({handleSubmit, handleChange, touched, errors, setFieldValue, values}) => {
            return (
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={6} className={classes.fieldContainer}>
                      <TextField
                          label='Rooftop Name'
                          name='rooftopName'
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.fieldContainer}>
                      <MaskedInputField
                          label='Phone Number'
                          name='phone'
                          mask="(999) 999-9999"
                          showMask
                      >
                      </MaskedInputField>
                      <div className={classes.customFormikError}>
                        <ErrorMessage name='phone'/>
                      </div>
                    </Grid>
                    <Grid item xs={12} className={classes.fieldContainer}>
                      <AddressForm
                          label='Location Address'
                          name='address'
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className={classes.formButtonContainer}>
                    <Button className={classes.formButton} onClick={handleSubmit}>Save</Button>
                    <Button className={classes.formButton} onClick={() => navigate('/rooftops')}>Cancel</Button>
                  </Grid>
                </Grid>
            )
          }}
        </Formik>
      </Grid>
    </StyledGrid>
  )
}

export default LocationManagementForm;