import { formatDaysAgo } from "utils/Formatters";
import AuctionPanel from "./AuctionPanel";
import { Typography, Grid } from "@mui/material";

const VehicleDetails = ({ vehicle, handleEvent }) => {
  const limitTitle = (inputString) => {
    const maxLength = 26;
    if (inputString?.length > maxLength) {
      return inputString.slice(0, maxLength) + "...";
    } else {
      return inputString;
    }
  };

  return (
    <div className="flex items-center first:mt-[15px] mt-[10px] h-[80px] w-full shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_.25)] rounded-[10px]">
      <div
        onClick={() => handleEvent("view_vehicle_details")}
        className="bg-[#213E98] h-full rounded-l-[7px] flex items-center pl-4 pr-1 font-bold text-[20px] min-w-[300px] max-w-[300px] cursor-pointer"
      >
        {limitTitle(vehicle?.title)}
      </div>

      <div
        onClick={() => handleEvent("view_vehicle_details")}
        className="bg-[#E9392C] h-full flex items-center p-4 text-[#F9F9F9] w-[92px] cursor-pointer"
      >
        <div>
          <Typography>Age: </Typography>
          <Typography fontWeight={700} className="w-max">
            {formatDaysAgo(vehicle?.inventory_date)} days
          </Typography>
        </div>
      </div>
      <div className="bg-[#9CA3B138] rounded-r-[10px] h-full  grow flex ">
        <div
          onClick={() => handleEvent("view_vehicle_details")}
          className="flex w-full justify-between p-5 cursor-pointer"
        >
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography variant="body2" color="textSecondary">
              VIN :
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {vehicle?.vin}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="body2" color="textSecondary">
              Interior :
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {vehicle?.interior_color}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="body2" color="textSecondary">
              Exterior :
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {vehicle?.exterior_color}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="body2" color="textSecondary">
              Cylinders :
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {vehicle?.engine_cylinders ? vehicle?.engine_cylinders : "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="body2" color="textSecondary">
              DriveTrain :
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {vehicle?.driven_wheels ? vehicle?.driven_wheels : "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Typography variant="body2" color="textSecondary">
              Fuel Type :
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {vehicle?.fuel_type ? vehicle?.fuel_type : "N/A"}
            </Typography>
          </Grid>
        </div>
        <div className="my-2 mx-2">
          <AuctionPanel
            vehicle={vehicle}
            handleEvent={(event) => handleEvent(event, vehicle)}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
