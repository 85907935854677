import React from "react";
import Grid from "@mui/material/Grid";
import SearchPanel from "components/SearchResultsPanel";

const BuyView = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <SearchPanel resultType={'buyPage'} />
            </Grid>
        </Grid>
    );
} 

export default BuyView;