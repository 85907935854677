import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from "react-redux";
import { getCategoryCount } from 'data/vehicle.actions';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Image from 'components/widgets/Image';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CategoryPanel from './CategoryPanel';
import styled from "@emotion/styled";

const PREFIX = 'SearchBox'

const classes = {
  btn: `${PREFIX}-btn`,
  btnBackground: `${PREFIX}-btnBackground`,
  select: `${PREFIX}-select`,
  image: `${PREFIX}-image`,
  comboBox: `${PREFIX}-comboBox`,
  option: `${PREFIX}-option`,
  downArrow: `${PREFIX}-downArrow`
}

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.card}`]: {
      marginBottom: 30,
      boxShadow: '0px 4px 31px rgb(0 0 0 / 5%)',
      borderRadius: 10,
    },
    [`& .${classes.btn}`]: {
      textTransform: 'capitalize',
      width: 100,
      height: 45,
    },
    [`& .${classes.btnBackground}`]: {
      marginRight: '2px',
      textTransform: 'capitalize',
      background: theme.palette.secondary.main,
      width: 100,
      height: 45,
    },
    [`& .${classes.select}`]: {
      background: theme.palette.background.grey,
      height: 45,
      color: '#6B7886',
      padding: 12
    },
    [`& .${classes.comboBox}`]: {
      display: 'flex',
      // width: '75px'
    },
    [`& .${classes.image}`]: {
      aspectRatio: 'auto',
      height: 22
    },
    [`& .${classes.option}`]: {
      marginLeft: 10
    },
    [`& .${classes.downArrow}`]: {
      marginLeft: 30
    },
  }
});

const SearchBox = ({
  criteria,
  resultType
}) => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const { search } = useSelector(state => state);
  const { categoryCount } = search;

  useEffect(() => {
    dispatch(getCategoryCount(resultType));
  }, [
    dispatch,
    resultType
  ]);

  const handleStorge = (val) => {
    localStorage.setItem('category', val);
    localStorage.setItem('search', "");
    localStorage.setItem('minDis', null);
    localStorage.setItem('maxDis', null);
    localStorage.setItem('used', "");
    localStorage.setItem('vehFuelTypes', "");
    localStorage.setItem('makes', "");
    localStorage.setItem('models', "");
    localStorage.setItem('trims', "");
    localStorage.setItem('vehDealers', "");
    localStorage.setItem('minWeight', "ANY");
    localStorage.setItem('maxWeight', "ANY");
    localStorage.setItem('minYear', null);
    localStorage.setItem('maxYear', null);
    localStorage.setItem('minMileage', null);
    localStorage.setItem('maxMileage', null);
    localStorage.setItem('minPrice', null);
    localStorage.setItem('maxPrice', null);
    localStorage.setItem('minKBBPrice', null);
    localStorage.setItem('maxKBBPrice', null);
    localStorage.setItem('minLength', "ANY");
    localStorage.setItem('maxLength', "ANY");
    localStorage.setItem('slideout', "");
    localStorage.setItem('sleeps', "");
    localStorage.setItem('sort', 'lowest_price');
  }

  return (
    <StyledGrid container>
        <Box sx={{display: 'flex', flexDirection: { xs: "column", md: "row"}, width: '1', mt: 4}}>

          <Paper
            component="form"
            sx={{ display: 'inline-flex', flex: 1, alignItems: 'center'}}
          >
            <Box className={classes.select} onClick={() => setIsShow(!isShow)}> 
              <div className={classes.comboBox}>
                <Image
                  src={`/static/images/${criteria.vehcategory}.png`}
                  alt="Auto"
                  className={classes.image}
                />
                <label className={classes.option}>{criteria.vehcategory}</label>
                {isShow ? <KeyboardArrowUpIcon className={classes.downArrow} /> : <KeyboardArrowDownIcon className={classes.downArrow} />}
              </div>
            </Box>
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Vehicles By Make, Model, Year OR VIN"
              inputProps={{
                'aria-label': 'search vehicles'
              }}
              onChange={(e) => {
                localStorage.setItem('search', e.target.value);
                criteria.handleSearchChange(e.target.value)
              }}
              value={criteria.vehSearch}
            />
          </Paper>
          <Box   sx={{
                        display: 'flex',
                        marginLeft: '5px',
                        marginTop:  {xs: '5px', md: '0px' }

                    }}> 
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.btnBackground}
              onClick={criteria.handleSearch}
            >
              Search
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.btn}
              onClick={criteria.handleReset}
              >
              Reset
            </Button>
          </Box>
          </Box>
        <Grid item xs={12}>
          <CategoryPanel
            isShow={isShow}
            handleCategory={(value) => {
              handleStorge(value);
              setIsShow(false);
              criteria.handleCategory(value);
            }}
            classes={classes}
            categoryArr={categoryCount}
            category={criteria.vehcategory}
          />
        </Grid>
    </StyledGrid>
  );
}

export default SearchBox;