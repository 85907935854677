import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatDateTime } from "utils/Formatters";
import { generatePaymentLink } from "data/transaction.actions";
import InfoPopover from "components/widgets/InfoPopover";

const FundingPanel = ({ classes, transaction, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { transaction: { paymentLink } } = useSelector(state => state);
  const { sertifiPaymentReceivedDate, errorMessage, folderId, documentId, paymentId, buyerId, buyerEmail } = transaction || {};

  const generateNewPaymentLink = (docId, documentFolderId, payId, email) => {
    const data = {
      documentId: docId,
      folderId: documentFolderId,
      paymentId: payId,
      buyerEmail: email
    };
    dispatch(generatePaymentLink(data));
    setIsOpen(true);
  }

  useEffect(() => {
    if (paymentLink !== "" && isOpen) {
      window.open(paymentLink, '_blank');
      setIsOpen(false);
    }
  }, [paymentLink, isOpen]);

  return (

    <Grid container>
      <Grid item xs={12}>
        {sertifiPaymentReceivedDate && (
          <CheckCircleIcon className={classes.completed} />
        )}
        {!sertifiPaymentReceivedDate && (
          <ScheduleIcon className={classes.scheduled} />
        )}
        <Typography variant='body'>Buyer Entered Bank Information</Typography>
        {(!sertifiPaymentReceivedDate && (buyerId === id)) ?
          ((folderId && paymentId) ?
            <Typography variant='body' className={classes.action} onClick={() => generateNewPaymentLink(documentId, folderId, paymentId, buyerEmail)}>Add</Typography>
            : <Typography variant='body' className={classes.action}><InfoPopover info="Please contact to the reinvent teams." text="N/A" /></Typography>)
          : ''}
        {sertifiPaymentReceivedDate && (
          <Typography variant='body' className={classes.actionDate}>{formatDateTime(sertifiPaymentReceivedDate)}</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {sertifiPaymentReceivedDate && (
          <CheckCircleIcon className={classes.completed} />
        )}
        {!sertifiPaymentReceivedDate && (
          <ScheduleIcon className={classes.scheduled} />
        )}
        <Typography variant='body'>Bank Transfer Initiated</Typography>
        {sertifiPaymentReceivedDate && (
          <Typography variant='body' className={classes.actionDate}>{formatDateTime(sertifiPaymentReceivedDate)}</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        {errorMessage && (
          <>
            <CheckCircleIcon className={classes.completed} />
            <Typography variant='body' className={classes.actionDate}><InfoPopover info={errorMessage} text="Exceptions" /></Typography>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default FundingPanel;