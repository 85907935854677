import { React, useState } from 'react';
import { useSelector } from 'react-redux';
import { TabPanel } from '@mui/lab';
import Grid from '@mui/material/Grid';
import RangeSlider from 'components/widgets/RangeSlider';
import { Typography, Checkbox, Divider } from '@mui/material';

const PriceFilter = ({ criteria }) => {
  const {
    search: { vehicleOption },
  } = useSelector((state) => state);
  const [isPrice, setIsPrice] = useState(true);
  const [isKbbPrice, setIsKbbPrice] = useState(true);

  return (
    <TabPanel value='price'>
      <Grid container justifyContent={'space-around'}>
        <Grid xs={5} p={2}>
          <Grid container justifyContent={'space-between'}>
            <Grid>
              <Typography variant='h4'>Price</Typography>
            </Grid>
            <Grid>
              <Checkbox
                onChange={(e) => {
                  criteria.setPage(1);
                  setIsPrice(e.target.checked);
                }}
                defaultChecked={isPrice}
              />
            </Grid>
          </Grid>
          <RangeSlider
            minValue={
              vehicleOption.price?.[vehicleOption.price?.length - 1]?.price
            }
            maxValue={vehicleOption.price?.[0]?.price}
            defaultValue={[criteria.defaultMinPrice, criteria.defaultMaxPrice]}
            labelText={'$'}
            handleChange={(value) => {
              const [min, max] = value;
              if (isPrice) {
                criteria.setPage(1);
                localStorage.setItem('minPrice', min);
                localStorage.setItem('maxPrice', max);
                criteria.handleMinPrice(min);
                criteria.handleMaxPrice(max);
                criteria.setDefaultMinPrice(null);
                criteria.setDefaultMaxPrice(null);
              }
            }}
            disabled={isPrice}
          />
        </Grid>
        <Divider orientation='vertical' variant='middle' flexItem />
        <Grid xs={5} p={2}>
          <Grid container justifyContent={'space-between'}>
            <Grid>
              <Typography variant='h4'>KBB Wholesale Price</Typography>
            </Grid>
            <Grid>
              <Checkbox
                onChange={(e) => {
                  criteria.setPage(1);
                  setIsKbbPrice(e.target.checked);
                }}
                defaultChecked={isKbbPrice}
                disabled={criteria.vehcategory === 'RV' ? false : true}
              />
            </Grid>
          </Grid>
          <RangeSlider
            minValue={
              vehicleOption.kbbPrice?.[vehicleOption.kbbPrice?.length - 1]
                ?.kbb_auction_value !== null
                ? vehicleOption.kbbPrice?.[vehicleOption.kbbPrice?.length - 1]
                    ?.kbb_auction_value
                : 0
            }
            maxValue={vehicleOption.kbbPrice?.[0]?.kbb_auction_value}
            defaultValue={[
              criteria.defaultMinKbbPrice,
              criteria.defaultMaxKbbPrice,
            ]}
            labelText={'$'}
            handleChange={(value) => {
              const [min, max] = value;
              if (isKbbPrice) {
                criteria.setPage(1);
                localStorage.setItem('minKBBPrice', min);
                localStorage.setItem('maxKBBPrice', max);
                criteria.handleMinKbbPrice(min);
                criteria.handleMaxKbbPrice(max);
                criteria.setDefaultMinKbbPrice(null);
                criteria.setDefaultMaxKbbPrice(null);
              }
            }}
            disabled={criteria.vehcategory === 'RV' ? isKbbPrice : false}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default PriceFilter;