import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import styled from "@emotion/styled";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import Button from "components/widgets/Button";
import Spinner from "components/Spinner";
import Checkbox from "components/widgets/Checkbox";
import TextField from "components/widgets/TextField";
import AddressForm from "components/AddressForm";
import { loadOffer, confirmSale, getBuyer, rejectSale, counterOffer } from 'data/offer.actions';
import { formatDateTime, formatDollarAmount } from "utils/Formatters";
import NumberFormatCustom from "components/widgets/NumberFormatCustom";

const PREFIX = 'confirmSaleDialog';
const GRID_PREFIX = 'confirmSale';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  transportProviderSelect: `${PREFIX}-transportProviderSelect`,
  bgCard: `${PREFIX}-bgCard`,
  bottomSpace: `${GRID_PREFIX}-bottopSpace`,
  formBlock: `${GRID_PREFIX}-formBlock`,
  button: `${GRID_PREFIX}-button`,
  TextField: `${PREFIX}-textField`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.closeButton}`]: {
      float: 'right',
      cursor: 'pointer'
    },
    [`& .${classes.transportProviderSelect}`]: {
      width: 410,
    },
    [`& .${classes.bgCard}`]: {
      marginTop: 40,
      padding: 30,
      borderRadius: 20,
      background: theme.palette.secondary.grey,
    },
    [`& .${classes.TextField}`]: {
      width: 470,
      background: '#fff',
      borderRadius: 8,
    },
  }
});

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    marginBottom: 20,
    [`& .${classes.bottomSpace}`]: {
      marginBottom: 10,
    },
    [`& .${classes.formBlock}`]: {
      marginTop: 20,
      marginBottom: 20,
    },
    [`& .${classes.button}`]: {
      borderRadius: 7,
      float: 'right',
      marginLeft: 10,
    },
  }
});

const ConfirmSale = ({ isOpen, action, handleClose, token, offerToken }) => {
  const dispatch = useDispatch();
  const [validationSchema, setValidationSchema] = useState();

  const {
    offer: {
      offer,
      processing,
      processingConfirm,
      error,
      offerAccepted,
      processingReject,
      offerRejected,
      offerCounted,
      processingCounter
    }
  } = useSelector(state => state);

  useEffect(() => {
    if (isOpen && token && offerToken && !error && !processing && !offer) {
      if ('auctionSuccess' !== action) {
        dispatch(loadOffer({ token, offerToken }));
      }
    }
  }, [isOpen, action, token, offerToken, offer, processing, error]);

  useEffect(() => {
    if (isOpen && offerToken && !error && !processing && !offer) {
      if ('auctionSuccess' === action) {
        dispatch(getBuyer({ offerToken }));
      }
    }
  }, [isOpen, offerToken, offer]);

  useEffect(() => {
    if (offerAccepted && isOpen) {
      toast.success('Offer accepted successfully!');
      handleClose();
    }
  }, [offerAccepted, isOpen]);

  useEffect(() => {
    if (offerRejected && isOpen) {
      toast.success('Offer rejected successfully!');
      handleClose();
    }
  }, [offerRejected, isOpen]);


  useEffect(() => {
    if (offerCounted && isOpen) {
      toast.success('Counter offer submitted successfully!');
      handleClose();
    }
  }, [offerCounted, isOpen]);


  useEffect(() => {
    let newSchema = {};
    if (action === 'auctionSuccess' || action === 'confirmSale') {
      newSchema.lienPresent = yup.boolean();
      newSchema.lienAmount = yup.number().when('hasLien', {
        is: true,
        then: (schema) => schema.min(1, 'Lien amount must be greater than or equal to 1.'),
      });
      newSchema.lienholderName = yup.string().when('hasLien', {
        is: true,
        then: (schema) => schema.required('Lien holder name required.'),
      });
      newSchema.lienholderAddress = yup.object({
        address: yup.string(),
        address1: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zip: yup.number()
      }).when('hasLien', {
        is: true,
        then: (schema) => schema.required('Lien holder address required.'),
      });
      newSchema.signerEmail = yup.string().required(`Please enter the signing dealer's email address`);
    } else if (action === 'rejectSale') {
      newSchema.message = yup.string().required(`Please enter the reason for the offer rejection.`);
    } else {
      newSchema.offer_amount = yup.number().when(['current_offer'], (current_offer) => {
        if (yup.number()) {
          return yup.number().integer()
            .required("Please enter counter offer amount")
            .moreThan(current_offer, 'Counter offer price must be greater than old offer price.')
        }
      });
    }

    setValidationSchema(yup.object().shape(newSchema));
  }, [action]);


  return (
    <StyledDialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: "80%" } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box
          onClick={() => handleClose()}
          className={classes.closeButton}
        >
          <Typography variant="h4">
            X
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="pl-10 pr-10">
          {offer?.error === undefined ? (
            <StyledGrid container>
              <Grid item xs={12}>
                <center>
                  <Typography variant="h2">
                    {(action === 'auctionSuccess' || action === 'confirmSale') && `Confirm ${offer?.transaction_type_name ? offer?.transaction_type_name : 'Sale'}`}
                    {(action === 'rejectSale' && offer?.rejected_date === null) && `Reject ${offer?.transaction_type_name ? offer?.transaction_type_name : 'Sale'}`}
                    {action === 'counterOffer' && 'Counter Offer'}
                  </Typography>
                </center>
              </Grid>
              {(action === 'auctionSuccess' || action === 'confirmSale') &&
                <Grid item xs={12} className={classes.bgCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <center>
                        <Typography variant="h3">{offer?.title}</Typography>
                      </center>
                    </Grid>

                    <Grid item xs={12} className={classes.bottomSpace}>
                      <Typography variant='body1'><b>Amount:</b> {formatDollarAmount(offer?.amount ? offer?.amount : offer?.bid_amount)}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant='body1'><b>Buyer:</b> {offer?.dealer_name ? offer?.dealer_name : offer?.dealer_name}</Typography>
                      <Typography variant='body1'><b>Location:</b> {offer?.dealer_location ? offer?.dealer_location : offer?.dealer_location}</Typography>
                      <Typography variant='body1'><b>Offer Date:</b> {formatDateTime(offer?.offer_date ? offer?.offer_date : offer?.created_date)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>}


              <Grid item xs={12}>
                {(action === 'auctionSuccess' || action === 'confirmSale') &&
                  <Grid container className={classes.bgCard}>
                    <Grid item xs={12} className={classes.bottomSpace}>
                      <Typography variant='body2'>
                        In order to initiate the sale transaction, which includes generating all necessary documents such as:
                        <b>Bill of Sale</b>, <b>Guarantee of Title</b> and <b>The Invoices</b> please fill out the following fields.
                      </Typography>
                      <Typography variant='body2'><b>Note:</b> All documents will be signed electronically.</Typography>
                    </Grid>
                  </Grid>}

                <Grid item xs={12}>
                  <Formik
                    initialValues={{
                      lienPresent: false,
                      lienAmount: '',
                      lienholderName: '',
                      lienholderAddress: {
                        address: '',
                        address1: '',
                        city: '',
                        state: '',
                        zip: ''
                      },
                      signerEmail: '',
                      message: '',
                      offer_amount: '',
                      current_offer: offer?.amount
                    }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                      if (action === 'auctionSuccess' || action === 'confirmSale') {
                        dispatch(confirmSale({ ...values, offerToken, token, action }));
                      } else if (action === 'rejectSale') {
                        const data = {
                          offerToken,
                          token,
                          message: values.message
                        }
                        dispatch(rejectSale(data));
                      } else {
                        const data = {
                          offerToken,
                          counterAmount: values.offer_amount
                        }
                        dispatch(counterOffer(data));
                      }
                      actions.setSubmitting(false);
                    }}
                  >
                    {({ handleSubmit, setFieldValue, values }) => {
                      return (
                        <Grid container>
                          {(action === 'auctionSuccess' || action === 'confirmSale') &&
                            <div className={classes.formBlock}>
                              <Grid item xs={12}>
                                <Checkbox
                                  name='lienPresent'
                                  text='Lien On Title?'
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField label='Lien Amount:' name='lienAmount' />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField label='Lien Holder"s Name:' name='lienholderName' />
                              </Grid>

                              <Grid item xs={12}>
                                <AddressForm label='Lien Holder"s Address:' name='lienholderAddress' />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField label='Seller Signer"s Email:' name='signerEmail' />
                              </Grid>
                            </div>}
                          {(action === 'rejectSale' && offer?.rejected_date === null) &&
                            <div className={classes.formBlock}>
                              <Grid item xs={12}>
                                <TextField
                                  name="message"
                                  placeholder="Enter your reason for rejecting this offer here..."
                                  multiline
                                  rows={6}
                                  className={classes.TextField}
                                />
                              </Grid>
                            </div>
                          }

                          {(action === 'rejectSale' && offer?.rejected_date) &&
                            <center>
                              <Typography variant="h3">Unable to perform this action. You have already rejected the offer.</Typography>
                            </center>
                          }

                          {(action === 'counterOffer') &&
                            <div className={classes.formBlock}>
                              <Grid item xs={12} className={classes.bottomSpace}>
                                <Typography variant="h3" name="current_offer">Current Offer: {formatDollarAmount(offer?.amount)}</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  label='Counter Offer:'
                                  name='offer_amount'
                                  className={classes.TextField}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                />
                              </Grid>
                            </div>
                          }

                          <Grid item xs={12}>
                            {(action === 'auctionSuccess' || action === 'confirmSale') &&
                              <Button
                                disabled={offer?.error}
                                className={classes.button}
                                onClick={handleSubmit}
                              >
                                Confirm
                              </Button>}

                            {(action === 'rejectSale' && offer?.rejected_date === null) &&
                              <Button
                                disabled={offer?.error}
                                className={classes.button}
                                onClick={handleSubmit}
                              >
                                Reject
                              </Button>}
                            {action === 'counterOffer' &&
                              <Button
                                disabled={offer?.error}
                                className={classes.button}
                                onClick={handleSubmit}
                              >
                                Counter
                              </Button>}
                          </Grid>
                        </Grid>
                      )
                    }}
                  </Formik>
                </Grid>
              </Grid>

              <Spinner show={processing || processingConfirm || processingReject || processingCounter} />
            </StyledGrid>
          ) : (
            <StyledGrid container>
              <Grid item xs={12}>
                {offer?.error &&
                  <center>
                    <Typography variant="h3">{offer?.error}</Typography>
                  </center>
                }
              </Grid>
            </StyledGrid>
          )}
        </DialogContentText>
      </DialogContent>
    </StyledDialog>
  )
}

export default ConfirmSale;