/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ManageAccountsIcon from '@mui/icons-material/ManageAccountsOutlined';
import ManageSettingsIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import Card from "@mui/material/Card";
import {doLogout} from "data/authentication.actions";

const PREFIX = 'ProfileMenu';

const classes = {
  header: `${PREFIX}-header`,
  linksContainer: `${PREFIX}-linksContainer`,
  profile: `${PREFIX}-profile`,
  profileName: `${PREFIX}-profileName`,
  profileImageContainer: `${PREFIX}-profileImageContainer`,
  profileImage: `${PREFIX}-profileImage`,
  emptyProfileImage: `${PREFIX}-emptyProfileImage`,
  image: `${PREFIX}-image`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
  menuImage: `${PREFIX}-menuImage`,
};

const StyledGrid = styled(Grid)((
    {
      theme
    }
) => {
  return {
    padding: 30,
    [`& .${classes.profile}`]: {
      padding: 30,
      borderRadius: 20,
      background: theme.palette.secondary.grey
    },
    [`& .${classes.profileImageContainer}`]: {
      width: 50,
      borderRadius: 20,
      [`& .${classes.profileImage}`]: {
        width: 50,
        marginRight: 10,
        borderRadius: 20,
      },
      [`& .${classes.emptyProfileImage}`]: {
        width: 50,
        height: 50,
        border: '1px black dashed',
        marginRight: 10,
        borderRadius: 8,
      },
    },
    [`& .${classes.profileName}`]: {
      height: 50,
      paddingTop: 10
    },
    [`& .${classes.menu}`]: {
      margin: '30px 0',
      paddingTop: 10,
      borderRadius: 20,
      background: theme.palette.secondary.grey,
    },
    [`& .${classes.menuItem}`]: {
      marginLeft: 20,
      '& :hover': {
        borderRadius: 20,
        color: theme.palette.text.white,
        background: theme.palette.primary.main,
      },
      '& p': {
        padding: 15,
        '& svg': {
          marginRight: 10
        },
      },
    },
  }
});

export default function ProfileMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.authentication);

  const [initials, setInitials] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    dispatch(doLogout());
    navigate('/');
  }

  useEffect(() => {
    if (user) {
      const newInitials = `${user?.first_name[0]}${user?.last_name[0]}`;
      setInitials(newInitials);
    }
  }, [user]);

  return (
      <div className={classes.menu}>
        <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
          {/*<Tooltip title="Account settings">*/}
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml:2}}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{width: 43, height: 43}}>{initials}</Avatar>
            </IconButton>
          {/*</Tooltip>*/}
        </Button>

        <Popover
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
              width: 300
            }}
        >
          <StyledGrid container>
            <Grid item xs={12} className={classes.profile}>
              <Grid container>
                <Grid item xs={2} className={classes.profileImageContainer}>
                  {user?.image && (
                    <img src={user?.profileImage} />
                  )}
                  {!user?.image && (
                    <Card className={classes.emptyProfileImage} />
                  )}
                </Grid>
                <Grid item xs={10} className={classes.profileName}>
                  <Typography variant='h6'>{user?.first_name} {user?.last_name}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.menu}>
              <div className={classes.menuItem}>
                <div onClick={() => {
                  navigate('/preferences');
                  handleClose();
                }}>
                  <Typography variant='body1'>
                    <ManageSettingsIcon />
                    Preferences
                  </Typography>
                </div>
              </div>

              <div className={classes.menuItem}>
                <div onClick={() => {
                  navigate('/users');
                  handleClose();
                }}>
                  <Typography variant='body1'>
                    <ManageAccountsIcon />
                    User Management
                  </Typography>
                </div>
              </div>

              <div className={classes.menuItem}>
                <div onClick={() => {
                  navigate('/rooftops');
                  handleClose();
                }}>
                  <Typography variant='body1'>
                    <ManageAccountsIcon />
                    Rooftop Management
                  </Typography>
                </div>
              </div>

              <div className={classes.menuItem}>
                <div onClick={handleLogout}>
                  <Typography variant='body1'>
                    <LogoutIcon />
                    Logout
                  </Typography>
                </div>
              </div>
            </Grid>
          </StyledGrid>
        </Popover>
      </div>
  );
}