import AuctionCountdown from "components/AuctionResultPanel/components/AuctionCountdown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { hasRooftopPermission } from "utils/User";
import { useSelector } from "react-redux";

const AuctionPanel = ({ vehicle, handleEvent }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { authentication: {user} } = useSelector(state => state);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePopover = (e) => setAnchorEl(e.currentTarget);
  const closePopover = () => setAnchorEl(null);

  return (
    <div>
      {vehicle && (
        <div className="flex mb-[2px]">
          {["Active", "Expiring"].includes(vehicle?.auction_status) && (
            <div className="text-[#2CC545] font-medium bg-[#2CC545]/10 rounded-[3.1px] w-[53px] text-[11px] leading-[95%] h-[33px] flex text-center items-center mr-[2px]">
              On
              {vehicle?.auction_type === "internal_auction"
                ? " Internal "
                : " "}
              Auction
            </div>
          )}

          {["Not Sold", "Canceled"].includes(vehicle?.auction_status) && (
            <div className="text-[#ED1D24] font-medium bg-[#ED1D24]/10 rounded-[3.1px] w-[150px] text-[14px] leading-[95%] h-[33px] flex justify-center items-center uppercase text-center">
              {vehicle?.auction_status}
            </div>
          )}

          {vehicle?.auction_status === "Scheduled" && (
            <div className="text-[#F4D03F] font-medium bg-[#F4D03F]/10 rounded-[3.1px] w-[150px] text-[14px] leading-[95%] h-[33px] flex justify-center items-center uppercase text-center">
              Scheduled
            </div>
          )}

          {vehicle?.auction_status === null &&
            ["Pending Sale", "Available"].includes(vehicle?.status) && (
              <div className="text-[#2CC545] font-medium bg-[#2CC545]/10 rounded-[3.1px] w-[150px] text-[14px] leading-[95%] h-[33px] flex justify-center items-center uppercase text-center">
                {vehicle?.status}
              </div>
            )}

          {["Sold", "Pending Sale", "Pending Transaction"].includes(
            vehicle?.auction_status
          ) && (
            <div className="text-[#2CC545] font-medium bg-[#2CC545]/10 rounded-[3.1px] w-[150px] text-[14px] leading-[95%] h-[33px] flex justify-center items-center uppercase text-center">
              {vehicle?.auction_status}
            </div>
          )}
          {["Active", "Expiring"].includes(vehicle?.auction_status) && (
            <AuctionCountdown auction={vehicle} size="small" />
          )}
        </div>
      )}

      <button
        aria-describedby={id}
        onClick={handlePopover}
        className={`${
          vehicle ? "" : "mt-4"
        } bg-[#213E98] rounded-[3.5px] shadow-[0px_2.2px_2.2px_0px_rgba(0,_0,_0,_.25)] w-[150px] h-[33px] font-medium flex items-center`}
      >
        <span className="ml-auto mr-auto">Actions</span>
        <ExpandMoreIcon
          htmlColor="#fff"
          className={`${open ? "rotate-180 " : ""} mr-2`}
        />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
        }}
      >
        <div className="text-white text-[9.92px] font-medium bg-white flex flex-col p-2 rounded-b-[3.8px] gap-[10px] shadow-[0px_2.2px_2.2px_0px]">
          <button
            onClick={() => {
              handleEvent("view_vehicle_details");
              closePopover();
            }}
            className="bg-[#213E98] w-[148px] h-[28px] rounded-[3.8px] shadow-[0px_2.2px_2.2px_0px]"
          >
            View Vehicle Details
          </button>
          <button
            onClick={() => {
              handleEvent("internal_external_dialog");
              closePopover();
            }}
            disabled={
              [
                "Sold",
                "Active",
                "Expiring",
                "Pending Sale",
                "Pending Transaction",
              ].includes(vehicle?.auction_status) ||
              ["Sold", "Pending Sale"].includes(vehicle?.status) || !hasRooftopPermission(user, vehicle.dealer_rooftop_id, 'sell_vehicle')
            }
            className="bg-[#213E98] w-[148px] h-[28px] rounded-[3.8px] shadow-[0px_2.2px_2.2px_0px] disabled:opacity-[.14]"
          >
            Place on Auction
          </button>
          <button
            onClick={() => {
              handleEvent("update_reserve_price");
              closePopover();
            }}
            disabled={
              !["Active", "Expiring"].includes(vehicle?.auction_status) ||
              vehicle?.auction_type === "internal_auction" || !hasRooftopPermission(user, vehicle.dealer_rooftop_id, 'sell_vehicle')
            }
            className="bg-[#213E98] w-[148px] h-[28px] rounded-[3.8px] shadow-[0px_2.2px_2.2px_0px] disabled:opacity-[.14]"
          >
            Update Reserve Price
          </button>
          <button
            onClick={() => {
              handleEvent("view_bid_history");
              closePopover();
            }}
            disabled={!["Active", "Expiring"].includes(vehicle?.auction_status)}
            className="bg-[#213E98]  w-[148px] h-[28px] rounded-[3.8px] shadow-[0px_2.2px_2.2px_0px] disabled:opacity-[.14]"
          >
            View History of Bids
          </button>
          <button
            onClick={() => {
              handleEvent("cancel_auctions");
              closePopover();
            }}
            disabled={!["Active", "Expiring"].includes(vehicle?.auction_status)}
            className="bg-[#E9392C] w-[148px] h-[28px] rounded-[3.8px] shadow-[0px_2.2px_2.2px_0px] disabled:opacity-[.14]"
          >
            Cancel Auctions
          </button>
        </div>
      </Popover>
    </div>
  );
};

export default AuctionPanel;
