import React from "react";

const Image = ({ src, alt, className }) => {
    return (
        <img
            style={{maxWidth:'100%', maxHeight: '100%'}}
            alt={alt}
            src={src}
            className={className}
        />
    )
}

export default Image;