import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";

const PREFIX = 'PostSaleProgressPanel';

export const classes = {
  scheduled: `${PREFIX}-scheduled`,
  completed: `${PREFIX}-completed`,
  actionDate: `${PREFIX}-actionDate`,
  action: `${PREFIX}-action`,
  img: `${PREFIX}-img`,
  checkImg: `${PREFIX}-checkImg`
}

export const StyledGrid = styled(Grid)((
  {
    theme
  }
) => {
  return {
    [`& .${classes.scheduled}`]: {
      color: theme.palette.secondary.main,
      maxHeight: 20,
    },
    [`& .${classes.completed}`]: {
      color: theme.palette.text.completed,
      maxHeight: 20,
    },
    [`& .${classes.checkImg}`]: {
      color: theme.palette.text.completed,
      width: 30,
      height: 30
    },
    [`& .${classes.action}`]: {
      float: 'right',
      color: theme.palette.text.link,
      cursor: 'pointer'
    },
    [`& .${classes.actionDate}`]: {
      float: 'right'
    },
    [`& .${classes.img}`]: {
      width: 30,
      height: 30
    },
  }
});