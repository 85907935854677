import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Image from "components/widgets/Image";
import Box from "@mui/material/Box";

const PREFIX = 'ConnectHeader';

const classes = {
    flex: `${PREFIX}-flex`,
    logo: `${PREFIX}-logo`,
    right: `${PREFIX}-right`,
    border: `${PREFIX}-border`,
}

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {
        [`& .${classes.flex}`]: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 10
        },
        [`& .${classes.logo}`]: {
            height: 50,
        },
        [`& .${classes.right}`]: {
            color: theme.palette.secondary.main
        },
        [`& .${classes.border}`]: {
            width: 160,
            marginBottom: 10,
            borderTop: `3px solid ${theme.palette.primary.main}`,
            float: 'left',
        },

    }
});

const ConnectHeader = () => {
    return (
        <StyledGrid container >
            <Grid item xs={12}>
                <Box className={classes.flex}>
                    <Image
                        alt="Banner Image"
                        src='/static/images/ReinventLogo.png'
                        className={classes.logo}
                    />
                    <Typography variant='h1' color="#213E98">
                        <span className={classes.right}>Connect</span> with ReinventX<span className={classes.right}>change</span>
                    </Typography>
                </Box>
                <hr className={classes.border} />
            </Grid>
        </StyledGrid>
    )
}

export default ConnectHeader;