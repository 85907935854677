import React, { createRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { ErrorMessage, Formik } from "formik";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "components/widgets/Button";
import Checkbox from "components/widgets/Checkbox";
import TextField from "components/widgets/TextField";
import Spinner from "components/Spinner";
import {
  createConditionReport,
  getConditionDefinitions,
  getConditionReport,
  updateConditionReport,
} from "data/vehicle.actions";
import ConditionReportFields from "./components/ConditionReportFields";
import ContentDialog from "../ContentDialog";
import { toast } from "react-toastify";
import S3Upload from "../../../utils/s3Upload";

const PREFIX = "LoginDialog";

const classes = {
  buy: `${PREFIX}-buy`,
  cancelButton: `${PREFIX}-cancelButton`,
  label: `${PREFIX}-label`,
  card: `${PREFIX}-card`,
  btnGroup: `${PREFIX}-btnGroup`,
};

const StyledDialog = styled(ContentDialog)(({ theme }) => {
  return {
    [`& .${classes.buy}`]: {
      marginTop: 10,
    },
    [`& .${classes.cancelButton}`]: {
      marginRight: 10,
    },
    [`& .${classes.label}`]: {
      marginLeft: 10,
    },
    [`& .${classes.card}`]: {
      background: "rgba(156, 163, 177, 0.1)",
      margin: "5px 5px 15px 15px",
      height: 140,
      overflowY: "auto",
      paddingLeft: 10,
    },
    [`& .${classes.btnGroup}`]: {
      position: "absolute",
      bottom: 22,
      right: 15,
    },
  };
});

const ConditionReportDialog = ({ isOpen, vehicle, handleClose }) => {
  const conditionRefs = useRef([]);
  const [initialValues, setInitialValues] = useState({});
  const [fileUploadCompleted, setFileUploadCompleted] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState(0);
  const [successfulUploads, setSuccessfulUploads] = useState(0);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  // 'A problem was encountered during the upload operation, please try again or contact Groupware support.'

  const dispatch = useDispatch();
  const {
    search: {
      definitions,
      conditionReport,
      creatingConditionReport,
      conditionReportCreated,
      updatingConditionReport,
      conditionReportUpdated,
      loadingConditionReport,
      loadingConditionDefinitions,
    },
    authentication: { user },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isOpen && !conditionReportCreated && !conditionReportUpdated) {
      if (definitions.length === 0) {
        dispatch(
          getConditionDefinitions({
            category: vehicle?.category,
            vertical: vehicle?.vertical,
          })
        );
      }

      if (
        vehicle?.token &&
        !loadingConditionReport &&
        conditionReport === undefined
      ) {
        dispatch(getConditionReport({ token: vehicle?.token }));
      }
    }
  }, [isOpen, vehicle, definitions]);

  useEffect(() => {
    if (
      isOpen &&
      (conditionReportCreated || conditionReportUpdated) &&
      fileUploadCompleted &&
      vehicle?.token
    ) {
      if (conditionReportCreated === vehicle.token) {
        toast.success("Condition Report created successfully");
        dispatch(getConditionReport({ token: vehicle?.token }));
        setFileUploadCompleted(false);
        handleClose(true);
      } else if (conditionReportUpdated === vehicle.token) {
        toast.success("Condition Report updated successfully");
        dispatch(getConditionReport({ token: vehicle?.token }));
        setFileUploadCompleted(false);
        handleClose(true);
      }
    }
  }, [
    conditionReportCreated,
    conditionReportUpdated,
    fileUploadCompleted,
    vehicle,
  ]);

  useEffect(() => {
    if (
      filesToUpload === 0 ||
      (filesToUpload > 0 &&
        filesToUpload === successfulUploads + uploadErrors.length)
    ) {
      setFileUploadCompleted(true);
    }
  }, [filesToUpload, successfulUploads, uploadErrors]);

  useEffect(() => {
    if (
      definitions.length > 0 &&
      !conditionReportCreated &&
      !conditionReportUpdated
    ) {
      let newValues = {
        isFrontLineReady: conditionReport?.is_front_line_ready || false,
        frontlineReadyDescription: ''
      };
      definitions.forEach((definition) => {
        const conditionName = `option-${definition.id}`;
        const notesName = `notes-${definition.id}`;
        const filesName = `files-${definition.id}`;
        let value = "";
        let notes = "";
        let filenames = [];

        if (conditionReport?.details?.length > 0) {
          const match = conditionReport.details.find(
            (detail) => definition.id === detail.type_id
          );
          value = match ? match.condition : value;
          notes = match ? match.notes : notes;
          filenames = match ? match.photos : filenames;
        }

        newValues[conditionName] = value;
        newValues[notesName] = notes;
        newValues[filesName] = filenames;
      });
      setInitialValues(newValues);
    }
    conditionRefs.current = definitions?.map(
      (def, i) => conditionRefs.current[i] ?? createRef()
    );
  }, [definitions, conditionReport]);

  const validateFields = (values) => {
    const errors = {};
    if (!values.isFrontLineReady) {
      definitions.forEach((definition) => {
        const notesName = `notes-${definition.id}`;
        const conditionName = `option-${definition.id}`;

        if (1 === definition?.notesRequired?.data[0]) {
          if (values[notesName] === undefined) {
            errors[
              notesName
            ] = `Please enter details in the notes section of the ${definition?.name}`;
          }
        } else {
          const requiredOptions = definition.options.filter(
            (option) => 1 === option?.notes_required?.data[0]
          );
          if (requiredOptions.length > 0) {
            if (
              requiredOptions.filter(
                (option) => option.name === values[conditionName]
              ) !== undefined
            ) {
              errors[
                notesName
              ] = `Please enter details in the notes section of the ${definition?.name}`;
            }
          }
        }
      });
    }

    return errors;
  };

  const uploadConditionPhotos = (values) => {
    setFilesToUpload(-1);
    setSuccessfulUploads(-1);
    setUploadErrors([]);

    definitions.forEach((definition) => {
      const filesName = `files-${definition.id}`;
      const s3UploaderProps = {
        autoUpload: true,
        signingUrl: `/api/vehicle/condition/report/photos/link/${vehicle.token}/${definition.id}`,
        signingUrlMethod: "GET",
        signingUrlHeaders: {},
        uploadRequestHeaders: {},
        withCredentials: true,
        onFinishS3Put: (signResult, file) => {
          setSuccessfulUploads(successfulUploads + 1);
        },
        onProgress: (evt) => {
          console.log("progress", evt);
        },
        onError: (evt) => {
          console.log("error uploading", evt);
          setUploadErrors([...uploadErrors, filesName]);
        },
      };

      const files = values[filesName];
      if (files?.length > 0) {
        new S3Upload({ ...s3UploaderProps, files });
        setFilesToUpload(filesToUpload + 1);
      }
    });

    // Let app know we are done and no files to upload
    if (filesToUpload === -1) {
      setFilesToUpload(0);
      setFileUploadCompleted(true);
    }
  };

  const saveConditionReport = (values) => {
    const conditions = definitions.map((definition) => {
      const conditionName = `option-${definition.id}`;
      const notesName = `notes-${definition.id}`;

      let conditionId;
      if (conditionReport.details) {
        const existingDetail = conditionReport.details.find(
          (detail) => detail.type_id === definition.id
        );
        if (existingDetail) {
          conditionId = existingDetail.condition_id;
        }
      }
      return {
        conditionId,
        reportId: conditionReport?.report?.id,
        conditionType: definition.id,
        selectedOption: values[conditionName],
        notes: values[notesName],
      };
    });

    uploadConditionPhotos(values);

    if (conditionReport?.report?.id) {
      dispatch(
        updateConditionReport({
          reportId: conditionReport.report.id,
          userToken: user.uid,
          inventoryToken: vehicle.token,
          isFrontLineReady: values.isFrontLineReady,
          frontlineReadyDescription: values.frontlineReadyDescription,
          conditions,
        })
      );
    } else {
      dispatch(
        createConditionReport({
          userToken: user.uid,
          inventoryToken: vehicle.token,
          isFrontLineReady: values.isFrontLineReady,
          frontlineReadyDescription: values.frontlineReadyDescription,
          conditions,
          dealer_id: vehicle?.dealer_id
        })
      );
    }
  };

  const navigateToSection = (index) => {
    conditionRefs.current[index].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={validateFields}
      onSubmit={(values, actions) => {
        saveConditionReport(values);
        actions.setSubmitting(false);
      }}
    >
      {({ handleSubmit, errors, setErrors, values }) => {
        return (
          <StyledDialog
            open={isOpen}
            title={
              <>
                <Typography variant="h1">CONDITION REPORT</Typography>
                <Typography variant="h4" className="pt-2">
                  Please add your vehicle condition report.
                </Typography>
              </>
            }
            buttonsPanel={
              <Grid container>
                <Grid item xs={9}>
                  {Object.keys(errors).length > 0 && showError && (
                    <Card className={classes.card}>
                      <CardContent>
                        {definitions?.map((def, i) => (
                          <Link onClick={() => navigateToSection(i)}>
                            <ErrorMessage
                              component="div"
                              name={`option-${def.id}`}
                              className="text-danger"
                            />
                            <ErrorMessage
                              component="div"
                              name={`notes-${def.id}`}
                              className="text-danger"
                            />
                          </Link>
                        ))}
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <Box
                    className={
                      Object.keys(errors).length > 0 &&
                      showError &&
                      classes.btnGroup
                    }
                  >
                    <Button
                      className={classes.cancelButton}
                      onClick={() => {
                        handleClose(false);
                        setErrors({});
                      }}
                    >
                      Close
                    </Button>

                    <Button
                      onClick={() => {
                        handleSubmit();
                        setShowError(true);
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            }
          >
            <Grid container>
              <Grid item xs={12}>
                <Checkbox text="Front line ready" name="isFrontLineReady" />

                {values.isFrontLineReady && (
                  <>
                    <TextField
                      label="Please describe the car's imperfections"
                      name="frontlineReadyDescription"
                      multiline
                      rows={6}
                      className="multiline "
                    />
                    <br />
                  </>
                )}

                {definitions?.map((def, i) => (
                  <ConditionReportFields
                    definition={def}
                    forwardedRef={conditionRefs.current[i]}
                  />
                ))}
              </Grid>
            </Grid>
            <Spinner
              show={
                loadingConditionReport ||
                updatingConditionReport ||
                creatingConditionReport ||
                loadingConditionDefinitions
              }
            />
          </StyledDialog>
        );
      }}
    </Formik>
  );
};

export default ConditionReportDialog;
