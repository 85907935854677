import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Form, Formik } from 'formik';
import Spinner from "components/Spinner";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DialogTitle from '@mui/material/DialogTitle';
import Button from "components/widgets/Button";
import TextField from 'components/widgets/TextField';
import InputLabel from '@mui/material/InputLabel';
import styled from "@emotion/styled";
import Checkbox from "components/widgets/Checkbox";
import Select from 'components/widgets/Select';
import { placeBid } from 'data/auction.actions';
import { toast } from "react-toastify";
import NumberFormatCustom from "components/widgets/NumberFormatCustom";
import { formatDollarAmount } from "utils/Formatters";
import { permittedBuyRooftops } from "utils/User";

const PREFIX = 'offerDialog';
const GRID_PREFIX = 'gridOfferDailog';

const classes = {
    closeButton: `${PREFIX}-closeButton`,
    transportProviderSelect: `${PREFIX}-transportProviderSelect`,
    bgCard: `${PREFIX}-bgCard`,
    bottomSpace: `${GRID_PREFIX}-bottopSpace`,
    buying: `${PREFIX}-buying`,
    box: `${PREFIX}-box`,
    btn: `${PREFIX}-btn`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => {
    return {

        [`& .${classes.closeButton}`]: {
            float: 'right',
            cursor: 'pointer'
        },
        [`& .${classes.transportProviderSelect}`]: {
            width: 398,
        },
        [`& .${classes.bgCard}`]: {
            marginTop: 40,
            padding: 30,
            borderRadius: 20,
            background: theme.palette.secondary.grey,
        },
        [`& .${classes.buying}`]: {
            color: 'black',
            fontWeight: 700
        },
        [`& .${classes.box}`]: {
            border: '3px solid #213E98',
            padding: 10,
            borderRadius: 10,
            color: '#213E98',
            marginBottom: 10
        },
        [`& .${classes.btn}`]: {
            marginTop: 30,
            background: '#213e97',
            borderRadius: 7,
            width: '100%',
            height: 50,
            textTransform: 'capitalize',
            marginBottom: 40
        }
    }
});

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => {
    return {

        marginBottom: 20,
        [`& .${classes.bottomSpace}`]: {
            marginBottom: 15,
        },


    }
});

const validationSchema = yup.object().shape({
    bid: yup.number().required("Bid amount is required").when(['minBid', 'bids'], ([minBid, bids]) => {
        if (yup.number()) {
            if (bids === 0) {
                return yup.number().integer().nullable().min(minBid, 'New bid must be the starting bid or greater.')
            }

            return yup.number().integer().nullable().min(minBid, 'New bid must be the minimum bid or greater.')
        }
    }),
    transProvider: yup.string().required("Transport Provider is required"),
    proxyBidMax: yup.number().when(['proxyBid', 'bid'], ([proxyBid, bid]) => {
        if (proxyBid) {
            return yup.number().integer().required('Proxy bid max is required for a proxy bid.').moreThan(bid, 'Max bid must be greater than the current bid.')
        }
        yup.number().nullable()
    }),
    proxyBidIncrement: yup.number().when(['proxyBid'], (proxyBid) => {
        if (proxyBid) {
            return yup.number().integer().required('Proxy bid increment is required for a proxy bid.');
        }
        yup.number().nullable();
    })
});

const PlaceBidDialog = ({ isOpen, handleClose, vehicle = {}, auction, transportProvider }) => {
    const dispatch = useDispatch();
    const { auction: { bidSaved, savingBid, placing }, search: { loadingShippingOptions } } = useSelector(state => state);
    const { rooftopsForUser } = useSelector(state => state.location);
    const { user } = useSelector(state => state.authentication);
    const { auction_token, title, current_bid, bids, bid_increment, vin, } = auction || {};
    const minBid = bids === 0 ? current_bid : current_bid + (bid_increment || 100);
    const permittedRooftops = permittedBuyRooftops(user, vehicle, auction, rooftopsForUser);

    const handleSubmit = (values) => {
        const data = {
            auction_id: auction_token,
            vin,
            bid_amount: values?.bid,
            shipping: values.transProvider,
            proxy_bid_max: values.proxyBid ? values?.proxyBidMax : 0,
            proxy_bid_increment: values.proxyBid ? values?.proxyBidIncrement : 0,
            proxy_bid: values.proxyBid,
            buyer_rooftop_id: values.buyerRooftopId
        };

        dispatch(placeBid(data));
    }

    useEffect(() => {
        if (bidSaved && bidSaved === auction_token) {
            toast.success('Bid saved successfully');
            handleClose();
        }
    }, [bidSaved, auction_token]);

    return (
        <StyledDialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { width: "80%" } }}
        >
            <DialogTitle id="alert-dialog-title">
                <Box
                    onClick={() => handleClose()}
                    className={classes.closeButton}
                >
                    <Typography variant="h4">
                        X
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="pl-10 pr-10">
                    <center>
                        <Typography variant="h1">
                            PLACE BID
                        </Typography>
                    </center>

                    <Formik
                        initialValues={{
                            currentBid: current_bid,
                            bid: minBid,
                            minBid,
                            bids,
                            transProvider: 'transport-reinvent-txn_transport_flat_fee',
                            proxyBidMax: '',
                            proxyBidIncrement: '100',
                            proxyBid: false,
                            buyerRooftopId: parseInt(user?.rooftop_id)
                        }}
                        enableReinitialize
                        validationSchema={validationSchema}
                        onSubmit={(values, actions) => {
                            handleSubmit(values);
                        }}>
                        {({ handleChange, values }) => {
                            return (
                                <Form autoComplete={false}>
                                    <StyledGrid container className={classes.bgCard}>
                                        <Grid item xs={12} className={classes.bottomSpace}>
                                            <Typography variant="h2">{title}</Typography>
                                        </Grid>
                                        <Grid item xs={12} className="pt-2">
                                            <Typography variant="h4" color="#9CA3B1" name="currentBid">{bids > 0 ? 'Minimum' : 'Starting'} Bid: <span className={classes.buying}>{minBid ? formatDollarAmount(minBid) : formatDollarAmount(0)}</span></Typography>
                                        </Grid>
                                        <Grid item xs={12} className="pt-6">
                                            <TextField
                                                name='bid'
                                                label='Your Bid'
                                                onChange={handleChange}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="pt-6">
                                            <InputLabel id="rooftop-label">Select Rooftop</InputLabel>
                                            <Select
                                                labelId="buyer-rooftop-id-label"
                                                id="buyerRooftopId"
                                                name="buyerRooftopId"
                                                label=""
                                                className="offerDialog-transportProviderSelect"
                                                items={permittedRooftops.map((rooftop) => { return {value: rooftop.dealerRooftopId, label: rooftop.rooftopName }})}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} className="pt-6">
                                            <InputLabel id="transport-provider-label">Shipping</InputLabel>
                                            <Select
                                                labelId="transport-provider-label"
                                                id="transProvider"
                                                name="transProvider"
                                                label=""
                                                className="offerDialog-transportProviderSelect"
                                                items={transportProvider}
                                                onChange={handleChange}>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={6} className="pt-8">
                                            <Box className={classes.box}>
                                                <Checkbox
                                                    name="proxyBid"
                                                    text="Proxy Bid"
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        {values.proxyBid ?
                                            <>
                                                <Grid item xs={12} className={classes.bottomSpace}>
                                                    <TextField
                                                        name='proxyBidMax'
                                                        label='Bid Max'
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={classes.bottomSpace}>
                                                    <TextField
                                                        name='proxyBidIncrement'
                                                        label='Bid increment'
                                                        onChange={handleChange}
                                                        InputProps={{
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                            : ''
                                        }
                                    </StyledGrid>
                                    {placing.error && (
                                        <Grid item xs={12} className="pt-4">
                                            <Typography variant='body1' color='error'>Your bid was not processed so you need to bid again.</Typography>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Button
                                            type='submit'
                                            className={classes.btn}
                                        >
                                            PLACE BID
                                        </Button>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>

                    <Spinner show={savingBid || loadingShippingOptions} />
                </DialogContentText>
            </DialogContent>
        </StyledDialog>
    )
}

export default PlaceBidDialog;