import { createSlice } from '@reduxjs/toolkit'
import { formatDollarAmount } from "../utils/Formatters";

const initialState = {
    count: 0,
    vehicles: [],
    error: undefined,
    loadingShippingOptions: false,
    loadingSearchOptions: false,
    loadingConditionReport: undefined,
    savingConditionReport: undefined,
    searching: false,
    vehicleOption: [],
    modelOptions: [],
    trimOptions: [],
    vehicleDetail: {},
    quotes: [],
    quoteOptions: [],
    buying: '',
    categoryCount: [],
    conditionReport: {},
    updatedConditionReport: undefined,
    savedConditionReport: undefined,
    transactionStatus: [],
    offer: '',
    definitions: [],
    reportStatus: {},
    imgResponse: {},
    photoRes: {},
    validationParams: {},
    offerSent: '',
    buyNowSent: '',
    buyNowLoading: false,
    auctionStarted: '',
    errorMakeOffer: undefined,
    isCancelAuctionLoading:false,
    agingVehicles: [],
    isAgingVehiclesLoading: false
};

export const vehicleSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        startingSearch: (state, action) => {
            return {
                ...state,
                error: undefined,
                searching: true
            }
        },
        searchSuccess: (state, action) => {
            const oldVehicles = JSON.parse(JSON.stringify(state.vehicles));

            if (action.payload.mergeArray) {
                return {
                    ...state,
                    ...action.payload,
                    vehicles: [...oldVehicles, ...action.payload.vehicles],
                    searching: false
                }
            } else {
                return {
                    ...state,
                    ...action.payload,
                    searching: false
                }
            }

        },
        searchFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                searching: false
            }
        },
        loadingConditionReport: (state, action) => {
            return {
                ...state,
                updatedConditionReport: false,
                savedConditionReport: false,
                loadingConditionReport: true,
                conditionReport: {},
                conditionReportCreated: undefined,
                conditionReportUpdated: undefined
            }
        },
        loadingConditionReportSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingConditionReport: false,
                processing: false
            }
        },
        loadingConditionReportFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingConditionReport: false,
                conditionReport: {},
                conditionReportCreated: undefined
            }
        },
        loadingSearchOptions: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingSearchOptions: true
            }
        },
        loadingSearchOptionsSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingSearchOptions: false
            }
        },
        loadingSearchOptionsFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingSearchOptions: false
            }
        },
        loadingModelOptions: (state) => {
            return {
                ...state,
                loadingModelOptions: true
            }
        },
        loadingModelOptionsSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingModelOptions: false
            }
        },
        loadingModelOptionsFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingModelOptions: false
            }
        },
        loadingTrimOptions: (state) => {
            return {
                ...state,
                processing: false
            }
        },
        loadingTrimOptionsSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                processing: false
            }
        },
        loadingTrimOptionsFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                processing: false
            }
        },
        loadingVehicleDetails: (state) => {
            return {
                ...state,
                vehicleDetail: {},
                loadingVehicleDetails: true
            }
        },
        loadingVehicleDetailsSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingVehicleDetails: false
            }
        },
        loadingVehicleDetailsFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingVehicleDetails: false
            }
        },
        loadingShippingOptionsSuccess: (state, action) => {
            const { quotes: { defaultData, shippingData} } = action.payload;
            let quoteOptions = [];

            quoteOptions.push({
                value: `transport-reinvent-${defaultData?.shippingQuotes?.fee_name.toLowerCase()}`,
                label: `Reinvent - ${formatDollarAmount(defaultData?.shippingQuotes?.fee_amount)}`
            });

            if (shippingData?.shippingQuotes.length > 0) {
                shippingData?.shippingQuotes?.forEach(quote => {
                    quoteOptions.push({
                        value: `transport-reinvent-${quote.name.toLowerCase()}`,
                        label: `Reinvent ${quote.name} - ${formatDollarAmount(quote.fare)}`
                    });
                });
            }

            quoteOptions.push({
                value: 'transport-dealer',
                label: 'Dealer'
            });

            return {
                ...state,
                quoteOptions,
                loadingShippingOptions: false
            }
        },
        loadingShippingOptions: (state) => {
            return {
                ...state,
                loadingShippingOptions: true,
            }
        },
        loadingShippingOptionsFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingShippingOptions: false,
            }
        },
        loadingCategoryCount: (state) => {
            return {
                ...state,
                loadingCategoryCount: true
            }
        },
        loadingCategoryCountSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingCategoryCount: false
            }
        },
        loadingCategoryCountFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingCategoryCount: false
            }
        },
        loadingTransactionStatuses: (state) => {
            return {
                ...state,
                loadingTransactionStatuses: true
            }
        },
        loadingTransactionStatusesSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingTransactionStatuses: false
            }
        },
        loadingTransactionStatusesFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingTransactionStatuses: false
            }
        },
        submittingMakeOffer: (state) => {
            return {
                ...state,
                submittingMakeOffer: true,
                offerSent: undefined
            }
        },
        submittingMakeOfferSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                submittingMakeOffer: false
            }
        },
        submittingMakeOfferFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                submittingMakeOffer: false
            }
        },
        loadingConditionDefinitions: (state) => {
            return {
                ...state,
                loadingConditionDefinitions: true,
                conditionReport: {},
                conditionReportCreated: undefined,
                conditionReportUpdated: undefined
            }
        },
        loadingConditionDefinitionsSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingConditionDefinitions: false
            }
        },
        loadingConditionDefinitionsFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingConditionDefinitions: false,
                conditionReport: {},
                conditionReportCreated: undefined
            }
        },
        creatingConditionReport: (state) => {
            return {
                ...state,
                conditionReportCreated: undefined,
                creatingConditionReport: true,
                conditionReport: {}
            }
        },
        creatingConditionReportSuccess: (state, action) => {
            const { response, token } = action.payload;

            return {
                ...state,
                response,
                conditionReportCreated: token,
                creatingConditionReport: false
            }
        },
        creatingConditionReportFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                creatingConditionReport: false,
                conditionReport: {}
            }
        },
        updatingConditionReport: (state) => {
            return {
                ...state,
                conditionReportUpdated: undefined,
                updatingConditionReport: true
            }
        },
        updatingConditionReportSuccess: (state, action) => {
            const { response, token } = action.payload;

            return {
                ...state,
                response,
                conditionReportUpdated: token,
                updatingConditionReport: false
            }
        },
        updatingConditionReportFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                updatingConditionReport: false
            }
        },
        conditionReportUpdated: (state, action) => {
            const { response, token } = action.payload;
            return {
                ...state,
                response,
                conditionReportUpdated: token,
                processing: false
            }
        },
        deleteConditionPhoto: (state) => {
            return {
                ...state,
                deletingConditionPhoto: true
            }
        },
        deleteConditionPhotoSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                deletingConditionPhoto: true
            }
        },
        deleteConditionPhotoFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                deletingConditionPhoto: true
            }
        },
        uploadImage: (state, action) => {
            return {
                ...state,
                ...action.payload,
                processing: false
            }
        },
        uploadPhotos: (state, action) => {
            return {
                ...state,
                ...action.payload,
                processing: false
            }
        },
        loadAuctionValidation: (state) => {
            return {
                ...state,
                loadingAuctionValidation: true
            }
        },
        loadAuctionValidationSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingAuctionValidation: false
            }
        },
        loadAuctionValidationFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                loadingAuctionValidation: false
            }
        },
        savingAuction: (state) => {
            return {
                ...state,
                processing: true
            }
        },
        savingAuctionSuccess: (state, action) => {
            return {
                ...state,
                ...action.payload,
                processing: false
            }
        },
        savingAuctionFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                processing: false
            }
        },
        buyingNowStart: (state, action) => {
            return {
                ...state,
                buyNowLoading: true
            }
        },
        buyingNow: (state, action) => {
            return {
                ...state,
                ...action.payload,
                buyNowLoading: false
            }
        },
        buyingNowFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                buyNowLoading: false
            }
        },
        cancelAuctionStart: (state, action) => {
            return {
              ...state,
              isCancelAuctionLoading: true,
            };
        },
        cancelAuctionSuccess: (state,action) => {
            return {
              ...state,
              ...action.payload,
              isCancelAuctionLoading: false,
            };
        },
        cancelAuctionFailed: (state, action) => {
            return {
              ...state,
              ...action.payload,
              isCancelAuctionLoading: false,
            };
        },
        loadingAgingVehicles: (state, action) => {
            return {
                ...state,
                agingVehicles: [],
                isAgingVehiclesLoading: true,

            }
        },
        loadedAgingVehicles: (state, action) => {
            return {
                ...state,
                ...action.payload,
                isAgingVehiclesLoading: false,
            }
        },
        agingVehiclesFailed: (state, action) => {
            return {
                ...state,
                ...action.payload,
                isAgingVehiclesLoading: false
            }
        },

        clearAgingVehicles: (state, action) => {
            return {
                ...state,
                agingVehicles: [],
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const {
    searchSuccess,
    startingSearch,
    searchFailed,
    loadingSearchOptions,
    loadingSearchOptionsSuccess,
    loadingSearchOptionsFailed,
    loadingModelOptions,
    loadingModelOptionsSuccess,
    loadingModelOptionsFailed,
    loadingTrimOptions,
    loadingTrimOptionsSuccess,
    loadingTrimOptionsFailed,
    loadingVehicleDetails,
    loadingVehicleDetailsSuccess,
    loadingVehicleDetailsFailed,
    loadingShippingOptions,
    loadingShippingOptionsFailed,
    loadingShippingOptionsSuccess,
    loadingCategoryCount,
    loadingCategoryCountSuccess,
    loadingCategoryCountFailed,
    loadingTransactionStatuses,
    loadingTransactionStatusesSuccess,
    loadingTransactionStatusesFailed,
    submittingMakeOffer,
    submittingMakeOfferSuccess,
    submittingMakeOfferFailed,
    loadingConditionDefinitions,
    loadingConditionDefinitionsSuccess,
    loadingConditionDefinitionsFailed,
    creatingConditionReport,
    creatingConditionReportSuccess,
    creatingConditionReportFailed,
    updatingConditionReport,
    updatingConditionReportSuccess,
    updatingConditionReportFailed,
    deleteConditionPhoto,
    deleteConditionPhotoFailed,
    deleteConditionPhotoSuccess,
    loadAuctionValidation,
    loadAuctionValidationSuccess,
    loadAuctionValidationFailed,
    uploadPhotos,
    savingAuction,
    savingAuctionSuccess,
    savingAuctionFailed,
    loadingConditionReport,
    loadingConditionReportSuccess,
    loadingConditionReportFailed,
    buyingNowStart,
    buyingNow,
    buyingNowFailed,
    cancelAuctionStart,
    cancelAuctionSuccess,
    cancelAuctionFailed,
    loadingAgingVehicles,
    loadedAgingVehicles,
    agingVehiclesFailed,
    clearAgingVehicles
} = vehicleSlice.actions

export default vehicleSlice.reducer