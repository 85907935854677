import React, { forwardRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "components/widgets/TextField";
import RadioControl from "components/widgets/RadioControl";
import UploadFiles from "components/widgets/UploadFiles";

const ConditionReportFields = forwardRef(({ definition, forwardedRef }) => {
  const items = definition.options.map(option => {
    return {
      label: option.name,
      value: option.name,
    }
  });
  
  return (
    <Card key={`condition-${definition.id}`} className="mt-5 condition-card" ref={forwardedRef}>
      <CardContent>
        <Box className="mt-2">
          <RadioControl
            label={definition.name}
            name={`option-${definition.id}`}
            description={`Please select condition of ${definition?.name} (Select One)`}
            items={items}
          />

          <Box className="mt-5">
            <Typography variant="h6">Notes:</Typography>
            <Box>
              <TextField
                name={`notes-${definition.id}`}
                label={`Enter notes regarding the condition of the ${definition?.name} (Optional)`}
                multiline
                rows={6}
                variant="outlined"
                className="multiline"
              />
            </Box>
          </Box>
          <Box className="mt-5 mb-2">
            <Typography variant="h6">Upload Photos</Typography>
            <UploadFiles name={`files-${definition.id}`} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
});

export default ConditionReportFields;