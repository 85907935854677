import React from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';

export default function MaskedInputField({
  label,
  labelHint,
  error,
  helperText,
  mask,
  innerLabel,
  ...props
}) {
  const [field] = useField(props);

  return (
    <div>
      {!innerLabel && (
        <Typography variant="body2" style={props.style || {}}>
          {label}
          {labelHint && (
            <span style={{ fontWeight: 'lighter' }}>
              &nbsp;&nbsp;{labelHint}
            </span>
          )}
        </Typography>
      )}
      <InputMask {...field} {...props} mask={mask}>
        {(inputProps) => (
          <TextField
            fullWidth
            disabled={props.disabled}
            variant="outlined"
            margin="dense"
            size="small"
            error={error}
            helperText={helperText}
            {...inputProps}
            label={innerLabel && label}
          />
        )}
      </InputMask>
    </div>
  );
}
