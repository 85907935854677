import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import Header from './components/Header';
import Welcome from './components/Welcome';
import FeaturePanel from './components/FeaturePanel';
import DealerDrivenPanel from './components/DealerDrivenPanel';
import GetConnected from './components/getConnected';
import Testimonials from './components/Testimonials';
import DealerPanel from './components/DealerPanel';
import RightVehiclePanel from './components/RightVehiclePanel';
import WorksPanel from './components/WorksPanel';
import NewsPanel from './components/NewsPanel';
import VehicleCategoryPanel from './components/VehicleCategoryPanel';
import ExchangeStoryPanel from './components/ExchangeStoryPanel';
import TeamPanel from './components/TeamPanel';
import Footer from './components/Footer';
import Box from '@mui/material/Box';
import Image from 'components/widgets/Image';
import styled from "@emotion/styled";

const PREFIX = 'FrontPage';

const classes = {
  body: `${PREFIX}-body`,
  headerContainer: `${PREFIX}-headerContainer`,
}

const StyledGrid = styled(Grid)((
    {
      theme
    }
) => {
  return {
    [`& .${classes.body}`]: {
      [theme.breakpoints.up('xs')]: {
        marginTop: 200,
        padding: '0 10px',
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 400,
        padding: '0 10px',
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: 85,
        padding: '0 10px',
      },
      [theme.breakpoints.up('xl')]: {
        marginTop: 95,
        padding: '0 200px',
      },
    },
    [`& .${classes.headerContainer}`]: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 1,
    },
  }
});

const FrontPage = () => {
  const headerRef = useRef(null);
  const homeRef = useRef(null);
  const howRef = useRef(null);
  const newsRef = useRef(null);
  const contactUsRef = useRef(null);
  const aboutUsRef = useRef(null);

  const navigateToSection = (menuItem) => {
    switch (menuItem) {
      case 'home':
        homeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'how':
        howRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'news':
        newsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'aboutus':
        aboutUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'contactus':
        contactUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      default:
        break;
    }
  }

  return (
    <StyledGrid container>
      <Grid item xs={12} ref={headerRef} className={classes.headerContainer}>
        <Header nav={navigateToSection} />
      </Grid>
      <Grid item xs={12} className={classes.body}>
        <Grid container>
          <Grid item xs={12} ref={homeRef}>
            <Welcome />
          </Grid>
          <Grid item xs={12}>
            <FeaturePanel />
          </Grid>
          <Grid item xs={12}>
            <DealerDrivenPanel />
          </Grid>
          <Grid item xs={12}>
            <RightVehiclePanel />
          </Grid>
          <Grid item xs={12}>
            <GetConnected />
          </Grid>
          <Grid item xs={12}>
            <Testimonials />
          </Grid>
          <Grid item xs={12}>
            <DealerPanel />
          </Grid>
          <Grid item xs={12} ref={howRef} >
            <WorksPanel />
          </Grid>
          <Grid item xs={12} ref={newsRef} >
            <NewsPanel />
          </Grid>
          <Grid item xs={12}>
            <VehicleCategoryPanel />
          </Grid>
          <Grid item xs={12} ref={aboutUsRef} >
            <ExchangeStoryPanel />
          </Grid>
          <Grid item xs={12}>
            <TeamPanel />
          </Grid>
          <Grid item xs={12} ref={contactUsRef}>
            <Footer nav={navigateToSection} />
          </Grid>
          <Box className='floating'>
            <Link onClick={
              () => navigateToSection('home')
            }>
              <Image src="/static/images/Floating.png" />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </StyledGrid>
  )
}

export default FrontPage;