import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "components/widgets/Button";
import Spinner from "components/Spinner";
import DataTable from "components/widgets/DataTable";
import LocationForm from "./components/LocationForm";
import {loadDetailedLocations, updateLocation, addLocation} from "data/location.actions";
import {hasRooftopPermission} from "utils/User";

const PREFIX = 'LocationManagementView';

const classes = {
    panel: `${PREFIX}-panel`,
    button: `${PREFIX}-button`,
    tableContainer: `${PREFIX}-table`,
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
)=> {
    return {
        [`& .${classes.panel}`]: {
            marginTop: 30,
            padding: 30,
            borderRadius: 20,
            background: theme.palette.secondary.grey
        },
        [`& .${classes.button}`]: {
            float: 'right',
        },
        [`& .${classes.tableContainer}`]: {
            marginTop: 30,
        },
    }});

const tableColumns = [
    {
        name: 'dealerRooftopId',
        options: {
            display: 'excluded',
            filter: false,
        }
    },
    {
        label: 'Name',
        name: 'rooftopName',
        options: {
            customBodyRender: (value, tableMeta) => {
                const id = tableMeta.rowData[0];
                return (
                    <Link className={classes.link} to={`/rooftops/edit/${id}`}>
                        <Typography variant='body1'>{value}</Typography>
                    </Link>
                )
            }
        }
    },
    {
        label: 'Address1',
        name: 'address.address1'
    },
    {
        label: 'Address2',
        name: 'address.address2'
    },
    {
        label: 'City',
        name: 'address.city'
    },
    {
        label: 'State',
        name: 'address.state'
    },
    {
        label: 'Zip',
        name: 'address.postalCode'
    },
];

const LocationManagementView = () => {
    const { action, locationId } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.authentication);
    const { processing: processingLocations, detailedLocations, error } = useSelector(state => state.location);
    let permittedDetailedRooftops

    if (user && detailedLocations) {
        if(user.isDealerAdmin){
            permittedDetailedRooftops = detailedLocations
        } else {
            permittedDetailedRooftops = detailedLocations.filter((location) => hasRooftopPermission(user, location.dealerRooftopId, 'add_user'))
        }
    }

    useEffect(() => {
        if (user) {
            dispatch(loadDetailedLocations({
                dealerId: parseInt(user.dealer_id)
            }))
        }
    }, []);

    useEffect(() => {
        if( user && !locationId && detailedLocations === undefined) {
            dispatch(loadDetailedLocations({
                dealerId: parseInt(user.dealer_id)
            }))
        }
    }, [dispatch, user, detailedLocations]);

    const handleUpdateLocation = (values) => {
        const newValues = {
            ...values,
            ...values.address,
            dealerId: parseInt(user.dealer_id)
        };

        dispatch(updateLocation(newValues));

        navigate('/rooftops');
    }
    
    const handleAddLocation = (values) => {
        const newValues = {
            address1: values.address.address1,
            address2: values.address.address2,
            city: values.address.city,
            // country: values.address.country,
            phone: values.phone,
            postalCode: values.address.postalCode,
            rooftopName: values.rooftopName,
            state: values.address.state,
            dealerId: parseInt(user.dealer_id)
        };
        
        dispatch(addLocation(newValues));
    
        navigate('/rooftops');
    }

    return (
        <StyledGrid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        {action === 'edit' &&
                        <Typography variant='h3'>Edit Rooftop</Typography>
                        }
                        {action === 'add' &&
                        <Typography variant='h3'>Add Rooftop</Typography>
                        }
                        {action === undefined &&
                        <Typography variant='h3'>Rooftop Management</Typography>
                        }
                    </Grid>

                    {locationId === undefined && user && user.isDealerAdmin &&
                        <Grid item xs={6}>
                            <Button className={classes.button} onClick={() => navigate('/rooftops/add')}>Add New Rooftop</Button>
                        </Grid>
                    }
                </Grid>
            </Grid>

            {locationId === undefined && action !== 'add' &&
            <Grid item xs={12} className={classes.tableContainer}>
                <DataTable
                    data={permittedDetailedRooftops}
                    columns={tableColumns}
                    options={{
                        selectableRows: 'none',
                        selectableRowsHideCheckboxes: true,
                        selectableRowsHeader: false
                    }}
                />
            </Grid>
            }

            {locationId !== undefined &&
            <Grid item xs={12} className={classes.tableContainer}>
                <LocationForm
                    locationId={parseInt(locationId)}
                    handleUpdateLocation={handleUpdateLocation}
                />
            </Grid>
            }

            {action === 'add' &&
            <Grid item xs={12} className={classes.tableContainer}>
                <LocationForm
                    dealerId={user?.dealer_id}
                    handleUpdateLocation={handleAddLocation}
                />
            </Grid>
            }

            <Typography variant='h6' color='error'>{error}</Typography>
            <Spinner show={processingLocations} />
        </StyledGrid>
    )
}

export default LocationManagementView;